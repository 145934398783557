import React, { useRef, useState, useEffect } from 'react';
import Topbar from './Topbar';
import mainLogo from '../../assets/images/header/mainLogo.svg';

import { ReactComponent as NotficationIcon } from '../../assets/images/Icons/notificationIcon.svg';
import { ReactComponent as CartIcon } from '../../assets/images/Icons/cartIcon.svg';

import { toast } from 'react-toastify';
// import wishlistIcon from '../../assets/images/Icons/wishlistIcon.svg';
import { ReactComponent as WishlistIcon } from '../../assets/images/Icons/wishlistIcon.svg';
import { ReactComponent as Exclusive_Icon16 } from '../../assets/images/contributor/add_details/Icon_Exclusive_16x16.svg';

import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';
import searchIcon from '../../assets/images/Icons/searchDarkIcon.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/Icons/searchDarkIcon.svg';

import { ReactComponent as ImageIcon } from '../../assets/images/Icons/pictureIcon.svg';
import userIcon from '../../assets/images/Icons/userIcon.svg';
import '../../../src/App.css';
// dropdown
import { ReactComponent as Location } from '../../assets/images/contributor/Icon_Location_6x8.svg';
import locatiomIcon from '../../assets/images/art-details/Icon - Location Pointer.svg';

import { ReactComponent as ArtIcon } from '../../assets/images/Icons/artIcon.svg';
import { ReactComponent as PhotoIcon } from '../../assets/images/Icons/photosIcon.svg';
import { ReactComponent as FootageIcon } from '../../assets/images/Icons/videoIcon.svg';
import { ReactComponent as MusicIcon } from '../../assets/images/Icons/music.svg';
import { ReactComponent as TemplatesIcon } from '../../assets/images/Icons/templatesIcon.svg';
import { ReactComponent as ProductsIcon } from '../../assets/images/Icons/productsIcon.svg';

import { ReactComponent as MenuIcon } from '../../assets/images/header/mobile/menuIcon.svg';

import { setNestedTabValueUpload } from '../../store/nestedTabSlice';
import { setNestedTabValueReleases } from '../../store/nestedTabSlice';
import { setNestedTabValueEarnings } from '../../store/nestedTabSlice';

import { setpath2 } from '../../store/contriPathSlice';

import { popupSliceAction } from '../../store/popupSlice';

import {
  Link,
  NavLink,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authSliceAction } from '../../store/authSlice';
import crossIcon from '../../assets/images/Icons/crossIcon.svg';

import searchThumb from '../../assets/images/static/searchThumb1.jpg';

import Popup from 'reactjs-popup';
import styled from 'styled-components';

import artDropImg from '../../assets/images/static/Layer2643.png';
import Animals from '../../assets/images/static/Animals.png';
import Architecture from '../../assets/images/static/Architecture.png';
import Botanical from '../../assets/images/static/Botanical.png';
import People from '../../assets/images/static/People.png';
import { httpClient } from '../../axios';

// Product
import comboPack from '../../assets/images/static/Icon - Combo Pack.png';
import customPack from '../../assets/images/static/Icon - Custom Pack.png';

// Profile Click
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import profile from '../../assets/images/Menubar/Profile.png';
import locationIcon from '../../assets/images/Menubar/location.png';

// Notification Click
import combo from '../../assets/images/Menubar/combo.png';
import mug from '../../assets/images/Menubar/mug.png';

// top Loading bar

import LoadingBar from 'react-top-loading-bar';
import { searchSliceAction } from '../../store/searchSlice';

import { styleSliceAction } from '../../store/styleSlice';
import { setSubjectId } from '../../store/subjectidSlice';

import { useDetectClickOutside } from 'react-detect-click-outside';
import { cartSliceAction } from '../../store/cartSlice';

import { wishlistSliceAction } from '../../store/wishlistSlice';

const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px;
    width: 43.125rem;
    height: 25.5rem;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  }
`;

const styles = {
  normalIp:
    'bg-[#EEEEEE] w-[100%] outline-0  px-[24px]  placeholder:font-normal rounded-tl-[1.5rem] rounded-bl-[1.5rem] border-r-2 border-[#DADADA]',
  activeIp:
    'bg-[#ffffff] rounded-tl-[1.5rem]  px-[24px] rounded-tr-[1.5rem] outline-none border border-[#E9E9E9] border-b-0 w-[100%] ',
};

const Mainbar = () => {
  const userAuth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDrop, setShowDrop] = useState(false);
  const ref = useRef(null);
  const [selectItems, setSelectItems] = useState('Art');

  const [dropArrowColor, setDropArrowColor] = useState('#878787');

  const [imageIconColor, setImageIconColor] = useState('#888888');

  const apps1 = () => {
    setShowDrop(false);
  };

  const ref1 = useDetectClickOutside({ onTriggered: apps1 });

  //loading bar
  const [progress, setProgress] = useState(0);

  const location = useLocation();

  const userDetails = useSelector((state) => state.auth.userDetails);

  const userRole = useSelector((state) => state.auth.userRole);

  const userId = useSelector((state) => state.auth.userId);

  const searchText = useSelector(
    (state) => state.searchText.searchText
  );

  const [activeLink, setActiveLink] = useState(
    location.pathname === '/'
      ? 'home'
      : location.pathname === '/art-home'
      ? 'Art'
      : location.pathname === '/photo-home'
      ? 'Photos'
      : location.pathname === '/footage-home'
      ? 'Footage'
      : location.pathname === '/music-home'
      ? 'Music'
      : location.pathname === '/templates-home'
      ? 'Templates'
      : location.pathname === '/products-home'
      ? 'Products'
      : location.pathname === '/blog-home'
      ? 'Blogs'
      : 'home'
  );

  // console.log(activeLink);
  const pathh = (val) => {
    navigate('/tabs', {
      state: val,
    });
  };

  const pathhcust = (val) => {
    navigate('/customer-tabs', {
      state: val,
    });
  };

  const logout = async () => {
    // dispatch(setUserRole(''));
    try {
      const res = await httpClient.get(
        `/user_master/logout/${userId}`
      );
      console.log(res.data);
      dispatch(authSliceAction.logoutUser());
      navigate('/');
      dispatch(popupSliceAction.setBackgroundColor('bg-pinkColor'));
      dispatch(
        popupSliceAction.setTitle1('User Successfully logged out.')
      );
      dispatch(popupSliceAction.setTitle2(''));
      dispatch(popupSliceAction.setOpenPopup());
      dispatch(popupSliceAction.setAnimationOpen());
      // toast.success('User Successfully logged out');
    } catch (error) {
      console.log(error);
    }
  };

  const changeNavigation = (nav) => {
    // console.log(nav);
    if (nav === 'home') {
      navigate('/');
      setActiveLink('home');
    }
  };

  const [searchedArtList, setsearchedArtList] = useState('');
  const [searchKeywords, setSearchKeywords] = useState();
  // const [keyword, setKeyword] = useState("")
  const [recentlyViewd, setRecentlyViewd] = useState();

  const changeinput = (e) => {
    // if (e.code == 'Space') {
    //   console.log('Space Bar Pressed!');
    //   // You can replace the alert with your custom function here
    // }

    setsearchedArtList(e.target.value);
    // console.log(event.target.code);
  };

  const removeArt = () => {
    setsearchedArtList('');
    dispatch(searchSliceAction.setSearchText(''));
  };

  useEffect(() => {
    location.pathname !== '/search' && removeArt();
  }, [location]);

  // Search Art
  useEffect(() => {
    if (location.pathname === '/search') {
      if (searchText.length > 0) {
        setsearchedArtList(searchText);
      }
    }
    getRecentSearch();
    getSearchKeyword();
    getRecentlyViwedArt();
  }, [searchText]);

  useEffect(() => {
    getSearchKeyword();
  }, [searchedArtList]);

  const getSearchKeyword = () => {
    // console.log(searchedArtList);
    if (searchedArtList === null || searchedArtList.length === 0) {
      httpClient
        .get('/art_master/getKeywordMasterList')
        .then((res) => {
          // console.log(res);
          setSearchKeywords(res?.data);
        });
    } else {
      httpClient
        .get(
          `/art_master/searchKeywordCountMaster/${searchedArtList}`
        )
        .then((res) => {
          // console.log(res);
          setSearchKeywords(res?.data);
        });
    }
  };

  useEffect(() => {
    getRecentlyViwedArt();
  }, [location.pathname]);

  const getRecentlyViwedArt = () => {
    if (userAuth.login) {
      httpClient
        .get(
          `/recently_view_master/getUserIdWiseRecentlyViewMaster/${userDetails?.userId}`
        )
        .then((res) => {
          console.log(res.data);
          setRecentlyViewd(res.data);
        });
    }
  };

  const searchKeyword = (keyword) => {
    getRecentSearch();
    apps1();
    navigate('/search');
    dispatch(searchSliceAction.setSearchType('keyword'));
    dispatch(searchSliceAction.setSearchText(keyword));
  };

  const searchRecentArt = (text) => {
    getRecentSearch();
    navigate('/search');
    apps1();
    dispatch(searchSliceAction.setSearchType('normal'));
    dispatch(searchSliceAction.setSearchText(text));
    dispatch(styleSliceAction.setStyle(null));
  };

  const searchArts = () => {
    getRecentSearch();
    navigate('/search');
    apps1();
    dispatch(searchSliceAction.setSearchType('normal'));
    dispatch(searchSliceAction.setSearchText(searchedArtList));
    dispatch(styleSliceAction.setStyle(null));
  };

  const [artDropdownList, setartDropdownList] = useState([]);
  const getArtDropdownTrue = () => {
    httpClient.get(`/style_master/getArtDropdownTrue`).then((res) => {
      setartDropdownList(res.data);
      // console.log(res.data);
    });
  };

  useEffect(() => {
    setProgress(100);
    getArtDropdownTrue();
    getAllActiveSubjects();
    getWishlistCount();
    getAllWishlistByUserId();
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      const res = await httpClient.get(
        `/user_master/getUserRecord/${userId}`
      );
      console.log(res.data);
      dispatch(authSliceAction.setUserDetails(res.data));
    } catch (error) {
      console.error(error);
    }
  };

  // Get Cart Count
  const cartCount = useSelector((state) => state.cart.cartCount);

  const [cartQuantity, setCartQuantity] = useState(0);

  useEffect(() => {
    getCartQuantity();
    // console.log(cartCount);
  }, [cartCount]);

  // const [wishListCount, setwishListCount] = useState(0);
  const wishListCount = useSelector(
    (state) => state.wishlist.wishlistCount
  );

  useEffect(() => {
    console.log(wishListCount);
  }, []);

  const [wishlist, setwishlist] = useState([]);

  const getAllWishlistByUserId = async () => {
    if (userAuth.login) {
      try {
        const res = await httpClient.get(
          `/wishlist_master/getByUserIdList/${userId}`
        );
        setwishlist(res.data);
        console.log(res.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getWishlistCount = async () => {
    if (userAuth.login) {
      try {
        const res = await httpClient.get(
          `/wishlist_master/getUserIdWiseWishListCount /${userId}`
        );
        console.log(res.data);

        dispatch(wishlistSliceAction.setWishlistCount(res.data));
        // setwishListCount(res.data);
      } catch (error) {
        console.log(error);
        dispatch(wishlistSliceAction.setWishlistCount(0));
      }
    }
  };

  // useEffect(() => {
  //   getWishlistCount();
  // }, []);

  const getCartQuantity = () => {
    if (userAuth.login) {
      try {
        httpClient
          .get(
            `/cart_master/UserWiseGetTotalCount/${userDetails?.userId}`
          )
          .then((res) => {
            console.log(res);
            setCartQuantity(res?.data?.totalCount);
            dispatch(
              cartSliceAction.setCartCount(res?.data?.totalCount)
            );
            // dispatch(styleSliceAction.setStyle(val));
          });
      } catch (err) {
        console.log(err);
        setCartQuantity(0);
        dispatch(cartSliceAction.setCartCount(0));
      }
    }
  };

  useEffect(() => {
    location.pathname === '/' && setSelectItems('Art');
    location.pathname === '/art-home' && setSelectItems('Art');
    location.pathname === '/photo-home' && setSelectItems('Photos');
    location.pathname === '/footage-home' &&
      setSelectItems('Footage');
    location.pathname === '/music-home' && setSelectItems('Music');
    location.pathname === '/templates-home' &&
      setSelectItems('Templates');
    location.pathname === '/product-home' &&
      setSelectItems('Products');
  }, [location]);

  const setStyle = (val) => {
    dispatch(styleSliceAction.setStyle(val));
    navigate('/search');
    dispatch(searchSliceAction.setSearchText(''));
  };

  // Recently search
  const [recentSearch, setRecentSearch] = useState([]);

  const getRecentSearch = () => {
    try {
      httpClient
        .get(
          `/recently_search_master/getUserIdWiseRecentlyKeywordSearch/${userDetails?.userId}`
        )
        .then((res) => {
          // console.log(res.data);
          setRecentSearch(res.data);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const cleaRecentSearch = (userId) => {
    // console.log(userId);
    try {
      httpClient
        .delete(`/recently_search_master/deleteCart/${userId}`)
        .then((res) => {
          // console.log(res);
          getRecentSearch();
          // console.log(res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const goToArtDetailsPage = (id) => {
    apps1();
    navigate(`/art/art-details`, { state: { id } });
  };

  const clearRecentSearch = (userId) => {
    try {
      httpClient
        .delete(`/recently_view_master/deleteByUserId/${userId}`)
        .then((res) => {
          // console.log(res);
          getRecentlyViwedArt();
          // console.log(res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const [WishIconHoverColor, setWishIconHoverColor] =
    useState('#888888');
  const [NotficationIconHoverColor, setNotficationIconHoverColor] =
    useState('#888888');

  const [DropArtColor, setDropArtColor] = useState('#888888');
  const [DropPhotosColor, setDropPhotosColor] = useState('#888888');
  const [DropFootageColor, setDropFootageColor] = useState('#888888');
  const [DropMusicColor, setDropMusicColor] = useState('#888888');
  const [DropTemplatesColor, setDropTemplatesColor] =
    useState('#888888');
  const [DropProductColor, setDropProductColor] = useState('#888888');

  const [isProfileOpen, setProfileOpen] = useState(false);
  const [isNotificationMenuOpen, setNotificationIsMenuOpen] =
    useState(false);
  const [isWishlistMenuOpen, setWishlistIsMenuOpen] = useState(false);
  const [isCartMenuOpen, setCartIsMenuOpen] = useState(false);

  // for random subjects

  const getRandomObjects = (objects, count) => {
    const shuffledArray = objects.sort(() => 0.5 - Math.random());
    const selectedObjects = shuffledArray.slice(0, count);
    return selectedObjects;
  };

  const [subjects, setSubjects] = useState({
    sub1: [],
    sub2: [],
    sub3: [],
    sub4: [],
    sub5: [],
    sub6: [],
  });

  const getAllActiveSubjects = async () => {
    try {
      const res = await httpClient.get(
        '/subject_master/getActiveSubject'
      );

      for (let i = 1; i < 7; i++) {
        const randomObjects = getRandomObjects(res.data, 5);
        if (i == 1) {
          setSubjects((prevObj) => ({
            ...prevObj,
            sub1: randomObjects,
          }));
        } else if (i == 2) {
          setSubjects((prevObj) => ({
            ...prevObj,
            sub2: randomObjects,
          }));
        } else if (i == 3) {
          setSubjects((prevObj) => ({
            ...prevObj,
            sub3: randomObjects,
          }));
        } else if (i == 4) {
          setSubjects((prevObj) => ({
            ...prevObj,
            sub4: randomObjects,
          }));
        } else if (i == 5) {
          setSubjects((prevObj) => ({
            ...prevObj,
            sub5: randomObjects,
          }));
        } else if (i == 6) {
          setSubjects((prevObj) => ({
            ...prevObj,
            sub6: randomObjects,
          }));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleKeyOnSearch = (event) => {
    if (event.keyCode === 32) {
      setShowDrop(false);
    }
  };

  // useEffect(() => {
  //   console.log(subjects);
  // }, [subjects]);

  const [artIconColor, setartIconColor] = useState('#888888');
  const [photosIconColor, setphotosIconColor] = useState('#888888');
  const [footageIconColor, setfootageIconColor] = useState('#888888');
  const [musicIconColor, setmusicIconColor] = useState('#888888');
  const [templatesIconColor, settemplatesIconColor] =
    useState('#888888');
  const [productsIconColor, setproductsIconColor] =
    useState('#888888');

  useEffect(() => {
    console.log('login :', userAuth.login);
  }, []);

  // code for mobile

  const [isXS, setIsXS] = useState(false);
  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line no-restricted-globals
      if (screen.width < 640) {
        // eslint-disable-next-line no-restricted-globals
        setIsXS(screen.availWidth < 640); // Adjust the threshold as needed
      } else {
        setIsXS(window.innerWidth < 640); // Adjust the threshold as needed
      }
    }

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // code for mobile end

  const ProfileDropdownClickOutside = useRef(null);

  const handleClickOutsideProfileDropdown = (event) => {
    if (
      ProfileDropdownClickOutside.current &&
      !ProfileDropdownClickOutside.current.contains(event.target)
    ) {
      setProfileOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutsideProfileDropdown
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideProfileDropdown
      );
    };
  }, []);

  return (
    <>
      {/* loading bar */}
      <LoadingBar
        color='linear-gradient(90deg, rgba(220,76,154,1) 0%, rgba(149,147,205,1) 35%, rgba(132,212,156,1) 65%, rgba(204,236,59,1) 100%)'
        progress={progress}
        onLoaderFinished={() => setProgress(100)}
      />

      <Topbar />

      {/*/ middle section*********************************************************************************** */}

      {isXS ? (
        <>
          <div className='px-[24px] pb-[11px] pt-[11px]'>
            <div className='flex items-center justify-between relative'>
              <MenuIcon />
              <div>
                <img
                  className='relative bottom-[3px] cursor-pointer'
                  onClick={() => changeNavigation('home')}
                  src={mainLogo}
                  alt=''
                />
              </div>

              <div className='flex '>
                <li
                  className={`inline-block ${
                    wishListCount > 0 ? 'mr-[30px]' : 'mr-[16px]'
                  } `}
                >
                  {/* <img
                    onClick={() => {
                      navigate('/wishlist');
                    }}
                    src={wishlistIcon}
                    alt=''
                  /> */}

                  <Menu
                    as='div'
                    onMouseEnter={() => {
                      setWishlistIsMenuOpen(true);
                    }}
                    onMouseLeave={() => {
                      setWishlistIsMenuOpen(false);
                    }}
                  >
                    <NavLink
                      style={{
                        paddingBottom: '10px',
                        cursor: 'default',
                      }}
                    >
                      <Menu.Button className='inline-flex w-full justify-center rounded-md bg-black bg-opacity-20 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
                        {/* <img src={notficationIcon} alt='' /> */}

                        <div
                          onClick={() => {
                            navigate('/wishlist');
                            // console.log('Wishlist');
                            // setWishlistIsMenuOpen(true);
                          }}
                          className='relative'
                        >
                          <WishlistIcon
                            onMouseEnter={() => {
                              setWishIconHoverColor('#333333');
                            }}
                            onMouseLeave={() => {
                              setWishIconHoverColor('#888888');
                            }}
                            style={{
                              fill: WishIconHoverColor,
                              marginTop: '2px',
                              position: 'relative',
                              top: '2px',
                            }}
                          />

                          <div className='mr-[16px]'>
                            {wishListCount > 0 ? (
                              <div className='absolute bottom-[-2px] right-[-18px] bg-[#FF369F] text-[#ffffff] text-[11px]  border border-[2px] border-[#ffffff] font-medium w-[24px] h-[18px] rounded-[5.32813rem] flex justify-center items-center'>
                                <span className='relative top-[0.9px] leading-[1]'>
                                  {wishListCount}
                                </span>
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                      </Menu.Button>
                    </NavLink>

                    <Transition
                      as={Fragment}
                      // show={true}
                      show={isWishlistMenuOpen}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      {wishListCount > 0 ? (
                        <Menu.Items className='absolute right-14 mt-[11px] px-[20px] w-w350 z-[99] origin-top-right  rounded-[16px] bg-[#ffffff] shadow-dropShadoww focus:outline-none'>
                          <Menu.Item
                            style={{
                              borderBottom: '1px solid #efefef',
                            }}
                            className='px-[14px] text-[12px] mt-[20px] text-center pb-[10px] text-[#757575]'
                          >
                            <p className='text-[#dc399b]'>
                              Recently Added
                            </p>
                          </Menu.Item>

                          {wishlist?.slice(0, 2).map((obj) => (
                            <>
                              {obj.artMaster !== null ? (
                                <div
                                  style={{
                                    borderBottom: '1px solid #efefef',
                                  }}
                                  className='flex justify-between py-[11px]'
                                >
                                  <div className='flex gap-2'>
                                    <div
                                      style={{
                                        backgroundImage: `url(${obj.artMaster?.imageMaster?.secureUrl})`,
                                      }}
                                      className='min-w-[80px] w-[80px] h-[80px] bg-cover rounded-[8px]'
                                    ></div>

                                    {/* <img src={obj} alt='' /> */}
                                    <div>
                                      <p className='text-[15px] font-medium leading-[19px] text-[#333333]'>
                                        {obj.artMaster?.artName
                                          .length > 49 ? (
                                          <p
                                          // style={{
                                          //   whiteSpace: 'nowrap',
                                          // }}
                                          >
                                            {obj.artMaster?.artName.slice(
                                              0,
                                              48
                                            )}
                                            <span>...</span>
                                          </p>
                                        ) : (
                                          <div>
                                            {obj.artMaster?.artName}
                                          </div>
                                        )}
                                        {/* <p>{obj.artMaster?.artName}</p> */}
                                        {/* Office Merchandise Combo */}
                                      </p>
                                      <p className='text-[11px] text-[#757575] leading-[14px] pt-1 w-[170px]'>
                                        {/* {obj.artMaster?.description} */}
                                        {/* International artist Azra's work{' '}
                                        <br /> dictates the expression */}

                                        {obj.artMaster?.description
                                          .length > 69 ? (
                                          <p
                                          // style={{
                                          //   whiteSpace: 'nowrap',
                                          // }}
                                          >
                                            {obj.artMaster?.description.slice(
                                              0,
                                              68
                                            )}
                                            <span>...</span>
                                          </p>
                                        ) : (
                                          <div>
                                            {
                                              obj.artMaster
                                                ?.description
                                            }
                                          </div>
                                        )}
                                      </p>

                                      {/* <p className='text-[13px] text-primaryBlack font-medium'>
                                  Quantity:{' '}
                                  <span className='font-normal'>1</span>
                                </p> */}
                                    </div>
                                  </div>
                                  <p className='text-[15px] text-orangeColor'>
                                    ${obj.artMaster.price}
                                    {/* $400.00 */}
                                  </p>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    borderBottom: '1px solid #efefef',
                                  }}
                                  className='flex justify-between py-[11px]'
                                >
                                  <div className='flex gap-2'>
                                    <div
                                      style={{
                                        backgroundImage: `url(${obj.artProductMaster?.images[0]?.image})`,
                                      }}
                                      className='min-w-[80px] w-[80px] h-[80px] bg-cover rounded-[8px]'
                                    ></div>

                                    {/* <img src={obj} alt='' /> */}
                                    <div>
                                      <p className='text-[15px] text-[#333333]'>
                                        {
                                          obj?.artProductMaster
                                            ?.artProductName
                                        }
                                        {/* Office Merchandise Combo */}
                                      </p>
                                      <p className='text-[11px] text-[#757575] leading-[14px] pt-1'>
                                        {/* International artist Azra's work{' '}
                                        <br /> dictates the expression */}
                                        {
                                          obj.artProductMaster
                                            ?.productMaster
                                            ?.description
                                        }
                                      </p>
                                    </div>
                                  </div>

                                  <p className='text-[15px] text-orangeColor'>
                                    $
                                    {
                                      obj.artProductMaster
                                        ?.productMaster
                                        ?.sizeAndPrices[0]?.sellPrice
                                    }
                                    {/* {
                                      obj.artProductMaster.productMaster
                                        .sizesAndPrices[0].sellPrice
                                    } */}
                                  </p>
                                </div>
                              )}
                            </>
                          ))}

                          <div
                            style={{
                              borderTop: '1px solid #efefef',
                            }}
                            className=' pt-[15px] mb-[20px]'
                          >
                            <div className='w-[100%] flex justify-center'>
                              <button
                                onClick={() => {
                                  navigate('/wishlist');
                                }}
                                className='blackBtn'
                              >
                                View Wishlist
                              </button>
                            </div>
                            <p className='text-primaryGray text-[11px] text-center mt-[3px]'>
                              By placing your order, you agree to the{' '}
                              <span className='text-orangeColor'>
                                Delivery Terms.
                              </span>
                            </p>

                            <p className='text-pinkColor text-[12px] text-center mt-[10px]'>
                              {cartQuantity} Items in cart
                            </p>

                            <div className='flex w-[100%] justify-center'>
                              <button className='w-[75px] mt-[3px]  h-[28px] bg-[#8e8e8e] text-[12px] text-medium justify-center items-center text-[#fff] rounded-[14px]'>
                                View Cart
                              </button>
                            </div>
                          </div>
                        </Menu.Items>
                      ) : (
                        <Menu.Items className='absolute right-8 py-[20px] mt-[11px] px-[20px] w-[257px] z-50 origin-top-right rounded-[16px] bg-[#ffffff] shadow-dropShadoww focus:outline-none'>
                          <p className='text-[15px] text-pinkColor text-center leading-[16px]'>
                            Nothing on the Wishlist
                          </p>
                          <p className='text-[12px] text-primaryGray text-center  leading-[16px]'>
                            We'll pont you out in the right direction
                          </p>

                          <div className='w-[100%] flex justify-center'>
                            <button
                              onClick={() => {
                                navigate('/art-list');
                              }}
                              className='blackBtn mt-[10px]'
                            >
                              Start Here
                            </button>
                          </div>
                        </Menu.Items>
                      )}
                    </Transition>
                  </Menu>
                </li>
                <li className='inline-block m-0'>
                  <Menu
                    as='div'
                    onMouseEnter={() => {
                      setCartIsMenuOpen(true);
                    }}
                    onMouseLeave={() => {
                      setCartIsMenuOpen(false);
                    }}
                  >
                    <NavLink
                      style={{
                        paddingBottom: '10px',
                        cursor: 'default',
                      }}
                    >
                      <Menu.Button className='inline-flex  w-full justify-center rounded-md bg-black bg-opacity-20 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
                        {/* <img src={notficationIcon} alt='' /> */}

                        <div
                          onClick={() => {
                            navigate('/shopping-cart');
                          }}
                          className='relative'
                        >
                          <CartIcon
                            style={{
                              position: 'relative',
                              top: '2px',
                              // cursor: 'pointer',
                            }}
                          />
                          {cartQuantity > 0 ? (
                            <div className='absolute bottom-[-2px] right-[-18px] bg-[#FF369F] text-[#ffffff] text-[11px] border border-[2px] border-[#ffffff] font-medium w-[24px] h-[18px] rounded-[5.32813rem] flex justify-center items-center  '>
                              <span className='relative top-[0.9px] leading-[1] '>
                                {cartQuantity}
                              </span>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </Menu.Button>
                    </NavLink>

                    <Transition
                      as={Fragment}
                      // show={true}
                      show={isCartMenuOpen}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      {cartQuantity > 0 ? (
                        <Menu.Items className='absolute right-4 mt-[11px] px-[20px] w-w350 z-[99] origin-top-right divide-y divide-[#efefef] rounded-[16px] bg-[#ffffff] shadow-dropShadoww focus:outline-none'>
                          <Menu.Item className='px-[14px] text-[12px] mt-[22px] text-center mb-[10px] text-[#757575]'>
                            <p className='text-[#dc399b]'>
                              Recently Added
                            </p>
                          </Menu.Item>

                          <div
                            onClick={() => {
                              navigate('/art/art-details');
                            }}
                            className='flex justify-between py-[11px] cursor-pointer'
                          >
                            <div className='flex gap-2'>
                              <img src={combo} alt='' />
                              <div>
                                <p className='text-[15px] text-[#333333] font-medium leading-[19px]'>
                                  Office Merchandise Combo
                                </p>
                                <p className='text-[11px] text-[#757575] leading-[14px] pt-1'>
                                  International artist Azra's work{' '}
                                  <br /> dictates the expression
                                </p>

                                <p className='text-[13px] text-primaryBlack font-medium'>
                                  Quantity:{' '}
                                  <span className='font-normal'>
                                    1
                                  </span>
                                </p>
                              </div>
                            </div>
                            <p className='text-[15px] text-orangeColor'>
                              $400.00
                            </p>
                          </div>

                          <div
                            onClick={() => {
                              navigate('/art/art-details');
                            }}
                            style={{
                              borderBottom: '1px solid #efefef',
                            }}
                            className='flex justify-between py-[11px] cursor-pointer'
                          >
                            <div className='flex gap-2'>
                              <img src={mug} alt='' />
                              <div>
                                <p className='text-[15px] text-[#333333] font-medium leading-[19px]'>
                                  Vector art printed on mug
                                </p>
                                <p className='text-[11px] text-[#757575] leading-[14px] pt-1'>
                                  International artist Azra's work{' '}
                                  <br /> dictates the expression
                                </p>{' '}
                                <p className='text-[13px] text-primaryBlack font-medium'>
                                  Quantity:{' '}
                                  <span className='font-normal'>
                                    1
                                  </span>
                                </p>
                              </div>
                            </div>
                            <p className='text-[15px] text-orangeColor'>
                              $400.00
                            </p>
                          </div>

                          <div
                            style={{
                              borderTop: '1px solid #efefef',
                            }}
                            className=' pt-[15px] mb-[22px]'
                          >
                            <div className='w-[100%] flex justify-center'>
                              <button className='blackBtn'>
                                Checkout
                              </button>
                            </div>
                            <p className='text-primaryGray text-[11px] text-center mt-[3px]'>
                              By placing your order, you agree to the{' '}
                              <span className='text-orangeColor'>
                                Delivery Terms.
                              </span>
                            </p>

                            <p className='text-pinkColor text-[12px] text-center mt-[10px]'>
                              3 Items in your cart
                            </p>

                            <div className='flex w-[100%] justify-center'>
                              <button
                                onClick={() => {
                                  navigate('/shopping-cart');
                                }}
                                className='w-[75px] mt-[3px]  h-[28px] bg-[#8e8e8e] text-[12px] text-medium justify-center items-center text-[#fff] rounded-[14px]'
                              >
                                View Cart
                              </button>
                            </div>
                          </div>
                        </Menu.Items>
                      ) : (
                        <Menu.Items className='absolute right-0 py-[20px] mt-[11px] px-[20px] w-[257px] z-50 origin-top-right rounded-[16px] bg-[#ffffff] shadow-dropShadoww focus:outline-none'>
                          <p className='text-[15px] text-pinkColor text-center leading-[16px]'>
                            Your cart is empty.
                          </p>
                          <p className='text-[12px] text-primaryGray text-center leading-[16px]'>
                            For now, we'll show you where to dive in.
                          </p>

                          <div className='w-[100%] flex justify-center'>
                            <button
                              onClick={() => {
                                navigate('/art-list');
                              }}
                              className='blackBtn mt-[10px]'
                            >
                              Start Here
                            </button>
                          </div>
                        </Menu.Items>
                      )}
                    </Transition>
                  </Menu>
                </li>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='px-[24px] pb-[11px] pt-[11px]'>
          <div className='flex items-center justify-between relative'>
            <div className='left flex items-center'>
              <img
                className='mr-[32px] relative bottom-[3px] cursor-pointer select-none'
                onClick={() => changeNavigation('home')}
                src={mainLogo}
                alt=''
              />
              {/* Links */}
              <ul>
                <li
                  className={`${
                    activeLink === 'Art' && 'text-primaryBlack'
                  } menu-link group hover:text-primaryBlack relative `}
                >
                  <NavLink
                    style={{
                      paddingBottom: '20px',
                    }}
                    onClick={() => setActiveLink('Art')}
                    to='/art-home'
                  >
                    ART
                  </NavLink>

                  <div className='bg-opacity-[0] absolute cursor-default  top-[33px] left-0 hidden group-hover:block  z-[999]'>
                    <div className='w-[100%] overflow-hidden h-[100%] bg-[#ffffff] shadow-regCardShadow pt-5 px-5 pb-7 rounded-3xl '>
                      <div className='flex gap-4 mb-7'>
                        <div>
                          <img
                            className='h-[150px] w-[150px] max-w-none rounded-[16px] hover:brightness-[80%] cursor-pointer'
                            src={artDropdownList[0]?.image}
                            alt=''
                          />
                          <p
                            onClick={() =>
                              setStyle(artDropdownList[0])
                            }
                            className='my-2.5 font-medium text-[15px] text-primaryBlack'
                          >
                            <span className='cursor-pointer'>
                              {artDropdownList[0]?.name}
                            </span>
                          </p>
                          <ul>
                            {subjects?.sub1?.map((subject) => (
                              <li
                                onClick={() => {
                                  dispatch(setSubjectId(subject));
                                  navigate('/art-list');
                                }}
                                className='text-[13px] text-primaryGray hover:text-primaryBlack font-normal leading-[19px]'
                              >
                                <span className='cursor-pointer'>
                                  {subject?.subjectName}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <img
                            className='h-[150px] w-[150px] max-w-none rounded-[16px] hover:brightness-[80%] cursor-pointer'
                            src={artDropdownList[1]?.image}
                            alt=''
                          />
                          <p
                            onClick={() =>
                              setStyle(artDropdownList[1])
                            }
                            className='my-2.5 font-medium text-[15px] text-primaryBlack'
                          >
                            <span className='cursor-pointer'>
                              {artDropdownList[1]?.name}
                            </span>
                          </p>
                          <ul>
                            {subjects?.sub2?.map((subject) => (
                              <li
                                onClick={() => {
                                  dispatch(setSubjectId(subject));
                                  navigate('/art-list');
                                }}
                                className='text-[13px] text-primaryGray hover:text-primaryBlack font-normal leading-[19px]'
                              >
                                <span className='cursor-pointer'>
                                  {subject?.subjectName}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <img
                            className='h-[150px] w-[150px] max-w-none rounded-[16px] cursor-pointer hover:brightness-[80%]'
                            src={artDropdownList[2]?.image}
                            alt=''
                          />
                          <p
                            onClick={() =>
                              setStyle(artDropdownList[2])
                            }
                            className='my-2.5 font-medium text-[15px] text-primaryBlack'
                          >
                            <span className='cursor-pointer'>
                              {artDropdownList[2]?.name}
                            </span>
                          </p>
                          <ul>
                            {subjects?.sub3?.map((subject) => (
                              <li
                                onClick={() => {
                                  dispatch(setSubjectId(subject));
                                  navigate('/art-list');
                                }}
                                className='text-[13px] text-primaryGray hover:text-primaryBlack font-normal leading-[19px]'
                              >
                                <span className='cursor-pointer'>
                                  {subject?.subjectName}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <img
                            className='h-[150px] w-[150px] max-w-none rounded-[16px] cursor-pointer hover:brightness-[80%]'
                            src={artDropdownList[3]?.image}
                            alt=''
                          />
                          <p
                            onClick={() =>
                              setStyle(artDropdownList[3])
                            }
                            className='my-2.5 font-medium text-[15px] text-primaryBlack'
                          >
                            <span className='cursor-pointer'>
                              {artDropdownList[3]?.name}
                            </span>
                          </p>
                          <ul>
                            {subjects?.sub4?.map((subject) => (
                              <li
                                onClick={() => {
                                  dispatch(setSubjectId(subject));
                                  navigate('/art-list');
                                }}
                                className='text-[13px] text-primaryGray hover:text-primaryBlack font-normal leading-[19px]'
                              >
                                <span className='cursor-pointer'>
                                  {subject?.subjectName}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <img
                            className='h-[150px] w-[150px] max-w-none rounded-[16px] cursor-pointer hover:brightness-[80%]'
                            src={artDropdownList[4]?.image}
                            alt=''
                          />
                          <p
                            onClick={() =>
                              setStyle(artDropdownList[4])
                            }
                            className='my-2.5 font-medium text-[15px] text-primaryBlack'
                          >
                            <span className='cursor-pointer'>
                              {artDropdownList[4]?.name}
                            </span>
                          </p>
                          <ul>
                            {subjects?.sub5?.map((subject) => (
                              <li
                                onClick={() => {
                                  dispatch(setSubjectId(subject));
                                  navigate('/art-list');
                                }}
                                className='text-[13px] text-primaryGray hover:text-primaryBlack font-normal leading-[19px]'
                              >
                                <span className='cursor-pointer'>
                                  {subject?.subjectName}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <img
                            className='h-[150px] w-[150px] max-w-none rounded-[16px] cursor-pointer hover:brightness-[80%]'
                            src={artDropdownList[5]?.image}
                            alt=''
                          />
                          <p
                            onClick={() =>
                              setStyle(artDropdownList[5])
                            }
                            className='my-2.5 font-medium text-[15px] text-primaryBlack'
                          >
                            <span className='cursor-pointer'>
                              {artDropdownList[5]?.name}
                            </span>
                          </p>
                          <ul>
                            {subjects?.sub6?.map((subject) => (
                              <li
                                onClick={() => {
                                  dispatch(setSubjectId(subject));
                                  navigate('/art-list');
                                }}
                                className='text-[13px] text-primaryGray hover:text-primaryBlack font-normal leading-[19px]'
                              >
                                <span className='cursor-pointer'>
                                  {subject?.subjectName}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          navigate('/art-list');
                          dispatch(setSubjectId(null));
                        }}
                        className='block mx-auto gray30HButton'
                      >
                        See All Subjects
                      </button>
                    </div>
                  </div>
                </li>

                <li
                  className={`${
                    activeLink === 'Photos' && 'text-primaryBlack'
                  } menu-link hover:text-primaryBlack relative `}
                >
                  <NavLink
                    style={{
                      paddingBottom: '20px',
                    }}
                    onClick={() => setActiveLink('Photos')}
                    to='/photos-home'
                  >
                    PHOTOS
                  </NavLink>
                  {/* PHOTOS */}
                </li>

                <li
                  className={`${
                    activeLink === 'Footage' && 'text-primaryBlack'
                  }  menu-link hover:text-primaryBlack relative `}
                >
                  <NavLink
                    style={{
                      paddingBottom: '20px',
                    }}
                    onClick={() => setActiveLink('Footage')}
                    to='/footage-home'
                  >
                    FOOTAGE
                  </NavLink>
                  {/* PHOTOS */}
                </li>
                <li
                  className={`${
                    activeLink === 'Music' && 'text-primaryBlack'
                  }  menu-link hover:text-primaryBlack relative `}
                >
                  <NavLink
                    style={{
                      paddingBottom: '20px',
                    }}
                    onClick={() => setActiveLink('Music')}
                    to='/music-home'
                  >
                    MUSIC
                  </NavLink>
                  {/* PHOTOS */}
                </li>
                {/* <li className='menu-link hover:text-primaryBlack'>
                  FOOTAGE
                </li> */}
                {/* <li className='menu-link hover:text-primaryBlack'>
                  MUSIC
                </li> */}
                <li
                  className={`${
                    activeLink === 'Templates' && 'text-primaryBlack'
                  }  menu-link hover:text-primaryBlack relative `}
                >
                  <NavLink
                    style={{
                      paddingBottom: '20px',
                    }}
                    onClick={() => setActiveLink('Templates')}
                    to='/templates-home'
                  >
                    TEMPLATES
                  </NavLink>
                </li>
                {/* <li className='menu-link hover:text-primaryBlack'>
                  TEMPLATES
                </li> */}
                <li
                  className={`${
                    activeLink === 'Products' && 'text-primaryBlack'
                  } menu-link group hover:text-primaryBlack relative`}
                >
                  <NavLink
                    style={{
                      paddingBottom: '20px',
                    }}
                    onClick={() => setActiveLink('Products')}
                    to='/product-home'
                  >
                    PRODUCTS
                  </NavLink>

                  <div className='bg-opacity-[0] absolute cursor-default  top-[33px] left-0 hidden group-hover:block  z-[99999]'>
                    <div className='w-[100%] overflow-hidden h-[100%] bg-[#ffffff] shadow-regCardShadow pt-5 px-5 pb-7 rounded-3xl'>
                      <div className='flex gap-4 mb-7'>
                        <div>
                          <div className='h-[150px] w-[150px] max-w-none cursor-pointer bg-[#e3e2e1] rounded-[16px]'></div>
                          {/* <img
                            className='h-[150px] w-[150px] max-w-none cursor-pointer hover:brightness-[80%]'
                            src={artDropImg}
                            alt=''
                          /> */}
                          <p className='my-2.5 font-medium text-[15px] text-primaryBlack cursor-pointer'>
                            Home Decor
                          </p>
                          <ul className='cursor-pointer'>
                            <li className='text-[13px] font-normal text-primaryGray leading-[19px] hover:text-primaryBlack'>
                              Coasters
                            </li>
                            <li className='text-[13px] font-normal text-primaryGray leading-[19px] hover:text-primaryBlack'>
                              Coffee Mugs
                            </li>
                            <li className='text-[13px] font-normal text-primaryGray leading-[19px] hover:text-primaryBlack'>
                              Flower Pots
                            </li>
                            <li className='text-[13px] font-normal text-primaryGray leading-[19px] hover:text-primaryBlack '>
                              Throw Pillow Covers
                            </li>
                          </ul>
                        </div>
                        <div>
                          {/* <img
                            className='h-[150px] w-[150px] max-w-none hover:brightness-[80%] cursor-pointer'
                            src={Animals}
                            alt=''
                          /> */}

                          <div className='h-[150px] w-[150px] max-w-none cursor-pointer bg-[#e3e2e1] rounded-[16px]'></div>
                          <p className='my-2.5 font-medium text-[15px] text-primaryBlack cursor-pointer'>
                            Lifestyle
                          </p>
                          <ul className='cursor-pointer'>
                            <li className='text-[13px] text-primaryGray font-normal leading-[19px] hover:text-primaryBlack'>
                              Button Badges
                            </li>
                            <li className='text-[13px] text-primaryGray font-normal leading-[19px] hover:text-primaryBlack'>
                              Carry-All Pouches
                            </li>
                            <li className='text-[13px] text-primaryGray font-normal leading-[19px] hover:text-primaryBlack'>
                              Drinking Glasses
                            </li>
                            <li className='text-[13px] text-primaryGray font-normal leading-[19px] hover:text-primaryBlack'>
                              Fridge Magnets
                            </li>
                            <li className='text-[13px] text-primaryGray font-normal leading-[19px] hover:text-primaryBlack'>
                              Tote Bags
                            </li>
                            <li className='text-[13px] text-primaryGray font-normal leading-[19px] hover:text-primaryBlack'>
                              Water Bottles
                            </li>
                          </ul>
                        </div>
                        <div>
                          {/* <img
                            className='h-[150px] w-[150px] max-w-none hover:brightness-[80%] cursor-pointer'
                            src={Architecture}
                            alt=''
                          /> */}

                          <div className='h-[150px] w-[150px] max-w-none cursor-pointer bg-[#e3e2e1] rounded-[16px]'></div>

                          <p className='my-2.5 font-medium text-[15px] text-primaryBlack cursor-pointer'>
                            Tech
                          </p>
                          <ul className='cursor-pointer'>
                            <li className='text-[13px] text-primaryGray font-normal leading-[19px] hover:text-primaryBlack'>
                              Mouse Pads
                            </li>

                            <li className='text-[13px] text-primaryGray font-normal leading-[19px] hover:text-primaryBlack'>
                              iPhone Cases
                            </li>
                            <li className='text-[13px] text-primaryGray font-normal leading-[19px] hover:text-primaryBlack'>
                              Android Cases
                            </li>
                            <li className='text-[13px] text-primaryGray font-normal leading-[19px] hover:text-primaryBlack'>
                              Laptop Skins
                            </li>
                            <li className='text-[13px] text-primaryGray font-normal leading-[19px] hover:text-primaryBlack'>
                              iPhone Skins
                            </li>
                          </ul>
                        </div>
                        <div>
                          {/* <img
                            className='h-[150px] w-[150px] max-w-none hover:brightness-[80%] cursor-pointer'
                            src={Botanical}
                            alt=''
                          /> */}

                          <div className='h-[150px] w-[150px] max-w-none cursor-pointer bg-[#e3e2e1] rounded-[16px]'></div>

                          <p className='my-2.5 font-medium text-[15px] text-primaryBlack cursor-pointer'>
                            Apparel
                          </p>
                          <ul className='cursor-pointer'>
                            <li className='text-[13px] text-primaryGray font-normal leading-[19px] hover:text-primaryBlack'>
                              Men's T-Shirts
                            </li>
                            <li className='text-[13px] text-primaryGray font-normal leading-[19px] hover:text-primaryBlack'>
                              Women's T-Shirts
                            </li>
                            <li className='text-[13px] text-primaryGray font-normal leading-[19px] hover:text-primaryBlack'>
                              Hoodies
                            </li>
                            <li className='text-[13px] text-primaryGray font-normal leading-[19px] hover:text-primaryBlack'>
                              Kid's T-Shirts
                            </li>
                          </ul>
                        </div>
                        <div>
                          {/* <img
                            className='h-[150px] w-[150px] max-w-none hover:brightness-[80%] cursor-pointer'
                            src={People}
                            alt=''
                          /> */}

                          <div className='h-[150px] w-[150px] max-w-none cursor-pointer bg-[#e3e2e1] rounded-[16px]'></div>

                          <p className='my-2.5 font-medium text-[15px] text-primaryBlack cursor-pointer'>
                            Stationary
                          </p>
                          <ul className='cursor-pointer'>
                            <li className='text-[13px] text-primaryGray font-normal leading-[19px] hover:text-primaryBlack'>
                              Notepads
                            </li>
                            <li className='text-[13px] text-primaryGray font-normal leading-[19px] hover:text-primaryBlack'>
                              Stickers
                            </li>
                            <li className='text-[13px] text-primaryGray font-normal leading-[19px] hover:text-primaryBlack'>
                              Book Marks
                            </li>
                            <li className='text-[13px] text-primaryGray font-normal leading-[19px] hover:text-primaryBlack'>
                              Tags
                            </li>
                          </ul>
                        </div>
                      </div>
                      <button className='block mx-auto gray30HButton'>
                        See All Products
                      </button>
                      <div className='bg-[#efefef] h-[1px] w-[100%] my-[30px]'></div>
                      <div className='flex items-center justify-center'>
                        <div className='flex items-center'>
                          <img src={comboPack} alt='' />
                          <p className='text-[28px] text-primaryBlack ml-2.5'>
                            Combo Pack
                          </p>
                        </div>
                        <div className='bg-[#efefef] w-[1px] h-[38px] mx-[25px]'></div>
                        <div className='flex items-center'>
                          <img src={customPack} alt='' />
                          <p className='text-[28px] text-primaryGray ml-2.5'>
                            Custom Pack
                          </p>
                        </div>
                      </div>
                      <button className='bg-[#ffffff] text-primaryGray text-sm11 border border-[#8e8e8e] py-0.5 px-1.5 rounded-xl mt-2.5 block mx-auto'>
                        Browse through our Pack Collections of
                        best-selling Limited Editions
                      </button>
                    </div>
                  </div>
                </li>
                {/* <li  className='menu-link hover:text-primaryBlack'>
                  BLOG
                </li> */}
                <li
                  className={`${
                    activeLink === 'Blog' && 'text-primaryBlack'
                  }  menu-link hover:text-primaryBlack relative `}
                >
                  <NavLink
                    style={{
                      paddingBottom: '20px',
                    }}
                    onClick={() => setActiveLink('Blog')}
                    to='/blog-home'
                  >
                    BLOG
                  </NavLink>
                  {/* PHOTOS */}
                </li>
                <li className='menu-link hover:text-primaryBlack opacity-[40%]'>
                  AUCTIONS
                </li>
              </ul>
            </div>
            <div
              className='mid absolute left-2/4'
              style={{ transform: 'translate(-40%, 0)' }}
            >
              <div
                onClick={() => {
                  navigate('/SellOnArtnstock-Home');
                }}
                className='greenYellowButton cursor-pointer'
              >
                <span className='ml-[1px]'>Sell on Artnstock</span>
              </div>
            </div>
            <div
              className={`flex items-center right ${
                cartCount > 0 ? 'pr-[16px]' : ''
              }`}
            >
              <div className='h-[16px] flex items-center'>
                <span
                  style={{
                    lineHeight: '11px',
                  }}
                  className='menu-link hover:text-primaryBlack'
                >
                  PLANS & PRICING
                </span>
                {userAuth.login ? (
                  <div className='text-primaryGray text-sm14 font-semibold leading-5 inline-block mr-4'>
                    <div className=''>
                      <Menu
                        ref={ProfileDropdownClickOutside}
                        as='div'
                        className='relative h-[16px]'
                      >
                        <Menu.Button
                          onClick={() => {
                            setProfileOpen(!isProfileOpen);
                          }}
                          className='inline-flex h-[16px] w-full justify-center rounded-md bg-black bg-opacity-20 text-sm font-medium text-white hover:bg-opacity-30 outline-none focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
                        >
                          <img
                            className='outline-none'
                            src={userIcon}
                            alt=''
                          />
                        </Menu.Button>

                        <Transition
                          as={Fragment}
                          show={isProfileOpen}
                          enter='transition ease-out duration-100'
                          enterFrom='transform opacity-0 scale-95'
                          enterTo='transform opacity-100 scale-100'
                          leave='transition ease-in duration-75'
                          leaveFrom='transform opacity-100 scale-100'
                          leaveTo='transform opacity-0 scale-95'
                        >
                          <Menu.Items className='absolute right-0 mt-[10px] w-[264px]  z-[99999] origin-top-right  rounded-[24px] bg-[#ffffff] shadow-dropShadoww outline-none focus:outline-none'>
                            <div className='px-[16px] text-[12px] text-[#757575] '>
                              <Menu.Item>
                                <div>
                                  <div className='flex gap-[8px] justify-left mt-[16px] mb-[16px]'>
                                    {/* <img src={profile} alt="" /> */}

                                    <div>
                                      <div
                                        className='w-[72px] h-[72px] rounded-[92%] bg-no-repeat bg-center bg-cover'
                                        style={{
                                          backgroundImage: `url(${userDetails?.profileImage})`,
                                        }}
                                      ></div>
                                    </div>

                                    <p className='leading-[1.3]'>
                                      <p className='text-[16px] font-medium text-[#333333]'>
                                        {userDetails?.displayName}
                                      </p>
                                      <p className='text-sm11 font-normal text-[#757575]'>
                                        User ID:{' '}
                                        {userDetails?.userUniqueNo}
                                      </p>
                                      <p className='text-sm11 text-[#757575] font-normal'>
                                        {userDetails
                                          ?.contributorTypeMaster
                                          ?.contributorTypeId ===
                                          null ||
                                        userDetails
                                          ?.contributorTypeMaster
                                          ?.contributorTypeId ===
                                          undefined ||
                                        userDetails
                                          ?.contributorTypeMaster
                                          ?.contributorTypeId === ''
                                          ? 'Select Contributor Type'
                                          : userDetails
                                              ?.contributorTypeMaster
                                              ?.contributorTypeName}
                                      </p>
                                      <p className='text-sm11 mt-[6px] text-[#757575] flex gap-[4px] items-center font-normal'>
                                        {/* <img
                                          src={locationIcon}
                                          className='inline'
                                          alt=''
                                        /> */}
                                        {/* <Location className='inline' />{' '} */}
                                        <div>
                                          <img
                                            className='inline'
                                            src={locatiomIcon}
                                            alt=''
                                          />
                                        </div>
                                        {userDetails?.userRole[0] ===
                                        'contributor' ? (
                                          <>
                                            {
                                              userDetails
                                                ?.residentialAddress
                                                ?.cityName
                                            }
                                            ,{' '}
                                            {
                                              userDetails
                                                ?.residentialAddress
                                                ?.stateName
                                            }
                                            ,{' '}
                                            {
                                              userDetails
                                                ?.residentialAddress
                                                ?.countryName
                                            }
                                          </>
                                        ) : (
                                          <>
                                            {
                                              userDetails
                                                ?.shippingAddress
                                                ?.cityName
                                            }
                                            ,{' '}
                                            {
                                              userDetails
                                                ?.shippingAddress
                                                ?.stateName
                                            }
                                            ,{' '}
                                            {
                                              userDetails
                                                ?.shippingAddress
                                                ?.countryName
                                            }
                                          </>
                                        )}
                                      </p>
                                    </p>
                                  </div>

                                  <button
                                    onClick={() => {
                                      navigate(
                                        `${
                                          userRole === 'contributor'
                                            ? '/contributor'
                                            : 'customer'
                                        }`
                                      );
                                      setProfileOpen(false);
                                    }}
                                    className='text-[#333333]  rounded-gradient-border-for-button text-[12px] font-medium h-[24px] flex justify-center items-center px-[10px] mb-[8px]'
                                  >
                                    <span>Account Settings</span>
                                  </button>
                                </div>
                              </Menu.Item>
                            </div>

                            {/* <div className='px-[16px]'> */}
                            <div className='w-[100%] px-[16px]'>
                              <div className=' text-[12px] text-primaryGray hover:text-primaryBlack  border-t border-t-[#efefef] border-b border-b-[#efefef] font-normal'>
                                <Menu.Item>
                                  <button
                                    onClick={() => {
                                      pathhcust('/ My Collections');
                                      setProfileOpen(false);
                                    }}
                                  >
                                    My Collections
                                  </button>
                                </Menu.Item>
                              </div>
                              <div className=' text-[12px] text-primaryGray hover:text-primaryBlack   border-b border-b-[#efefef] font-normal'>
                                <Menu.Item>
                                  <button
                                    // onClick={() => {
                                    //   pathhcust('/ My Plans');
                                    // }}
                                    className='disabled'
                                  >
                                    My Plans
                                  </button>
                                </Menu.Item>
                              </div>
                              <div className=' text-[12px] text-primaryGray hover:text-primaryBlack   border-b border-b-[#efefef] font-normal'>
                                <Menu.Item>
                                  <button
                                    onClick={() => {
                                      pathhcust('/ Purchase History');
                                      setProfileOpen(false);
                                    }}
                                  >
                                    Purchase History
                                  </button>
                                </Menu.Item>
                              </div>
                              <div className=' text-[12px] text-primaryGray hover:text-primaryBlack   border-b border-b-[#efefef] font-normal'>
                                <Menu.Item>
                                  <button
                                    onClick={() => {
                                      pathhcust(
                                        '/ Referral Earnings'
                                      );
                                      setProfileOpen(false);
                                    }}
                                  >
                                    Referral Earnings
                                  </button>
                                </Menu.Item>
                              </div>
                              <div className=' text-[12px] text-primaryGray hover:text-primaryBlack   border-b border-b-[#efefef] font-normal'>
                                <Menu.Item>
                                  <button
                                    onClick={() => {
                                      navigate(
                                        '/BuyersSupportCenter'
                                      );
                                      setProfileOpen(false);
                                    }}
                                  >
                                    Customer Support
                                  </button>
                                </Menu.Item>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* contributor view start */}
                            <div className='w-[100%] px-[16px]'>
                              {userRole === 'contributor' ? (
                                <div>
                                  <div className='text-[12px] text-primaryGray hover:text-primaryBlack'>
                                    <Menu.Item>
                                      <p className='text-[#333333] text-[15px] font-medium pt-[13px] pb-[3px]'>
                                        Contributor
                                      </p>
                                    </Menu.Item>
                                  </div>
                                  <div className=' text-[12px] text-primaryGray hover:text-primaryBlack border-t border-b  border-t-[#efefef] border-b-[#efefef] font-normal'>
                                    <Menu.Item>
                                      <button
                                        onClick={() => {
                                          pathh('/ Dashboard');
                                          setProfileOpen(false);
                                        }}
                                      >
                                        Dashboard{' '}
                                      </button>
                                    </Menu.Item>
                                  </div>
                                  <div className=' text-[12px] text-primaryGray hover:text-primaryBlack border-b border-b-[#efefef] font-normal'>
                                    <Menu.Item>
                                      <button
                                        onClick={() => {
                                          pathh('/ Upload');
                                          dispatch(
                                            setpath2('/ Upload Files')
                                          );
                                          // dispatch(
                                          //   setNestedTabValueUpload(
                                          //     '2.1'
                                          //   )
                                          // );
                                          setProfileOpen(false);
                                        }}
                                      >
                                        Upload
                                      </button>
                                    </Menu.Item>
                                  </div>
                                  <div className=' text-[12px] text-primaryGray hover:text-primaryBlack   border-b border-b-[#efefef] font-normal'>
                                    <Menu.Item>
                                      <button
                                        onClick={() => {
                                          pathh('/ File Manager');
                                          setProfileOpen(false);
                                        }}
                                      >
                                        File Manager
                                      </button>
                                    </Menu.Item>
                                  </div>
                                  <div className=' text-[12px] text-primaryGray hover:text-primaryBlack  border-b border-b-[#efefef] font-normal'>
                                    <Menu.Item>
                                      <button
                                        onClick={() => {
                                          pathh('/ Releases');
                                          dispatch(
                                            setNestedTabValueReleases(
                                              '4.1'
                                            )
                                          );
                                          setProfileOpen(false);
                                        }}
                                      >
                                        Releases
                                      </button>
                                    </Menu.Item>
                                  </div>
                                  <div className=' text-[12px] text-primaryGray hover:text-primaryBlack  border-b border-b-[#efefef] font-normal'>
                                    <Menu.Item>
                                      <button
                                        onClick={() => {
                                          pathh('/ Earnings');
                                          dispatch(
                                            setNestedTabValueEarnings(
                                              '/ Earning Summary'
                                            )
                                          );
                                          setProfileOpen(false);
                                        }}
                                      >
                                        Earnings
                                      </button>
                                    </Menu.Item>
                                  </div>
                                  <div className=' text-[12px] text-primaryGray hover:text-primaryBlack border-b border-b-[#efefef]  font-normal'>
                                    <Menu.Item>
                                      <button
                                        onClick={() => {
                                          navigate(
                                            '/ContributorSupportCenter'
                                          );
                                          setProfileOpen(false);
                                        }}
                                      >
                                        Contributor Support
                                      </button>
                                    </Menu.Item>
                                  </div>
                                  <div className=' text-[12px] text-primaryGray  border-b border-b-[#efefef] font-normal'>
                                    <Menu.Item>
                                      <button className='disabled'>
                                        Promote My Work
                                      </button>
                                    </Menu.Item>
                                  </div>
                                  <div
                                    onClick={() => {
                                      navigate('/view-my-store', {
                                        state: userDetails,
                                      });
                                      setProfileOpen(false);
                                    }}
                                    className=' text-[12px] pt-[4px] pb-[3px] cursor-pointer text-primaryGray text-start flex flex-col hover:text-primaryBlack  border-b border-b-[#efefef] font-normal'
                                  >
                                    <p className='leading-[1]'>
                                      View My Store
                                    </p>

                                    <p className='text-[#bbbbbb] mt-[2px] leading-[1]  text-[11px]'>
                                      Customers will see your store
                                      page like this.
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </div>
                            {/* contributor view end */}

                            <div className=' flex justify-center '>
                              <Menu.Item>
                                <button
                                  onClick={() => {
                                    logout();
                                    setProfileOpen(false);
                                  }}
                                  className='black30HButton mt-[16px] mb-[16px]'
                                >
                                  Sign Out
                                </button>
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='menu-link flex items-center h-[16px]  relative leading-[11px]'>
                      <span onClick={() => navigate('/join')}>
                        JOIN
                      </span>
                    </div>
                    <div className='menu-link flex items-center h-[16px]  relative leading-[11px]'>
                      <span onClick={() => navigate('/login')}>
                        SIGN IN
                      </span>
                    </div>
                  </>
                )}
                <div className={`inline-block mr-[16px]`}>
                  <Menu
                    as='div'
                    onMouseEnter={() => {
                      setNotificationIsMenuOpen(true);
                    }}
                    onMouseLeave={() => {
                      setNotificationIsMenuOpen(false);
                    }}
                    className='relative h-[16px]'
                  >
                    {/* <NavLink
                        style={{
                          paddingBottom: '10px',
                          cursor: 'default',
                        }}
                      > */}
                    <Menu.Button className=' w-full h-[16px] flex items-center justify-center '>
                      {/* <img src={notficationIcon} alt='' /> */}

                      <NotficationIcon
                        className='outline-none h-[16px]'
                        onMouseEnter={() => {
                          setNotficationIconHoverColor('#333333');
                          setProfileOpen(false);
                        }}
                        onMouseLeave={() => {
                          setNotficationIconHoverColor('#888888');
                        }}
                        style={{
                          fill: NotficationIconHoverColor,
                        }}
                      />
                    </Menu.Button>
                    {/* </NavLink> */}

                    <Transition
                      as={Fragment}
                      // show={true}
                      show={isNotificationMenuOpen}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      <Menu.Items className='absolute right-0 mt-[16px] p-[20px] w-[344px] z-[99] origin-top-right rounded-[32px] bg-[#ffffff] shadow-dropShadoww focus:outline-none'>
                        <Menu.Item className='text-sm11  text-center  mb-[8px]'>
                          <p className='text-pinkColor'>
                            Recently Added
                          </p>
                        </Menu.Item>

                        <div
                          onClick={() => {
                            navigate('/art/art-details');
                          }}
                          className='flex justify-between pb-[8px] cursor-pointer '
                        >
                          <div className='flex gap-[16px]'>
                            <img
                              className='h-[84px] w-[84px]'
                              src={combo}
                              alt=''
                            />
                            <div>
                              <p className='text-sm12 text-primaryBlack font-medium w-[172px]'>
                                Assorted Artnstock Lifestyle <br />
                                Combo 2024
                              </p>
                              <p className='text-sm11 text-primaryGray '>
                                Combo ID: ANS15994539
                              </p>
                              <div className='text-sm11 text-primaryGray mt-[4px]  '>
                                Style Fusion Pack - Combining the
                                latest trends in fashion and
                                accessories..
                                {/* <p className='text-sm12 text-pinkColor font-medium'>
                                  $200.00
                                </p>
                                <p className='text-sm12 text-primaryGray'>
                                  Qty.1
                                </p> */}
                              </div>
                              <button className='h-[16px] flex px-[4px] mt-[4px] text-[10px] block leading-[1] text-[#33cdf4] cursor-default border border-[#33cdf4] font-medium items-center justify-center rounded-[8px]'>
                                10% Discount Applied
                              </button>
                            </div>
                          </div>

                          {/* <p className='text-[15px] text-orangeColor'>
                              $400.00
                            </p> */}
                        </div>
                        <div
                          onClick={() => {
                            navigate('/art/art-details');
                          }}
                          className='flex justify-between pt-[8px] pb-[16px] border-b border-b-[2px] border-[#efefef] cursor-pointer'
                        >
                          <div className='flex gap-[16px]'>
                            <img
                              className='h-[84px] w-[84px]'
                              src={mug}
                              alt=''
                            />
                            <div>
                              <p className='text-sm12 text-primaryBlack font-medium  w-[172px]'>
                                Assorted Artnstock Lifestyle <br />
                                Combo 2024
                              </p>
                              <p className='text-sm11 text-primaryGray '>
                                Combo ID: ANS15994539
                              </p>
                              <div className='text-sm11 text-primaryGray mt-[4px] '>
                                Personalized mugs with vibrant prints
                                that brighten up your daily coffee...
                                {/* <p className='text-sm12 text-pinkColor font-medium'>
                                  $200.00
                                </p>
                                <p className='text-sm12 text-primaryGray'>
                                  Qty.1
                                </p> */}
                              </div>
                              <div className='flex gap-[4px] pt-[4px]'>
                                <div>
                                  <Exclusive_Icon16 className='fill-[#b5a56d]' />
                                </div>
                                <div className='flex flex-col font-bold'>
                                  <p className='text-[9px] text-[#b5a56d] leading-[1]'>
                                    ARTNSTOCK
                                  </p>
                                  <p className='text-[9px] text-[#b5a56d] leading-[1]'>
                                    EXCLUSIVE
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <p className='text-[15px] text-orangeColor'>
                              $400.00
                            </p> */}
                        </div>

                        <button className='mt-[16px] mx-[auto] block gray30HButton'>
                          Clear Notification(s)
                        </button>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>

                <div
                  className={`inline-block ${
                    wishListCount > 0 ? 'mr-[30px]' : 'mr-[16px]'
                  } `}
                >
                  {/* <img
                    onClick={() => {
                      navigate('/wishlist');
                    }}
                    src={wishlistIcon}
                    alt=''
                  /> */}

                  <Menu
                    as='div'
                    onMouseEnter={() => {
                      setWishlistIsMenuOpen(true);
                      setProfileOpen(false);
                    }}
                    onMouseLeave={() => {
                      setWishlistIsMenuOpen(false);
                    }}
                    className='h-[16px] relative flex items-center'
                  >
                    {/* <NavLink
                      style={{
                        paddingBottom: '10px',
                        cursor: 'default',
                      }}
                    > */}
                    <Menu.Button
                      onClick={() => {
                        navigate('/wishlist');
                        // console.log('Wishlist');
                        // setWishlistIsMenuOpen(true);
                      }}
                      className='inline-flex h-[16px] w-full justify-center  origin-top-right rounded-md bg-black bg-opacity-20 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
                    >
                      {/* <img src={notficationIcon} alt='' /> */}

                      <div className='relative'>
                        <WishlistIcon
                          className='h-[16px]   outline-none'
                          onMouseEnter={() => {
                            setWishIconHoverColor('#333333');
                          }}
                          onMouseLeave={() => {
                            setWishIconHoverColor('#888888');
                          }}
                          style={{
                            fill: WishIconHoverColor,
                            // marginTop: '2px',
                            position: 'relative',
                            // top: '2px',
                          }}
                          // className=''
                        />

                        <div className='mr-[16px]'>
                          {wishListCount > 0 ? (
                            <div
                              // onClick={() => {
                              //   navigate('/wishlist');
                              //   // console.log('Wishlist');
                              //   // setWishlistIsMenuOpen(true);
                              // }}
                              className='absolute top-[0px] right-[-12px] bg-[#FF369F] text-[#ffffff] text-[11px]  border-[#ffffff] font-medium w-[20px] h-[14px] rounded-[5.32813rem] flex justify-center items-center'
                            >
                              <span className='relative top-[0.9px] leading-[1]'>
                                {wishListCount}
                              </span>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </Menu.Button>
                    {/* </NavLink> */}

                    <Transition
                      as={Fragment}
                      // show={true}
                      show={isWishlistMenuOpen}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      {wishListCount > 0 ? (
                        <Menu.Items className='absolute top-[21px] right-[0px] mt-[11px] p-[20px] w-[344px] z-[99] origin-top-right  rounded-[30px] bg-[#ffffff] shadow-dropShadoww focus:outline-none'>
                          <Menu.Item className='text-sm11  text-center  mb-[8px]'>
                            <p className='text-pinkColor'>
                              Recently Added
                            </p>
                          </Menu.Item>
                          {/* <div className=' border-t border-[#efefef]'>
                            {wishlist?.slice(0, 2).map((obj) => (
                              <>
                                {obj.artMaster !== null ? (
                                  <div
                                   
                                    className='flex justify-between py-[8px] cursor-pointer  '
                                  >
                                    <div className='flex gap-[16px]'>
                                      <div
                                        style={{
                                          backgroundImage: `url(${obj.artMaster?.imageMaster?.thumbnailUrl})`,
                                        }}
                                        className='min-w-[84px] w-[84px] h-[84px] bg-cover rounded-[12px]'
                                      ></div>

                                     
                                      <div>
                                        <p className='text-[15px] font-medium leading-[19px] text-[#333333]'>
                                          {obj.artMaster?.artName
                                            .length > 49 ? (
                                            <p>
                                              {obj.artMaster?.artName.slice(
                                                0,
                                                48
                                              )}
                                              <span>...</span>
                                            </p>
                                          ) : (
                                            <div>
                                              {obj.artMaster?.artName}
                                            </div>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                   
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      borderBottom:
                                        '1px solid #efefef',
                                    }}
                                    className='flex justify-between py-[11px]'
                                  >
                                    <div className='flex gap-2'>
                                      <div
                                        style={{
                                          backgroundImage: `url(${obj.artProductMaster?.images[0]?.image})`,
                                        }}
                                        className='min-w-[80px] w-[80px] h-[80px] bg-cover rounded-[8px]'
                                      ></div>

                                     
                                      <div>
                                        <p className='text-[15px] text-[#333333]'>
                                          {
                                            obj?.artProductMaster
                                              ?.artProductName
                                          }
                                         
                                        </p>
                                      </div>
                                    </div>

                                    <p className='text-[15px] text-orangeColor'>
                                      $
                                      {
                                        obj.artProductMaster
                                          ?.productMaster
                                          ?.sizeAndPrices[0]
                                          ?.sellPrice
                                      }
                                      
                                    </p>
                                  </div>
                                )}
                              </>
                            ))}
                          </div> */}

                          {/* <div
                            style={{
                              borderTop: '1px solid #efefef',
                            }}
                            className=' pt-[15px] mb-[20px]'
                          >
                            <div className='w-[100%] flex justify-center'>
                              <button
                                onClick={() => {
                                  navigate('/wishlist');
                                }}
                                className='blackBtn'
                              >
                                View Wishlist
                              </button>
                            </div>
                            <p className='text-primaryGray text-[11px] text-center mt-[3px]'>
                              By placing your order, you agree to the{' '}
                              <span className='text-orangeColor'>
                                Delivery Terms.
                              </span>
                            </p>

                            <p className='text-pinkColor text-[12px] text-center mt-[10px]'>
                              {cartQuantity} Items in cart
                            </p>

                            <div className='flex w-[100%] justify-center'>
                              <button className='w-[75px] mt-[3px]  h-[28px] bg-[#8e8e8e] text-[12px] text-medium justify-center items-center text-[#fff] rounded-[14px]'>
                                View Cart
                              </button>
                            </div>
                          </div> */}

                          <div
                            onClick={() => {
                              navigate('/art/art-details');
                            }}
                            className='flex justify-between pb-[8px] cursor-pointer'
                          >
                            <div className='flex gap-[16px]'>
                              <img
                                className='h-[84px] w-[84px]'
                                src={combo}
                                alt=''
                              />
                              <div>
                                <p className='text-sm12 text-primaryBlack font-medium  w-[172px]'>
                                  Assorted Artnstock Lifestyle <br />
                                  Combo 2024
                                </p>
                                <p className='text-sm11 text-primaryGray '>
                                  Combo ID: ANS15994539
                                </p>
                                <div className='flex  gap-[8px] mt-[4px]'>
                                  <p className='text-sm12 text-pinkColor font-medium'>
                                    $200.00
                                  </p>
                                  <p className='text-sm12 text-primaryGray'>
                                    Qty.1
                                  </p>
                                </div>
                                <button className='h-[16px] flex px-[4px] mt-[4px] text-[10px] block leading-[1] text-[#33cdf4] cursor-default border border-[#33cdf4] font-medium items-center justify-center rounded-[8px]'>
                                  10% Discount Applied
                                </button>
                              </div>
                            </div>

                            {/* <p className='text-[15px] text-orangeColor'>
                              $400.00
                            </p> */}
                          </div>
                          <div
                            onClick={() => {
                              navigate('/art/art-details');
                            }}
                            className='flex justify-between pt-[8px] pb-[16px] border-b border-[#efefef] cursor-pointer'
                          >
                            <div className='flex gap-[16px]'>
                              <img
                                className='h-[84px] w-[84px]'
                                src={mug}
                                alt=''
                              />
                              <div>
                                <p className='text-sm12 text-primaryBlack font-medium  w-[172px]'>
                                  Assorted Artnstock Lifestyle <br />
                                  Combo 2024
                                </p>
                                <p className='text-sm11 text-primaryGray '>
                                  Combo ID: ANS15994539
                                </p>
                                <div className='flex  gap-[8px] mt-[4px]'>
                                  <p className='text-sm12 text-pinkColor font-medium'>
                                    $200.00
                                  </p>
                                  <p className='text-sm12 text-primaryGray'>
                                    Qty.1
                                  </p>
                                </div>
                                <div className='flex gap-[4px] pt-[4px]'>
                                  <div>
                                    <Exclusive_Icon16 className='fill-[#b5a56d]' />
                                  </div>
                                  <div className='flex flex-col font-bold'>
                                    <p className='text-[9px] text-[#b5a56d] leading-[1]'>
                                      ARTNSTOCK
                                    </p>
                                    <p className='text-[9px] text-[#b5a56d] leading-[1]'>
                                      EXCLUSIVE
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <p className='text-[15px] text-orangeColor'>
                              $400.00
                            </p> */}
                          </div>

                          <div
                            style={{
                              borderTop: '1px solid #efefef',
                            }}
                            className=' pt-[16px] '
                          >
                            {/* <p className='text-sm11 text-center text-primaryGray'>
                              Total Amount
                            </p>
                            <div className='flex items-baseline justify-center mt-[2px]'>
                              <p className='text-pinkColor text24 '>
                                $
                              </p>
                              <p className='text-pinkColor text24 '>
                               
                                214.00
                              </p>
                            </div> */}
                            <div className='w-[100%] flex justify-center mt-[4px]'>
                              <button
                                onClick={() => {
                                  navigate('/wishlist');
                                }}
                                className='blackBtn'
                              >
                                View Wishlist
                              </button>
                            </div>
                            <p className='text-primaryGray text-sm11 text-center mt-[8px]'>
                              By placing your order, you agree to the{' '}
                              <span className='text-orangeColor'>
                                Delivery Terms.
                              </span>
                            </p>

                            <p className='text-pinkColor text-sm11 text-center mt-[16px]'>
                              3 Items in your cart
                            </p>

                            <div className='flex w-[100%] justify-center mt-[8px]'>
                              <button
                                onClick={() => {
                                  navigate('/shopping-cart');
                                }}
                                className='gray30HButton'
                              >
                                View Cart
                              </button>
                            </div>
                          </div>
                        </Menu.Items>
                      ) : (
                        <Menu.Items className='absolute top-[0px] right-[0px] p-[20px] mt-[32px] w-[234px] z-50 origin-top-right rounded-[24px] bg-[#ffffff] shadow-dropShadoww focus:outline-none'>
                          <p className='text-[16px] text-pinkColor text-center leading-[20px] font-medium'>
                            Nothing on the Wishlist
                          </p>
                          <p className='text-sm12 text-primaryGray text-center mt-[4px]'>
                            We’ll direct you in the right direction.
                          </p>

                          <div className='w-[100%] flex justify-center'>
                            <button
                              onClick={() => {
                                navigate('/art-list');
                              }}
                              className='gray30HButton mt-[8px]'
                            >
                              Start Here
                            </button>
                          </div>
                        </Menu.Items>
                      )}
                    </Transition>
                  </Menu>
                </div>
                <div className='inline-block m-0'>
                  <Menu
                    as='div'
                    onMouseEnter={() => {
                      setCartIsMenuOpen(true);
                      setProfileOpen(false);
                    }}
                    onMouseLeave={() => {
                      setCartIsMenuOpen(false);
                    }}
                    className='h-[16px] relative flex items-center'
                  >
                    {/* <NavLink
                      style={{
                        paddingBottom: '10px', 
                        cursor: 'default',
                      }}
                    > */}
                    <Menu.Button className='inline-flex h-[16px]  w-full justify-center rounded-md bg-black bg-opacity-20 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
                      {/* <img src={notficationIcon} alt='' /> */}

                      <div
                        onClick={() => {
                          navigate('/shopping-cart');
                        }}
                        className='relative h-[16px]'
                      >
                        <CartIcon
                          className='outline-none h-[16px]'
                          // style={{
                          //   position: 'relative',
                          //   // top: '2px',
                          //   // cursor: 'pointer',
                          // }}
                        />
                        {cartQuantity > 0 ? (
                          <div className='absolute top-[0px] right-[-12px] bg-[#FF369F] text-[#ffffff] text-[11px]  border-[#ffffff] font-medium w-[20px] h-[14px] rounded-[5.32813rem] flex justify-center items-center '>
                            <span className='relative top-[0.9px] leading-[1] '>
                              {cartQuantity}
                            </span>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </Menu.Button>
                    {/* </NavLink> */}

                    <Transition
                      as={Fragment}
                      // show={true}
                      show={isCartMenuOpen}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      {cartQuantity > 0 ? (
                        <Menu.Items className='absolute top-[21px] right-0 mt-[11px] p-[20px] w-[344px] z-[99] origin-top-right rounded-[30px] bg-[#ffffff] shadow-dropShadoww focus:outline-none'>
                          <Menu.Item className='text-sm11  text-center  mb-[8px]'>
                            <p className='text-pinkColor'>
                              Recently Added
                            </p>
                          </Menu.Item>

                          <div
                            onClick={() => {
                              navigate('/art/art-details');
                            }}
                            className='flex justify-between pb-[8px] cursor-pointer  '
                          >
                            <div className='flex gap-[16px]'>
                              <img
                                className='h-[84px] w-[84px]'
                                src={combo}
                                alt=''
                              />
                              <div>
                                <p className='text-sm12 text-primaryBlack font-medium  w-[172px]'>
                                  Assorted Artnstock Lifestyle <br />
                                  Combo 2024
                                </p>
                                <p className='text-sm11 text-primaryGray '>
                                  Combo ID: ANS15994539
                                </p>
                                <div className='flex gap-[8px] mt-[4px]'>
                                  <p className='text-sm12 text-pinkColor font-medium'>
                                    $200.00
                                  </p>
                                  <p className='text-sm12 text-primaryGray'>
                                    Qty.1
                                  </p>
                                </div>
                                <button className='h-[16px] flex px-[4px] mt-[4px] text-[10px] block leading-[1] text-[#33cdf4] cursor-default border border-[#33cdf4] font-medium items-center justify-center rounded-[8px]'>
                                  10% Discount Applied
                                </button>
                              </div>
                            </div>

                            {/* <p className='text-[15px] text-orangeColor'>
                              $400.00
                            </p> */}
                          </div>
                          <div
                            onClick={() => {
                              navigate('/art/art-details');
                            }}
                            className='flex justify-between pt-[8px] pb-[16px] border-b border-[#efefef] cursor-pointer'
                          >
                            <div className='flex gap-[16px]'>
                              <img
                                className='h-[84px] w-[84px]'
                                src={mug}
                                alt=''
                              />
                              <div>
                                <p className='text-sm12 text-primaryBlack font-medium  w-[172px]'>
                                  Assorted Artnstock Lifestyle <br />
                                  Combo 2024
                                </p>
                                <p className='text-sm11 text-primaryGray '>
                                  Combo ID: ANS15994539
                                </p>
                                <div className='flex  gap-[8px] mt-[4px]'>
                                  <p className='text-sm12 text-pinkColor font-medium'>
                                    $200.00
                                  </p>
                                  <p className='text-sm12 text-primaryGray'>
                                    Qty.1
                                  </p>
                                </div>
                                <div className='flex gap-[4px] pt-[4px]'>
                                  <div>
                                    <Exclusive_Icon16 className='fill-[#b5a56d]' />
                                  </div>
                                  <div className='flex flex-col font-bold'>
                                    <p className='text-[9px] text-[#b5a56d] leading-[1]'>
                                      ARTNSTOCK
                                    </p>
                                    <p className='text-[9px] text-[#b5a56d] leading-[1]'>
                                      EXCLUSIVE
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <p className='text-[15px] text-orangeColor'>
                              $400.00
                            </p> */}
                          </div>

                          <div
                            style={{
                              borderTop: '1px solid #efefef',
                            }}
                            className=' pt-[16px] '
                          >
                            <p className='text-sm11 text-center text-primaryGray'>
                              Total Amount
                            </p>
                            <div className='flex items-baseline justify-center mt-[2px]'>
                              <p className='text-pinkColor text24 '>
                                $
                              </p>
                              <p className='text-pinkColor text24 '>
                                {/* {cartResData?.totalAmount} */}{' '}
                                214.00
                              </p>
                            </div>
                            <div className='w-[100%] flex justify-center mt-[4px]'>
                              <button className='blackBtn'>
                                Checkout
                              </button>
                            </div>
                            <p className='text-primaryGray text-sm11 text-center mt-[8px]'>
                              By placing your order, you agree to the{' '}
                              <span className='text-orangeColor'>
                                Delivery Terms.
                              </span>
                            </p>

                            <p className='text-pinkColor text-sm11 text-center mt-[16px]'>
                              3 Items in your cart
                            </p>

                            <div className='flex w-[100%] justify-center mt-[8px]'>
                              <button
                                onClick={() => {
                                  navigate('/shopping-cart');
                                }}
                                className='gray30HButton'
                              >
                                View Cart
                              </button>
                            </div>
                          </div>
                        </Menu.Items>
                      ) : (
                        <Menu.Items className='absolute  top-[21px] right-0 mt-[11px] p-[20px] mt-[11px] w-[218px] z-50 origin-top-right rounded-[24px] bg-[#ffffff] shadow-dropShadoww focus:outline-none'>
                          <p className='text-[16px] text-pinkColor text-center leading-[20px] font-medium'>
                            Your cart is empty.
                          </p>
                          <p className='text-sm12 text-primaryGray text-center mt-[4px]'>
                            Let us show you the way through.
                          </p>

                          <div className='w-[100%] flex justify-center'>
                            <button
                              onClick={() => {
                                navigate('/art-list');
                              }}
                              className='gray30HButton mt-[8px]'
                            >
                              Start Here
                            </button>
                          </div>
                        </Menu.Items>
                      )}
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          {/* </div> */}
        </div>
      )}

      {/*/ bottom section************************************************ *********************************** */}

      <div className='pb-[8px] px-[24px]'>
        <div className='flex items-center '>
          {/* Search Input */}
          <div
            className={`group relative w-fit flex-1 bg-[#EEEEEE]  rounded-tl-[24px] rounded-bl-[24px] ${
              showDrop ? 'shadow-topShadow' : ''
            }`}
            ref={ref1}
          >
            <input
              className={`${
                showDrop ? styles.activeIp : styles.normalIp
              } custom-input border-none leading-[120%] h-[48px] text-primaryGray outline-none ::placeholder:text-[#BBB] text-[17px] xs:text-[15px] md:text-[17px] text-[17px]`}
              style={{
                caretColor: '#ec008c',
              }}
              type='text'
              placeholder='Search for what inspires you'
              onChange={changeinput}
              onKeyDown={handleKeyOnSearch}
              ref={ref}
              onFocus={() => setShowDrop(true)}
              // onBlur={() => setShowDrop(false)}
              value={searchedArtList}
            />
            <img
              onClick={() => {
                setShowDrop(false);
                setsearchedArtList('');
              }}
              className={`${
                showDrop || searchedArtList.length !== 0
                  ? 'block'
                  : 'hidden'
              } absolute z-20 right-5 top-4 cursor-pointer `}
              src={crossIcon}
              alt=''
            />
            <div
              className={`${
                showDrop ? 'block' : 'hidden'
              } absolute border-[#E9E9E9] shadow-dropShadow bg-[#ffffff] w-[100%] pl-5 pr-4  border-t-0 rounded-bl-[24px] rounded-br-[24px] z-[99999999999]`}
            >
              <div className=' border-t border-[#EFEFEF] pt-5 pb-7 flex'>
                <div className=' w-[340px] border-r border-[#EFEFEF]'>
                  <ul>
                    {searchKeywords?.map((keyword, i) => {
                      if (i < 10) {
                        return (
                          <li
                            key={keyword?.keywordCountId}
                            className='linkSearch'
                            onClick={() =>
                              searchKeyword(keyword?.keyword)
                            }
                          >
                            <span className='cursor-pointer hover:text-primaryBlack'>
                              {keyword?.keyword}
                            </span>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
                <div className='w-[340px] flex flex-col justify-between border-r border-[#EFEFEF] pl-5'>
                  <div>
                    <p className='text-sm18 leading-[29.7px] text-primaryBlack font-semibold'>
                      Recent Searches
                    </p>
                    <ul>
                      {recentSearch?.map((item, index) => {
                        return (
                          <li
                            key={item?.recentlySearchId}
                            className='linkSearch'
                            onClick={() =>
                              searchRecentArt(item?.text)
                            }
                          >
                            <span className='cursor-pointer hover:text-primaryBlack'>
                              {item?.text}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <button
                    onClick={() =>
                      cleaRecentSearch(userDetails?.userId)
                    }
                    style={{ width: 'fit-content' }}
                    className='gray30HButton'
                  >
                    Clear All Recent Searches
                  </button>
                </div>
                <div className='flex-1 pl-5 flex flex-col justify-between'>
                  <div>
                    <p className='text-sm18 leading-[29.7px] text-primaryBlack font-semibold'>
                      Recently Viewed
                    </p>
                    <div className='flex gap-2.5 flex-wrap'>
                      {recentlyViewd?.map((recent) => {
                        return (
                          <div
                            key={recent.recentlyViewId}
                            className='rounded-2xl w-[6.25rem] h-[6.25rem] overflow-hidden cursor-pointer'
                            onClick={() =>
                              goToArtDetailsPage(
                                recent?.artMaster?.artId
                              )
                            }
                          >
                            <img
                              className='w-[6.25rem] h-[6.25rem] hover:brightness-[80%]'
                              src={
                                recent?.artMaster?.imageMaster
                                  ?.imageOrientation?.squareUrl
                              }
                              alt=''
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <button
                    style={{ width: 'fit-content' }}
                    className='gray30HButton mt-7'
                    onClick={() =>
                      clearRecentSearch(userDetails?.userId)
                    }
                  >
                    Clear All Recently Viewed
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="rightdiv py-3 bg-[#E6E6E6] flex"> */}

          <div
            onMouseEnter={() => {
              setDropArrowColor('#333333');
              setartIconColor('#333333');
              setphotosIconColor('#333333');
              setfootageIconColor('#333333');
              setmusicIconColor('#333333');
              settemplatesIconColor('#333333');
              setproductsIconColor('#333333');
            }}
            onMouseLeave={() => {
              setDropArrowColor('#878787');
              setartIconColor('#888888');
              setphotosIconColor('#888888');
              setfootageIconColor('#888888');
              setmusicIconColor('#888888');
              settemplatesIconColor('#888888');
              setproductsIconColor('#888888');
            }}
            className='group relative text-primaryGray border-l-[2px] border-[#DADADA] hover:text-primaryBlack dropdown flex items-center cursor-pointer h-[48px] bg-[#E6E6E6]'
          >
            <div className=' '>
              <a className='px-[16px] xs:px-[0px] s:px-[16px] xs:pr-[8px]  text-[14px] font-medium flex'>
                {!isXS ? (
                  <>
                    {selectItems === 'Art' ? (
                      <div>
                        {/* <img
            src={artIcon}
            className='inline-block mr-3'
            alt=''
          /> */}
                        <ArtIcon
                          style={{
                            fill: `${artIconColor}`,
                            display: 'inline-block',
                            marginRight: '8px',
                            marginBottom: '1px',
                          }}
                        />
                        Art
                      </div>
                    ) : selectItems === 'Photos' ? (
                      <div>
                        {/* <img
            src={photoIcon}
            className='inline-block mr-3'
            alt=''
          /> */}
                        <PhotoIcon
                          style={{
                            fill: `${photosIconColor}`,
                            display: 'inline-block',
                            marginRight: '8px',
                            marginBottom: '1px',
                          }}
                        />
                        Photos
                      </div>
                    ) : selectItems === 'Footage' ? (
                      <div>
                        {/* <img
            src={footageIcon}
            className='inline-block mr-3'
            alt=''
          /> */}
                        <FootageIcon
                          style={{
                            fill: `${footageIconColor}`,
                            display: 'inline-block',
                            marginRight: '8px',
                            marginBottom: '1px',
                          }}
                        />
                        Footage
                      </div>
                    ) : selectItems === 'Music' ? (
                      <div>
                        {/* <img
            src={musicIcon}
            className='inline-block mr-3'
            alt=''
          /> */}
                        <MusicIcon
                          style={{
                            fill: `${musicIconColor}`,
                            display: 'inline-block',
                            marginRight: '8px',
                            marginBottom: '1px',
                          }}
                        />
                        Music
                      </div>
                    ) : selectItems === 'Templates' ? (
                      <div>
                        {/* <img
            src={templatesIcon}
            className='inline-block mr-3'
            alt=''
          /> */}
                        <TemplatesIcon
                          style={{
                            fill: `${templatesIconColor}`,
                            display: 'inline-block',
                            marginRight: '8px',
                            marginBottom: '1px',
                          }}
                        />
                        Templates
                      </div>
                    ) : selectItems === 'Products' ? (
                      <div>
                        {/* <img
            src={productsIcon}
            className='inline-block mr-3'
            alt=''
          /> */}
                        <ProductsIcon
                          style={{
                            fill: `${productsIconColor}`,
                            display: 'inline-block',
                            marginRight: '8px',
                            marginBottom: '1px',
                          }}
                        />
                        Products
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {/* <img src={dropArrow} alt='' /> */}
                <div className='flex items-center ml-2'>
                  <DropArrow
                    style={{
                      fill: dropArrowColor,
                    }}
                  />
                </div>
              </a>
            </div>
            <div className='group-hover:block shadow-dropShadoww z-[99] rounded-[16px] z-10 dropdown-menu absolute top-12 hidden h-auto w-[8.813rem]'>
              <ul className='top-0 bg-white  rounded-[16px] bg-[#ffffff] hover:overflow-hidden'>
                <li
                  className='px-3.5 h-[38px] flex items-center hover:bg-[#F0F0F0] border-b border-[#EFEFEF] text-primaryGray hover:text-primaryBlack'
                  onClick={() => setSelectItems('Art')}
                  onMouseEnter={() => {
                    setDropArtColor('#333333');
                  }}
                  onMouseLeave={() => {
                    setDropArtColor('#888888');
                  }}
                >
                  <a className='block cursor-pointer text-[0.813rem] font-normal leading-[2.438rem]'>
                    {/* <img
            src={artIcon}
            className='inline-block mr-3'
            alt=''
          /> */}
                    <ArtIcon
                      style={{
                        fill: DropArtColor,
                        display: 'inline-block',
                        marginRight: '12px',
                      }}
                    />
                    Art
                  </a>
                </li>
                <li
                  className=' px-3.5 h-[38px] flex items-center hover:bg-[#F0F0F0] border-b border-[#EFEFEF] text-primaryGray hover:text-primaryBlack'
                  onClick={() => setSelectItems('Photos')}
                  onMouseEnter={() => {
                    setDropPhotosColor('#333333');
                  }}
                  onMouseLeave={() => {
                    setDropPhotosColor('#888888');
                  }}
                >
                  <a className='block cursor-pointer text-[0.813rem]  font-normal leading-[2.438rem]'>
                    {/* <img
            src={photoIcon}
            className='inline-block mr-3'
            alt=''
          /> */}
                    <PhotoIcon
                      style={{
                        fill: DropPhotosColor,
                        display: 'inline-block',
                        marginRight: '12px',
                      }}
                    />
                    Photos
                  </a>
                </li>
                <li
                  className=' px-3.5 h-[38px] flex items-center hover:bg-[#F0F0F0] border-b border-[#EFEFEF] text-primaryGray hover:text-primaryBlack'
                  onClick={() => setSelectItems('Footage')}
                  onMouseEnter={() => {
                    setDropFootageColor('#333333');
                  }}
                  onMouseLeave={() => {
                    setDropFootageColor('#888888');
                  }}
                >
                  <a className='block cursor-pointer text-[0.813rem] font-normal  leading-[2.438rem]'>
                    {/* <img
            src={footageIcon}
            className='inline-block mr-3'
            alt=''
          /> */}
                    <FootageIcon
                      style={{
                        fill: DropFootageColor,
                        display: 'inline-block',
                        marginRight: '12px',
                      }}
                    />
                    Footage
                  </a>
                </li>
                <li
                  className=' px-3.5 h-[38px] flex items-center hover:bg-[#F0F0F0] border-b border-[#EFEFEF] text-primaryGray hover:text-primaryBlack'
                  onClick={() => setSelectItems('Music')}
                  onMouseEnter={() => {
                    setDropMusicColor('#333333');
                  }}
                  onMouseLeave={() => {
                    setDropMusicColor('#888888');
                  }}
                >
                  <a className='block cursor-pointer text-[0.813rem]  font-normal  leading-[2.438rem]'>
                    {/* <img
            src={musicIcon}
            className='inline-block mr-3'
            alt=''
          /> */}
                    <MusicIcon
                      style={{
                        fill: DropMusicColor,
                        display: 'inline-block',
                        marginRight: '12px',
                      }}
                    />
                    Music
                  </a>
                </li>
                <li
                  className=' px-3.5 h-[38px] flex items-center hover:bg-[#F0F0F0] border-b border-[#EFEFEF] text-primaryGray hover:text-primaryBlack'
                  onClick={() => setSelectItems('Templates')}
                  onMouseEnter={() => {
                    setDropTemplatesColor('#333333');
                  }}
                  onMouseLeave={() => {
                    setDropTemplatesColor('#888888');
                  }}
                >
                  <a className='block cursor-pointer text-[0.813rem]  font-normal  leading-[2.438rem]'>
                    {/* <img
            src={templatesIcon}
            className='inline-block mr-3'
            alt=''
          /> */}
                    <TemplatesIcon
                      style={{
                        fill: DropTemplatesColor,
                        display: 'inline-block',
                        marginRight: '12px',
                      }}
                    />
                    Templates
                  </a>
                </li>
                <li
                  className=' px-3.5 h-[38px] flex items-center hover:bg-[#F0F0F0] text-primaryGray hover:text-primaryBlack'
                  onClick={() => setSelectItems('Products')}
                  onMouseEnter={() => {
                    setDropProductColor('#333333');
                  }}
                  onMouseLeave={() => {
                    setDropProductColor('#888888');
                  }}
                >
                  <a className='block cursor-pointer text-[0.813rem]  font-normal  leading-[2.438rem]'>
                    {/* <img
            src={productsIcon}
            className='inline-block mr-3'
            alt=''
          /> */}
                    <ProductsIcon
                      style={{
                        fill: DropProductColor,
                        display: 'inline-block',
                        marginRight: '12px',
                      }}
                    />
                    Products
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <StyledPopup
            trigger={
              <button
                onMouseEnter={() => {
                  setImageIconColor('#333333');
                }}
                onMouseLeave={() => {
                  setImageIconColor('#888888');
                }}
                className='border-l xs:border-l-[0px] s:border-l border-[#DADADA] xs:px-[0px] s:px-4 py-3.5 bg-[#E6E6E6] px-4 text-primaryGray hover:text-primaryBlack text-sm14 font-medium'
              >
                {/* <img
        src={imageIcon}
        alt=''
      /> */}
                <ImageIcon
                  className='inline-block xs:mr-[8px] s:mr-2  mr-2'
                  style={{
                    fill: imageIconColor,
                  }}
                />
                {!isXS ? (
                  <span className='relative top-[1px]'>
                    Search by image
                  </span>
                ) : (
                  <></>
                )}
              </button>
            }
            modal
          >
            {(close) => (
              <div className='bg-[#F9F9F9] border h-[21.75rem] border-[#F1F1F1] flex justify-center items-center rounded-3xl'>
                <div className='text-center'>
                  <p className='text-[38px] leading-[38px] font-semibold text-primaryBlack'>
                    Search by image
                  </p>
                  <p className='text-sm11 text-primaryGray mt-1 mb-4'>
                    Choose any of the methods below to start your
                    search
                  </p>
                  <p className='mb-3 text-sm18 font-semibold text-primaryBlack'>
                    Drag and drop your image here
                  </p>
                  <div className='flex gap-2.5 mx-auto justify-center'>
                    <button className='blackBtn'>
                      Choose a File
                    </button>
                    <button onClick={close} className='outlineBtn'>
                      Cancel
                    </button>
                  </div>
                  <p className='text-sm11 text-primaryGray mt-2.5'>
                    Your JPEG or PNG image can’t be larger than 4000px
                    in width <br /> and height, and must be smaller
                    than 5 MB in file size.
                  </p>
                </div>
              </div>
            )}
          </StyledPopup>

          <button
            onClick={searchArts}
            className='h-[48px] w-[106px] flex items-center justify-center rounded-r-[5000px]  bg-placeholderColor pl-[16px] pr-[24px] xs:pl-[12px] xs:pr-[16px] md:pl-[16px] md:pr-[24px] flex items-center text-primaryBlack text-sm14 font-medium'
          >
            {/* <img
              className='inline-block mr-[8px]'
              src={searchIcon}
              alt=''
            /> */}
            <div>
              <SearchIcon className='inline-block xs:mr-[0px] s:mr-[8px] mr-[8px] fill-[#363636]' />
            </div>
            {!isXS ? (
              <span className='relative top-[1px]'>Search</span>
            ) : (
              <></>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default Mainbar;
