import { useState, useMemo, useEffect } from 'react';
import { setSelectedImages } from '../../../store/imageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { popupSliceAction } from '../../../store/popupSlice';

const GrayBox = ({ card, style, TempCards, cardsLength }) => {
  const [hovered, setHovered] = useState(false);

  // this is checked logic for upload context which was initial flow
  const [isChecked, setIsChecked] = useState(false);

  const dispatch = useDispatch();

  const selectedImages = useSelector(
    (state) => state.images.selectedImages
  );
  const draftCount = useSelector(
    (state) => state.contriCounter.toSubmitFilesCount
  );

  useEffect(() => {
    console.log(selectedImages);
  }, [selectedImages]);

  const backgroundImageStyle = useMemo(
    () => ({
      backgroundImage: `url(${URL.createObjectURL(card)})`,
    }),
    [card]
  );

  // this is checked logic for upload context which was initial flow
  const handleButtonClick = () => {
    if (isChecked) {
      dispatch(
        setSelectedImages(
          selectedImages.filter((image) => image !== card)
        )
      );
      // setIsChecked(!isChecked);
      // const findImage = selectedImages.find((item) => item === card);
    } else {
      if (selectedImages.length + draftCount > 16) {
        dispatch(popupSliceAction.setBackgroundColor('bg-pinkColor'));
        dispatch(
          popupSliceAction.setTitle1(
            'The To Submit Files draft is restricted to a maximum of 15 files in total.'
          )
        );
        dispatch(popupSliceAction.setTitle2(''));
        dispatch(popupSliceAction.setOpenPopup());
        dispatch(popupSliceAction.setAnimationOpen());
        return;
      } else {
        dispatch(setSelectedImages([...selectedImages, card]));
      }
    }
  };

  const handleCheckboxChange = () => {
    if (isChecked) {
      dispatch(
        setSelectedImages(
          selectedImages.filter((image) => image !== card)
        )
      );
      // setIsChecked(!isChecked);
      // const findImage = selectedImages.find((item) => item === card);
    } else {
      if (selectedImages.length + draftCount > 16) {
        dispatch(popupSliceAction.setBackgroundColor('bg-pinkColor'));
        dispatch(
          popupSliceAction.setTitle1(
            'The To Submit Files draft is restricted to a maximum of 15 files in total.'
          )
        );
        dispatch(popupSliceAction.setTitle2(''));
        dispatch(popupSliceAction.setOpenPopup());
        dispatch(popupSliceAction.setAnimationOpen());
        return;
      } else {
        dispatch(setSelectedImages([...selectedImages, card]));
      }
    }
  };

  useEffect(() => {
    const abc = checkCheckboxTrueOrNot();
    setIsChecked(abc);
  }, [selectedImages]);

  const checkCheckboxTrueOrNot = () => {
    const bool = selectedImages.find((item) => item === card);
    return bool;
  };

  useEffect(() => {
    console.log('TempCards', TempCards);
  }, [TempCards]);

  return (
    <div
      className={`box-border h-[127px] w-[127px] rounded-[16px] relative`}
      // h-[127px] w-[126.99px]
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={style}
    >
      <div
        className={`h-full w-full bg-no-repeat bg-center bg-cover filter z-[99] bg-[#f7f7f7] rounded-[16px] brightness-100 absolute overflow-hidden inset-0 ${
          selectedImages.find((item) => item === card) || hovered
            ? 'brightness-[80%]'
            : ''
        }`}
        // style={backgroundImageStyle}
      ></div>

      <div
        className={`h-full w-full bg-no-repeat bg-center bg-cover flex justify-center items-center filter z-[999] rounded-[16px] brightness-100 absolute overflow-hidden inset-0 ${
          selectedImages.find((item) => item === card) || hovered
            ? 'brightness-[80%]'
            : ''
        }`}
        style={backgroundImageStyle}
      >
        {TempCards === card ? (
          <div className='w-[100px] h-[4px] bg-[#eaeaea] rounded-[5000px]'>
            <div
              style={{
                width: `70%`,
                // width: `${userDetail?.profileProgress}%`,
              }}
              className={` h-[4px] gradient-background3 rounded-l-[5000px]`}
            ></div>{' '}
          </div>
        ) : (
          <></>
        )}
      </div>

      {hovered ? (
        <div className='absolute z-[999] inset-0 flex items-center justify-center'>
          <div
            className='imageHoverButtonSmall'
            onClick={handleButtonClick}
          >
            {selectedImages.find(
              (item) => item.imageId === card.imageId
            ) ? (
              <span>Deselect</span>
            ) : (
              <span>Select</span>
            )}
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {selectedImages.find((item) => item === card) || hovered ? (
        <label className='containerCheckWhite p-2'>
          <input
            type='checkbox'
            checked={isChecked}
            onChange={handleCheckboxChange}
          />{' '}
          <span className='checkmarkWhite z-[999]'></span>
        </label>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default GrayBox;
