import React, { useEffect, useState } from 'react';
import lock from '../../assets/images/ShoppingCart/lock.png';
import leftarrow from '../../assets/images/ShoppingCart/leftarrow.png';
import Cards from '../../assets/images/ShoppingCart/Cards.png';
import removed_1 from '../../assets/images/ShoppingCart/removed_1.png';
import orderSummary from '../../assets/images/ShoppingCart/orderSummary.png';
import Paymentmethods from '../../assets/images/ShoppingCart/PaymentMethods.png';
import Footer from '../footer/Footer';
import msgBg from '../../assets/images/ShoppingCart/messageBG.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { httpClient } from '../../axios';
import checkBtnBackImg from '../../assets/images/ShoppingCart/CompleteOrderBG.svg';
import { ReactComponent as Exclusive_Icon16 } from '../../assets/images/contributor/add_details/Icon_Exclusive_16x16.svg';
import combo from '../../assets/images/Menubar/combo.png';
import mug from '../../assets/images/Menubar/mug.png';
import { ReactComponent as EditIcon } from '../../assets/images/contributor/svg/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/contributor/svg/delete.svg';
import { ReactComponent as Icon_Lock16 } from '../../assets/images/accountSettings/Icon_Lock_16x16.svg';
import { ReactComponent as Icon_RightArrow_12x12 } from '../../assets/images/ShoppingCart/28Oct24/Icon_RightArrow_12x12.svg';

// import styled from 'styled-components';
// import Popup from 'reactjs-popup';

import styled from 'styled-components';
import Popup from 'reactjs-popup';

import Certificate from '../contributor/contri-components/Certificate';

import useRazorpay from 'react-razorpay';
import logo from '../../assets/Favicon.svg';
import { orderModel } from '../../models/allModel';
import { toast } from 'react-toastify';
import dropArrow from '../../assets/images/Icons/Down arrow.svg';
import { Menu } from '@headlessui/react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useDispatch, useSelector } from 'react-redux';
import { cartSliceAction } from '../../store/cartSlice';
import { useRef } from 'react';
import welcomeOffer from '../../assets/images/trackOrder/welcomeOffer.jpg';
import { ReactComponent as LogoWithoutText } from '../../assets/images/ShoppingCart/logoWithoutText.svg';

import minusIcon from '../../assets/images/Icons/minusIcon.svg';
import plusIcon from '../../assets/images/Icons/plusIcon.svg';

import Thumbnail1 from '../../assets/images/Checkout/Thumbnail1.jpg';

import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';

import { ReactComponent as Paypal } from '../../assets/images/footer/Paypal.svg';
import { ReactComponent as Visa } from '../../assets/images/footer/Visa.svg';
import { ReactComponent as Mastercard } from '../../assets/images/footer/Mastercard.svg';
import { ReactComponent as AmerExpress } from '../../assets/images/footer/AmerExpress.svg';
import { ReactComponent as Discover } from '../../assets/images/footer/Discover.svg';

import hdfc from '../../assets/images/Checkout/hdfc.jpg';
import ici from '../../assets/images/Checkout/ici.jpg';
import axis from '../../assets/images/Checkout/axis.jpg';
import sbi from '../../assets/images/Checkout/sbi.jpg';
import kotak from '../../assets/images/Checkout/kotak.jpg';

import Certificate1 from '../../assets/images/FooterSubComp/Certificate1.png';
import Certificate2 from '../../assets/images/FooterSubComp/Certificate2.png';
import Certificate3 from '../../assets/images/FooterSubComp/Certificate3.png';

const StyledPopupDelete = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 24px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: fit-content;
  }
`;

const StyledPopupAddCard = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 32px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    width: 491px;
  }
`;

const StyledPopupAddressAdd = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    // text-align: center;
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 20px;
    max-width: 488px;
    width: 100%;
    overflow: hidden;
    border: none;
  }
`;
const StyledPopupAddressEdit = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    // text-align: center;
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 20px;
    max-width: 488px;
    width: 100%;
    overflow: hidden;
    border: none;
  }
`;

const Checkout = () => {
  const [cartData, setCartData] = useState();
  const [userData, setUserData] = useState();
  const [paymentCheck, setPaymentCheck] = useState('card');
  const [finalCheckout, setFinalCheckout] = useState('false');

  const [SelectedTab, setSelectedTab] = useState('Card 1');

  const shippingRef = useRef();

  let location = useLocation();

  useEffect(() => {
    console.log('cartData', cartData);
  }, [cartData]);

  useEffect(() => {
    if (location?.state?.type === 'all') {
      getUserIdWiseCart(location?.state?.userId);
      getShiipingList();
    } else if (location?.state?.type === 'one') {
      getUserIdWiseBuyNow(location?.state);
      // getShiipingList();
    }
  }, [location?.state?.userId]);

  // const getUserIdWiseCart = (userId) => {
  //   httpClient
  //     .get(`/cart_master/getUserIdWiseCartData/${userId}`)
  //     .then((res) => {
  //       console.log(res.data);
  //       setSelect(res?.data?.shippingMethod);
  //       shippingRef.current = res?.data?.shippingMethod;
  //       setUserData(res?.data['userMaster']);

  //       setCartData(res?.data);
  //       setApplyPromo(res?.data?.codeType != null ? true : false);

  //       setShipDataToResData();
  //       if (res?.data?.codeType === 'Promo Code') {
  //         setPromoCode(res?.data?.promoCode);
  //       } else if (res?.data?.codeType === 'Promo Code') {
  //         setGiftCode(res?.data?.giftCode);
  //       }
  //     });
  // };

  const getUserIdWiseCart = async (userId) => {
    try {
      const res = await httpClient.get(
        `/cart_master/getUserIdWiseCartDetails/${userId}`
      );
      console.log(res.data);
      setSelect(res?.data?.shippingMethod);
      shippingRef.current = res?.data?.shippingMethod;
      setUserData(res?.data?.userMaster);
      setCartData(res?.data);
      setApplyPromo(res?.data?.codeType != null ? true : false);
      setShipDataToResData();

      if (res?.data?.codeType === 'Promo Code') {
        setPromoCode(res?.data?.promoCode);
      } else if (res?.data?.codeType === 'Promo Code') {
        setGiftCode(res?.data?.giftCode);
      }
    } catch (error) {
      // Handle errors here if needed
      console.error('Error fetching data:', error);
    }
  };

  // this setShipDataToResData function sets shipping data to residential data on mount of the component.

  const setShipDataToResData = () => {
    setIsChecked(true);
    setUserData({
      ...userData,
      ['residentialAddress']: {
        ...userData['residentialAddress'],
        ['addressLine1']: userData.shippingAddress.addressLine1,
        ['addressLine2']: userData.shippingAddress.addressLine2,
        ['cityName']: userData.shippingAddress.cityName,
        ['countryName']: userData.shippingAddress.countryName,
        ['phoneNo']: userData.shippingAddress.phoneNo,
        ['postalCode']: userData.shippingAddress.zipCode,
        ['stateName']: userData.shippingAddress.stateName,
      },
    });
  };

  const getUserIdWiseBuyNow = async (state) => {
    try {
      let object = {
        cartId: state.cartId,
        userId: state.userId,
        cartArtFrameId: state.cartArtFrameId,
        qty: state.qty,
      };

      const res = await httpClient.post(
        '/cart_master/BuyNow',
        object
      );

      console.log(res.data);

      setSelect(res?.data?.shippingMethod);
      shippingRef.current = res?.data?.shippingMethod;
      setUserData(res?.data?.userMaster);
      setCartData(res?.data);
      setApplyPromo(res?.data?.codeType != null ? true : false);

      if (res?.data?.codeType === 'Promo Code') {
        setPromoCode(res?.data?.promoCode);
      } else if (res?.data?.codeType === 'Gift Code') {
        setGiftCode(res?.data?.giftCode);
      }
    } catch (error) {
      // Handle errors if any
      console.error('Error fetching data:', error);
      // You might want to set an error state or perform error handling here
    }
  };

  function addZeroes(num) {
    const dec = String(num).split('.')[1];
    const len = dec && dec.length > 1 ? dec.length : 2;
    return Number(num).toFixed(len);
  }

  // Razorpay
  const [orderId, setOrderId] = useState();
  const [Razorpay] = useRazorpay();

  const createOrder = async (amount) => {
    // console.log(amount);
    const res = await httpClient.get(`/payment/${amount}`);

    console.log('createOrder: ', res?.data);

    setOrderId(res?.data);
  };

  const handlePayment = async (amount) => {
    amount = amount * 100;
    await createOrder(amount);

    const options = {
      key: 'rzp_test_HCTUbGUU3bXXQ6',
      amount: amount,
      currency: 'INR',
      name: 'Artnstock',
      description: 'Test Transaction',
      image: logo,
      order_id: orderId,
      handler: async (res) => {
        console.log(res);
        if (res) {
          await createNewOrder(res);
        }
      },
      prefill: {
        name: `${userData?.userFirstName} ${userData?.userLastName}`,
        email: userData?.emailAddress,
        contact: userData?.shippingAddress?.phoneNo,
      },
      notes: {
        address: 'Test',
      },
      theme: {
        color: '#9772FB',
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.on('payment.failed', function (response) {
      toast.error(
        'Something Went Wrong',
        response?.error?.description
      );
    });

    rzpay.open();
  };
  //End Razorpay

  // order Create
  const createNewOrder = async (paymentRes) => {
    //   {
    //     "razorpay_payment_id": "pay_MG1fxTeT3mr34J",
    //     "razorpay_order_id": "order_MG0wO31u2aNIFw",
    //     "razorpay_signature": "c12c45f998d441d55e00a5af05bf065c0d712122334ff7ef12c979f7147aeb94"
    // }

    try {
      orderModel.orderPaymentStatus = 'Success';
      orderModel.paymentInformation.razorpayOrderId =
        paymentRes?.razorpay_order_id;
      orderModel.paymentInformation.razorpayPaymentId =
        paymentRes?.razorpay_payment_id;
      orderModel.paymentInformation.signature =
        paymentRes?.razorpay_signature;
      orderModel.paymentInformation.status = 'Success';
      orderModel.userId = location?.state?.userId;
      // let idArray = [];
      // for (let i = 0; i < cartData?.list?.length; i++) {
      //   idArray.push(cartData?.list[i]?.cartArtFrameId);
      // }
      orderModel.cartArtFrameId = cartData?.cartArtFrameMaster;
      orderModel.cartProductId = cartData?.cartProductMaster;
      orderModel.cartAdminArtProductId =
        cartData?.cartAdminArtProductMaster;

      orderModel.shippingMethodId =
        cartData?.shippingMethod?.shippingMethodId;

      console.log(orderModel);
      // herenow

      await httpClient
        .post('/order_master/create', orderModel)
        .then((res) => {
          // console.log(res.data);
          toast.success('Order Successfull');

          pathhcust('3');
        });
    } catch (err) {
      console.log(err);
    }
  };

  const navigate = useNavigate();

  const pathhcust = (val) => {
    navigate('/customer-tabs', {
      state: val,
    });
  };

  // Gift and Promo code
  const [applyPromo, setApplyPromo] = useState(false);
  const [giftCode, setGiftCode] = useState('');
  const [promoCode, setPromoCode] = useState('');

  const addGiftCode = async () => {
    try {
      const promoObj = {
        giftCode: String,
        userMasterId: String,
      };
      promoObj.userMasterId = location?.state?.userId;
      promoObj.giftCode = giftCode;
      console.log(promoObj);
      await httpClient
        .post('/user_gift_code_master/create', promoObj)
        .then((res) => {
          // console.log(res.data);
          toast.success('added Gift code');
          getUserIdWiseCart();
        });
    } catch (err) {
      console.log(err);
    }
  };

  const addPromoCode = async () => {
    try {
      const promoObj = {
        promoCode: String,
        userId: String,
      };
      promoObj.userId = location?.state?.userId;
      promoObj.promoCode = promoCode;
      console.log(promoObj);
      await httpClient
        .post('/use_promo_code_master/create')
        .then((res) => {
          console.log(res.data);
          toast.success('added promo code');
        });
    } catch (err) {
      console.log(err);
    }
  };

  //  Dropdown

  const [openDropdown, setOpenDropDown] = useState(false);
  const [shipping, setShipping] = useState();
  const [select, setSelect] = useState(null);

  const dropdownEvent = () => {
    setOpenDropDown(!openDropdown);
  };

  const getShiipingList = async () => {
    try {
      const res = await httpClient.get('/shipping_method');

      setShipping(res?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const outsideClickEvent = () => {
    setOpenDropDown(false);
  };
  const ref1 = useDetectClickOutside({
    onTriggered: outsideClickEvent,
  });

  const selectValue = (data) => {
    setSelect(data);
    setOpenDropDown(false);
    if (
      shippingRef.current?.shippingMethodId === data?.shippingMethodId
    ) {
      // console.log("No");
    } else {
      // console.log("Yes");
      updateShippingMethod(data);
    }
  };

  // Remove Order
  const cartCount = useSelector((state) => state.cart.cartCount);
  const dispatch = useDispatch();

  const removeOrder = async (cartArtFrameId) => {
    try {
      await httpClient
        .delete(`/cart_art_frame_master/deleteCart/${cartArtFrameId}`)
        .then((res) => {
          // console.log(res.data);
          getUserIdWiseCart(location?.state?.userId);
          dispatch(cartSliceAction.setCartCount(cartCount - 1));
          return res.data;
        });
    } catch (err) {
      console.log(err);
      getUserIdWiseCart(location?.state?.userId);
    }
  };

  // update shipping method

  const updateShippingMethod = (shippingData) => {
    try {
      const shippingModel = {
        cartId: String,
        shippingMethod: {
          dayToReceive: String,
          shippingMethodId: String,
          shippingMethodName: String,
          shippingMethodPrice: 0,
        },
      };
      shippingModel.cartId = cartData?.cartId;
      shippingModel.shippingMethod = shippingData;
      // console.log(shippingModel)
      httpClient
        .put('/cart_master/updateCartShippingMethod', shippingModel)
        .then((res) => {
          if (res.data) {
            getUserIdWiseCart(location?.state?.userId);
          }
        });
    } catch (err) {
      getUserIdWiseCart(location?.state?.userId);
      console.log(err);
    }
  };

  // Edit Art

  const editArt = () => {};

  const [selectCardTypeValue, setselectCardTypeValue] = useState();
  const [showCardType, setShowCardType] = useState(false);

  const dropList = [{ name: 'Credit Card' }, { name: 'Debit Card' }];

  const [isChecked, setIsChecked] = useState(false);

  const handleInputChangeNested = (topName, lowName, value) => {
    setUserData({
      ...userData,
      [topName]: { ...userData[topName], [lowName]: value },
    });
  };

  // const changeCheckBox = (event) => {
  //   setIsChecked(event.target.checked);
  //   if (!isChecked) {
  //     setUserData({
  //       ...userData,
  //       ['residentialAddress']: {
  //         ...userData['residentialAddress'],
  //         ['addressLine1']: userData.shippingAddress.addressLine1,
  //         ['addressLine2']: userData.shippingAddress.addressLine2,
  //         ['cityName']: userData.shippingAddress.cityName,
  //         ['countryName']: userData.shippingAddress.countryName,
  //         ['phoneNo']: userData.shippingAddress.phoneNo,
  //         ['postalCode']: userData.shippingAddress.zipCode,
  //         ['stateName']: userData.shippingAddress.stateName,
  //       },
  //     });
  //   } else {
  //     setUserData({
  //       ...userData,
  //       ['residentialAddress']: {
  //         ...userData['residentialAddress'],
  //         ['addressLine1']: '',
  //         ['addressLine2']: '',
  //         ['cityName']: '',
  //         ['countryName']: '',
  //         ['phoneNo']: '',
  //         ['postalCode']: '',
  //         ['stateName']: '',
  //       },
  //     });
  //   }
  // };

  useEffect(() => {
    console.log(userData);
  }, [userData]);

  const userId = useSelector((state) => state.auth.userId);

  const [userDetail, setUserDetail] = useState({});

  // const navigate = useNavigate();

  const getUserRecord = async () => {
    try {
      const res = await httpClient.get(
        `/user_master/getUserRecord/${userId}`
      );
      setUserDetail(res.data);
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserDetail({ ...userDetail, [name]: value });
  };

  // const handleInputChangeNested = (topName, lowName, value) => {
  //   setUserDetail({
  //     ...userDetail,
  //     [topName]: { ...userDetail[topName], [lowName]: value },
  //   });
  // };

  // const handleInputChangeAddAddress = (event) => {
  //   const { name, value } = event.target;
  //   setAddAdress({ ...addAdress, [name]: value });
  // };

  const handleInputChangeAddAddressNested = (event) => {
    const { name, value } = event.target;
    // setAddAdress({
    //   ...addAdress,
    //   shippingAddress: { ...addAdress, [name]: value },
    // });

    setAddAdress((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        [name]: value,
      },
    }));
  };
  const handleInputChangeEditAddressNested = (event) => {
    const { name, value } = event.target;
    // setAddAdress({
    //   ...addAdress,
    //   shippingAddress: { ...addAdress, [name]: value },
    // });

    setEditAdress((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        [name]: value,
      },
    }));
  };

  // const [isChecked, setIsChecked] = useState(true);

  const changeCheckBox = (event) => {
    setIsChecked(event.target.checked);
    if (!isChecked) {
      setUserDetail({
        ...userDetail,
        ['residentialAddress']: {
          ...userDetail['residentialAddress'],
          ['addressLine1']: userDetail.shippingAddress.addressLine1,
          ['addressLine2']: userDetail.shippingAddress.addressLine2,
          ['cityName']: userDetail.shippingAddress.cityName,
          ['countryName']: userDetail.shippingAddress.countryName,
          ['phoneNo']: userDetail.shippingAddress.phoneNo,
          ['postalCode']: userDetail.shippingAddress.zipCode,
          ['stateName']: userDetail.shippingAddress.stateName,
        },
      });
    } else if (isChecked) {
      setUserDetail({
        ...userDetail,
        ['residentialAddress']: {
          ...userDetail['residentialAddress'],
          ['addressLine1']: '',
          ['addressLine2']: '',
          ['cityName']: '',
          ['countryName']: '',
          ['phoneNo']: '',
          ['postalCode']: '',
          ['stateName']: '',
        },
      });
    }
  };

  const updateUserMaster = async () => {
    try {
      const res = await httpClient.put(
        '/user_master/update',
        userDetail
      );
      toast.success('Successfully Updated User Details');
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getUserRecord();
    getAllCountries();
    getAllStatesShip();
    getAllStatesResi();
    getAllCitiesShip();
    getAllCitiesResi();
  }, []);

  const [locationMailling, setlocationMailling] = useState(false);
  const [cityMailling, setcityMailling] = useState(false);
  const [stateMailling, setstateMailling] = useState(false);

  const [locationResidential, setlocationResidential] =
    useState(false);
  const [cityResidential, setcityResidential] = useState(false);
  const [stateResidential, setstateResidential] = useState(false);

  const [allDropHover, setAllDropHover] = useState(false);

  const scrollbarStyle = `
  ::-webkit-scrollbar {
    background: transparent!important;
    width: 7px;
 
  }

  ::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 5px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb:hover { 
    background: gray; /* Change the thumb background color on hover */
  }

    /* Add any other scrollbar styles here */
  `;

  const handleClickOutsideLocation = () => {
    // Code to handle click outside
    setlocationMailling(false);
  };

  const locationClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideLocation,
  });

  const handleClickOutsideLocationResi = () => {
    // Code to handle click outside
    setlocationResidential(false);
  };

  const locationResiClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideLocationResi,
  });

  const handleClickOutsideCityMailling = () => {
    // Code to handle click outside
    setcityMailling(false);
  };

  const cityMaillingClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideCityMailling,
  });

  const handleClickOutsideCityResidential = () => {
    // Code to handle click outside
    setcityResidential(false);
  };

  const cityResidentialClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideCityResidential,
  });

  const handleClickOutsideStateMailling = () => {
    setstateMailling(false);
  };

  const stateMaillingClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideStateMailling,
  });

  const handleClickOutsideStateResidential = () => {
    setstateResidential(false);
  };

  const stateResidentialClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideStateResidential,
  });

  const [countriesList, setCountriesList] = useState([]);
  const [countriesListTemp, setCountriesListTemp] = useState([]);
  const [countriesListShipTemp, setCountriesListShipTemp] = useState(
    []
  );

  const getAllCountries = async () => {
    try {
      const res = await httpClient.get(
        `/country_master/getAllCountryNames`
      );

      setCountriesList(res.data.countries);
      setCountriesListTemp(res.data.countries);
      setCountriesListShipTemp(res.data.countries);

      console.log('countriesList: ', res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [stateList, setStateList] = useState();
  const [stateListTemp, setStateListTemp] = useState();
  const [stateListShipTemp, setStateListShipTemp] = useState();

  const getAllStatesResi = async () => {
    try {
      const res = await httpClient.get(
        `/country_master/getAllStateNames/{geonameId}?geoNameId=${userDetail?.residentialAddress?.countryId}`
      );
      console.log('StatesList: ', res.data);
      setStateList(res.data.stateNameList);
      setStateListTemp(res.data.stateNameList);
      // setStateListShipTemp(res.data.stateNameList);
      // setCountriesListTemp(res.data.countries);

      // console.log('countriesList: ', res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllStatesShip = async () => {
    try {
      const res = await httpClient.get(
        `/country_master/getAllStateNames/{geonameId}?geoNameId=${userDetail?.shippingAddress?.countryId}`
      );
      console.log('StatesList: ', res.data);
      setStateList(res.data.stateNameList);
      // setStateListTemp(res.data.stateNameList);
      setStateListShipTemp(res.data.stateNameList);
      // setCountriesListTemp(res.data.countries);

      // console.log('countriesList: ', res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllStatesShipAdd = async () => {
    try {
      const res = await httpClient.get(
        `/country_master/getAllStateNames/{geonameId}?geoNameId=${addAdress?.shippingAddress?.countryId}`
      );
      console.log('StatesList: ', res.data);
      setStateList(res.data.stateNameList);
      // setStateListTemp(res.data.stateNameList);
      setStateListShipTemp(res.data.stateNameList);
      // setCountriesListTemp(res.data.countries);

      // console.log('countriesList: ', res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllStatesShipEdit = async () => {
    try {
      const res = await httpClient.get(
        `/country_master/getAllStateNames/{geonameId}?geoNameId=${editAdress?.shippingAddress?.countryId}`
      );
      console.log('StatesList: ', res.data);
      setStateList(res.data.stateNameList);
      // setStateListTemp(res.data.stateNameList);
      setStateListShipTemp(res.data.stateNameList);
      // setCountriesListTemp(res.data.countries);

      // console.log('countriesList: ', res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [cityList, setCityList] = useState();
  const [cityListTemp, setCityListTemp] = useState();
  const [cityListShipTemp, setCityListShipTemp] = useState();

  const getAllCitiesResi = async () => {
    try {
      const res = await httpClient.get(
        `/country_master/getAllCitysNames/{geonameId}?geoNameId=${userDetail?.residentialAddress?.stateId}`
      );
      console.log('CityList: ', res.data);
      setCityList(res.data.cityNameList);
      setCityListTemp(res.data.cityNameList);
      // setCityListShipTemp(res.data.cityNameList);
      // setCountriesListTemp(res.data.countries);

      // console.log('countriesList: ', res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllCitiesShip = async () => {
    try {
      const res = await httpClient.get(
        `/country_master/getAllCitysNames/{geonameId}?geoNameId=${userDetail?.shippingAddress?.stateId}`
      );
      console.log('CityList: ', res.data);
      setCityList(res.data.cityNameList);
      // setCityListTemp(res.data.cityNameList);
      setCityListShipTemp(res.data.cityNameList);
      // setCountriesListTemp(res.data.countries);

      // console.log('countriesList: ', res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCitiesShipAdd = async () => {
    try {
      const res = await httpClient.get(
        `/country_master/getAllCitysNames/{geonameId}?geoNameId=${addAdress?.shippingAddress?.stateId}`
      );
      console.log('CityList: ', res.data);
      setCityList(res.data.cityNameList);
      // setCityListTemp(res.data.cityNameList);
      setCityListShipTemp(res.data.cityNameList);
      // setCountriesListTemp(res.data.countries);

      // console.log('countriesList: ', res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllCitiesShipEdit = async () => {
    try {
      const res = await httpClient.get(
        `/country_master/getAllCitysNames/{geonameId}?geoNameId=${editAdress?.shippingAddress?.stateId}`
      );
      console.log('CityList: ', res.data);
      setCityList(res.data.cityNameList);
      // setCityListTemp(res.data.cityNameList);
      setCityListShipTemp(res.data.cityNameList);
      // setCountriesListTemp(res.data.countries);

      // console.log('countriesList: ', res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [resiCountryObj, setresiCountryObj] = useState();
  const [resiStateObj, setresiStateObj] = useState();
  const [resiCityObj, setresiCityObj] = useState();

  const SetResiCountry = (country) => {
    // setresiCountryObj(country);
    setUserDetail({
      ...userDetail,
      ['residentialAddress']: {
        ...userDetail['residentialAddress'],

        ['countryName']: country.countryName,
        ['countryId']: country.geonameId,
      },
    });
  };

  const SetResiState = (state) => {
    // setresiStateObj(state);
    setUserDetail({
      ...userDetail,
      ['residentialAddress']: {
        ...userDetail['residentialAddress'],

        ['stateName']: state?.name,
        ['stateId']: state?.geonameId,
      },
    });
  };

  const SetResiCity = (city) => {
    // setresiCityObj(city);
    setUserDetail({
      ...userDetail,
      ['residentialAddress']: {
        ...userDetail['residentialAddress'],

        ['cityName']: city?.name,
      },
    });
  };

  //shipping
  const SetShipCountry = (country) => {
    // setresiCountryObj(country);
    setUserDetail({
      ...userDetail,
      ['shippingAddress']: {
        ...userDetail['shippingAddress'],

        ['countryName']: country?.countryName,
        ['countryId']: country?.geonameId,
      },
    });
  };

  const SetShipState = (state) => {
    // setresiStateObj(state);
    setUserDetail({
      ...userDetail,
      ['shippingAddress']: {
        ...userDetail['shippingAddress'],

        ['stateName']: state?.name,
        ['stateId']: state?.geonameId,
      },
    });
  };

  const SetShipCity = (city) => {
    // setresiCityObj(city);
    setUserDetail({
      ...userDetail,
      ['shippingAddress']: {
        ...userDetail['shippingAddress'],

        ['cityName']: city?.name,
      },
    });
  };

  const [pressedKey, setPressedKey] = useState();
  // Add an event listener to the document for keydown events
  useEffect(() => {
    const handleKeyDown = (event) => {
      // console.log(`Key pressed: ${event.key}`);
      setPressedKey(event.key.toLowerCase()); // Convert to lowercase immediately
    };

    // Attach the event listener
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // const dropdownRef = useRef(null);

  const FilterData = () => {
    if (locationMailling) {
      const list = countriesList?.filter(
        (obj) => obj.countryName[0].toLowerCase() === pressedKey
      );
      if (list.length > 0) {
        setCountriesListTemp(list);
      }
    } else if (stateMailling) {
      const list = stateList?.filter(
        (obj) => obj.name[0].toLowerCase() === pressedKey
      );
      if (list.length > 0) {
        setStateListTemp(list);
      }
    } else if (cityMailling) {
      const list = cityList?.filter(
        (obj) => obj.name[0].toLowerCase() === pressedKey
      );
      if (list.length > 0) {
        setCityListTemp(list);
      }
    } else if (locationResidential) {
      const list = countriesList?.filter(
        (obj) => obj.countryName[0].toLowerCase() === pressedKey
      );
      if (list.length > 0) {
        setCountriesListShipTemp(list);
      }
    } else if (stateResidential) {
      const list = stateList?.filter(
        (obj) => obj.name[0].toLowerCase() === pressedKey
      );
      if (list.length > 0) {
        setStateListShipTemp(list);
      }
    } else if (cityResidential) {
      const list = cityList?.filter(
        (obj) => obj.name[0].toLowerCase() === pressedKey
      );
      if (list.length > 0) {
        setCityListShipTemp(list);
      }
    }
  };

  useEffect(() => {
    FilterData();
  }, [pressedKey]);

  const [addressPopup, setAddressPopup] = useState(false);
  const [addAddressPopup, setAddAddressPopup] = useState(false);
  const [editAddressPopup, setEditAddressPopup] = useState(false);

  const defaultStateAddAdress = {
    userId,
    shippingAddress: {
      // shippingAddressId: '',
      firstName: '',
      lastName: '',
      countryName: '',
      addressLine1: '',
      addressLine2: '',
      cityName: '',
      zipCode: '',
      stateName: '',
      phoneNo: '',
      addressStatus: '',
      countryId: '',
      stateId: '',
      cityId: '',
      type: '',
      defaultType: false,
    },
  };
  const defaultStateEditAdress = {
    userId,
    shippingAddress: {
      shippingAddressId: '',
      firstName: '',
      lastName: '',
      countryName: '',
      addressLine1: '',
      addressLine2: '',
      cityName: '',
      zipCode: '',
      stateName: '',
      phoneNo: '',
      addressStatus: '',
      countryId: '',
      stateId: '',
      cityId: '',
      type: '',
      defaultType: false,
    },
  };

  const [addAdress, setAddAdress] = useState(defaultStateAddAdress);
  const [editAdress, setEditAdress] = useState(
    defaultStateEditAdress
  );

  const setEntireObject = async (obj) => {
    // console.log(obj);
    setEditAdress((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        shippingAddressId: obj?.shippingAddressId,
        firstName: obj?.firstName,
        lastName: obj?.lastName,
        countryName: obj?.countryName,
        addressLine1: obj?.addressLine1,
        addressLine2: obj?.addressLine2,
        cityName: obj?.cityName,
        zipCode: obj?.zipCode,
        stateName: obj?.stateName,
        phoneNo: obj?.phoneNo,
        addressStatus: obj?.addressStatus,
        countryId: obj?.countryId,
        stateId: obj?.stateId,
        cityId: obj?.cityId,
        type: obj?.type,
        defaultType: obj?.defaultType,
      },
    }));
  };

  const [DeleteId, setDeleteId] = useState();

  useEffect(() => {
    console.log('editAdress', editAdress);
  }, [editAdress]);
  // defaultStateEditAdress

  useEffect(() => {
    console.log(addAdress);
  }, [addAdress]);

  //shipping
  const SetShipCountryAdd = (country) => {
    setAddAdress((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        countryName: country?.countryName,
        countryId: country?.geonameId,
      },
    }));
  };

  const SetShipStateAdd = (state) => {
    setAddAdress((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        ['stateName']: state?.name,
        ['stateId']: state?.geonameId,
      },
    }));
  };

  const SetShipCityAdd = (city) => {
    setAddAdress((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        ['cityName']: city?.name,
      },
    }));
  };

  const SetShipCountryEdit = (country) => {
    setEditAdress((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        countryName: country?.countryName,
        countryId: country?.geonameId,
      },
    }));
  };

  const SetShipStateEdit = (state) => {
    setEditAdress((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        ['stateName']: state?.name,
        ['stateId']: state?.geonameId,
      },
    }));
  };

  const SetShipCityEdit = (city) => {
    setEditAdress((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        ['cityName']: city?.name,
      },
    }));
  };

  useEffect(() => {
    getAllStatesShip();
  }, [userDetail?.shippingAddress?.countryId]);
  useEffect(() => {
    getAllStatesShipAdd();
  }, [
    //  userDetail?.shippingAddress?.countryId ||
    addAdress?.shippingAddress?.countryId,
  ]);
  useEffect(() => {
    getAllStatesShipEdit();
  }, [
    //  userDetail?.shippingAddress?.countryId ||
    editAdress?.shippingAddress?.countryId,
  ]);

  useEffect(() => {
    getAllStatesResi();
  }, [userDetail?.residentialAddress?.countryId]);

  useEffect(() => {
    getAllCitiesResi();
  }, [userDetail?.residentialAddress?.stateId]);

  useEffect(() => {
    getAllCitiesShip();
  }, [userDetail?.shippingAddress?.stateId]);

  useEffect(() => {
    getAllCitiesShipAdd();
  }, [addAdress?.shippingAddress?.stateId]);

  useEffect(() => {
    getAllCitiesShipEdit();
  }, [editAdress?.shippingAddress?.stateId]);

  const CreateShippingAddress = async () => {
    try {
      const res = await httpClient.post(
        `/user_master/createShippingAddress`,
        addAdress
      );
      setAddAddressPopup(false);
      setAddAdress(defaultStateAddAdress);
      getShippingList();
      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const UpdateShippingAddress = async () => {
    try {
      const res = await httpClient.put(
        `/user_master/UpdateShippingAddress1`,
        editAdress
      );
      setEditAddressPopup(false);
      setEditAdress(defaultStateAddAdress);
      getShippingList();
      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const DeleteShippingAddress = async () => {
    let obj = {
      userId,
      shippingAddressId: DeleteId.shippingAddressId,
    };
    try {
      const res = await httpClient.post(
        '/user_master/deleteShippingAddress',
        obj
      );
      console.log(res.data);
      getShippingList();
      // toast.success('Successfully Updated User Details');
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const [ShippingAddressList, setShippingAddressList] = useState();

  const getShippingList = async () => {
    try {
      const res = await httpClient.get(
        `/user_master/getShippingAddressByUserId/${userId}`
      );
      // setAddAddressPopup(false);
      setShippingAddressList(res.data);

      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getShippingList();
  }, []);

  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);

  const handleCheckboxChange = () => {
    if (addAdress?.shippingAddress?.defaultType === false) {
      setAddAdress((prev) => ({
        ...prev,
        shippingAddress: {
          ...prev.shippingAddress,
          ['defaultType']: true,
        },
      }));
    } else {
      setAddAdress((prev) => ({
        ...prev,
        shippingAddress: {
          ...prev.shippingAddress,
          ['defaultType']: false,
        },
      }));
    }
  };

  const handleCheckboxChangeEdit = () => {
    if (editAdress?.shippingAddress?.defaultType === false) {
      setEditAdress((prev) => ({
        ...prev,
        shippingAddress: {
          ...prev.shippingAddress,
          ['defaultType']: true,
        },
      }));
    } else {
      setEditAdress((prev) => ({
        ...prev,
        shippingAddress: {
          ...prev.shippingAddress,
          ['defaultType']: false,
        },
      }));
    }
  };

  // outside click code

  const CategoryLocationAddClickOutside = useRef(null);

  const handleClickOutsideCategoryLocationAdd = (event) => {
    if (
      CategoryLocationAddClickOutside.current &&
      !CategoryLocationAddClickOutside.current.contains(event.target)
    ) {
      setlocationResidential(false);
      // setContributorTypeDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutsideCategoryLocationAdd
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideCategoryLocationAdd
      );
    };
  }, []);

  const CategoryStateAddClickOutside = useRef(null);

  const handleClickOutsideCategoryStateAdd = (event) => {
    if (
      CategoryStateAddClickOutside.current &&
      !CategoryStateAddClickOutside.current.contains(event.target)
    ) {
      setstateResidential(false);
      // setContributorTypeDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutsideCategoryStateAdd
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideCategoryStateAdd
      );
    };
  }, []);

  const CategoryCityAddClickOutside = useRef(null);

  const handleClickOutsideCategoryCityAdd = (event) => {
    if (
      CategoryCityAddClickOutside.current &&
      !CategoryCityAddClickOutside.current.contains(event.target)
    ) {
      setcityResidential(false);
      // setContributorTypeDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutsideCategoryCityAdd
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideCategoryCityAdd
      );
    };
  }, []);

  const CategoryLocationEditClickOutside = useRef(null);

  const handleClickOutsideCategoryLocationEdit = (event) => {
    if (
      CategoryLocationEditClickOutside.current &&
      !CategoryLocationEditClickOutside.current.contains(event.target)
    ) {
      setlocationResidential(false);
      // setContributorTypeDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutsideCategoryLocationEdit
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideCategoryLocationEdit
      );
    };
  }, []);

  const CategoryStateEditClickOutside = useRef(null);

  const handleClickOutsideCategoryStateEdit = (event) => {
    if (
      CategoryStateEditClickOutside.current &&
      !CategoryStateEditClickOutside.current.contains(event.target)
    ) {
      setstateResidential(false);
      // setContributorTypeDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutsideCategoryStateEdit
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideCategoryStateEdit
      );
    };
  }, []);

  const CategoryCityEditClickOutside = useRef(null);

  const handleClickOutsideCategoryCityEdit = (event) => {
    if (
      CategoryCityEditClickOutside.current &&
      !CategoryCityEditClickOutside.current.contains(event.target)
    ) {
      setcityResidential(false);
      // setContributorTypeDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutsideCategoryCityEdit
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideCategoryCityEdit
      );
    };
  }, []);

  let paymentType = [
    {
      id: 1,
      name: 'UPI',
    },
    {
      id: 2,
      name: 'Credit/Debit Card',
    },
    {
      id: 3,
      name: 'NetBanking',
    },
    {
      id: 4,
      name: 'Gift Card',
    },
    {
      id: 5,
      name: 'EMI',
    },
  ];

  const [show, setShow] = useState();

  const showw = (id) => {
    if (id === show) {
      setShow();
    } else {
      setShow(id);
    }
  };
  const [placeOrder, setPlaceOrder] = useState(false);

  return (
    <>
      <StyledPopupDelete
        open={confirmDeletePopup}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setConfirmDeletePopup(false);
          document.body.classList.remove('body-no-scroll');
          // setOpenOnMountPop2(true);
        }}
      >
        <p className='text-[16px] text-primaryBlack leading-[20px] text-center'>
          Delete Address
        </p>
        <p className='text-sm12 text-primaryGray  text-center mt-[4px]'>
          Are you sure you want to delete this <br />
          shipping address?
        </p>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex gap-[4px] mx-[auto]  mt-[8px]'
        >
          <button
            onClick={DeleteShippingAddress}
            className='gray30HButton'
          >
            Delete
          </button>
          <button
            onClick={() => {
              setConfirmDeletePopup(false);
            }}
            className='gray30HBorderButton'
          >
            Cancel
          </button>
        </div>
      </StyledPopupDelete>

      <StyledPopupAddressAdd
        open={addAddressPopup}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setAddAddressPopup(false);
          document.body.classList.remove('body-no-scroll');
        }}
      >
        <p className='text24 text-center'>Add Shipping Address</p>

        <div className='flex gap-[8px]  mt-[16px]'>
          <div className='relative w-[50%]'>
            <input
              type='text'
              placeholder='Enter your first name'
              className='InputField44H '
              value={addAdress?.shippingAddress?.firstName || ''}
              onChange={handleInputChangeAddAddressNested}
              name='firstName'
            />

            <p className='InputFieldTopText44H'>First Name*</p>
          </div>

          <div className='relative  w-[50%]'>
            <input
              type='text'
              placeholder='Enter your last name'
              className='InputField44H '
              value={addAdress?.shippingAddress?.lastName || ''}
              onChange={handleInputChangeAddAddressNested}
              name='lastName'
            />

            <p className='InputFieldTopText44H'>Last Name*</p>
          </div>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            className='InputField44H'
            placeholder='Enter Address line 1'
            name='addressLine1'
            value={addAdress?.shippingAddress?.addressLine1 || ''}
            onChange={handleInputChangeAddAddressNested}
          />

          <p className='InputFieldTopText44H'>Address line 1*</p>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            className='InputField44H'
            placeholder='Enter Address line 2'
            name='addressLine2'
            value={addAdress?.shippingAddress?.addressLine2 || ''}
            onChange={handleInputChangeAddAddressNested}
          />

          <p className='InputFieldTopText44H'>Address line 2*</p>
        </div>

        <div
          className='abc w-[100%] mt-[12px]'
          ref={CategoryLocationAddClickOutside}
        >
          <style
            dangerouslySetInnerHTML={{
              __html: scrollbarStyle,
            }}
          />

          <div
            onClick={() =>
              setlocationResidential(!locationResidential)
            }
            className={`${
              locationResidential
                ? 'dropdownTopButtonOpenedStyle'
                : 'dropdownTopButtonClosedStyle'
            } dropdownTopButtonCommonStyle `}
          >
            <div className='flex flex-col h-[100%]'>
              <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                Country/Region*
              </p>
              <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                {addAdress?.shippingAddress?.countryName ? (
                  addAdress.shippingAddress.countryName
                ) : (
                  <span className='text-[#bbbbbb]'>
                    Select Country
                  </span>
                )}
              </p>
            </div>

            <DropArrow
              className={`${
                locationResidential === true
                  ? 'transform rotate-180'
                  : ''
              }`}
              style={{
                fill: '#878787',
              }}
            />
          </div>
          {locationResidential && (
            <div className='relative'>
              <div
                onMouseEnter={() => setAllDropHover(true)}
                onMouseLeave={() => setAllDropHover(false)}
                style={{
                  boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                }}
                className={`dropdownTopDivOfULCommonStyle ${
                  allDropHover ? 'pr-[4px]' : ''
                }`}
              >
                <ul
                  onClick={handleClickOutsideLocationResi}
                  className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                >
                  {countriesListShipTemp?.map((country, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        SetShipCountryAdd(country);
                      }}
                      className={`dropdownLiCommonStyle  ${
                        index === countriesListShipTemp.length - 1
                          ? 'pb-[4px] h-[39px]'
                          : 'border-b'
                      }   border-[#efefef]  h-[35px]`}
                    >
                      {country?.countryName}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>

        <div
          className='abc w-[100%] mt-[16px]'
          ref={CategoryStateAddClickOutside}
        >
          <style
            dangerouslySetInnerHTML={{
              __html: scrollbarStyle,
            }}
          />

          <div
            onClick={() => setstateResidential(!stateResidential)}
            className={`${
              stateResidential
                ? 'dropdownTopButtonOpenedStyle'
                : 'dropdownTopButtonClosedStyle'
            } dropdownTopButtonCommonStyle `}
          >
            <div className='flex flex-col h-[100%]'>
              <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                State/Province*
              </p>
              <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                {addAdress?.shippingAddress?.stateName ? (
                  addAdress.shippingAddress?.stateName
                ) : (
                  <span className='text-[#bbbbbb]'>
                    Select State/Province
                  </span>
                )}
              </p>
            </div>

            <DropArrow
              className={`${
                stateResidential === true
                  ? 'transform rotate-180'
                  : ''
              }`}
              style={{
                fill: '#878787',
              }}
            />
          </div>
          {stateResidential && (
            <div className='relative'>
              <div
                onMouseEnter={() => setAllDropHover(true)}
                onMouseLeave={() => setAllDropHover(false)}
                style={{
                  boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                }}
                className={`dropdownTopDivOfULCommonStyle ${
                  allDropHover ? 'pr-[4px]' : ''
                }`}
              >
                <ul
                  onClick={handleClickOutsideCityResidential}
                  className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                >
                  {stateListShipTemp?.map((state, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        SetShipStateAdd(state);
                        setstateResidential(false);
                      }}
                      className={`dropdownLiCommonStyle  ${
                        index === stateListShipTemp.length - 1
                          ? 'pb-[4px] h-[39px]'
                          : 'border-b'
                      }   border-[#efefef]  h-[35px]`}
                    >
                      {state?.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className='flex gap-[8px]  mt-[16px]'>
          <div
            className='abc  w-[50%] '
            ref={CategoryCityAddClickOutside}
          >
            <style
              dangerouslySetInnerHTML={{
                __html: scrollbarStyle,
              }}
            />

            <div
              onClick={() => setcityResidential(!cityResidential)}
              className={`${
                cityResidential
                  ? 'dropdownTopButtonOpenedStyle'
                  : 'dropdownTopButtonClosedStyle'
              } dropdownTopButtonCommonStyle `}
            >
              <div className='flex flex-col h-[100%]'>
                <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                  City*
                </p>
                <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                  {addAdress?.shippingAddress?.cityName ? (
                    addAdress.shippingAddress?.cityName
                  ) : (
                    <span className='text-[#bbbbbb]'>
                      Select City
                    </span>
                  )}
                </p>
              </div>

              <DropArrow
                className={`${
                  cityResidential === true
                    ? 'transform rotate-180'
                    : ''
                }`}
                style={{
                  fill: '#878787',
                }}
              />
            </div>
            {cityResidential && (
              <div className='relative'>
                <div
                  onMouseEnter={() => setAllDropHover(true)}
                  onMouseLeave={() => setAllDropHover(false)}
                  style={{
                    boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                  }}
                  className={`dropdownTopDivOfULCommonStyle ${
                    allDropHover ? 'pr-[4px]' : ''
                  }`}
                >
                  <ul
                    onClick={handleClickOutsideCityResidential}
                    className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                  >
                    {cityListShipTemp?.map((city, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          SetShipCityAdd(city);
                        }}
                        className={`dropdownLiCommonStyle  ${
                          index === cityListShipTemp.length - 1
                            ? 'pb-[4px] h-[39px]'
                            : 'border-b'
                        }   border-[#efefef]  h-[35px]`}
                      >
                        {city?.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>

          <div className='relative w-[50%]'>
            <input
              type='text'
              className='InputField44H'
              placeholder='Enter Zip/Postal Code'
              name='zipCode'
              value={addAdress?.shippingAddress?.zipCode || ''}
              onChange={handleInputChangeAddAddressNested}
            />

            <p className='InputFieldTopText44H'>Zip/Postal Code*</p>
          </div>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            className='InputField44H'
            placeholder='Your Mobile Number'
            name='phoneNo'
            value={addAdress?.shippingAddress?.phoneNo || ''}
            onChange={handleInputChangeAddAddressNested}
          />

          <p className='InputFieldTopText44H'>Mobile Number*</p>
        </div>

        <div>
          <p className='text-sm11 text-[#bbbbbb] mt-[16px]'>
            Save above address as
          </p>
          <div className='flex gap-[4px] mt-[6px]'>
            <button
              onClick={() => {
                setAddAdress((prev) => ({
                  ...prev,
                  shippingAddress: {
                    ...prev.shippingAddress,
                    ['type']: 'Home',
                  },
                }));
              }}
              className={`gray30HBorderButton ${
                addAdress?.shippingAddress?.type === 'Home'
                  ? 'border-[#333333] text-[#333333]'
                  : ' '
              }`}
            >
              Home
            </button>

            <button
              onClick={() => {
                setAddAdress((prev) => ({
                  ...prev,
                  shippingAddress: {
                    ...prev.shippingAddress,
                    ['type']: 'Work',
                  },
                }));
              }}
              className={`gray30HBorderButton ${
                addAdress?.shippingAddress?.type === 'Work'
                  ? 'border-[#333333] text-[#333333]'
                  : ' '
              }`}
            >
              Work
            </button>
          </div>

          <div className='mt-[10px]'>
            <label
              style={{
                width: 'fit-content',
              }}
              className='containerCheckGray text-[12px] text-primaryGray'
            >
              <input
                onChange={handleCheckboxChange}
                type='checkbox'
                checked={addAdress?.shippingAddress?.defaultType}
              />{' '}
              <span className='relative top-[1px] cursor-default'>
                Make this my default shipping address
              </span>
              <span className='checkmarkGray'></span>
            </label>
          </div>

          <p className='text-sm11 text-primaryGray mt-[10px]'>
            The first address will be your default address. <br />
            You can change the default address through account setting
            <br />
            after adding second address.
          </p>
        </div>

        <div className='flex gap-[8px] justify-center w-[100%] mt-[16px]'>
          <button
            onClick={() => {
              // document.body.classList.add('body-no-scroll');
              CreateShippingAddress();
            }}
            className='black40HButton'
          >
            Add Address
          </button>
          <button
            onClick={() => {
              setAddAddressPopup(false);
            }}
            className='outlineBtn40h'
          >
            Cancel
          </button>
        </div>
      </StyledPopupAddressAdd>

      <StyledPopupAddressEdit
        open={editAddressPopup}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setEditAddressPopup(false);
          document.body.classList.remove('body-no-scroll');
        }}
      >
        <p className='text24 text-center'>Edit Shipping Address</p>

        <div className='flex gap-[8px]  mt-[16px]'>
          <div className='relative w-[50%]'>
            <input
              type='text'
              placeholder='Enter your first name'
              className='InputField44H'
              value={editAdress?.shippingAddress?.firstName || ''}
              onChange={handleInputChangeEditAddressNested}
              name='firstName'
            />

            <p className='InputFieldTopText44H'>First Name*</p>
          </div>

          <div className='relative  w-[50%]'>
            <input
              type='text'
              placeholder='Enter your last name'
              className='InputField44H '
              value={editAdress?.shippingAddress?.lastName || ''}
              onChange={handleInputChangeEditAddressNested}
              name='lastName'
            />

            <p className='InputFieldTopText44H'>Last Name*</p>
          </div>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            className='InputField44H'
            placeholder='Enter Address line 1'
            name='addressLine1'
            value={editAdress?.shippingAddress?.addressLine1 || ''}
            onChange={handleInputChangeEditAddressNested}
          />

          <p className='InputFieldTopText44H'>Address line 1*</p>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            className='InputField44H'
            placeholder='Enter Address line 2'
            name='addressLine2'
            value={editAdress?.shippingAddress?.addressLine2 || ''}
            onChange={handleInputChangeEditAddressNested}
          />

          <p className='InputFieldTopText44H'>Address line 2*</p>
        </div>

        <div
          className='abc w-[100%] mt-[12px]'
          ref={CategoryLocationEditClickOutside}
        >
          <style
            dangerouslySetInnerHTML={{
              __html: scrollbarStyle,
            }}
          />

          <div
            onClick={() =>
              setlocationResidential(!locationResidential)
            }
            className={`${
              locationResidential
                ? 'dropdownTopButtonOpenedStyle'
                : 'dropdownTopButtonClosedStyle'
            } dropdownTopButtonCommonStyle `}
          >
            <div className='flex flex-col h-[100%]'>
              <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                Country/Region*
              </p>
              <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                {editAdress?.shippingAddress?.countryName ? (
                  editAdress.shippingAddress.countryName
                ) : (
                  <span className='text-[#bbbbbb]'>
                    Select Country
                  </span>
                )}
              </p>
            </div>

            <DropArrow
              className={`${
                locationResidential === true
                  ? 'transform rotate-180'
                  : ''
              }`}
              style={{
                fill: '#878787',
              }}
            />
          </div>
          {locationResidential && (
            <div className='relative'>
              <div
                onMouseEnter={() => setAllDropHover(true)}
                onMouseLeave={() => setAllDropHover(false)}
                style={{
                  boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                }}
                className={`dropdownTopDivOfULCommonStyle ${
                  allDropHover ? 'pr-[4px]' : ''
                }`}
              >
                <ul
                  onClick={handleClickOutsideLocationResi}
                  className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                >
                  {countriesListShipTemp?.map((country, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        SetShipCountryEdit(country);
                      }}
                      className={`dropdownLiCommonStyle  ${
                        index === countriesListShipTemp.length - 1
                          ? 'pb-[4px] h-[39px]'
                          : 'border-b'
                      }   border-[#efefef]  h-[35px]`}
                    >
                      {country?.countryName}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>

        <div
          className='abc w-[100%] mt-[16px]'
          ref={CategoryStateEditClickOutside}
        >
          <style
            dangerouslySetInnerHTML={{
              __html: scrollbarStyle,
            }}
          />

          <div
            onClick={() => setstateResidential(!stateResidential)}
            className={`${
              stateResidential
                ? 'dropdownTopButtonOpenedStyle'
                : 'dropdownTopButtonClosedStyle'
            } dropdownTopButtonCommonStyle `}
          >
            <div className='flex flex-col h-[100%]'>
              <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                State/Province*
              </p>
              <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                {editAdress?.shippingAddress?.stateName ? (
                  editAdress.shippingAddress?.stateName
                ) : (
                  <span className='text-[#bbbbbb]'>
                    Select State/Province
                  </span>
                )}
              </p>
            </div>

            <DropArrow
              className={`${
                stateResidential === true
                  ? 'transform rotate-180'
                  : ''
              }`}
              style={{
                fill: '#878787',
              }}
            />
          </div>
          {stateResidential && (
            <div className='relative'>
              <div
                onMouseEnter={() => setAllDropHover(true)}
                onMouseLeave={() => setAllDropHover(false)}
                style={{
                  boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                }}
                className={`dropdownTopDivOfULCommonStyle ${
                  allDropHover ? 'pr-[4px]' : ''
                }`}
              >
                <ul
                  onClick={handleClickOutsideCityResidential}
                  className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                >
                  {stateListShipTemp?.map((state, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        SetShipStateEdit(state);
                        setstateResidential(false);
                      }}
                      className={`dropdownLiCommonStyle  ${
                        index === stateListShipTemp.length - 1
                          ? 'pb-[4px] h-[39px]'
                          : 'border-b'
                      }   border-[#efefef]  h-[35px]`}
                    >
                      {state?.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className='flex gap-[8px]  mt-[16px]'>
          <div
            className='abc  w-[50%] '
            ref={CategoryCityEditClickOutside}
          >
            <style
              dangerouslySetInnerHTML={{
                __html: scrollbarStyle,
              }}
            />

            <div
              onClick={() => setcityResidential(!cityResidential)}
              className={`${
                cityResidential
                  ? 'dropdownTopButtonOpenedStyle'
                  : 'dropdownTopButtonClosedStyle'
              } dropdownTopButtonCommonStyle `}
            >
              <div className='flex flex-col h-[100%]'>
                <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                  City*
                </p>
                <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                  {editAdress?.shippingAddress?.cityName ? (
                    editAdress.shippingAddress?.cityName
                  ) : (
                    <span className='text-[#bbbbbb]'>
                      Select City
                    </span>
                  )}
                </p>
              </div>

              <DropArrow
                className={`${
                  cityResidential === true
                    ? 'transform rotate-180'
                    : ''
                }`}
                style={{
                  fill: '#878787',
                }}
              />
            </div>
            {cityResidential && (
              <div className='relative'>
                <div
                  onMouseEnter={() => setAllDropHover(true)}
                  onMouseLeave={() => setAllDropHover(false)}
                  style={{
                    boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                  }}
                  className={`dropdownTopDivOfULCommonStyle ${
                    allDropHover ? 'pr-[4px]' : ''
                  }`}
                >
                  <ul
                    onClick={handleClickOutsideCityResidential}
                    className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                  >
                    {cityListShipTemp?.map((city, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          SetShipCityEdit(city);
                        }}
                        className={`dropdownLiCommonStyle  ${
                          index === cityListShipTemp.length - 1
                            ? 'pb-[4px] h-[39px]'
                            : 'border-b'
                        }   border-[#efefef]  h-[35px]`}
                      >
                        {city?.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>

          <div className='relative w-[50%]'>
            <input
              type='text'
              className='InputField44H'
              placeholder='Enter Zip/Postal Code'
              name='zipCode'
              value={editAdress?.shippingAddress?.zipCode || ''}
              onChange={handleInputChangeEditAddressNested}
            />

            <p className='InputFieldTopText44H'>Zip/Postal Code*</p>
          </div>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            className='InputField44H'
            placeholder='Your Mobile Number'
            name='phoneNo'
            value={editAdress?.shippingAddress?.phoneNo || ''}
            onChange={handleInputChangeEditAddressNested}
          />

          <p className='InputFieldTopText44H'>Mobile Number*</p>
        </div>

        <div>
          <p className='text-sm11 text-[#bbbbbb] mt-[16px]'>
            Save above address as
          </p>
          <div className='flex gap-[4px] mt-[6px]'>
            <button
              onClick={() => {
                setEditAdress((prev) => ({
                  ...prev,
                  shippingAddress: {
                    ...prev.shippingAddress,
                    ['type']: 'Home',
                  },
                }));
              }}
              className={`gray30HBorderButton ${
                editAdress?.shippingAddress?.type === 'Home'
                  ? 'border-[#333333] text-[#333333]'
                  : ''
              }`}
            >
              Home
            </button>
            <button
              onClick={() => {
                setEditAdress((prev) => ({
                  ...prev,
                  shippingAddress: {
                    ...prev.shippingAddress,
                    ['type']: 'Work',
                  },
                }));
              }}
              className={`gray30HBorderButton ${
                editAdress?.shippingAddress?.type === 'Work'
                  ? 'border-[#333333] text-[#333333]'
                  : ''
              }`}
            >
              Work
            </button>
          </div>

          <div className='mt-[10px]'>
            <label
              style={{
                width: 'fit-content',
              }}
              className='containerCheckGray text-[12px] text-primaryGray'
            >
              <input
                onChange={handleCheckboxChangeEdit}
                type='checkbox'
                checked={editAdress?.shippingAddress?.defaultType}
              />{' '}
              <span className='relative top-[1px] cursor-default'>
                Make this my default shipping address
              </span>
              <span className='checkmarkGray'></span>
            </label>
          </div>

          <p className='text-sm11 text-primaryGray mt-[10px]'>
            The first address will be your default address. <br />
            You can change the default address through account setting
            <br />
            after adding second address.
          </p>
        </div>

        <div className='flex gap-[8px] justify-center w-[100%] mt-[16px]'>
          <button
            onClick={() => {
              UpdateShippingAddress();
            }}
            // onClick={UpdateShippingAddress}
            className='black40HButton'
          >
            Save Address
          </button>

          <button
            onClick={() => {
              setEditAddressPopup(false);
            }}
            className='outlineBtn40h'
          >
            Cancel
          </button>
        </div>
      </StyledPopupAddressEdit>

      <div className='pt-[20px] mb-[20px]'>
        <div className='text-[11px] leading-[1] text-[#d6d6d6] font-medium flex justify-center gap-[8px] items-center '>
          <span
            onClick={() => {
              navigate('/shopping-cart');
            }}
            className='text-primaryGray cursor-pointer '
          >
            SHOPPING CART
          </span>
          <div>
            <Icon_RightArrow_12x12 className='fill-[#d6d6d6]' />
          </div>

          {finalCheckout ? (
            <span
              // onClick={() => {
              //  setFinalCheckout(true);
              // }}
              className={` text-pinkColor`}
            >
              {' '}
              <span>SHIPPING ADDRESS </span>
            </span>
          ) : (
            <span
              onClick={() => {
                setFinalCheckout(true);
              }}
              className={`cursor-pointer text-primaryGray`}
            >
              {' '}
              <span>SHIPPING ADDRESS </span>
            </span>
          )}

          <div>
            <Icon_RightArrow_12x12 className='fill-[#d6d6d6]' />
          </div>
          <span
            className={` ${!finalCheckout ? 'text-pinkColor' : ''}`}
          >
            {' '}
            PAYMENT
          </span>
        </div>
        {/* <p className='text-heading text-center'>Shopping Cart</p> */}

        <div className='w-[100%]'>
          <div className='flex gap-[152px] justify-center mt-[32px]'>
            {finalCheckout ? (
              <>
                {ShippingAddressList?.length > 0 ? (
                  <div className='max-w-[448px] w-[100%] flex flex-col '>
                    <div className='flex gap-[8px]'>
                      <div className='h-[25px] w-[25px] border border-[#d6d6d6] flex justify-center rounded-[6px] items-center'>
                        <Icon_Lock16 className='fill-[#8e8e8e]' />
                      </div>
                      <div className='flex-col justify-between'>
                        <p className='text-sm11 text-primaryGray font-medium'>
                          Your information is secure.
                        </p>
                        <p className='text-sm11 text-primaryGray'>
                          Your encrypted payment method will be saved
                          for future orders.
                        </p>
                      </div>
                    </div>
                    <div className='mt-[32px]'>
                      <p
                        className={`text-[16px] font-medium leading-[1] text-primaryBlack mb-[4px] `}
                      >
                        Shipping Address(s)
                      </p>

                      {ShippingAddressList?.map((obj, i) => (
                        <>
                          <p
                            className={`text-[13px] font-medium text-[#bbbbbb] ${
                              i > 0 ? ' mt-[26px]' : 'mt-[16px]'
                            }`}
                          >
                            Address {i + 1}
                          </p>
                          <div className='flex gap-[6px] items-center'>
                            <p className='text-[13px] font-medium text-primaryBlack'>
                              {obj?.firstName} {obj?.lastName}
                            </p>

                            {obj?.type === 'Home' ? (
                              <button className='h-[16px] flex px-[4px] text-[10px] leading-[1] text-[#33cdf4] cursor-default border border-[#33cdf4] font-medium items-center justify-center rounded-[8px]'>
                                <span> Home</span>
                              </button>
                            ) : obj?.type === 'Work' ? (
                              <button className='h-[16px] flex px-[4px] text-[10px] leading-[1] text-[#33cdf4] cursor-default border border-[#33cdf4] font-medium items-center justify-center rounded-[8px]'>
                                <span> Work</span>
                              </button>
                            ) : (
                              <></>
                            )}

                            {obj?.defaultType && (
                              <p className='text-[11px] text-pinkColor'>
                                Default Shipping Address
                              </p>
                            )}
                          </div>
                          <p className='text-[13px] text-primaryGray'>
                            {obj?.addressLine1} {obj?.addressLine2}
                            <br />
                            {obj?.cityName} {obj?.zipCode},{' '}
                            {obj?.stateName}, {obj?.countryName}
                            {/* Pune 411014, Maharashtra, India */}
                          </p>
                          <p className='font-medium text-[13px] text-primaryGray'>
                            Mobile Number:{' '}
                            <span className=' text-primaryBlack'>
                              {obj?.phoneNo}
                            </span>
                          </p>

                          <div className='flex  mt-[16px]'>
                            <div className=' pr-[9px]'>
                              {/* <img src={edit} alt='' /> */}
                              <EditIcon
                                onClick={() => {
                                  document.body.classList.add(
                                    'body-no-scroll'
                                  );
                                  setEntireObject(obj);
                                  setEditAddressPopup(true);
                                }}
                                className='fill-[#888888] hover:fill-[#333333] cursor-pointer'
                              />
                            </div>
                            <div className=' pl-[9px] border-l border-l-[#efefef]'>
                              {/* <img src={deletee} alt='' /> */}
                              <DeleteIcon
                                onClick={() => {
                                  document.body.classList.add(
                                    'body-no-scroll'
                                  );
                                  setDeleteId(obj);
                                  setConfirmDeletePopup(true);
                                }}
                                className='fill-[#888888]  hover:fill-[#333333] cursor-pointer'
                              />
                            </div>
                          </div>
                        </>
                      ))}

                      <button
                        onClick={() => {
                          document.body.classList.add(
                            'body-no-scroll'
                          );
                          setAddAddressPopup(true);
                        }}
                        className='gray30HButton mt-[30px]'
                      >
                        Add Address
                      </button>
                    </div>
                    <div className='h-[1px] bg-[#efefef] w-[100%] mb-[32px] mt-[32px]'></div>

                    <div
                      style={{
                        borderRadius: '24px',
                      }}
                      className='rounded-gradient-border-for-cart p-[16px] flex-col '
                    >
                      <p className='text-sm11 text-primaryGray'>
                        Expected Delivery on
                      </p>
                      <p className='text-[16px] text-pinkColor font-medium'>
                        12 September 2024
                      </p>
                      <div className='flex gap-[16px] mt-[8px]'>
                        <img
                          className='h-[84px] w-[84px]'
                          src={combo}
                          alt=''
                        />
                        <div>
                          <p className='text-sm12 text-primaryBlack font-medium '>
                            Office Merchandise Combo
                          </p>
                          <p className='text-sm11 text-primaryGray '>
                            International Artist Azra's work dictates
                            the <br /> expression of man and beast.
                          </p>
                          <p className='text-sm11 text-primaryGray mt-[8px]'>
                            No Cancellations and Returns. <br /> Read
                            our{' '}
                            <span className='text-orangeColor'>
                              {' '}
                              Returns & Exchange policy.
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='max-w-[448px] w-[100%] flex flex-col'>
                    <p className='text-[16px] font-semibold '>
                      Shipping Address
                    </p>

                    <div className='flex gap-[8px]  mt-[12px]'>
                      <div className='relative w-[50%]'>
                        <input
                          type='text'
                          placeholder='Enter your first name'
                          className='InputField44H '
                          value={
                            addAdress?.shippingAddress?.firstName ||
                            ''
                          }
                          // onChange={handleInputChangeAddAddressNested}
                          name='firstName'
                        />

                        <p className='InputFieldTopText44H'>
                          First Name
                        </p>
                      </div>

                      <div className='relative  w-[50%]'>
                        <input
                          type='text'
                          placeholder='Enter your last name'
                          className='InputField44H '
                          value={
                            addAdress?.shippingAddress?.lastName || ''
                          }
                          // onChange={handleInputChangeAddAddressNested}
                          name='lastName'
                        />

                        <p className='InputFieldTopText44H'>
                          Last Name
                        </p>
                      </div>
                    </div>

                    <div className='relative  mt-[16px]'>
                      <input
                        type='text'
                        className='InputField44H'
                        placeholder='Enter Address line 1*'
                        name='addressLine1'
                        value={
                          addAdress?.shippingAddress?.addressLine1 ||
                          ''
                        }
                        // onChange={handleInputChangeAddAddressNested}
                      />

                      <p className='InputFieldTopText44H'>
                        Address line 1
                      </p>
                    </div>

                    <div className='relative  mt-[16px]'>
                      <input
                        type='text'
                        className='InputField44H'
                        placeholder='Enter Address line 2*'
                        name='addressLine2'
                        value={
                          addAdress?.shippingAddress?.addressLine2 ||
                          ''
                        }
                        // onChange={handleInputChangeAddAddressNested}
                      />

                      <p className='InputFieldTopText44H'>
                        Address line 2
                      </p>
                    </div>

                    <div
                      className='abc w-[100%] mt-[12px]'
                      ref={CategoryLocationAddClickOutside}
                    >
                      <style
                        dangerouslySetInnerHTML={{
                          __html: scrollbarStyle,
                        }}
                      />

                      <div
                        onClick={() =>
                          setlocationResidential(!locationResidential)
                        }
                        className={`${
                          locationResidential
                            ? 'dropdownTopButtonOpenedStyle'
                            : 'dropdownTopButtonClosedStyle'
                        } dropdownTopButtonCommonStyle `}
                      >
                        <div className='flex flex-col h-[100%]'>
                          <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                            Country/Region*
                          </p>
                          <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                            {addAdress?.shippingAddress
                              ?.countryName ? (
                              addAdress.shippingAddress.countryName
                            ) : (
                              <span className='text-[#bbbbbb]'>
                                Select Country
                              </span>
                            )}
                          </p>
                        </div>

                        <DropArrow
                          className={`${
                            locationResidential === true
                              ? 'transform rotate-180'
                              : ''
                          }`}
                          style={{
                            fill: '#878787',
                          }}
                        />
                      </div>
                      {locationResidential && (
                        <div className='relative'>
                          <div
                            onMouseEnter={() => setAllDropHover(true)}
                            onMouseLeave={() =>
                              setAllDropHover(false)
                            }
                            style={{
                              boxShadow:
                                'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                            }}
                            className={`dropdownTopDivOfULCommonStyle ${
                              allDropHover ? 'pr-[4px]' : ''
                            }`}
                          >
                            <ul
                              onClick={handleClickOutsideLocationResi}
                              className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                            >
                              {countriesListShipTemp?.map(
                                (country, index) => (
                                  <li
                                    key={index}
                                    onClick={() => {
                                      SetShipCountryAdd(country);
                                    }}
                                    className={`dropdownLiCommonStyle  ${
                                      index ===
                                      countriesListShipTemp.length - 1
                                        ? 'pb-[4px] h-[39px]'
                                        : 'border-b'
                                    }   border-[#efefef]  h-[35px]`}
                                  >
                                    {country?.countryName}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      className='abc w-[100%] mt-[16px]'
                      ref={CategoryStateAddClickOutside}
                    >
                      <style
                        dangerouslySetInnerHTML={{
                          __html: scrollbarStyle,
                        }}
                      />

                      <div
                        onClick={() =>
                          setstateResidential(!stateResidential)
                        }
                        className={`${
                          stateResidential
                            ? 'dropdownTopButtonOpenedStyle'
                            : 'dropdownTopButtonClosedStyle'
                        } dropdownTopButtonCommonStyle `}
                      >
                        <div className='flex flex-col h-[100%]'>
                          <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                            State/Province
                          </p>
                          <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                            {addAdress?.shippingAddress?.stateName ? (
                              addAdress.shippingAddress?.stateName
                            ) : (
                              <span className='text-[#bbbbbb]'>
                                Select State/Province
                              </span>
                            )}
                          </p>
                        </div>

                        <DropArrow
                          className={`${
                            stateResidential === true
                              ? 'transform rotate-180'
                              : ''
                          }`}
                          style={{
                            fill: '#878787',
                          }}
                        />
                      </div>
                      {stateResidential && (
                        <div className='relative'>
                          <div
                            onMouseEnter={() => setAllDropHover(true)}
                            onMouseLeave={() =>
                              setAllDropHover(false)
                            }
                            style={{
                              boxShadow:
                                'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                            }}
                            className={`dropdownTopDivOfULCommonStyle ${
                              allDropHover ? 'pr-[4px]' : ''
                            }`}
                          >
                            <ul
                              onClick={
                                handleClickOutsideCityResidential
                              }
                              className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                            >
                              {stateListShipTemp?.map(
                                (state, index) => (
                                  <li
                                    key={index}
                                    onClick={() => {
                                      SetShipStateAdd(state);
                                      setstateResidential(false);
                                    }}
                                    className={`dropdownLiCommonStyle  ${
                                      index ===
                                      stateListShipTemp.length - 1
                                        ? 'pb-[4px] h-[39px]'
                                        : 'border-b'
                                    }   border-[#efefef]  h-[35px]`}
                                  >
                                    {state?.name}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='flex gap-[8px]  mt-[16px]'>
                      <div
                        className='abc  w-[50%] '
                        ref={CategoryCityAddClickOutside}
                      >
                        <style
                          dangerouslySetInnerHTML={{
                            __html: scrollbarStyle,
                          }}
                        />

                        <div
                          onClick={() =>
                            setcityResidential(!cityResidential)
                          }
                          className={`${
                            cityResidential
                              ? 'dropdownTopButtonOpenedStyle'
                              : 'dropdownTopButtonClosedStyle'
                          } dropdownTopButtonCommonStyle `}
                        >
                          <div className='flex flex-col h-[100%]'>
                            <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                              City
                            </p>
                            <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                              {addAdress?.shippingAddress
                                ?.cityName ? (
                                addAdress.shippingAddress?.cityName
                              ) : (
                                <span className='text-[#bbbbbb]'>
                                  Select City
                                </span>
                              )}
                            </p>
                          </div>

                          <DropArrow
                            className={`${
                              cityResidential === true
                                ? 'transform rotate-180'
                                : ''
                            }`}
                            style={{
                              fill: '#878787',
                            }}
                          />
                        </div>
                        {cityResidential && (
                          <div className='relative'>
                            <div
                              onMouseEnter={() =>
                                setAllDropHover(true)
                              }
                              onMouseLeave={() =>
                                setAllDropHover(false)
                              }
                              style={{
                                boxShadow:
                                  'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                              }}
                              className={`dropdownTopDivOfULCommonStyle ${
                                allDropHover ? 'pr-[4px]' : ''
                              }`}
                            >
                              <ul
                                onClick={
                                  handleClickOutsideCityResidential
                                }
                                className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                              >
                                {cityListShipTemp?.map(
                                  (city, index) => (
                                    <li
                                      key={index}
                                      onClick={() => {
                                        SetShipCityAdd(city);
                                      }}
                                      className={`dropdownLiCommonStyle  ${
                                        index ===
                                        cityListShipTemp.length - 1
                                          ? 'pb-[4px] h-[39px]'
                                          : 'border-b'
                                      }   border-[#efefef]  h-[35px]`}
                                    >
                                      {city?.name}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='relative w-[50%]'>
                        <input
                          type='text'
                          className='InputField44H'
                          placeholder='Enter Zip/Postal Code'
                          name='zipCode'
                          value={
                            addAdress?.shippingAddress?.zipCode || ''
                          }
                          // onChange={handleInputChangeAddAddressNested}
                        />

                        <p className='InputFieldTopText44H'>
                          Zip/Postal Code
                        </p>
                      </div>
                    </div>

                    <div className='relative  mt-[16px]'>
                      <input
                        type='text'
                        className='InputField44H'
                        placeholder='Your Mobile Number'
                        name='phoneNo'
                        value={
                          addAdress?.shippingAddress?.phoneNo || ''
                        }
                        // onChange={handleInputChangeAddAddressNested}
                      />

                      <p className='InputFieldTopText44H'>
                        Mobile Number
                      </p>
                    </div>

                    <div>
                      <p className='text-sm11 text-[#bbbbbb] mt-[16px]'>
                        Save above address as
                      </p>
                      <div className='flex gap-[4px] mt-[6px]'>
                        <button
                          onClick={() => {
                            setAddAdress((prev) => ({
                              ...prev,
                              shippingAddress: {
                                ...prev.shippingAddress,
                                ['type']: 'Home',
                              },
                            }));
                          }}
                          className={`gray30HBorderButton ${
                            addAdress?.shippingAddress?.type ===
                            'Home'
                              ? 'border-[#333333] text-[#333333]'
                              : ' '
                          }`}
                        >
                          Home
                        </button>

                        <button
                          onClick={() => {
                            setAddAdress((prev) => ({
                              ...prev,
                              shippingAddress: {
                                ...prev.shippingAddress,
                                ['type']: 'Work',
                              },
                            }));
                          }}
                          className={`gray30HBorderButton ${
                            addAdress?.shippingAddress?.type ===
                            'Work'
                              ? 'border-[#333333] text-[#333333]'
                              : ' '
                          }`}
                        >
                          Work
                        </button>
                      </div>

                      <div className='mt-[10px]'>
                        <label
                          style={{
                            width: 'fit-content',
                          }}
                          className='containerCheckGray text-[12px] text-primaryGray'
                        >
                          <input
                            onChange={handleCheckboxChange}
                            type='checkbox'
                            checked={
                              addAdress?.shippingAddress?.defaultType
                            }
                          />{' '}
                          <span className='relative top-[1px] cursor-default'>
                            Make this my default shipping address
                          </span>
                          <span className='checkmarkGray'></span>
                        </label>
                      </div>

                      <p className='text-sm11 text-primaryGray mt-[10px]'>
                        The first address will be your default
                        address. <br />
                        You can change the default address through
                        account setting
                        <br />
                        after adding second address.
                      </p>
                    </div>

                    <div className='flex gap-[8px] justify-center w-[100%] mt-[16px]'>
                      <button
                        // onClick={() => {
                        //   // document.body.classList.add('body-no-scroll');
                        //   CreateShippingAddress();
                        // }}
                        className='black40HButton'
                      >
                        Add Address
                      </button>
                      {/* <button
       onClick={() => {
         setAddAddressPopup(false);
       }}
       className='outlineBtn40h'
     >
       Cancel
     </button> */}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {placeOrder ? (
                  <>
                    <div className='max-w-[448px] w-[100%] flex flex-col '>
                      <div
                        onClick={() => {
                          navigate('/checkout2');
                        }}
                        className='text-[25px] font-medium text-primaryGray leading-[1] gap-[4px] mb-[16px] cursor-pointer flex flex-col'
                      >
                        <p className='text-[12px] leading-[1] '>
                          Order Number
                        </p>{' '}
                        <p>ANS9876547</p>
                      </div>

                      <div className='text-primaryGray text-sm12 font-medium leading-4'>
                        <div className='flex border-t border-t-[2px] border-b gap-[8px] border-[#efefef]'>
                          <p className='w-[61px] font-medium'>
                            Customer:
                          </p>
                          <p className='font-normal'>Khalid Shaikh</p>
                        </div>
                        <div className='flex  border-b-[2px] border-b gap-[8px] border-[#efefef]'>
                          <p className='w-[61px] font-medium'>
                            Order Date:
                          </p>
                          <p className='font-normal'>18 Jan 2024</p>
                        </div>
                      </div>

                      <div className='flex flex-col'>
                        <p className='text-primaryBlack text-[16px] mt-[30px] mb-[8px] leading-[18px] font-medium'>
                          Payment Method
                        </p>
                        <div>
                          <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                        </div>
                        <p className='text-sm12 text-primaryGray mt-[8px] flex items-center'>
                          HDFC Bank Credit Card ending in 2139
                        </p>
                        <p className='text-sm11 text-primaryGray flex items-center'>
                          Name on the Card:{' '}
                          <span className='text-primaryBlack font-medium'>
                            Arshaan Shaikh
                          </span>
                        </p>
                      </div>

                      {/* <div className='mt-[16px]'>
                        <p className='text-primaryBlack text-[16px]  mb-[16px] leading-[18px] font-medium'>
                          Mailling Address
                        </p>
                        <p className='text-sm12  text-primaryGray'>
                          {userData?.userFirstName}{' '}
                          {userData?.userLastName}
                        </p>
                        <p className='text-sm12  text-primaryGray'>
                          {userData?.residentialAddress?.addressLine1}
                          ,
                        </p>
                        <p className='text-sm12  text-primaryGray'>
                          {userData?.residentialAddress?.addressLine2}
                          , {userData?.residentialAddress?.cityName}{' '}
                          {userData?.residentialAddress?.zipCode}.
                        </p>
                        <p className='text-sm12  text-primaryGray'>
                          {userData?.residentialAddress?.stateName}
                        </p>
                        <p className='text-sm12  text-primaryGray'>
                          {userData?.residentialAddress?.countryName}
                        </p>
                        <p className='text-sm12  text-primaryGray'>
                          Contact No.:
                          {userData?.residentialAddress?.phoneNo}
                        </p>
                        <p className='text-sm12  text-primaryGray'>
                          Email:{userData?.emailAddress}
                        </p>
                      </div> */}
                      <div className='mt-[16px]'>
                        <p className='text-primaryBlack text-[16px]  mb-[16px] leading-[18px] font-medium'>
                          Shipping Address
                        </p>
                        <p className='text-sm12  text-primaryGray'>
                          {userData?.userFirstName}{' '}
                          {userData?.userLastName}
                        </p>
                        <p className='text-sm12  text-primaryGray'>
                          {userData?.shippingAddress?.addressLine1},
                        </p>
                        <p className='text-sm12  text-primaryGray'>
                          {userData?.shippingAddress?.addressLine2},{' '}
                          {userData?.shippingAddress?.cityName}{' '}
                          {userData?.shippingAddress?.zipCode}.
                        </p>
                        <p className='text-sm12  text-primaryGray'>
                          {userData?.shippingAddress?.stateName}
                        </p>
                        <p className='text-sm12  text-primaryGray'>
                          {userData?.shippingAddress?.countryName}
                        </p>
                        <p className='text-sm12  text-primaryGray'>
                          Mobile No.:
                          {/* {userData?.shippingAddress?.phoneNo} */}
                          +91 7378666093
                        </p>
                        {/* <p className='text-sm12  text-primaryGray'>
                          Email:{userData?.emailAddress}
                        </p> */}
                      </div>
                      <div className='mt-[40px]'>
                        <p className='text-primaryBlack text-[186x] mb-[4px] leading-[18px] font-medium'>
                          Shipping Method
                        </p>
                        <p className='text-sm12  text-primaryGray'>
                          {select?.shippingMethodName}
                        </p>
                        {/* <p className='text-sm12 text-primaryGray'>
                          Order now to receive in{' '}
                          {select?.dayToReceive} business days
                        </p> */}
                      </div>

                      <div className='mt-[30px] mb-[24px]'>
                        <label
                          style={{
                            width: 'fit-content',
                          }}
                          className='containerCheckGray text-[12px] text-primaryGray'
                        >
                          <input
                            // onChange={handleCheckboxChange}
                            type='checkbox'
                            defaultChecked='true'
                          />{' '}
                          <span className='relative top-[1px] cursor-default'>
                            Send me news and exclusive offers from
                            Artnstock
                          </span>
                          <span className='checkmarkGray'></span>
                        </label>
                        {/* <label
                          style={{
                            width: 'fit-content',
                          }}
                          className='containerCheckGray text-[12px] text-primaryGray '
                        >
                          <input
                            type='checkbox'
                            defaultChecked='true'
                          />{' '}
                          Send me news and exclusive offers from
                          Artnstock
                          <span className='checkmarkGray'></span>
                        </label> */}
                      </div>

                      <div className=''>
                        <div
                          style={{
                            width: 'fit-content',
                          }}
                          onClick={() =>
                            handlePayment(cartData?.finalAmount)
                          }
                          className='px-[22px] h-[48px] flex justify-center items-center bg-primaryBlack relative rounded-[5000px] cursor-pointer '
                        >
                          {/* <div className='w-[100%] h-[100%] gradient-background  rounded-[5000px]  opacity-[80%] absolute'></div> */}
                          <div className='flex flex-col text-[#ffffff]'>
                            <p className='text-sm11 leading-[1] font-medium z-[999] pl-[2px]'>
                              Complete order for
                            </p>
                            <p className='text-[24px]  leading-[1]   font-medium z-[999]'>
                              ${addZeroes(cartData?.finalAmount)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='max-w-[448px] w-[100%] flex flex-col '>
                    <div className='flex gap-[8px]'>
                      <div className='h-[25px] w-[25px] border border-[#d6d6d6] flex justify-center rounded-[6px] items-center'>
                        <Icon_Lock16 className='fill-[#8e8e8e]' />
                      </div>
                      <div className='flex-col justify-between'>
                        <p className='text-sm11 text-primaryGray font-medium'>
                          Your information is secure.
                        </p>
                        <p className='text-sm11 text-primaryGray'>
                          Your encrypted payment method will be saved
                          for future orders.
                        </p>
                      </div>
                    </div>

                    <div className='mt-[32px]'>
                      <p className='text-sm11 text-primaryGray font-medium'>
                        Select Payment Method
                      </p>
                      <p className='text-sm11 text-pinkColor'>
                        Cash on Delivery not available
                      </p>
                    </div>

                    <div className='flex flex-col justify-between mt-[8px]'>
                      <div>
                        {paymentType?.map((item, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              showw(item.id);
                            }}
                            className={`text-primaryBlack text-sm14 leading-[49px] border-t border-[#EFEFEF] cursor-pointer ${
                              index === paymentType.length - 1
                                ? 'border-b'
                                : ''
                            }`}
                          >
                            <div className='flex justify-between'>
                              <span
                                className={`font-medium ${
                                  item.name === 'Gift Card' ||
                                  item.name === 'EMI'
                                    ? 'disabled  cursor-default'
                                    : ''
                                } `}
                              >
                                {item.name}
                              </span>
                              <div className='flex items-center'>
                                <DropArrow
                                  style={{
                                    fill: '#878787',
                                  }}
                                  className={`${
                                    item.name === 'Gift Card' ||
                                    item.name === 'EMI'
                                      ? 'disabled cursor-default'
                                      : ''
                                  }`}
                                />
                              </div>
                            </div>
                            {show === item.id && (
                              <div
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                                className='cursor-default'
                              >
                                {show === 1 ? (
                                  <>
                                    <p className='text-sm11 text-primaryGray'>
                                      The UPI ID is in the format of
                                      name/phone number@bankname
                                    </p>
                                    <p className='text-sm11 text-orangeColor'>
                                      How to find UPI ID?
                                    </p>

                                    <div className='relative w-[220px] mt-[8px]'>
                                      <input
                                        type='text'
                                        className='w-[220px] h-[44px] rounded-[5000px] text-primaryGray text-[14px] pt-[9px] border border-[#d6d6d6] pl-[20px] outline-none placeholder:font-medium  placeholder:text-[14px] placeholder:text-[#bbbbbb]'
                                        placeholder='9876543210@upi'
                                        // onChange={keywordText}
                                        // value={textWord}
                                      />

                                      <button
                                        onClick={() => {
                                          setPlaceOrder(true);
                                        }}
                                        // onClick={addKeyword}
                                        className='inputBoxButton'
                                      >
                                        Add
                                      </button>
                                      <span className='absolute leading-[1.2] top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
                                        Please enter your UPI ID
                                      </span>
                                    </div>

                                    <div
                                      style={{
                                        height: 'fit-content',
                                      }}
                                      className=' mt-[2px] mb-[32px] leading-[1]'
                                    >
                                      <label
                                        style={{
                                          width: 'fit-content',
                                        }}
                                        className='containerCheckGray text-[11px] text-primaryGray'
                                      >
                                        <input
                                          type='checkbox'
                                          // name='typeOfContent'
                                          // value='Original Art'
                                          // onChange={() => setAgree(!agree)}
                                        />{' '}
                                        <span className='relative  top-[2px]'>
                                          Save this UPI for faster
                                          checkout
                                        </span>
                                        <span className='checkmarkGray'></span>
                                      </label>
                                    </div>
                                  </>
                                ) : show === 2 ? (
                                  <div className='leading-[1.2]'>
                                    <p className='text-[#bbbbbb] font-medium text-[11px] mb-[2px]'>
                                      We accept
                                    </p>
                                    <div
                                      style={{
                                        width: 'fit-content',
                                      }}
                                      className='flex gap-[4px] items-center'
                                    >
                                      <div>
                                        <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                                      </div>

                                      <div>
                                        <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                                      </div>

                                      <div>
                                        <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                                      </div>

                                      <div>
                                        <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                                      </div>
                                      <div>
                                        <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                                      </div>
                                    </div>

                                    <div className='flex justify-between mt-[16px]'>
                                      <div className='flex'>
                                        <input
                                          type='radio'
                                          // onClick={() => setPaymentCheck('card')}
                                          // checked={paymentCheck === 'card'}
                                          name='payment'
                                          className='mr-[7px] relative bottom-[6px]'
                                          id=''
                                        />{' '}
                                        <div className='flex flex-col'>
                                          <div className='flex gap-[8px]'>
                                            <span className='text-sm12 text-primaryGray'>
                                              HDFC Bank Credit Card
                                              ending in 2139
                                            </span>
                                            <span className='text-sm11 text-pinkColor'>
                                              Default Purchase
                                              Preference
                                            </span>
                                          </div>
                                          <span className='text-sm11 text-primaryGray'>
                                            Name on the Card:{' '}
                                            <span className='text-sm11 text-primaryBlack font-medium'>
                                              Arshaan Shaikh
                                            </span>
                                          </span>
                                        </div>
                                      </div>

                                      <div>
                                        <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                                      </div>
                                    </div>
                                    <div className='flex justify-between mt-[16px]'>
                                      <div className='flex'>
                                        <input
                                          type='radio'
                                          // onClick={() => setPaymentCheck('card')}
                                          // checked={paymentCheck === 'card'}
                                          name='payment'
                                          className='mr-[7px] relative bottom-[6px]'
                                          id=''
                                        />{' '}
                                        <div className='flex flex-col'>
                                          <div className='flex gap-[8px]'>
                                            <span className='text-sm12 text-primaryGray'>
                                              HDFC Bank Credit Card
                                              ending in 2139
                                            </span>
                                            {/* <span className='text-sm11 text-pinkColor'>
                Default Purchase Preference
              </span> */}
                                          </div>
                                          <span className='text-sm11 text-primaryGray'>
                                            Name on the Card:{' '}
                                            <span className='text-sm11 text-primaryBlack font-medium'>
                                              Arshaan Shaikh
                                            </span>
                                          </span>
                                        </div>
                                      </div>

                                      <div>
                                        <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                                      </div>
                                    </div>

                                    <div className='mt-[16px]'>
                                      <label
                                        style={{
                                          width: 'fit-content',
                                        }}
                                        className='containerCheckGray text-[12px] text-primaryGray'
                                      >
                                        <input
                                          // onChange={handleCheckboxChange}
                                          type='checkbox'
                                          checked={true}
                                        />{' '}
                                        <span className='relative top-[1px]'>
                                          Default purchase preference
                                        </span>
                                        <span className='checkmarkGray'></span>
                                      </label>
                                    </div>
                                    <div className='mt-[8px]'>
                                      <label
                                        style={{
                                          width: 'fit-content',
                                        }}
                                        className='containerCheckGray text-[12px] text-primaryGray'
                                      >
                                        <input
                                          // onChange={handleCheckboxChange}
                                          type='checkbox'
                                          checked={true}
                                        />{' '}
                                        <span className='relative top-[1px]'>
                                          Save this card for future
                                          transactions
                                        </span>
                                        <span className='checkmarkGray'></span>
                                      </label>
                                    </div>

                                    <StyledPopupAddCard
                                      trigger={
                                        <button className='gray30HButton mt-[16px] mb-[32px]'>
                                          Add Card
                                        </button>
                                      }
                                      modal
                                    >
                                      {(close) => (
                                        <div
                                          style={{
                                            overflowY: 'auto',
                                          }}
                                          className=' w-[100%] '
                                        >
                                          <p className='text-[24px] font-medium leading-[29px] text-primaryBlack mb-[15px] text-center'>
                                            Enter Card Details
                                          </p>

                                          {/* <div
                                          style={{
                                            width: 'fit-content',
                                          }}
                                          className='flex gap-[4px] mb-[8px] mx-[auto]'
                                        >
                                          <div
                                            onClick={() => {
                                              setSelectedTab(
                                                'Card 1'
                                              );
                                            }}
                                            className={`${
                                              SelectedTab === 'Card 1'
                                                ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                                                : 'border-[#eaeaea] text-primaryGray font-medium'
                                            }  border rounded-[14px] cursor-pointer h-[28px]  hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                                          >
                                            Card 1
                                          </div>
                                          <div
                                            onClick={() => {
                                              setSelectedTab(
                                                'Card 2'
                                              );
                                            }}
                                            className={`${
                                              SelectedTab === 'Card 2'
                                                ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                                                : 'border-[#eaeaea] text-primaryGray font-medium'
                                            }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                                          >
                                            Card 2
                                          </div>
                                          <div
                                            onClick={() => {
                                              setSelectedTab(
                                                'Card 3'
                                              );
                                            }}
                                            className={`${
                                              SelectedTab === 'Card 3'
                                                ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                                                : 'border-[#eaeaea] text-primaryGray  font-medium'
                                            }  border rounded-[14px] h-[28px] cursor-pointer  hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px]`}
                                          >
                                            Card 3
                                          </div>
                                        </div> */}

                                          {SelectedTab ===
                                          'Card 1' ? (
                                            <div>
                                              <div className='relative mt-[8px]'>
                                                <input
                                                  type='text'
                                                  placeholder='Enter Your Name'
                                                  className='InputField44H'
                                                />

                                                <p className='InputFieldTopText44H'>
                                                  Your Name on the
                                                  Card
                                                </p>
                                              </div>

                                              <div className='flex gap-[10px] w-[100%] mt-[13px]'>
                                                <div className='relative w-[100%]'>
                                                  <input
                                                    type='text'
                                                    className='InputField44H'
                                                    placeholder='1234 5678 9876 5432'
                                                    name='zipCode'
                                                  />

                                                  <p className='InputFieldTopText44H'>
                                                    Credit/Debit Card
                                                    Number
                                                  </p>

                                                  <div
                                                    style={{
                                                      width:
                                                        'fit-content',
                                                    }}
                                                    className='flex gap-[4px] items-center absolute right-[20px] top-[12px]'
                                                  >
                                                    <div>
                                                      <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                                                    </div>

                                                    <div>
                                                      <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                                                    </div>

                                                    <div>
                                                      <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                                                    </div>

                                                    <div>
                                                      <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                                                    </div>
                                                    <div>
                                                      <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className='flex gap-[10px] w-[100%] mt-[13px]'>
                                                <div className='relative w-[50%]'>
                                                  <input
                                                    type='text'
                                                    className='InputField44H'
                                                    placeholder='12/21'
                                                    name='zipCode'
                                                  />

                                                  <p className='InputFieldTopText44H'>
                                                    Month and Year
                                                    (Expiration Date)
                                                  </p>
                                                </div>

                                                <div className='relative w-[50%]'>
                                                  <input
                                                    type='text'
                                                    className='InputField44H'
                                                    placeholder='123'
                                                    name='zipCode'
                                                  />

                                                  <p className='InputFieldTopText44H'>
                                                    CVC (Security
                                                    Code)
                                                  </p>
                                                </div>
                                              </div>

                                              <p className='text-sm11 text-center text-primaryGray mt-[16px]'>
                                                Artnstock will make
                                                sure your credit card
                                                is valid by
                                                authorizing it for a
                                                small amount. <br />
                                                This is NOT a charge,
                                                but rather an
                                                authorization that
                                                allows us to verify
                                                that your credit card
                                                is active. The
                                                information you enter
                                                will apply to all
                                                active subscriptions
                                                currently enrolled in
                                                Auto Renewal for this
                                                account.
                                              </p>

                                              <div className='flex justify-center item-center mt-[16px]'>
                                                <label
                                                  style={{
                                                    width:
                                                      'fit-content',
                                                  }}
                                                  className='containerCheckGray text-[11px] text-primaryGray mx-[auto]'
                                                >
                                                  <input type='checkbox' />{' '}
                                                  <span className='leading-[1] relative top-[4px]'>
                                                    Default purchase
                                                    preference
                                                  </span>
                                                  <span className='checkmarkGray'></span>
                                                </label>
                                              </div>

                                              <div className='flex gap-[8px] justify-center mt-[18px]'>
                                                <button className='black40HButton'>
                                                  Add Card
                                                </button>
                                                <button className='outlineBtn40h'>
                                                  Cancel
                                                </button>
                                              </div>

                                              {/* <div
                                              style={{
                                                width: 'fit-content',
                                              }}
                                              className='flex items-center mx-[auto] mt-[12px]'
                                            >
                                              <div>
                                                <img
                                                  src={lock}
                                                  className='mr-2'
                                                  alt=''
                                                />
                                              </div>
                                              <p className='text-sm11 text-[#bbbbbb] '>
                                                Your encrypted payment
                                                method will be saved
                                                for future orders.
                                              </p>
                                            </div> */}
                                            </div>
                                          ) : SelectedTab ===
                                            'Card 2' ? (
                                            <></>
                                          ) : SelectedTab ===
                                            'Card 3' ? (
                                            <></>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      )}
                                    </StyledPopupAddCard>
                                  </div>
                                ) : show === 3 ? (
                                  <>
                                    <p className='text-sm11 text-[#bbbbbb] mb-[5px]'>
                                      Select Bank
                                    </p>
                                    <div className='flex gap-[16px]'>
                                      <div className='flex flex-col gap-[8px]'>
                                        <img
                                          className='mx-[auto]'
                                          src={hdfc}
                                          alt=''
                                        />
                                        <p className='text-sm11 text-primaryGray text-center'>
                                          HDFC
                                        </p>
                                      </div>
                                      <div className='flex flex-col  gap-[8px]'>
                                        <img
                                          src={ici}
                                          alt=''
                                          className='mx-[auto]'
                                        />
                                        <p className='text-sm11 text-primaryGray text-center'>
                                          ICICI
                                        </p>
                                      </div>
                                      <div className='flex flex-col gap-[8px] '>
                                        <img
                                          src={axis}
                                          alt=''
                                          className='mx-[auto]'
                                        />
                                        <p className='text-sm11 text-primaryGray text-center'>
                                          Axis Bank
                                        </p>
                                      </div>
                                      <div className='flex flex-col  gap-[8px]'>
                                        <img
                                          src={sbi}
                                          alt=''
                                          className='mx-[auto]'
                                        />
                                        <p className='text-sm11 text-primaryGray text-center'>
                                          SBI
                                        </p>
                                      </div>
                                      <div className='flex flex-col  gap-[8px]'>
                                        <img
                                          src={kotak}
                                          alt=''
                                          className='mx-[auto]'
                                        />
                                        <p className='text-sm11 text-primaryGray text-center'>
                                          Kotak Bank
                                        </p>
                                      </div>
                                    </div>
                                    <button className='gray30HButton mt-[16px] mb-[32px]'>
                                      View More Banks
                                    </button>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {/* Second Section */}
            <div className='w-[328px] '>
              <div className='rounded-2xl overflow-hidden'>
                <div className='bg-[#bbbbbb] h-[40px] px-[16px] flex justify-between items-center'>
                  <p className='text-[16px] leading-[1] text-primaryBlack font-medium'>
                    Your Order Summary
                  </p>
                  {/* <img src={leftarrow} alt='' /> */}
                </div>

                {/* <div className='bg-[#EEEEEE] px-4 py-2.5 flex items-center'>
                  <div className='bg-[#333333] text-[#FFFFFF] rounded-full w-[25px] h-[25px] flex items-center justify-center'>
                    {cartData?.totalCount}
                  </div>
                  <p className='text-[13px] text-[#333333] ml-1 font-medium'>
                    Items in your cart
                  </p>
                </div> */}

                <div className='h-[60px] bg-[#eeeeee] flex gap-[8px] items-center px-[16px]'>
                  <div className='h-[44px] w-[44px] rounded-[5000px] bg-[#dadada] flex justify-center items-center'>
                    <span className='text-[#333333] text-[25px] leading-[1] '>
                      {/* {cartData?.totalCount} */}12
                    </span>
                  </div>
                  <div className='flex flex-col'>
                    <p className='font-medium text-sm11 text-primaryBlack'>
                      Item(s) in your cart
                    </p>
                    <p className='text-sm11 text-primaryGray '>
                      Shipping Charges, Promotional Discounts, <br />
                      Taxes, and GST calculated at checkout.
                    </p>
                  </div>
                </div>

                <div className='px-[16px] pb-[16px] pt-[16px] bg-[#f9f9f9] border-x border-x-[#efefef] border-b border-b-[#efefef] rounded-b-[16px]'>
                  {applyPromo ? (
                    <div className=''>
                      <div className='relative'>
                        <input
                          type='text'
                          className='w-[100%] h-[40px] rounded-[5000px] text-primaryGray text-[14px] pt-[9px] border border-[#d6d6d6] pl-[20px] outline-none placeholder:font-medium  placeholder:text-[14px] placeholder:text-[#bbbbbb]'
                          placeholder='Enter Promo Code here'
                          // onChange={keywordText}
                          // value={textWord}
                        />
                        <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
                          Add minimum 7 and maximum 50 keywords
                        </p>

                        <button
                          // onClick={addKeyword}
                          className='inputBoxButton'
                        >
                          Apply
                        </button>

                        {/* <button
                          // onClick={addKeyword}
                          className='inputBoxButton'
                        >
                          Add
                        </button> */}
                      </div>

                      {/* <div className='relative mb-[8px]'>
                        <input
                          type='text'
                          className='regInput mt-0 placeholder:text-[13px] placeholder:font-medium placeholder:text-[#bbbbb]'
                          placeholder='Enter Promo or Coupon code'
                          value={promoCode}
                          disabled={
                            giftCode.length > 0 ? true : false
                          }
                          onChange={(e) =>
                            setPromoCode(e.target.value)
                          }
                        />
                        <button
                          onClick={addPromoCode}
                          disabled={
                            giftCode.length > 0 ? true : false
                          }
                          className='text-primaryGray bg-[#EEEEEE] border border-[#E9E9E9] rounded-2xl py-[0.45rem]  px-[10px] text-[10px] leading-[10px] font-medium absolute top-[7px] right-[7px]'
                        >
                          Add
                        </button>
                      </div> */}
                      {/* <div className='relative'>
                        <input
                          type='text'
                          className='regInput mt-0 placeholder:text-[13px]  placeholder:font-medium  placeholder:text-[#bbbbb]'
                          placeholder='Enter Gift Card code'
                          value={giftCode}
                          disabled={
                            promoCode.length > 0 ? true : false
                          }
                          onChange={(e) =>
                            setGiftCode(e.target.value)
                          }
                        />
                        <button
                          onClick={addGiftCode}
                          disabled={
                            promoCode.length > 0 ? true : false
                          }
                          className='text-primaryGray bg-[#EEEEEE] border border-[#E9E9E9] rounded-2xl py-[0.45rem] px-[10px] text-[10px] leading-[10px] font-medium absolute  top-[7px] right-[7px]'
                        >
                          Add
                        </button>
                      </div> */}
                    </div>
                  ) : (
                    <div className=' '>
                      <div className='relative'>
                        <input
                          type='text'
                          className='w-[100%] h-[44px] rounded-[5000px] text-primaryGray text-[14px] pt-[9px] border border-[#d6d6d6] pl-[20px] outline-none placeholder:font-medium  placeholder:text-[14px] placeholder:text-[#bbbbbb]'
                          placeholder='Enter Promo Code here'
                          // onChange={keywordText}
                          // value={textWord}
                        />
                        <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
                          Promo Code (One Promo Code per order)
                        </p>

                        <button
                          // onClick={addKeyword}
                          className='inputBoxButton '
                        >
                          Apply
                        </button>
                        {/* <button
                          // onClick={addKeyword}
                          className='inputBoxButton'
                        >
                          Add
                        </button> */}
                      </div>
                      {/* <div className='relative mb-[8px]'>
                        <input
                          type='text'
                          className='regInput mt-0 placeholder:text-[13px] placeholder:font-medium placeholder:text-[#bbbbb] bg-[#fff]'
                          placeholder='Enter Promo or Coupon code'
                          value={promoCode}
                          disabled
                        />
                        <button className='text-primaryGray cursor-default bg-[#EEEEEE] border border-[#E9E9E9] rounded-2xl py-[0.45rem]  px-[10px] text-[10px] leading-[10px] font-medium absolute top-[7px] right-[7px]'>
                          Add
                        </button>
                      </div> */}
                      {/* <div className='relative'>
                        <input
                          type='text'
                          className='regInput mt-0 placeholder:text-[13px] placeholder:font-medium  placeholder:text-[#bbbbb] bg-[#fff]'
                          placeholder='Enter Gift Card code'
                          value={giftCode}
                          disabled
                        />
                        <button className='text-primaryGray cursor-default bg-[#EEEEEE] border border-[#E9E9E9] rounded-2xl py-[0.45rem] px-[10px] text-[10px] leading-[10px] font-medium absolute  top-[7px] right-[7px]'>
                          Add
                        </button>
                      </div> */}
                    </div>
                  )}

                  <div
                    onClick={() => {
                      navigate('/art/art-details');
                    }}
                    className='flex justify-between pt-[8px] pb-[8px] cursor-pointer  '
                  >
                    <div className='flex gap-[16px]'>
                      <img
                        className='h-[84px] w-[84px]  rounded-[12px] border border-[#efefef]'
                        src={combo}
                        alt=''
                      />
                      <div>
                        <p className='text-sm12 text-primaryBlack font-medium '>
                          Assorted Artnstock Lifestyle <br />
                          Combo 2024
                        </p>
                        <p className='text-sm11 text-primaryGray '>
                          Combo ID: ANS15994539
                        </p>
                        <div className='flex gap-[8px] mt-[4px]'>
                          <p className='text-sm12 text-pinkColor font-medium'>
                            $200.00
                          </p>
                          <p className='text-sm12 text-primaryGray'>
                            Qty.1
                          </p>
                        </div>
                        <button className='h-[16px] flex px-[4px] mt-[4px] text-[10px] block leading-[1] text-[#33cdf4] cursor-default border border-[#33cdf4] font-medium items-center justify-center rounded-[8px]'>
                          10% Discount Applied
                        </button>
                      </div>
                    </div>

                    {/* <p className='text-[15px] text-orangeColor'>
                              $400.00
                            </p> */}
                  </div>
                  <div
                    onClick={() => {
                      navigate('/art/art-details');
                    }}
                    className='flex justify-between pt-[8px] pb-[16px] border-b border-[#d6d6d6] cursor-pointer'
                  >
                    <div className='flex gap-[16px]'>
                      <img
                        className='h-[84px] w-[84px]  rounded-[12px] border border-[#efefef]'
                        src={mug}
                        alt=''
                      />
                      <div>
                        <p className='text-sm12 text-primaryBlack font-medium '>
                          Assorted Artnstock Lifestyle <br />
                          Combo 2024
                        </p>
                        <p className='text-sm11 text-primaryGray '>
                          Combo ID: ANS15994539
                        </p>
                        <div className='flex  gap-[8px] mt-[4px]'>
                          <p className='text-sm12 text-pinkColor font-medium'>
                            $200.00
                          </p>
                          <p className='text-sm12 text-primaryGray'>
                            Qty.1
                          </p>
                        </div>
                        <div className='flex gap-[4px] pt-[4px]'>
                          <div>
                            <Exclusive_Icon16 className='fill-[#b5a56d]' />
                          </div>
                          <div className='flex flex-col font-bold'>
                            <p className='text-[9px] text-[#b5a56d] leading-[1]'>
                              ARTNSTOCK
                            </p>
                            <p className='text-[9px] text-[#b5a56d] leading-[1]'>
                              EXCLUSIVE
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <p className='text-[15px] text-orangeColor'>
                              $400.00
                            </p> */}
                  </div>

                  {/* <div className='py-[16px]'>
                    <div className='h-[40px] relative w-[294px] rounded-[20px] flex items-center text-center justify-center text-sm11 font-medium'>
                      <span
                        style={{
                          width: 'fit-content',
                        }}
                        className='opacity-[100%]'
                      >
                        Shipping, Taxes, GST, and Promotional
                        Discounts <br />
                        calculated at Checkout.
                      </span>
                      <div className='w-[100%] h-[100%] absolute opacity-[15%] gradient-background rounded-[20px]'></div>
                    </div>
                  </div> */}
                  {/* <div
                    className='h-[100%] w-[100%] py-1 mb-[20px]'
                    style={{
                      background: `url(${msgBg})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                    }}
                  >
                    <p className='text-[12px] text-primaryBlack font-semibold text-center'>
                      Shipping, Taxes, GST, and Promotional Discounts{' '}
                      <br />
                      calculated at Checkout.
                    </p>
                  </div> */}
                  {/* <div className='h-[2px] w-[100%] bg-[#808080]'></div> */}
                  <div className='pt-[16px] pb-[16px]'>
                    <div className=' flex justify-between mb-[8px]'>
                      <div>
                        <p className='text-sm12  text-primaryBlack font-medium'>
                          Your Cart item(s) Total
                        </p>
                        <p className='text-sm11 text-primaryGray '>
                          10% Discount on $220.00 (-$20.00)
                        </p>
                      </div>
                      <p className='text-sm12  text-primaryBlack font-medium'>
                        {/* ${addZeroes(cartData?.totalAmount)} */}
                        $220.00
                      </p>
                    </div>
                    {/* <div className='h-[1px] my-[8px] w-[100%] bg-[#efefef]'></div> */}

                    <div className='flex justify-between mb-[8px]'>
                      <div>
                        <p className='text-sm12  text-primaryBlack font-medium'>
                          Tax
                        </p>
                        <p className='text-sm11 text-primaryGray '>
                          GST and other taxes
                        </p>
                      </div>
                      <p className='text-sm12  text-primaryBlack font-medium'>
                        {/* ${addZeroes(cartData?.taxAmount)} */}{' '}
                        $35.00
                      </p>
                    </div>

                    <div className=' flex justify-between  '>
                      <div>
                        <p className='text-sm12  text-primaryBlack font-medium'>
                          Shipping Charges
                        </p>
                        <p className='text-sm11 text-primaryGray '>
                          Your order will be shipped in 1 package
                        </p>
                      </div>
                      <p className='text-sm12  text-primaryBlack font-medium '>
                        {/* ${addZeroes(cartData?.estimateShipping)} */}
                        $12.00
                      </p>
                    </div>
                    {/* <div className='h-[1px] my-[8px] w-[100%] bg-[#efefef]'></div> */}
                  </div>
                  <div className='h-[2px] w-[100%] bg-[#d6d6d6]'></div>
                  <div className='flex justify-between items-center h-[60px] '>
                    <p className='text-[16px] leading-[16px] text-primaryBlack font-medium'>
                      Total Amount
                    </p>

                    {/* <div className='flex flex-col items-end '> */}
                    <div className='flex  items-baseline text-pinkColor  font-light'>
                      <div className='flex items-baseline '>
                        <p className='text-pinkColor text-[32px] '>
                          $
                        </p>
                        <p className='text-pinkColor text-[32px] font-medium'>
                          {/* {addZeroes(cartData?.finalAmount)?.toFixed(
                            2
                          )} */}
                          2500.00
                        </p>
                      </div>
                      {/* <p className='text-[22px] leading-[1]'>$</p>
                        <p className='text-[35px] leading-[1]'>
                          {addZeroes(cartData?.finalAmount).toFixed(
                            2
                          )}
                        </p> */}
                    </div>

                    {/* </div> */}
                  </div>
                  <div className='h-[2px] w-[100%] bg-[#d6d6d6]'></div>
                  {/* Promo Code */}
                  <div className='pt-[10px]'>
                    <div className='flex gap-2 items-start'>
                      {/* <div className=''>
                        <label
                          style={{
                            width: 'fit-content',
                          }}
                          className='containerCheckGray text-sm11 text-primaryGray'
                        >
                          <input
                            type='checkbox'
                            checked={applyPromo}
                            onChange={(e) =>
                              setApplyPromo(!applyPromo)
                            }
                           
                          />{' '}
                          Apply Promo codes and Gift Cards
                          <span className='checkmarkGray'></span>
                        </label>
                        <p className='text-sm11 text-primaryGray ml-[21px] relative bottom-[3px]'>
                          (One Promo code per order)
                        </p>
                      </div> */}

                      {/* <p className='text-sm12 text-primaryGray'>
                       
                      </p> */}
                    </div>
                  </div>
                  {/* {cartData?.list?.length > 0 ? (
                    <div>
                      {cartData?.list?.length > 1 ? (
                        <button
                          onClick={gotoCheckout}
                          onClick={() => {
                            setFinalCheckout(false);
                          }}
                          className={`blackBtn mt-[32px] mx-auto mb-[16px] block ${
                            !finalCheckout
                              ? 'disabledButtonsPopup'
                              : ''
                          }`}
                        >
                          Continue to Checkout
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setFinalCheckout(false);
                          }}
                          onClick={() => {
                            gotoCheckoutWithOne(
                              cartData?.cartId,
                              cartData?.list[0]?.cartArtFrameId,
                              cartData?.list[0]?.quantity
                            );
                          }}
                          className={`blackBtn mt-[32px] mx-auto mb-[16px] block ${
                            !finalCheckout
                              ? 'disabledButtonsPopup'
                              : ''
                          }`}
                        >
                          Continue to Checkout
                        </button>
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )} */}
                  <button
                    // onClick={gotoCheckout}
                    onClick={() => {
                      setFinalCheckout(false);
                    }}
                    className={`blackBtn mt-[32px] mx-auto mb-[16px] block ${
                      !finalCheckout ? 'disabledButtonsPopup' : ''
                    }`}
                  >
                    Continue to Checkout
                  </button>

                  <p className='text-sm11 text-primaryGray text-center'>
                    Since our products are printed on demand
                    especially <br /> for you, it is{' '}
                    <span className='font-medium'>
                      {' '}
                      not eligible for cancellations and returns.
                      <br />
                    </span>
                    Read our{' '}
                    <span className='text-orangeColor'>
                      Returns & Exchange policy
                    </span>
                    .
                    <br /> By placing your order, you agree to the{' '}
                    <span className='text-orangeColor'>
                      Delivery Terms
                    </span>
                    .
                  </p>

                  <p className='text-sm11 text-primaryGray text-center mt-[32px]'>
                    Accepted Payment Methods
                  </p>
                  <div
                    style={{
                      width: 'fit-content',
                    }}
                    className='flex gap-[4px] mx-[auto] mt-[3px]'
                  >
                    <div>
                      <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                    </div>

                    <div>
                      <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                    </div>

                    <div>
                      <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                    </div>

                    <div>
                      <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                    </div>
                    <div>
                      <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                    </div>
                    {/* <div>
                    <Discover />
                  </div> */}
                  </div>
                  {/* <div
                    style={{
                      boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
                    }}
                    className=' w-[300px] rounded-[16px] flex flex-col bg-[#ffffff] p-[10px] mt-[20px] '
                  >
                    <div className='rounded-[20px] h-[40px] border-[#d6d6d6] border text-primaryBlack text-[12px] flex justify-center items-center px-[30px] gap-[5px]'>
                      <div>
                        <LogoWithoutText />
                      </div>
                      <span className='font-medium'>
                        Sign in to your Artnstock account
                      </span>
                    </div>
                    <p className='text-[15px] text-[#333333] leading-[19px] font-medium mt-[7px]'>
                      Need Help?
                    </p>
                    <p className='text-sm12 text-primaryGray leading-[15px]'>
                      Shipping
                    </p>
                    <p className='text-sm12 text-primaryGray leading-[15px]'>
                      Return & Exchanges
                    </p>
                    <p className='text-sm12 text-primaryGray leading-[15px]'>
                      Contact Us
                    </p>
                  </div> */}
                </div>
              </div>

              {/* <div className='my-[16px] flex justify-center gap-[8px] py-[8px] border-[#efefef] border-t-[2px] border-b-[2px] border-t border-b'>
                <div>
                  <img
                    src={Certificate1}
                    alt=''
                    className='w-[68px] h-[68px]'
                  />
                 
                </div>
                <div>
                  <img
                    src={Certificate2}
                    alt=''
                    className='w-[68px] h-[68px]'
                  />
                  
                </div>
                <div>
                  <img
                    src={Certificate3}
                    alt=''
                    className='w-[68px] h-[68px]'
                  />
                 
                </div>
              </div> */}
              <p className='text-sm11 text-primaryGray text-center mt-[16px]'>
                Faster Checkout Every Time
              </p>
              <div
                style={{
                  width: 'fit-content',
                }}
                className='mt-[4px] mx-[auto] h-[40px] px-[14px] flex items-center rounded-gradient-border-for-button'
              >
                <img src={welcomeOffer} alt='' />
              </div>
              <div className='pl-[17px] mt-[16px]'>
                <p className='text-sm12 text-[#333333] font-medium'>
                  Customer Support
                </p>
                <p className='text-sm11 text-orangeColor '>
                  Shipping
                </p>
                <p className='text-sm11 text-orangeColor '>
                  Return & Exchanges
                </p>
                <p className='text-sm11 text-orangeColor '>
                  Contact Us
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Certificate />
      <Footer />
    </>
  );
};
export default Checkout;

/* <div className='wrapper pt-[30px] mb-[100px]'>
        <p className='bullet text-center mb-1 leading-[1.2]'>
          Artnstock / Shopping Cart / Checkout
        </p>
       
        <div className='flex mt-7 w-w930 mx-auto gap-[32px]'>
          {finalCheckout ? (
            <div className='w-[568px]'>
              <div className='flex items-center px-[8px] h-[46px] bg-[#f9f9f9] border-[#efefef] border rounded-[12px]'>
                <img src={lock} className='mr-2' alt='' />
                <p className='text-sm11 text-primaryGray'>
                  <span className='font-medium'>
                    Your information is secure.
                  </span>{' '}
                  Your encrypted payment method will be saved for
                  future orders. <br /> Manage your payment methods in
                  your{' '}
                  <span className='text-[#F9944C]'>
                    Account Settings
                  </span>
                  .
                </p>
              </div>
              <p className='text-sm11 text-pinkColor mt-[32px]'>
                All fields are required.
              </p>
              <div>
                <p className='text-[24px] font-medium text-primaryBlack mt-[13px] leading-[1.2]'>
                  Payment Method
                </p>
                <p className='text-sm11 text-primaryGray mt-[13px]'>
                  Use the <span className='font-medium'> Notes</span>{' '}
                  section to explain, if your Art, Photos, Footage,
                  Music, or Template is Original Content, Art with
                  Reference, Exclusive Art, Matured Content, or
                  Re-Submission.
                </p>
                <p className='text-primaryBlack text-[16px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
                  Select Payment Method
                </p>
                <div className=' text-sm12 rounded-[13px] text-primaryGray border border-[#efefef]  bg-[#f9f9f9] p-[16px] w-w557'>
                  <div className='flex items-center'>
                    <input
                      type='radio'
                      onClick={() => setPaymentCheck('card')}
                      checked={paymentCheck === 'card'}
                      name='payment'
                      className='mr-[7px]'
                      id=''
                    />{' '}
                    <span className='leading-[1] mr-[15px]'>
                      Credit/Debit Card
                    </span>
                   
                    <div
                      style={{
                        width: 'fit-content',
                      }}
                      className='flex gap-[4px]'
                    >
                      <div>
                        <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>

                      <div>
                        <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>

                      <div>
                        <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>

                      <div>
                        <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>
                      <div>
                        <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>
                    </div>
                  </div>
                  <div className='flex items-center mt-[12px]'>
                    <input
                      type='radio'
                      onClick={() => setPaymentCheck('paypal')}
                      checked={paymentCheck === 'paypal'}
                      name='payment'
                      className='mr-2'
                      id=''
                    />{' '}
                    <span className='leading-[1]'>Paypal</span>
                  </div>
                </div>
              </div>

              <div>
                <p className='text-primaryBlack text-[16px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
                  Credit/Debit Card Information
                </p>

                <div>
                  <button
                    onClick={() => setShowCardType((prev) => !prev)}
                    className={`${
                      showCardType === true
                        ? 'dropdownTwoGlobalTrue'
                        : 'dropdownTwoGlobalFalse'
                    } dropdownTwoGlobalCommon44h  w-[100%]`}
                  >
                    <div className='flex flex-col'>
                      <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                        Card Type
                      </p>
                      <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                       
                        {selectCardTypeValue
                          ? selectCardTypeValue
                          : 'Select Card'}
                     
                      </p>
                    </div>

                    <div>
                      <DropArrow
                        className={`${
                          showCardType ? 'transform rotate-180' : ''
                        }`}
                        style={{
                          fill: '#878787',
                        }}
                      />
                    </div>
                  </button>
                  {showCardType && (
                    <div className='relative z-[999]'>
                      <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray w-[100%]'>
                        {dropList.map((obj, index) => (
                          <li
                            key={obj.name}
                            onClick={() => {
                              setselectCardTypeValue(obj.name);
                              setShowCardType(false);
                            }}
                            className={`cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] ${
                              index === dropList.length - 1
                                ? ''
                                : 'border-b'
                            } border-[#efefef]`}
                          >
                            {obj.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                

                <div className='flex  mt-4'>
                  <div className='w-[274px] mr-[20px]'>
                    <div className='relative '>
                      <input
                        type='text'
                        placeholder='Select Credit/Debit Card Number'
                        className='InputField44H'
                        
                      />

                      <p className='InputFieldTopText44H'>
                        Card Number
                      </p>
                    </div>
                   
                  </div>
                  <div className='w-[133px] mr-[8px]'>
                    <div className='relative '>
                      <input
                        type='text'
                        placeholder='MM/YY'
                        className='InputField44H'
                       
                      />

                      <p className='InputFieldTopText44H'>
                        Month & Year
                      </p>
                    </div>

                   

                    <p className='text-sm11 text-primaryGray mt-[2px]'>
                      Expiration Date
                    </p>
                  </div>
                  <div className='w-[133px]'>
                    <div className='relative '>
                      <input
                        type='text'
                        placeholder='123'
                        className='InputField44H'
                      
                      />

                      <p className='InputFieldTopText44H'>CVC</p>
                    </div>
                   

                    <p className='text-sm11 text-primaryGray mt-[2px]'>
                      Security Code
                    </p>
                  </div>
                </div>
              </div>

              <div className='h-[1px] bg-[#efefef] w-[100%] my-[32px]'></div>
              <div>
                <p className='text-[24px] font-medium text-primaryBlack leading-[1.2]'>
                  Contact Information
                </p>
                <p className='text-sm11 text-primaryGray mt-[13px]'>
                  Use the <span className='font-medium'> Notes</span>{' '}
                  section to explain, if your Art, Photos, Footage,
                  Music, or Template is Original Content, Art with
                  Reference, Exclusive Art, Matured Content, or
                  Re-Submission.
                </p>

                <div>
                  <p className='text-primaryBlack text-[16px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
                    Personal Details
                  </p>
                  <div className='mb-0'>
                    <div className='relative'>
                      <input
                        type='text'
                        placeholder='Azra'
                        className='InputField44H'
                        value={userData?.userFirstName || ''}
                      
                      />

                      <p className='InputFieldTopText44H'>
                        First Name
                      </p>
                    </div>
                  </div>

                  <div className='mt-[13px] mb-0'>
                    <div className='relative '>
                      <input
                        type='text'
                        placeholder='Shaikh'
                        className='InputField44H'
                        value={userData?.userLastName || ''}
                       
                      />

                      <p className='InputFieldTopText44H'>
                        Last Name
                      </p>
                    </div>

                   
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <div className='relative '>
                      <input
                        type='text'
                        placeholder='ksgrafiks2012@gmail.com'
                        className='InputField44H'
                        value={userData?.emailAddress}
                       
                      />

                      <p className='InputFieldTopText44H'>
                        Email Address
                      </p>
                    </div>
                    
                  </div>
                </div>

                <div>
                  <p className='text-primaryBlack text-[16px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
                    Shipping Address
                  </p>
                  <div className='mb-0'>
                    <div>
                      <button
                        onClick={() => {
                          setselectCountry(!selectCountry);
                        }}
                        className={`${
                          selectCountry === true
                            ? 'dropdownTwoGlobalTrue'
                            : 'dropdownTwoGlobalFalse'
                        } dropdownTwoGlobalCommon44h  w-[100%]`}
                      >
                        <div className='flex flex-col'>
                          <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                            Location
                          </p>
                          <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                            {userData?.shippingAddress?.countryName}
                          </p>
                        </div>

                        <div>
                          <DropArrow
                            className={`${
                              selectCountry
                                ? 'transform rotate-180'
                                : ''
                            }`}
                            style={{
                              fill: '#878787',
                            }}
                          />
                        </div>
                      </button>
                      {selectCountry && (
                        <div className='relative z-[999]'>
                          <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray  w-[100%]'>
                            <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                              India
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>

                  
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <div className='relative '>
                      <input
                        type='text'
                        
                        className='InputField44H'
                        value={
                          userData?.shippingAddress?.addressLine1 ||
                          ''
                        }
                        placeholder='S. No. 203/2A, Plot 16A, Rajiv Nagar (South),'
                        name='addressLine1'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'shippingAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                      />

                      <p className='InputFieldTopText44H'>
                        Address Line 1
                      </p>
                    </div>
                  
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <div className='relative '>
                      <input
                        type='text'
                       
                        className='InputField44H'
                        value={
                          userData?.shippingAddress?.addressLine2
                        }
                        placeholder='Viman Nagar,'
                        name='addressLine2'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'shippingAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                      />

                      <p className='InputFieldTopText44H'>
                        Address Line 2
                      </p>
                    </div>
                   
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <div>
                      <button
                        onClick={() => {
                          setselectCity(!selectCity);
                        }}
                        className={`${
                          selectCity === true
                            ? 'dropdownTwoGlobalTrue'
                            : 'dropdownTwoGlobalFalse'
                        } dropdownTwoGlobalCommon44h  w-[100%]`}
                      >
                        <div className='flex flex-col'>
                          <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                            City
                          </p>
                          <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                            {userData?.shippingAddress?.cityName}
                          </p>
                        </div>

                        <div>
                          <DropArrow
                            className={`${
                              selectCity ? 'transform rotate-180' : ''
                            }`}
                            style={{
                              fill: '#878787',
                            }}
                          />
                        </div>
                      </button>
                      {selectCity && (
                        <div className='relative z-[999]'>
                          <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray  w-[100%]'>
                            <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                              Pune
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    
                  </div>
                  <div className='flex gap-[14px] w-[100%]'>
                    <div className='mt-[13px] mb-0 w-[100%]'>
                      <div className='relative w-[100%]'>
                        <input
                          type='text'
                         
                          className='InputField44H'
                          value={userData?.shippingAddress?.zipCode}
                          placeholder='411014'
                          name='zipCode'
                          onChange={(e) =>
                            handleInputChangeNested(
                              'shippingAddress',
                              e.target.name,
                              e.target.value
                            )
                          }
                        />

                        <p className='InputFieldTopText44H'>
                          Zip/Postal Code
                        </p>
                      </div>
                    </div>
                    <div className='mt-[13px] mb-0 w-[100%]'>
                      <div className='w-[100%]'>
                        <button
                          onClick={() => {
                            setselectState(!selectState);
                          }}
                          className={`${
                            selectState === true
                              ? 'dropdownTwoGlobalTrue'
                              : 'dropdownTwoGlobalFalse'
                          } dropdownTwoGlobalCommon44h  w-[100%]`}
                        >
                          <div className='flex flex-col'>
                            <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                              State/Province
                            </p>
                            <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                              {userData?.shippingAddress?.stateName}
                            </p>
                          </div>

                          <div>
                            <DropArrow
                              className={`${
                                selectState
                                  ? 'transform rotate-180'
                                  : ''
                              }`}
                              style={{
                                fill: '#878787',
                              }}
                            />
                          </div>
                        </button>
                        {selectState && (
                          <div className='relative z-[999]'>
                            <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray  w-[100%]'>
                              <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                                Maharashtra
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>

                     
                    </div>
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <div className='relative mt-[12px]'>
                      <input
                        type='text'
                       
                        className='InputField44H'
                        value={userData?.shippingAddress?.phoneNo}
                        placeholder='+91 7378666093'
                        name='phoneNo'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'shippingAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                        disabled
                      />

                      <p className='InputFieldTopText44H'>Phone</p>
                    </div>
                  
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <div>
                      <button
                        onClick={dropdownEvent}
                        className={`${
                          openDropdown === true
                            ? 'dropdownTwoGlobalTrue'
                            : 'dropdownTwoGlobalFalse'
                        } dropdownTwoGlobalCommon44h  w-[100%]`}
                      >
                        <div className='flex flex-col'>
                          <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                            Shipping Method
                          </p>
                          <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                           
                            {select !== null
                              ? select?.shippingMethodName
                              : 'Select Shipping Method'}
                            
                          </p>
                        </div>

                        <div>
                          <DropArrow
                            className={`${
                              openDropdown
                                ? 'transform rotate-180'
                                : ''
                            }`}
                            style={{
                              fill: '#878787',
                            }}
                          />
                        </div>
                      </button>
                      {openDropdown && (
                        <div className='relative z-[999]'>
                          <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray w-[100%]'>
                            {shipping?.map((item, index) => (
                              <li
                               
                                onClick={() => selectValue(item)}
                                className={`cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] ${
                                  index === shipping.length - 1
                                    ? ''
                                    : 'border-b'
                                } border-[#efefef]`}
                              >
                                {item?.shippingMethodName}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>

                    
                  </div>
                </div>

                <div>
                  <p className='text-primaryBlack text-[16px] mt-[32px] mb-[6px] leading-[21px] font-medium'>
                    Residential Address
                  </p>

                  <label
                    style={{
                      width: 'fit-content',
                    }}
                    className='containerCheckGray text-[12px] text-primaryGray'
                  >
                    <input
                      type='checkbox'
                      onChange={changeCheckBox}
                      checked={isChecked}
                      
                    />{' '}
                    My shipping and residential address are the same.
                    <span className='checkmarkGray'></span>
                  </label>
                 

                  <div className='mb-0 mt-[12px]'>
                    <div>
                      <button
                        onClick={() => {
                          setselectCountryResi(!selectCountryResi);
                        }}
                        className={`${
                          selectCountryResi === true
                            ? 'dropdownTwoGlobalTrue'
                            : 'dropdownTwoGlobalFalse'
                        } dropdownTwoGlobalCommon44h  w-[100%]`}
                      >
                        <div className='flex flex-col'>
                          <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                            Location
                          </p>
                          <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                            {
                              userData?.residentialAddress
                                ?.countryName
                            }
                          </p>
                        </div>

                        <div>
                          <DropArrow
                            className={`${
                              selectCountryResi
                                ? 'transform rotate-180'
                                : ''
                            }`}
                            style={{
                              fill: '#878787',
                            }}
                          />
                        </div>
                      </button>
                      {selectCountryResi && (
                        <div className='relative z-[999]'>
                          <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray  w-[100%]'>
                            <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                              India
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>

                  

                  <div className='mt-[13px] mb-0'>
                    <div className='relative '>
                      <input
                        type='text'
                       
                        className='InputField44H'
                        value={
                          userData?.residentialAddress
                            ?.addressLine1 || ''
                        }
                        placeholder='S. No. 203/2A, Plot 16A, Rajiv Nagar (South),'
                        name='addressLine1'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'residentialAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                      />

                      <p className='InputFieldTopText44H'>
                        Address Line 1
                      </p>
                    </div>
                  </div>

                 

                  <div className='mt-[13px] mb-0'>
                    <div className='relative '>
                      <input
                        type='text'
                       
                        className='InputField44H'
                        value={
                          userData?.residentialAddress?.addressLine2
                        }
                        placeholder='Viman Nagar,'
                        name='addressLine2'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'residentialAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                      />

                      <p className='InputFieldTopText44H'>
                        Address Line 2
                      </p>
                    </div>
                  </div>

                 

                  <div className='mt-[13px] mb-0'>
                    <div>
                      <button
                        onClick={() => {
                          setselectCityResi(!selectCityResi);
                        }}
                        className={`${
                          selectCityResi === true
                            ? 'dropdownTwoGlobalTrue'
                            : 'dropdownTwoGlobalFalse'
                        } dropdownTwoGlobalCommon44h  w-[100%]`}
                      >
                        <div className='flex flex-col'>
                          <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                            City
                          </p>
                          <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                            {userData?.residentialAddress?.cityName}
                          </p>
                        </div>

                        <div>
                          <DropArrow
                            className={`${
                              selectCityResi
                                ? 'transform rotate-180'
                                : ''
                            }`}
                            style={{
                              fill: '#878787',
                            }}
                          />
                        </div>
                      </button>
                      {selectCityResi && (
                        <div className='relative z-[999]'>
                          <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray  w-[100%]'>
                            <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                              Pune
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>

                

                  <div className='flex gap-[14px] w-[100%]'>
                    <div className='mt-[13px] mb-0 w-[100%]'>
                      <div className='relative w-[100%]'>
                        <input
                          type='text'
                         
                          className='InputField44H'
                          value={
                            userData?.residentialAddress?.postalCode
                          }
                          placeholder='411014'
                          name='postalCode'
                          onChange={(e) =>
                            handleInputChangeNested(
                              'residentialAddress',
                              e.target.name,
                              e.target.value
                            )
                          }
                        />

                        <p className='InputFieldTopText44H'>
                          Zip/Postal Code
                        </p>
                      </div>
                     
                    </div>
                    <div className='mt-[13px] mb-0 w-[100%]'>
                      <div className='w-[100%]'>
                        <button
                          onClick={() => {
                            setselectStateResi(!selectStateResi);
                          }}
                          className={`${
                            selectStateResi === true
                              ? 'dropdownTwoGlobalTrue'
                              : 'dropdownTwoGlobalFalse'
                          } dropdownTwoGlobalCommon44h  w-[100%]`}
                        >
                          <div className='flex flex-col'>
                            <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                              State/Province
                            </p>
                            <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                              {
                                userData?.residentialAddress
                                  ?.stateName
                              }
                            </p>
                          </div>

                          <div>
                            <DropArrow
                              className={`${
                                selectStateResi
                                  ? 'transform rotate-180'
                                  : ''
                              }`}
                              style={{
                                fill: '#878787',
                              }}
                            />
                          </div>
                        </button>
                        {selectStateResi && (
                          <div className='relative z-[999]'>
                            <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray  w-[100%]'>
                              <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                                Maharashtra
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>

                     
                    </div>
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <div className='relative mt-[12px]'>
                      <input
                        type='text'
                       
                        className='InputField44H'
                        value={userData?.residentialAddress?.phoneNo}
                        placeholder='+91 7378666093'
                        name='phoneNo'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'residentialAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                        disabled
                      />

                      <p className='InputFieldTopText44H'>Phone</p>
                    </div>
                   
                  </div>
                 

                  <button
                    onClick={() => setFinalCheckout(false)}
                    className='blackBtn block mx-auto mt-[36px]'
                  >
                    Continue to Checkout
                  </button>
                </div>

                <p className='text-sm11 text-primaryGray text-center mt-[13px] mb-[32px]'>
                  Since our products are printed on demand especially
                  for you, <br /> it is{' '}
                  <span className='font-medium'>
                    {' '}
                    not eligible for cancellations and returns
                  </span>
                  . Read our{' '}
                  <span className='text-orangeColor'>
                    Returns & Exchange policy
                  </span>
                  . <br /> By placing your order, you agree to the{' '}
                  <span className='text-orangeColor'>
                    Delivery Terms
                  </span>
                  .
                </p>
               
              </div>
            </div>
          ) : (
            <div className='w-[568px]'>
              <div className='flex items-center px-[8px] h-[46px] bg-[#f9f9f9] border-[#efefef] border rounded-[12px]'>
                <img src={lock} className='mr-2' alt='' />
                <p className='text-sm11 text-primaryGray'>
                  <span className='font-medium'>
                    Your information is secure.
                  </span>{' '}
                  Your encrypted payment method will be saved for
                  future orders. <br /> Manage your payment methods in
                  your{' '}
                  <span className='text-[#F9944C]'>
                    Account Settings
                  </span>
                  .
                </p>
              </div>

              <p
                onClick={() => {
                  navigate('/checkout2');
                }}
                className='text-[24px] font-medium text-[#333333] leading-[1.2] mt-[32px] mb-[16px] cursor-pointer'
              >
                Order Number: ANS9876547
              </p>

              <div className='text-primaryGray text-sm12 font-medium leading-4'>
                <div className='flex border-t border-t-[2px] border-b gap-[8px] border-[#efefef]'>
                  <p className='w-[61px] font-medium'>Customer:</p>
                  <p className='font-normal'>Khalid Shaikh</p>
                </div>
                <div className='flex  border-b-[2px] border-b gap-[8px] border-[#efefef]'>
                  <p className='w-[61px] font-medium'>Order Date:</p>
                  <p className='font-normal'>18 Jan 2024</p>
                </div>
              </div>

             

              <div className='flex flex-col'>
                <p className='text-primaryBlack text-[16px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
                  Payment Method
                </p>
                <div>
                  <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>
                <p className='text-sm12 text-primaryGray mt-[4px] flex items-center'>
                  Card Number, ************ 5248
                </p>
                <p className='text-sm12 text-primaryGray mt-[4px] flex items-center'>
                  Expiry: 24/02/2025
                </p>
              </div>
           
              <div className='mt-[20px]'>
                <p className='text-primaryBlack text-[16px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
                  Mailling Address
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.userFirstName} {userData?.userLastName}
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.residentialAddress?.addressLine1},
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.residentialAddress?.addressLine2},{' '}
                  {userData?.residentialAddress?.cityName}{' '}
                  {userData?.residentialAddress?.zipCode}.
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.residentialAddress?.stateName}
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.residentialAddress?.countryName}
                </p>
                <p className='text-sm12  text-primaryGray'>
                  Contact No.:{userData?.residentialAddress?.phoneNo}
                </p>
                <p className='text-sm12  text-primaryGray'>
                  Email:{userData?.emailAddress}
                </p>
              </div>
              <div className='mt-[20px]'>
                <p className='text-primaryBlack text-[16px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
                  Shipping Address
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.userFirstName} {userData?.userLastName}
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.shippingAddress?.addressLine1},
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.shippingAddress?.addressLine2},{' '}
                  {userData?.shippingAddress?.cityName}{' '}
                  {userData?.shippingAddress?.zipCode}.
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.shippingAddress?.stateName}
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.shippingAddress?.countryName}
                </p>
                <p className='text-sm12  text-primaryGray'>
                  Contact No.:{userData?.shippingAddress?.phoneNo}
                </p>
                <p className='text-sm12  text-primaryGray'>
                  Email:{userData?.emailAddress}
                </p>
              </div>
              <div className='mt-[20px]'>
                <p className='text-primaryBlack text-[16px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
                  Shipping Method
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {select?.shippingMethodName}
                </p>
                <p className='text-sm12 text-primaryGray'>
                  Order now to receive in {select?.dayToReceive}{' '}
                  business days
                </p>
              </div>

              <div className='my-[32px]'>
                <label
                  style={{
                    width: 'fit-content',
                  }}
                  className='containerCheckGray text-[12px] text-primaryGray '
                >
                  <input
                    type='checkbox'
                    
                    defaultChecked='true'
                    
                  />{' '}
                  Send me news and exclusive offers from Artnstock
                  <span className='checkmarkGray'></span>
                </label>
              </div>

              <div className=''>
                <div
                  style={{
                    width: 'fit-content',
                  }}
                  onClick={() => handlePayment(cartData?.finalAmount)}
                  className='px-[20px] h-[40px] flex justify-center items-center relative rounded-[5000px] cursor-pointer '
                 
                >
                  <div className='w-[100%] h-[100%] gradient-background  rounded-[5000px]  opacity-[80%] absolute'></div>
                  <p className='text-sm14 text-primaryBlack font-medium z-[999]'>
                    Complete order for $
                    {addZeroes(cartData?.finalAmount)}
                  </p>
                </div>
              </div>

              <div className='h-[1px] w-[100%] bg-[#efefef] mt-[32px] mb-[32px]'></div>

              <p className='text-sm11 text-primaryGray mb-[26px]'>
                All products are manufactured on-demand and ship from
                the manufacturing facility located nearest to your
                destination address. Gift cards are delivered
                instantly via e-mail. International orders may take up
                to 10 days to arrive due to FedEx/UPS delivery times.{' '}
                <br /> <br /> Please note - your government may charge
                an import tax on products received from outside your
                country. The import tax (if applicable) is not
                included in our shipping charge.
              </p>

             
            </div>
          )}

       
          <div className='w-[328px] '>
            <div className=' rounded-2xl overflow-hidden'>
              <div className='bg-[#8e8e8e] py-2.5 px-[16px] flex justify-between items-center'>
                <p className='text-[16px] leading-[1.2] text-[#FFFFFF] font-medium'>
                  Order Summary
                </p>
                <img src={leftarrow} alt='' />
              </div>

              <div className='h-[60px] bg-[#eeeeee] flex gap-[8px] items-center px-[16px]'>
                <div className='h-[44px] w-[44px] rounded-[5000px] bg-[#dadada] flex justify-center items-center'>
                  <span className='text-[#333333] text-[24px] leading-[1] font-light'>
                    {cartCount}
                  </span>
                </div>
                <div className='flex flex-col'>
                  <p className='font-medium text-sm12 text-primaryBlack'>
                    Items in your cart
                  </p>
                  <p className='text-[11px] leading-[13px] text-primaryGray '>
                    Shipping Charges, Taxes, GST, and Promotional{' '}
                    <br />
                    Discounts calculated at checkout.
                  </p>
                </div>
              </div>

            

              <div className='px-[16px] pb-[32px] pt-[32px] bg-[#f9f9f9] border-x border-x-[#efefef] border-b border-b-[#efefef] rounded-b-[16px]'>
               

                <div className='flex flex-col'>
                  <div className='flex gap-[8px]'>
                    <div className='w-[88px] h-[88px]  flex justify-center items-center bg-[#efefef] rounded-[12px] p-[8px]'>
                      <img
                        src={
                          'https://res.cloudinary.com/artnstockimg/image/upload/v1698394614/artnstock/blybtnmdplwh7si1uqx1.jpg'
                        }
                       
                        className='w-[100%]  rounded-[2px]'
                        alt=''
                      />
                    
                    </div>
                    <div className='max-w-[198px] w-[100%]'>
                      <p className='text-[#333333] text-[15px] font-medium leading-[18px]'>
                        Office Merchandise Combo
                      </p>
                      <p className='text-[#757575] text-[12px] leading-[1.2]'>
                        International Artist Azra's work dictates the
                        expression of...
                      </p>
                      <p className='pt-[11px] text-[#F9944C] text-[15px] font-medium leading-[1.2]'>
                        $1000
                        <span className='text-[#757575] line-through ml-[5px]'>
                          $220.00
                        </span>
                      </p>
                      <p className='text-[#F9944C] text-[11px] mt-0 leading-[1]'>
                        10% Discount Applied
                      </p>
                      <div
                        className='flex mt-[6px]'
                        style={{ width: 'fit-content' }}
                      >
                        <div
                          className='bg-[#EEEEEE] h-[27px] w-[25px] flex items-center justify-center rounded-l-3xl cursor-pointer'
                        
                        >
                          <img src={minusIcon} alt='' />
                        </div>
                        <input
                          className='w-[25px] text-[13px] leading-[15px] font-medium text-primaryGray text-center border-y-[2px] border-y-[#EEEEEE]  outline-none'
                          type='text'
                          value={1}
                        
                        />
                        <div
                          className='bg-[#EEEEEE]  h-[27px] w-[25px] flex items-center justify-center rounded-r-3xl  cursor-pointer'
                          
                        >
                          <img
                            className='w-[11px] h-[11px]'
                            src={plusIcon}
                            alt=''
                          />
                        </div>
                      </div>
                      <p className='text-primaryGray text-sm11 flex gap-[8px] mt-1 '>
                        <span
                      
                        >
                          Edit
                        </span>
                        <span
                          className='cursor-pointer'
                         
                        >
                          Delete
                        </span>
                        <span>Move to Wishlist</span>
                      </p>
                    </div>
                  </div>
                  <div className='h-[1px] w-[100%] bg-[#efefef] mt-[30px] mb-[28px]'></div>

                  {cartData?.list?.map((cart, i) => (
                    <>
                      <div className='flex gap-[8px]'>
                        <div className='w-[88px] h-[88px]  flex justify-center items-center  bg-[#efefef] rounded-[12px] p-[8px]'>
                          <img
                            src={cart?.imgUrl}
                            className={`${
                              cart?.orientationMaster?.shape ===
                              'Vertical'
                                ? 'h-[100%]'
                                : cart?.orientationMaster?.shape ===
                                  'Horizontal'
                                ? 'w-[100%]'
                                : 'h-[100%] h-[100%]'
                            } rounded-[2px]`}
                            alt=''
                          />
                          
                        </div>
                        <div className='max-w-[198px] w-[100%]'>
                          <p className='text-[#333333] text-[15px] font-medium leading-[18px]'>
                            {cart?.artMaster?.artName?.length > 45 ? (
                              <>
                                {cart?.artMaster?.artName.slice(
                                  0,
                                  45
                                )}
                                ...
                              </>
                            ) : (
                              <>{cart?.artMaster?.artName}</>
                            )}
                          </p>
                          <p className='text-[#757575] text-[12px] leading-[1.2]'>
                            {!cart?.artMaster?.description.length >
                            60 ? (
                              <>{cart?.artMaster?.description}</>
                            ) : (
                              <>
                                {cart?.artMaster?.description.slice(
                                  0,
                                  60
                                )}
                                ...
                              </>
                            )}
                          </p>
                          <p className='pt-[11px] text-[#F9944C] text-[15px] font-medium leading-[1.2]'>
                            ${addZeroes(cart?.amount)}
                            
                          </p>
                          <p className='text-[#F9944C] text-[11px] mt-0 leading-[1]'>
                            10% Discount Applied
                          </p>
                          <div
                            className='flex mt-[6px]'
                            style={{ width: 'fit-content' }}
                          >
                            <div
                              className='bg-[#EEEEEE] h-[27px] w-[25px] flex items-center justify-center rounded-l-3xl cursor-pointer'
                            
                            >
                              <img src={minusIcon} alt='' />
                            </div>
                            <input
                              className='w-[25px] text-[13px] leading-[15px] font-medium text-primaryGray text-center border-y-[2px] border-y-[#EEEEEE]  outline-none'
                              type='text'
                              value={1}
                           
                            />
                            <div
                              className='bg-[#EEEEEE]  h-[27px] w-[25px] flex items-center justify-center rounded-r-3xl  cursor-pointer'
                              
                            >
                              <img
                                className='w-[11px] h-[11px]'
                                src={plusIcon}
                                alt=''
                              />
                            </div>
                          </div>
                          <p className='text-primaryGray text-sm11 flex gap-[8px] mt-1 '>
                            <span onClick={() => editArt()}>
                              Edit
                            </span>
                            <span
                              className='cursor-pointer'
                              onClick={() =>
                                removeOrder(cart?.cartArtFrameId)
                              }
                            >
                              Delete
                            </span>
                            <span>Move to Wishlist</span>
                          </p>
                        </div>
                      </div>
                      {!(cartData?.list?.length - 1 === i) && (
                        <div className='h-[1px] w-[100%] bg-[#efefef] mt-[30px] mb-[28px]'></div>
                      )}
                    </>
                  ))}
                </div>

                <div className='h-[2px] w-[100%] mt-[20px] bg-[#8e8e8e]'></div>

                <div className='pt-[32px]'>
                  <div className='flex justify-between '>
                    <div className='leading-[1.2]'>
                      <p className='text-[15px] leading-[18px]  text-primaryBlack font-medium'>
                        Your Cart Items
                      </p>
                      <p className='text-sm11 text-primaryGray mt-[1px]'>
                        includes $45 discount
                      </p>
                    </div>
                    <p className='text-[15px] leading-[18px]  text-primaryBlack  font-medium'>
                      ${addZeroes(cartData?.totalAmount)}
                    </p>
                  </div>

                  <div className='h-[1px] my-[8px] w-[100%] bg-[#efefef]'></div>

                  <div className='flex justify-between '>
                    <div className=''>
                      <p className='text-[15px] leading-[18px]  text-primaryBlack font-medium'>
                        Estimated Shipping
                      </p>
                      <p className='text-sm11 text-primaryGray mt-[1px]'>
                        Your Order will be shipped in 1 package
                      </p>{' '}
                    </div>
                    <p className='text-[15px] leading-[18px]  text-primaryBlack  font-medium'>
                      ${addZeroes(cartData?.estimateShipping)}
                    </p>
                  </div>

                  <div className='h-[1px] my-[8px] w-[100%] bg-[#efefef]'></div>
                  <div className='flex justify-between mb-[14px]'>
                    <div className='leading-[1.2]'>
                      <p className='text-[15px] leading-[18px]  text-primaryBlack font-medium'>
                        Tax
                      </p>
                      <p className='text-sm11 text-primaryGray mt-[1px]'>
                        Lorem Ipsum dolor set
                      </p>{' '}
                    </div>
                    <p className='text-[15px] leading-[18px] text-primaryBlack  font-medium'>
                      ${addZeroes(cartData?.taxAmount)}
                    </p>
                  </div>
                  <div className='h-[1px] w-[100%]  bg-[#8e8e8e]'></div>
                  <div className='flex justify-between pt-[6px] pb-[10px]'>
                    <div>
                      <p className='text-[15px] leading-[18px]  text-primaryBlack font-medium '>
                        Estimated Total
                      </p>
                      <p className='text-sm11 text-primaryGray  mt-[1px]'>
                        includes $45 discount
                      </p>
                    </div>

                    <div className='flex flex-col items-end'>
                      <div className='flex  items-baseline text-pinkColor  font-light  '>
                        <p className='text-[22px] leading-[1]'>$</p>
                        <p className='text-[35px] leading-[1]'>
                          {addZeroes(cartData?.finalAmount)}
                        </p>
                      </div>
                      <p className='text-sm11 font-normal text-orangeColor leading-[8px] mt-[1px] text-right'>
                        Show Price Details
                      </p>
                    </div>
                   
                  </div>
                </div>

                <div className='h-[2px] w-[100%]  bg-[#8e8e8e]'></div>
               
                <div className='pt-[10px]'>
                  <div className=''>
                    <label
                      style={{
                        width: 'fit-content',
                      }}
                      className='containerCheckGray text-sm11 text-primaryGray'
                    >
                      <input
                        type='checkbox'
                        checked={applyPromo}
                        onChange={(e) => setApplyPromo(!applyPromo)}
                      
                      />{' '}
                      Apply Promo codes and Gift Cards
                      <span className='checkmarkGray'></span>
                    </label>
                    <p className='text-sm11 text-primaryGray ml-[21px] relative bottom-[3px]'>
                      (One Promo code per order)
                    </p>
                  </div>
                 

                  {applyPromo ? (
                    <div className='mt-2.5'>
                      <div className='relative mb-[8px]'>
                        <input
                          type='text'
                          className='regInput mt-0 placeholder:text-[13px] placeholder:font-medium placeholder:text-[#bbbbb]'
                          placeholder='Enter Promo or Coupon code'
                          value={promoCode}
                          disabled={
                            giftCode.length > 0 ? true : false
                          }
                          onChange={(e) =>
                            setPromoCode(e.target.value)
                          }
                        />
                        <button
                          onClick={addPromoCode}
                          disabled={
                            giftCode.length > 0 ? true : false
                          }
                          className='text-primaryGray bg-[#EEEEEE] border border-[#E9E9E9] rounded-2xl py-[0.45rem]  px-[10px] text-[10px] leading-[10px] font-medium absolute top-[7px] right-[7px]'
                        >
                          Add
                        </button>
                      </div>
                      <div className='relative '>
                        <input
                          type='text'
                          className='regInput mt-0 placeholder:text-[13px] placeholder:font-medium placeholder:text-[#bbbbb]'
                          placeholder='Enter Gift Card code'
                          value={giftCode}
                          disabled={
                            promoCode.length > 0 ? true : false
                          }
                          onChange={(e) =>
                            setGiftCode(e.target.value)
                          }
                        />
                        <button
                          onClick={addGiftCode}
                          disabled={
                            promoCode.length > 0 ? true : false
                          }
                          className='text-primaryGray bg-[#EEEEEE] border border-[#E9E9E9] rounded-2xl py-[0.45rem]  px-[10px] text-[10px] leading-[10px] font-medium absolute top-[7px] right-[7px]'
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className='mt-2.5 opacity-[40%]'>
                      <div className='relative mb-[8px]'>
                        <input
                          type='text'
                          className='regInput mt-0 placeholder:text-[13px] placeholder:font-medium placeholder:text-[#bbbbb]'
                          placeholder='Enter Promo or Coupon code'
                          disabled
                        />
                        <button
                          disabled
                          className='text-primaryGray bg-[#EEEEEE] border border-[#E9E9E9] rounded-2xl py-[0.45rem]  px-[10px] text-[10px] leading-[10px] font-medium absolute top-[7px] right-[7px]'
                        >
                          Add
                        </button>
                      </div>
                      <div className='relative '>
                        <input
                          type='text'
                          className='regInput mt-0 placeholder:text-[13px] placeholder:font-medium placeholder:text-[#bbbbb] bg-[#fff]'
                          placeholder='Enter Gift Card code'
                          value={giftCode}
                          disabled
                        />
                        <button
                          disabled
                          className='text-primaryGray bg-[#EEEEEE] border border-[#E9E9E9] rounded-2xl py-[0.45rem]  px-[10px] text-[10px] leading-[10px] font-medium absolute top-[7px] right-[7px]'
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <button
                  onClick={() => setFinalCheckout(false)}
                  className='blackBtn mt-[32px] mx-auto mb-[16px] block'
                >
                  Continue to Checkout
                </button>
                <p className='text-sm11 text-primaryGray text-center'>
                  Since our products are printed on demand especially
                  for <br /> you, it is{' '}
                  <span className='font-medium'>
                    {' '}
                    not eligible for cancellations and returns.
                    <br />
                  </span>
                  Read our{' '}
                  <span className='text-orangeColor'>
                    Returns & Exchange policy
                  </span>
                  .
                  <br /> By placing your order, you agree to the{' '}
                  <span className='text-orangeColor'>
                    Delivery Terms
                  </span>
                  .
                </p>

                
              </div>
            </div>
            <p className='text-sm11 text-primaryGray text-center mt-[20px]'>
              Accepted Payment Methods
            </p>
            <div
              style={{
                width: 'fit-content',
              }}
              className='flex gap-[4px] mx-[auto] mt-[3px]'
            >
              <div>
                <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>

              <div>
                <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>

              <div>
                <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>

              <div>
                <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>
              <div>
                <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>
            
            </div>

            <div className='my-[16px] flex justify-center gap-[8px] py-[8px] border-[#efefef] border-t-[2px] border-b-[2px] border-t border-b'>
              <div>
                <img
                  src={Certificate1}
                  alt=''
                  className='w-[68px] h-[68px]'
                />
              
              </div>
              <div>
                <img
                  src={Certificate2}
                  alt=''
                  className='w-[68px] h-[68px]'
                />
           
              </div>
              <div>
                <img
                  src={Certificate3}
                  alt=''
                  className='w-[68px] h-[68px]'
                />
                
              </div>
            </div>
            <p className='text-sm11 text-primaryGray text-center mt-[16px]'>
              Faster Checkout Every Time
            </p>
            <div
              style={{
                width: 'fit-content',
              }}
              className='mt-[4px] mx-[auto] h-[40px] px-[14px] flex items-center rounded-gradient-border-for-button'
            >
              <img src={welcomeOffer} alt='' />
            </div>
            <div className='pl-[17px]'>
              <p className='text-[15px] text-[#333333] leading-[19px] font-medium mt-[14px]'>
                Need Help?
              </p>
              <p className='text-sm11 text-orangeColor ]'>Shipping</p>
              <p className='text-sm11 text-orangeColor ]'>
                Return & Exchanges
              </p>
              <p className='text-sm11 text-orangeColor ]'>
                Contact Us
              </p>
            </div>
          </div>
        </div>
      </div> */

// order numver below

// <div className='max-w-[448px] w-[100%]'>
//           <div className='flex items-center px-[8px] h-[46px] bg-[#f9f9f9] border-[#efefef] border rounded-[12px]'>
//             <img src={lock} className='mr-2' alt='' />
//             <p className='text-sm11 text-primaryGray'>
//               <span className='font-medium'>
//                 Your information is secure.
//               </span>{' '}
//               Your encrypted payment method will be saved for
//               future orders. <br /> Manage your payment methods
//               in your{' '}
//               <span className='text-[#F9944C]'>
//                 Account Settings
//               </span>
//               .
//             </p>
//           </div>

//           <p
//             onClick={() => {
//               navigate('/checkout2');
//             }}
//             className='text-[24px] font-medium text-[#333333] leading-[1.2] mt-[32px] mb-[16px] cursor-pointer'
//           >
//             Order Number: ANS9876547
//           </p>

//           <div className='text-primaryGray text-sm12 font-medium leading-4'>
//             <div className='flex border-t border-t-[2px] border-b gap-[8px] border-[#efefef]'>
//               <p className='w-[61px] font-medium'>Customer:</p>
//               <p className='font-normal'>Khalid Shaikh</p>
//             </div>
//             <div className='flex  border-b-[2px] border-b gap-[8px] border-[#efefef]'>
//               <p className='w-[61px] font-medium'>
//                 Order Date:
//               </p>
//               <p className='font-normal'>18 Jan 2024</p>
//             </div>
//           </div>

//           <div className='flex flex-col'>
//             <p className='text-primaryBlack text-[16px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
//               Payment Method
//             </p>
//             <div>
//               <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
//             </div>
//             <p className='text-sm12 text-primaryGray mt-[4px] flex items-center'>
//               Card Number, ************ 5248
//             </p>
//             <p className='text-sm12 text-primaryGray mt-[4px] flex items-center'>
//               Expiry: 24/02/2025
//             </p>
//           </div>

//           <div className='mt-[20px]'>
//             <p className='text-primaryBlack text-[16px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
//               Mailling Address
//             </p>
//             <p className='text-sm12  text-primaryGray'>
//               {userData?.userFirstName} {userData?.userLastName}
//             </p>
//             <p className='text-sm12  text-primaryGray'>
//               {userData?.residentialAddress?.addressLine1},
//             </p>
//             <p className='text-sm12  text-primaryGray'>
//               {userData?.residentialAddress?.addressLine2},{' '}
//               {userData?.residentialAddress?.cityName}{' '}
//               {userData?.residentialAddress?.zipCode}.
//             </p>
//             <p className='text-sm12  text-primaryGray'>
//               {userData?.residentialAddress?.stateName}
//             </p>
//             <p className='text-sm12  text-primaryGray'>
//               {userData?.residentialAddress?.countryName}
//             </p>
//             <p className='text-sm12  text-primaryGray'>
//               Contact No.:
//               {userData?.residentialAddress?.phoneNo}
//             </p>
//             <p className='text-sm12  text-primaryGray'>
//               Email:{userData?.emailAddress}
//             </p>
//           </div>
//           <div className='mt-[20px]'>
//             <p className='text-primaryBlack text-[16px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
//               Shipping Address
//             </p>
//             <p className='text-sm12  text-primaryGray'>
//               {userData?.userFirstName} {userData?.userLastName}
//             </p>
//             <p className='text-sm12  text-primaryGray'>
//               {userData?.shippingAddress?.addressLine1},
//             </p>
//             <p className='text-sm12  text-primaryGray'>
//               {userData?.shippingAddress?.addressLine2},{' '}
//               {userData?.shippingAddress?.cityName}{' '}
//               {userData?.shippingAddress?.zipCode}.
//             </p>
//             <p className='text-sm12  text-primaryGray'>
//               {userData?.shippingAddress?.stateName}
//             </p>
//             <p className='text-sm12  text-primaryGray'>
//               {userData?.shippingAddress?.countryName}
//             </p>
//             <p className='text-sm12  text-primaryGray'>
//               Contact No.:{userData?.shippingAddress?.phoneNo}
//             </p>
//             <p className='text-sm12  text-primaryGray'>
//               Email:{userData?.emailAddress}
//             </p>
//           </div>
//           <div className='mt-[20px]'>
//             <p className='text-primaryBlack text-[16px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
//               Shipping Method
//             </p>
//             <p className='text-sm12  text-primaryGray'>
//               {select?.shippingMethodName}
//             </p>
//             <p className='text-sm12 text-primaryGray'>
//               Order now to receive in {select?.dayToReceive}{' '}
//               business days
//             </p>
//           </div>

//           <div className='my-[32px]'>
//             <label
//               style={{
//                 width: 'fit-content',
//               }}
//               className='containerCheckGray text-[12px] text-primaryGray '
//             >
//               <input type='checkbox' defaultChecked='true' />{' '}
//               Send me news and exclusive offers from Artnstock
//               <span className='checkmarkGray'></span>
//             </label>
//           </div>

//           <div className=''>
//             <div
//               style={{
//                 width: 'fit-content',
//               }}
//               onClick={() =>
//                 handlePayment(cartData?.finalAmount)
//               }
//               className='px-[20px] h-[40px] flex justify-center items-center relative rounded-[5000px] cursor-pointer '
//             >
//               <div className='w-[100%] h-[100%] gradient-background  rounded-[5000px]  opacity-[80%] absolute'></div>
//               <p className='text-sm14 text-primaryBlack font-medium z-[999]'>
//                 Complete order for $
//                 {addZeroes(cartData?.finalAmount)}
//               </p>
//             </div>
//           </div>

//           <div className='h-[1px] w-[100%] bg-[#efefef] mt-[32px] mb-[32px]'></div>

//           <p className='text-sm11 text-primaryGray mb-[26px]'>
//             All products are manufactured on-demand and ship
//             from the manufacturing facility located nearest to
//             your destination address. Gift cards are delivered
//             instantly via e-mail. International orders may take
//             up to 10 days to arrive due to FedEx/UPS delivery
//             times. <br /> <br /> Please note - your government
//             may charge an import tax on products received from
//             outside your country. The import tax (if applicable)
//             is not included in our shipping charge.
//           </p>
//         </div>
