import React, { useState } from 'react';
import { ReactComponent as HeadProd } from '../Assets/PhotosDetails/Merchandise/IconHeadProd.svg';
import { ReactComponent as HeadRen } from '../Assets/PhotosDetails/Merchandise/IconHeadRen.svg';

import { ReactComponent as HeadWal } from '../Assets/PhotosDetails/Merchandise/IconHeadWal.svg';
import MugColor from '../Assets/PhotosDetails/Merchandise/mugcolor.png';
import ShirtColor from '../Assets/PhotosDetails/Merchandise/shirtcolor.png';
import MouseColor from '../Assets/PhotosDetails/Merchandise/mousecolor.png';
import FlowerColor from '../Assets/PhotosDetails/Merchandise/flowercolor.png';
import NoteColor from '../Assets/PhotosDetails/Merchandise/notepadcolor.png';
import GiftColor from '../Assets/PhotosDetails/Merchandise/giftcolor.png';
import BagColor from '../Assets/PhotosDetails/Merchandise/bagcolor.png';
import CoasterColor from '../Assets/PhotosDetails/Merchandise/coastercolor.png';
import GlassColor from '../Assets/PhotosDetails/Merchandise/glasscolor.png';
import tshirtphoto from '../Assets/PhotosDetails/Merchandise/T-Shirt.png';
import img1 from '../Assets/PhotosDetails/Merchandise/img1.png';
import img2 from '../Assets/PhotosDetails/Merchandise/img2.png';
import threeDImg from '../Assets/PhotosDetails/Merchandise/Icon - 3D View.svg';
import { ReactComponent as Add } from '../Assets/PhotosDetails/Merchandise/addIcon.svg';
import { ReactComponent as Wishlist } from '../Assets/PhotosDetails/Merchandise/wishlistIcon.svg';
import artprint from '../Assets/PhotosDetails/Merchandise/artprint.jpg';
import { ReactComponent as Menshirt } from '../Assets/PhotosDetails/Merchandise/Icon_Men.svg';
import m from '../Assets/PhotosDetails/Merchandise/M.jpg';

import { ReactComponent as Share } from '../Assets/PhotosDetails/Merchandise/shareIcon.svg';
import { ReactComponent as IconTickMark } from '../Assets/PhotosDetails/Merchandise/Icon_TickMark.svg';
import dropdown from '../Assets/PhotosDetails/Merchandise/dropdown.png';
import { ReactComponent as Icon_CareInstuction01 } from '../Assets/PhotosDetails/Merchandise/Icon_CareInstuction01.svg';
import { ReactComponent as Icon_CareInstuction02 } from '../Assets/PhotosDetails/Merchandise/Icon_CareInstuction02.svg';
import { ReactComponent as Icon_CareInstuction03 } from '../Assets/PhotosDetails/Merchandise/Icon_CareInstuction03.svg';
import { ReactComponent as Icon_CareInstuction04 } from '../Assets/PhotosDetails/Merchandise/Icon_CareInstuction04.svg';
import { ReactComponent as Icon_CareInstuction05 } from '../Assets/PhotosDetails/Merchandise/Icon_CareInstuction05.svg';
import { ReactComponent as Icon_CareInstuction06 } from '../Assets/PhotosDetails/Merchandise/Icon_CareInstuction06.svg';
import { ReactComponent as Icon_CareInstuction07 } from '../Assets/PhotosDetails/Merchandise/Icon_CareInstuction07.svg';
import plusIcon from '../Assets/PhotosDetails/Merchandise/plusIcon.svg';
import minusIcon from '../Assets/PhotosDetails/Merchandise/minusIcon.svg';
import { ReactComponent as Festivee } from '../Assets/PhotosDetails/Merchandise/FestiveOffer.svg';
import { ReactComponent as VanIcon } from '../Assets/PhotosDetails/Merchandise/vanIcon.svg';
import packingIcon from '../Assets/PhotosDetails/Merchandise/packingIcon.svg';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import sizePopupImg from '../Assets/PhotosDetails/Popup/sizePopupImg.jpg';
import { useDetectClickOutside } from 'react-detect-click-outside';

const StyledPopupDelivery = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 32px;
    width: 450px;
  }
`;

const StyledPopupShirtSize = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 50px;
    padding-right: 50px;
    width: 550px;
    height: 629px;
  }
`;

const PriceDetailsPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px 28px;
    width: 34.375rem;
    height: 36.25rem;
    overflow: hidden;
    border: none;
  }
`;

const ReturnAndExchangePopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px 28px;
    width: 28.0635rem;
    height: 10.563rem;
    overflow: hidden;
    border: none;
  }
`;

const StyledPopupPackage = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px;
    width: 514px;
  }
`;

const Merchandise = () => {
  const circle = [
    {
      a: '#314f75',
      b: '#314f75',
    },
    { a: '#00FFFF', b: '#00FFFF' },
    { a: '#808000', b: '#808000' },
    { a: '#F67280', b: '#F67280' },
    { a: '#C0C0C0', b: '#C0C0C0' },
    { a: '#8D918D', b: '#8D918D' },
    { a: '#000000', b: '#000000' },
    { a: '#FFFFFF', b: '#000000' },
  ];
  const [selected, setSelected] = useState('LifeStyle');
  const [ProdSizePopup, setProdSizePopup] = useState('');
  const [selectedSize, setSelectedSize] = useState('L');
  const [PopupShirtSize, setPopupShirtSize] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [returnAndExchange, setReturnAndExchange] = useState(false);
  const [isAllStyleOpen, setIsAllStyleOpen] = useState(false);

  const styleDrop = [
    { a: `Men's T-Shirt` },
    { a: `Women's T-Shirt` },
    { a: `Kid's T-Shirt` },
  ];

  const handleSizeClick = (size) => {
    setSelectedSize(size);
  };

  const [productQuantity, setproductQuantity] = useState(1);
  const setproductQuantityFunc = (e) => {
    if (e.target.value >= 0 && e.target.value <= 99) {
      setproductQuantity(e.target.value);
    }
  };

  const [pinCodeIconHover, setpinCodeIconHover] = useState(false);

  const handleClickOutsideCate = () => {
    setIsAllStyleOpen(false);
  };

  const CateClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideCate,
  });
  return (
    <>
      <div className='w-w1168 mx-[auto]'>
        <div className='w-[100%] mb-[15px]'>
          <div
            style={{ width: 'fit-content' }}
            className='mx-[auto] flex flex-col justify-center '
          >
            <div className=''>
              <p className='text-[64px] font-bold text-[#EEEEEE] tracking-tight text-center'>
                Merchandise
              </p>
            </div>
            <div className='flex gap-[16px] justify-center text-center mt-[10px]'>
              <div
                // onClick={() => setartProductTabs('Products')}
                className='flex flex-col cursor-pointer'
              >
                <div>
                  <HeadProd />
                  <p className='text-sm12 leading-[15px] mt-[3px]'>
                    Merchandise
                  </p>
                </div>
              </div>
              <div
                // onClick={() => setartProductTabs('Products')}
                className='flex flex-col cursor-pointer'
              >
                <div>
                  <HeadWal />
                  <p className='text-sm12 leading-[15px] mt-[3px]'>
                    Wallpaper
                  </p>
                </div>
              </div>
              <div
                // onClick={() => setartProductTabs('Products')}
                className='flex flex-col cursor-pointer'
              >
                <div>
                  <HeadRen />

                  <p className='text-sm12 leading-[15px] mt-[3px]'>
                    Branding
                  </p>
                </div>
              </div>
            </div>
            <div className='w-full mt-[16px]'>
              <div className='mx-[auto]'>
                <div className='flex gap-[4px] justify-center h-[28px]'>
                  <button
                    className={`${
                      selected === 'HomeDecor'
                        ? 'border-[#e6e6e6] bg-[#e6e6e6] border rounded-[14px] text-primaryBlack px-[12px] flex items-center text-[12px] font-medium outline-none'
                        : 'border-[#eaeaea] border rounded-[14px] text-primaryGray hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px] font-medium'
                    }`}
                    onClick={() => setSelected('HomeDecor')}
                  >
                    Home Decor
                  </button>
                  <button
                    className={`${
                      selected === 'LifeStyle'
                        ? 'border-[#e6e6e6] bg-[#e6e6e6] border rounded-[14px] text-primaryBlack px-[12px] flex items-center text-[12px] font-medium outline-none'
                        : 'border-[#eaeaea] border rounded-[14px] text-primaryGray hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px] font-medium'
                    }`}
                    onClick={() => setSelected('LifeStyle')}
                  >
                    Life Style
                  </button>
                  <button
                    className={`${
                      selected === 'Tech'
                        ? 'border-[#e6e6e6] bg-[#e6e6e6] border rounded-[14px] text-primaryBlack px-[12px] flex items-center text-[12px] font-medium outline-none'
                        : 'border-[#eaeaea] border rounded-[14px] text-primaryGray hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px] font-medium'
                    }`}
                    onClick={() => setSelected('Tech')}
                  >
                    Tech
                  </button>
                  <button
                    className={`${
                      selected === 'Apperal'
                        ? 'border-[#e6e6e6] bg-[#e6e6e6] border rounded-[14px] text-primaryBlack px-[12px] flex items-center text-[12px] font-medium outline-none'
                        : 'border-[#eaeaea] border rounded-[14px] text-primaryGray hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px] font-medium'
                    }`}
                    onClick={() => setSelected('Apperal')}
                  >
                    Apperal
                  </button>
                  <button
                    className={`${
                      selected === 'Stationary'
                        ? 'border-[#e6e6e6] bg-[#e6e6e6] border rounded-[14px] text-primaryBlack px-[12px] flex items-center text-[12px] font-medium outline-none'
                        : 'border-[#eaeaea] border rounded-[14px] text-primaryGray hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px] font-medium'
                    }`}
                    onClick={() => setSelected('Stationary')}
                  >
                    Stationary
                  </button>
                </div>
              </div>
            </div>
            <div className='pt-[16px]'>
              <div className='w-[100%] flex justify-center'>
                <div className='w-w456 flex gap-[10px] border-t border-b border-[#efefef] pt-[8px] pb-[2px]'>
                  <div className='flex-col w-[40px]'>
                    <img
                      className='mx-auto'
                      src={ShirtColor}
                      alt=''
                    />
                    <p className='text-primaryGray text-[11px] text-center'>
                      T-Shirt
                    </p>
                  </div>
                  <div className='flex-col w-[40px]'>
                    <img className='mx-auto' src={MugColor} alt='' />
                    <p className='text-primaryGray text-[11px] text-center'>
                      Mugs
                    </p>
                  </div>
                  <div className='flex-col w-[40px]'>
                    <img
                      className='mx-auto'
                      src={GlassColor}
                      alt=''
                    />
                    <p className='text-primaryGray text-[11px] text-center'>
                      Glass
                    </p>
                  </div>
                  <div className='flex-col w-[40px]'>
                    <img
                      className='mx-auto'
                      src={MouseColor}
                      alt=''
                    />
                    <p className='text-primaryGray text-[11px] text-center'>
                      Mouse Pad
                    </p>
                  </div>
                  <div className='flex-col w-[40px]'>
                    <img
                      className='mx-auto'
                      src={CoasterColor}
                      alt=''
                    />
                    <p className='text-primaryGray text-[11px] text-center'>
                      Coaster
                    </p>
                  </div>
                  <div className='flex-col w-[40px]'>
                    <img
                      className='mx-auto'
                      src={FlowerColor}
                      alt=''
                    />
                    <p className='text-primaryGray text-[11px] text-center'>
                      Flower Pot
                    </p>
                  </div>
                  <div className='flex-col w-[40px]'>
                    <img className='mx-auto' src={GiftColor} alt='' />
                    <p className='text-primaryGray text-[11px] text-center'>
                      Gift Box
                    </p>
                  </div>
                  <div className='flex-col w-[40px]'>
                    <img className='mx-auto' src={BagColor} alt='' />
                    <p className='text-primaryGray text-[11px] text-center'>
                      Bag
                    </p>
                  </div>
                  <div className='flex-col w-[40px]'>
                    <img className='mx-auto' src={NoteColor} alt='' />
                    <p className='text-primaryGray text-[11px] text-center'>
                      Notepad
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='flex flex-col'>
              <div className='w-full flex justify-center pt-[32px] '>
                <div className='w-w1170 flex p-[32px] rounded-[32px] gap-[30px] shadow-regCardShadow'>
                  <div className='w-[50%] pr-[10px]'>
                    <div className='relative w-[540px] p-[32px] h-[540px] rounded-[16.01px] bg-[#f5f5f7] flex flex-col justify-center  items-center bg-no-repeat item-center'>
                      <img src={tshirtphoto} />
                    </div>
                    <div className='flex justify-between mt-2.5 mb-5'>
                      <div className='flex gap-x-[8px]'>
                        <div>
                          <img
                            src={img1}
                            className='cursor-pointer'
                          />
                        </div>

                        <div>
                          <img
                            src={img2}
                            className='cursor-pointer'
                          />
                        </div>

                        <img src={threeDImg} alt='' />
                      </div>
                      <div className='flex gap-x-2.5 items-center'>
                        {/* <img src={addIcon} alt='' /> */}
                        <Add className='cursor-pointer fill-[#888888] hover:fill-[#333333]' />
                        <div>
                          <Wishlist
                            // id={artDetails.artId}
                            type='small'
                            prodType='art'
                            className='cursor-pointer fill-[#888888] hover:fill-[#ec008c]'
                          />
                        </div>
                        <Share className='cursor-pointer fill-[#888888] hover:fill-[#333333]' />
                      </div>
                    </div>
                    <p className=' text-left text-primaryBlack text-[15px] font-medium leading-5 mb-1'>
                      You have selected
                    </p>
                    <div className='w-[100%] bg-[#ffffff] rounded-gradient-border pt-[12px] pb-[24px] px-[12px] '>
                      <div className='flex flex-col h-[100%] w-[100%] bg-[#ffffff] rounded-2xl text-primaryGray text-[12px]'>
                        <div className='flex text-[11px]'>
                          <div className='w-[48px] mr-[6px]'>
                            <div
                              style={{
                                backgroundImage: `url(${artprint})`,
                              }}
                              className='w-[48px] h-[48px] rounded-[10px] bg-cover bg-center'
                            ></div>
                            <p
                              className={`text-sm11 leading-[12px] text-center mt-[3px] text-primaryGray`}
                            >
                              Digital <br />
                              Art Print
                            </p>
                          </div>
                          <div className='flex-col w-[60px]'>
                            <Menshirt className='mx-[auto]' />

                            <p
                              className={`text-sm11 leading-[12px] text-center mt-[3px] text-primaryGray`}
                            >
                              Mens's <br /> T-Shirt
                            </p>
                          </div>
                          <div className='flex-col w-[60px]'>
                            <div className='w-[48px] h-[48px] rounded-[10px] border border-[#bbbbbb] mx-[auto] flex justify-center items-center'>
                              <div
                                style={{
                                  backgroundColor: '#314f75',
                                }}
                                className='w-[32px] h-[32px] rounded-[5000px]'
                              ></div>
                            </div>
                            <p
                              className={`text-sm11 leading-[12px] text-center mt-[3px] text-primaryGray`}
                            >
                              Dark
                              <br /> Blue
                            </p>
                          </div>

                          <div className='flex-col w-[60px]'>
                            <div className='w-[48px] h-[48px] rounded-[10px] border border-[#bbbbbb] mx-[auto] flex justify-center items-center'>
                              <img src={m} />
                            </div>
                            <p
                              className={`text-sm11 leading-[12px] text-center mt-[3px] text-primaryGray`}
                            >
                              Medium
                              <br /> Size
                            </p>
                          </div>
                        </div>
                        <div className='text-[11px] text-left'>
                          <div className='flex border-b border-t border-[#efefef] mt-[10px]'>
                            <p className='w-[80px] font-medium'>
                              Style:
                            </p>
                            <p>All Styles</p>
                          </div>

                          <div className='flex border-b border-[#efefef]'>
                            <p className='w-[80px] font-medium'>
                              Size:
                            </p>
                            <p>Large</p>
                          </div>
                          <div className='flex border-b border-[#efefef]'>
                            <p className='w-[80px] font-medium'>
                              Quantity:
                            </p>
                            <p>2</p>
                          </div>
                          <div className='flex border-b border-[#efefef]'>
                            <p className='w-[80px] font-medium'>
                              Material:
                            </p>
                            <p>100% Cotton,Bio-Wash and Pre Shrunk</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='w-[50%] text-left'>
                    <div className='w-[380px] '>
                      <p className='text-[24px] font-medium leading-[29px] text-primaryBlack mb-[7px]'>
                        Artnstock Limited Edition Print <br /> Cotton
                        T-Shirt
                      </p>
                    </div>
                    <div className='mt-[10px] mb-8 border-t-2 border-b-2 border-[#EFEFEF]'>
                      <div className='w-[100%]'>
                        <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                          <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                            Product ID:
                          </p>
                          <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                            ANSFF15994544
                          </p>
                        </div>
                        <div className='flex items-center  h-[17px]'>
                          <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                            Availability:
                          </p>
                          <p className='text-primaryGray text-sm12 font-normal  leading-[15px] flex gap-[4px] items-center'>
                            In Stock{' '}
                            <span>
                              {' '}
                              <IconTickMark />
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className='flex flex-col mt-[13px]'>
                      <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[8px]'>
                        Select Style
                      </p>

                      {/* test */}
                      <div className='relative h-[40px]'>
                        <div
                          ref={CateClickOutside}
                          className={`${
                            isAllStyleOpen === true
                              ? 'shadow-dropShadow'
                              : ''
                          } absolute rounded-[20px] w-[228px] z-[99] max-h-[260px]`}
                        >
                          <button
                            onClick={() => {
                              setIsAllStyleOpen(!isAllStyleOpen);
                            }}
                            className={`${
                              isAllStyleOpen === true
                                ? 'rounded-t-[20px] border-b border-[#EFEFEF]'
                                : 'border rounded-[20px] border-[#d6d6d6]'
                            } flex items-center justify-between px-[15px]  text-sm14 font-medium cursor-pointer w-[228px] h-[40px] bg-[#FFFFFF]`}
                          >
                            <span className='text-[#bbbbbb]'>
                              Select Category
                            </span>
                            <img
                              className='inline-block'
                              src={dropdown}
                              alt=''
                            />
                          </button>

                          {isAllStyleOpen && (
                            <ul
                              onClick={handleClickOutsideCate}
                              className='cursor-pointer rounded-b-2xl bg-[#ffffff] overflow w-[228px] text-left text-[13px] text-primaryGray max-h-[220px] overflow-y-auto'
                            >
                              {styleDrop.map((obj) => (
                                <li className='flex h-[40px] items-center px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'>
                                  {obj.a}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className='pt-[17px]'>
                      <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-1'>
                        Select Colours
                      </p>
                      <div className='flex gap-[8px] pt-[3px]'>
                        {circle.map((color, index) => (
                          <div
                            key={index}
                            className={`w-[30px] h-[30px] rounded-[5000px] border flex justify-center items-center cursor-pointer`}
                            style={{
                              backgroundColor: `${color.a}`,
                              borderColor: `${color.b}`,
                            }}
                            // onClick={() => check(item)}
                          ></div>
                        ))}
                      </div>
                    </div>

                    <div className='flex flex-col pt-[17px]'>
                      <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-1'>
                        Select Size
                      </p>
                      <div className='flex gap-[12px] p-[3px] text-[15px] font-medium'>
                        <div
                          onMouseEnter={() => setProdSizePopup('XS')}
                          onMouseLeave={() => setProdSizePopup('')}
                          className={`relative cursor-pointer w-[48px] h-[48px] hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-primaryBlack border border-[#D6D6D6] rounded-[10px] flex justify-center items-center ${
                            selectedSize === 'XS'
                              ? 'bg-[#e6e6e6] border-[#e6e6e6]'
                              : 'border-[#D6D6D6] text-primaryGray'
                          }`}
                          onClick={() => handleSizeClick('XS')}
                        >
                          XS
                          {ProdSizePopup === 'XS' && (
                            <div className='shadow-regCardShadow rounded-[8px] h-[52px] bg-[#ffffff] absolute bottom-[59px] px-[20px] py-[8px] text-center'>
                              <p className='text-[15px] whitespace-nowrap text-primaryBlack font-medium'>
                                Measurement (Extra Small)
                              </p>
                              <p className='text-[11px] whitespace-nowrap text-primaryGray leading-[13px]'>
                                Shoulder: 17”&nbsp;&nbsp;&nbsp;Chest:
                                42”
                              </p>
                              <div className='absolute left-[46%] bottom-[-8px] w-[17px] h-[17px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                            </div>
                          )}
                        </div>
                        <div
                          onMouseEnter={() => setProdSizePopup('S')}
                          onMouseLeave={() => setProdSizePopup('')}
                          className={`relative cursor-pointer w-[48px] h-[48px]  hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-primaryBlack border rounded-[10px] flex justify-center items-center ${
                            selectedSize === 'S'
                              ? 'bg-[#e6e6e6] border-[#e6e6e6]'
                              : 'border-[#D6D6D6] text-primaryGray'
                          }`}
                          onClick={() => handleSizeClick('S')}
                        >
                          S
                          {ProdSizePopup === 'S' && (
                            <div className='shadow-regCardShadow rounded-[8px]  h-[52 px] bg-[#ffffff] absolute bottom-[59px] px-[20px] py-[8px] text-center'>
                              <p className='text-[15px] whitespace-nowrap text-primaryBlack font-medium'>
                                Measurement (Small)
                              </p>
                              <p className='text-[11px] whitespace-nowrap text-primaryGray leading-[13px]'>
                                Shoulder: 17”&nbsp;&nbsp;&nbsp;Chest:
                                42”
                              </p>
                              <div className='absolute left-[46%] bottom-[-8px] w-[17px] h-[17px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                            </div>
                          )}
                        </div>
                        <div
                          onMouseEnter={() => setProdSizePopup('M')}
                          onMouseLeave={() => setProdSizePopup('')}
                          className={`relative cursor-pointer w-[48px] h-[48px]  hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-primaryBlack border rounded-[10px] flex justify-center items-center ${
                            selectedSize === 'M'
                              ? 'bg-[#e6e6e6] border-[#e6e6e6]'
                              : 'border-[#D6D6D6] text-primaryGray'
                          }`}
                          onClick={() => handleSizeClick('M')}
                        >
                          M
                          {ProdSizePopup === 'M' && (
                            <div className='shadow-regCardShadow rounded-[8px]  h-[52px] bg-[#ffffff] absolute bottom-[59px] px-[20px] py-[8px] text-center'>
                              <p className='text-[15px] whitespace-nowrap  text-primaryBlack font-medium'>
                                Measurement (Medium)
                              </p>
                              <p className='text-[11px] whitespace-nowrap text-primaryGray leading-[13px]'>
                                Shoulder: 17”&nbsp;&nbsp;&nbsp;Chest:
                                42”
                              </p>
                              <div className='absolute left-[46%] bottom-[-8px] w-[17px] h-[17px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                            </div>
                          )}
                        </div>
                        <div
                          onMouseEnter={() => setProdSizePopup('L')}
                          onMouseLeave={() => setProdSizePopup('')}
                          className={`relative cursor-pointer w-[48px] h-[48px]  hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-primaryBlack border rounded-[10px] flex justify-center items-center ${
                            selectedSize === 'L'
                              ? 'bg-[#e6e6e6] border-[#e6e6e6]'
                              : 'border-[#D6D6D6] text-primaryGray'
                          }`}
                          onClick={() => handleSizeClick('L')}
                        >
                          L
                          {ProdSizePopup === 'L' && (
                            <div className='shadow-regCardShadow rounded-[8px]  h-[52px] bg-[#ffffff] absolute bottom-[59px] px-[20px] py-[8px] text-center'>
                              <p className='text-[15px] whitespace-nowrap  text-primaryBlack font-medium'>
                                Measurement (Large)
                              </p>
                              <p className='text-[11px] whitespace-nowrap  text-primaryGray leading-[13px]'>
                                Shoulder: 17”&nbsp;&nbsp;&nbsp;Chest:
                                42”
                              </p>
                              <div className='absolute left-[46%] bottom-[-8px] w-[17px] h-[17px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                            </div>
                          )}
                        </div>
                        <div
                          onMouseEnter={() => setProdSizePopup('XL')}
                          onMouseLeave={() => setProdSizePopup('')}
                          className={`relative cursor-pointer w-[48px] h-[48px]  hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-primaryBlack border rounded-[10px] flex justify-center items-center ${
                            selectedSize === 'XL'
                              ? 'bg-[#e6e6e6] border-[#e6e6e6]'
                              : 'border-[#D6D6D6] text-primaryGray'
                          }`}
                          onClick={() => handleSizeClick('XL')}
                        >
                          XL
                          {ProdSizePopup === 'XL' && (
                            <div className='shadow-regCardShadow rounded-[8px]  h-[52px] bg-[#ffffff] absolute bottom-[59px] px-[20px] py-[8px] text-center'>
                              <p className='text-[15px] whitespace-nowrap  text-primaryBlack font-medium'>
                                Measurement (Extra Large)
                              </p>
                              <p className='text-[11px] whitespace-nowrap  text-primaryGray leading-[13px]'>
                                Shoulder: 17”&nbsp;&nbsp;&nbsp;Chest:
                                42”
                              </p>
                              <div className='absolute left-[46%] bottom-[-8px] w-[17px] h-[17px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                            </div>
                          )}
                        </div>
                        <div
                          onMouseEnter={() => setProdSizePopup('2XL')}
                          onMouseLeave={() => setProdSizePopup('')}
                          className={`relative cursor-pointer w-[48px] h-[48px]  hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-primaryBlack border rounded-[10px] flex justify-center items-center ${
                            selectedSize === '2XL'
                              ? 'bg-[#e6e6e6] border-[#e6e6e6]'
                              : 'border-[#D6D6D6] text-primaryGray'
                          }`}
                          onClick={() => handleSizeClick('2XL')}
                        >
                          2XL
                          {ProdSizePopup === '2XL' && (
                            <div className='shadow-regCardShadow rounded-[8px]  h-[52px] bg-[#ffffff] absolute bottom-[59px] px-[20px] py-[8px] text-center'>
                              <p className='text-[15px] whitespace-nowrap  text-primaryBlack font-medium'>
                                Measurement (Double Extra Large)
                              </p>
                              <p className='text-[11px] whitespace-nowrap  text-primaryGray leading-[13px]'>
                                Shoulder: 17”&nbsp;&nbsp;&nbsp;Chest:
                                42”
                              </p>
                              <div className='absolute left-[46%] bottom-[-8px] w-[17px] h-[17px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                            </div>
                          )}
                        </div>
                      </div>
                      <p className='text-[11px] text-orangeColor'>
                        <span
                          onClick={() => {
                            setPopupShirtSize(true);
                          }}
                          className='cursor-pointer'
                        >
                          Size Guide
                        </span>
                      </p>
                      <StyledPopupShirtSize
                        open={PopupShirtSize}
                        closeOnDocumentClick={true}
                        position={'top top'}
                        onClose={() => setPopupShirtSize(false)}
                      >
                        <div>
                          <p className='text-[38px] text-primaryBlack font-medium leading-[41px] mb-[15px] text-center'>
                            Size Guide
                          </p>
                          <table className='table border-separate border-spacing-0 mx-[auto]'>
                            <tbody>
                              <tr className='text-center'>
                                <td
                                  rowspan='2'
                                  className='border border-[#dddddd] rounded-tl-[10px] bg-[#ececec] text-[12px] font-medium text-primaryBlack leading-[13px] h-[25px] text-center w-[50px]'
                                >
                                  Size
                                </td>
                                <td
                                  colspan='2'
                                  className='border border-[#dddddd] bg-[#ececec] text-[12px] font-medium text-primaryBlack leading-[13px] h-[25px]'
                                >
                                  Centimeter
                                </td>
                                <td
                                  colspan='2'
                                  className=' border border-[#dddddd] rounded-tr-[10px] bg-[#ececec] text-[12px] font-medium text-primaryBlack leading-[13px] h-[25px]'
                                >
                                  Inch{' '}
                                </td>
                              </tr>

                              <tr className='text-center'>
                                <td className='border-r border-b border-[#dddddd] bg-[#ececec] text-[12px] font-medium text-primaryBlack leading-[13px] w-[98px] h-[25px]'>
                                  Shoulder
                                </td>
                                <td className='border-r border-b border-[#dddddd] bg-[#ececec] text-[12px] font-medium text-primaryBlack leading-[13px]  w-[98px] h-[25px]'>
                                  Chest
                                </td>
                                <td className='border-r border-b border-l border-[#dddddd] bg-[#ececec] text-[12px] font-medium text-primaryBlack leading-[13px]  w-[98px] h-[25px]'>
                                  Shoulder
                                </td>
                                <td className='border-r border-b border-[#dddddd] bg-[#ececec] text-[12px] font-medium text-primaryBlack leading-[13px]  w-[98px] h-[25px]'>
                                  Chest
                                </td>
                              </tr>

                              <tr className='text-center'>
                                <td className='border-r border-b border-l border-[#dddddd] font-medium text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  S
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  38.0
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  16.5
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  16.5
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  16.5
                                </td>
                              </tr>
                              <tr className='text-center'>
                                <td className='border-r border-b border-l border-[#dddddd] font-medium text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  M
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  38.0
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  16.5
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  16.5
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  16.5
                                </td>
                              </tr>
                              <tr className='text-center'>
                                <td className='border-r border-b border-l border-[#dddddd] font-medium text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  L
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  38.0
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  16.5
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  16.5
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  16.5
                                </td>
                              </tr>
                              <tr className='text-center'>
                                <td className='border-r border-b border-l border-[#dddddd] font-medium text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  XL
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  38.0
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  16.5
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  16.5
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  16.5
                                </td>
                              </tr>
                              <tr className='text-center'>
                                <td className='border-r border-b border-l border-[#dddddd]  font-medium text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  XXL
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  38.0
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  16.5
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  16.5
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  16.5
                                </td>
                              </tr>
                              <tr className='text-center'>
                                <td className='border-r rounded-bl-[10px] border-b border-l font-medium border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  3XL
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  38.0
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  16.5
                                </td>
                                <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  16.5
                                </td>
                                <td className='border-r rounded-br-[10px] border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                  16.5
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <p className='text-[11px] text-primaryGray mt-[5px] leading-[13px] text-center'>
                            Tip: If you dont find an exact match, go
                            for the next size.
                          </p>

                          <p className='text-[16px] text-primaryBlack font-medium mt-[28px] text-center'>
                            Find the right size for you
                          </p>
                          <img
                            className='mx-[auto] mt-[11px]'
                            src={sizePopupImg}
                            alt=''
                          />
                          <p className='text-primaryGray text-[11px] text-center mt-[15px]'>
                            *Measure suggestions are based on body
                            measurements
                          </p>
                        </div>
                      </StyledPopupShirtSize>
                    </div>
                    <div className='flex flex-col pt-[20px]'>
                      <div className='flex gap-[5px]'>
                        <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[6px]'>
                          Description
                        </p>
                      </div>
                      <p className='text-[13px] text-primaryGray leading-[1.3]'>
                        180 GSM, 100% Pre-Combed cotton (Bio-Washed
                        and Pre-Shrunk) <br /> Round Neck, Half
                        Sleeved <br /> Machine wash, Wash in cold
                        water
                      </p>
                    </div>
                    <div className='mt-[15px] mb-[32px] flex items-center gap-[12px]'>
                      <div>
                        <Icon_CareInstuction01 />
                      </div>
                      <div>
                        <Icon_CareInstuction02 />
                      </div>
                      <div>
                        <Icon_CareInstuction03 />
                      </div>
                      <div>
                        <Icon_CareInstuction04 />
                      </div>
                      <div>
                        <Icon_CareInstuction05 />
                      </div>
                      <div>
                        <Icon_CareInstuction06 />
                      </div>
                      <div>
                        <Icon_CareInstuction07 />
                      </div>
                    </div>

                    <div className='wrapper w-[520px]'>
                      {/* test */}
                      <div
                        className='flex mb-[8px]'
                        style={{ width: 'fit-content' }}
                      >
                        <div
                          onClick={() => {
                            setproductQuantity(productQuantity - 1);
                          }}
                          className='bg-[#EEEEEE] h-[40px] w-[38px] flex items-center justify-center rounded-l-3xl cursor-pointer'
                          // onClick={() => {
                          //   quantity > 1 && setQuantity(quantity - 1);
                          //   quantityRef.current = quantityRef.current - 1;
                          // }}
                        >
                          <img src={minusIcon} alt='' />
                        </div>
                        <input
                          className='w-[36px] text-[13px] leading-[15px] font-medium text-primaryGray text-center border-y-[2px] border-y-[#EEEEEE]  outline-none'
                          type='text'
                          value={productQuantity}
                          onChange={setproductQuantityFunc}
                        />
                        <div
                          onClick={() => {
                            setproductQuantity(productQuantity + 1);
                          }}
                          className='bg-[#EEEEEE]  h-[40px] w-[38px] flex items-center justify-center rounded-r-3xl  cursor-pointer'
                          // onClick={() => {
                          //   setQuantity(quantity + 1);
                          //   quantityRef.current = quantityRef.current + 1;
                          // }}
                        >
                          <img
                            className='w-[11px] h-[11px]'
                            src={plusIcon}
                            alt=''
                          />
                        </div>
                      </div>
                    </div>
                    <div className='flex  items-baseline text-pinkColor'>
                      <p className=' text-[22px] font-normal leading-[1]'>
                        $
                      </p>{' '}
                      <p className=' text-[38px] font-normal leading-[1]'>
                        189.00
                      </p>
                    </div>

                    <p className='text-sm12 font-normal text-orangeColor leading-[8px]'>
                      <span
                        className='cursor-pointer'
                        onClick={() => setShowPrice(true)}
                      >
                        Show Price Details
                      </span>
                    </p>

                    <PriceDetailsPopup
                      open={showPrice}
                      closeOnDocumentClick={true}
                      position={'top center'}
                      onClose={() => setShowPrice(false)}
                    >
                      <p className='text-heading mb-5 text-center font-medium'>
                        Price Details
                      </p>
                      <div className='mt-5 mb-8 border-t-2 border-b-2 border-[#EFEFEF]'>
                        <table className='w-[100%]'>
                          <tr className='border-b border-[#EFEFEF] '>
                            <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                              Digital Art Print
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-4'>
                              2 Quantity, Print Size: 20cm x 20cm,
                              Colour
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-4 '>
                              $150.00
                            </td>
                          </tr>
                          <tr className='border-b border-[#EFEFEF] '>
                            <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                              Digital Art Print Material
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-4'>
                              Glossy Photo Paper
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                          </tr>
                          <tr className='border-b border-[#EFEFEF] '>
                            <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                              Mat
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-4'>
                              Double Mat
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                          </tr>
                          <tr className='border-b border-[#EFEFEF] '>
                            <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                              Frame
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-4'>
                              <tr>
                                <td className='w-[80px]'>
                                  Frame Type:
                                </td>
                                <td>Chelsea Black</td>
                              </tr>
                              <tr>
                                <td className='w-[80px]'>
                                  Frame ID:
                                </td>
                                <td>ANSFR4567</td>
                              </tr>
                              <tr>
                                <td className='w-[80px]'>Colour:</td>
                                <td>Black</td>
                              </tr>
                              <tr>
                                <td className='w-[80px]'>Width:</td>
                                <td>0.75”</td>
                              </tr>
                              <tr>
                                <td className='w-[80px]'>Depth:</td>
                                <td>1.13”</td>
                              </tr>
                              <tr>
                                <td className='w-[80px]'>
                                  Material:
                                </td>
                                <td>Wood</td>
                              </tr>
                              <tr>
                                <td className='w-[80px]'>Finish:</td>
                                <td>Gesso</td>
                              </tr>
                              <tr>
                                <td className='w-[80px]'>Style:</td>
                                <td>Contemporary</td>
                              </tr>
                              <p className='w-[100%] mt-5'>
                                Slim and tall, this contemporary
                                Chelsea <br />
                                wood frame has a matte black finish
                                and is well <br />
                                suited for all art types.
                              </p>
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                          </tr>
                          <tr className='border-b border-[#EFEFEF] '>
                            <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                              Glass
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-4'>
                              Plain Clear Glass
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                          </tr>
                          <tr className='border-b border-[#EFEFEF] '>
                            <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                              Assembly
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-4'>
                              Assembly Charge
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                          </tr>
                          <tr className='border-b border-[#EFEFEF] '>
                            <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                              Packaging
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-4'>
                              Hard Cardboard
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                          </tr>
                          <tr className='border-b border-[#EFEFEF] '>
                            <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                              Courier
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-4'>
                              Courier charges
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                          </tr>
                          <tr className='border-b border-[#EFEFEF] '>
                            <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                              Total
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-4'></td>
                            <td className='text-primaryGray text-[24px] font-normal '>
                              $339.00
                            </td>
                          </tr>
                        </table>
                      </div>
                    </PriceDetailsPopup>

                    <div className='mt-[24px] flex gap-[8px] mb-[9px]'>
                      <button
                        // onClick={() => {
                        //   storeTotalPrice();
                        // }}
                        className='px-[20px] h-[40px] border-[2px] border-[#333333] hover:border-[black] flex items-center rounded-3xl text-sm14 text-[#333333] hover:text-[black] font-medium'
                      >
                        Add to Cart
                      </button>

                      {/* <button onClick={checkoutPage} className='blackBtn'> */}

                      <button
                        // onClick={checkoutPage}
                        className='px-[20px] h-[40px] bg-primaryBlack flex items-center text-[#fff] rounded-3xl text-sm14 font-medium hover:bg-[#000]'
                      >
                        Shop Now
                      </button>
                    </div>

                    <p className='text-primaryGray text-sm11 font-normal'>
                      * GST, Branding, Logistics and Customized
                      Packaging charges additional as applicable.{' '}
                      <br />
                      Since this product is printed on demand
                      especially for you, it is{' '}
                      <span className='font-semibold'>
                        not eligible for cancellations and returns.
                      </span>{' '}
                      <br />
                      Read our{' '}
                      <span
                        className='text-orangeColor cursor-pointer'
                        onClick={() => setReturnAndExchange(true)}
                      >
                        Returns & Exchange
                      </span>{' '}
                      policy.
                    </p>

                    <ReturnAndExchangePopup
                      open={returnAndExchange}
                      closeOnDocumentClick={true}
                      position={'top top'}
                      onClose={() => setReturnAndExchange(false)}
                    >
                      <p className='text-heading mb-2.5 font-medium text-center'>
                        Returns & Exchanges
                      </p>
                      <p className='text-primaryGray text-sm11 font-normal mb-3.5 text-center'>
                        Shipping dispatched in 1-2 working days.
                      </p>
                      <p className='text-primaryGray text-sm11 font-normal text-center'>
                        Returnable within 7 days of delivery. <br />
                        For details, please refer our{' '}
                        <span className='text-orangeColor cursor-pointer'>
                          Return Policy
                        </span>
                        .
                      </p>
                    </ReturnAndExchangePopup>

                    <div className=' my-[30px]'>
                      <Festivee />
                    </div>

                    <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[8px]'>
                      Delivery Options
                    </p>
                    <div
                      className='relative mb-[9px]'
                      style={{ width: 'fit-content' }}
                    >
                      <input
                        className='text-[#BBBBBB] border border-[#BBBBBB] w-[228px] rounded-3xl font-medium text-sm14 px-4 h-[40px] flex items-center outline-none'
                        type='text'
                        placeholder='Enter Pincode'
                      />

                      {/* <img src={vanIcon} alt='' className='inline mr-1' /> */}
                      <StyledPopupDelivery
                        // open={openBig}
                        // closeOnDocumentClick={true}
                        // position={'top center'}
                        // onClose={() => setOpenBig(false)}
                        trigger={
                          <div
                            onMouseEnter={() =>
                              setpinCodeIconHover(true)
                            }
                            onMouseLeave={() =>
                              setpinCodeIconHover(false)
                            }
                            className='absolute right-[7px] bg-[#EEEEEE] cursor-pointer border border-[#E9E9E9] flex items-center justify-center gap-[4px] rounded-2xl top-[7px] h-[26px] w-[66px] text-[10px] text-primaryBlack font-medium'
                          >
                            {/* <img src={vanIcon} alt='' className='inline mr-1' /> */}
                            <div>
                              <VanIcon
                                className={`${
                                  pinCodeIconHover
                                    ? 'fill-[#333333]'
                                    : 'fill-[#888888]'
                                }`}
                              />
                            </div>
                            <span className='leading-[1] text-primaryGray'>
                              Check
                            </span>
                          </div>
                        }
                        modal
                      >
                        {(close) => (
                          <div>
                            <p className='font-medium text-[38px] text-center text-primaryBlack leading-[41px]'>
                              Check Service <br /> Availability
                            </p>

                            <div
                              className='relative mb-[9px] mx-[auto] mt-[18px]'
                              style={{ width: '272px' }}
                            >
                              <input
                                className='text-[#BBBBBB] w-[272px] border border-[#BBBBBB] rounded-3xl font-medium text-sm14 px-4 py-2.5 outline-none'
                                type='text'
                                placeholder='Enter Pincode'
                              />
                              <div
                                onMouseEnter={() =>
                                  setpinCodeIconHover(true)
                                }
                                onMouseLeave={() =>
                                  setpinCodeIconHover(false)
                                }
                                className='absolute right-2 bg-[#EEEEEE] cursor-pointer border border-[#E9E9E9] flex items-center justify-center gap-[4px] rounded-2xl top-2 h-[26px] w-[66px] text-[10px] text-primaryBlack font-medium'
                              >
                                {/* <img src={vanIcon} alt='' className='inline mr-1' /> */}
                                <div>
                                  <VanIcon
                                    className={`${
                                      pinCodeIconHover
                                        ? 'fill-[#333333]'
                                        : 'fill-[#888888]'
                                    }`}
                                  />
                                </div>
                                <span className='leading-[1]'>
                                  Check
                                </span>
                              </div>
                            </div>

                            <p className='text-[11px] text-primaryGray text-center'>
                              Please enter PIN code to check delivery
                              time and availability.
                            </p>
                            <p className='text-[12px] text-[#00a656] text-center'>
                              Expected Delivery Date: 13 Mar 2021
                            </p>
                          </div>
                        )}
                      </StyledPopupDelivery>
                    </div>
                    <p className='text-sm11 font-normal text-primaryGray mb-[31px]'>
                      Please enter PIN code to check delivery time and
                      Pay on Delivery Availability.
                      <br />
                      <StyledPopupPackage
                        trigger={
                          <img
                            className='cursor-pointer mt-[5px]'
                            src={packingIcon}
                            alt=''
                          />
                        }
                        modal
                      >
                        {(close) => <div></div>}
                      </StyledPopupPackage>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Merchandise;
