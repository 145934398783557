import React from 'react';
import { ReactComponent as TechnicalSpecIcon } from '../../../assets/images/contributor/svg/TechnicalSpec.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setpath2 } from '../../../store/contriPathSlice';

const TechinicalSpec = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  return (
    <div
      onClick={() => dispatch(setpath2('TechSpec'))}
      className='cursor-pointer mt-[1px]'
    >
      <TechnicalSpecIcon />
    </div>
  );
};

export default TechinicalSpec;
