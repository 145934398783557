// src/utils/httpClient.js

import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { loaderSliceAction } from './store/loaderSlice';

// export const baseURL = 'http://192.168.0.106:5000';

// export const baseURL = 'http://97.74.89.72:5000';
export const baseURL = 'http://148.72.246.9:5000';
// export const baseURL = 'http://97.74.80.230:5000';

// Create an Axios instance
export const httpClient = axios.create({
  baseURL,
});

export const useHttpClient = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const makeLoaderVisible = () => {
      dispatch(loaderSliceAction.setLoaderTrue());
    };

    const makeLoaderInvisible = () => {
      dispatch(loaderSliceAction.setLoaderFalse());
    };

    // Add request interceptor
    const requestInterceptor = httpClient.interceptors.request.use(
      (config) => {
        makeLoaderVisible();
        console.log('Request Interceptor:', config);
        return config;
      },
      (error) => {
        makeLoaderVisible();
        return Promise.reject(error);
      }
    );

    // Add response interceptor
    const responseInterceptor = httpClient.interceptors.response.use(
      (response) => {
        makeLoaderInvisible();
        console.log('Response Interceptor:', response);
        return response;
      },
      (error) => {
        makeLoaderInvisible();
        return Promise.reject(error);
      }
    );

    // Cleanup interceptors on unmount
    return () => {
      httpClient.interceptors.request.eject(requestInterceptor);
      httpClient.interceptors.response.eject(responseInterceptor);
    };
  }, [dispatch]);

  return httpClient;
};
