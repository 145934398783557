import { useState, useRef, useEffect } from 'react';

const Canvass = ({
  photo,
  sizeRangeValue,
  vertcalAlign,
  horzontalAlign,
  sendDataToParent,
  sendDataToParent2,
  tempXYOnMount, // x and y to initialize imagePosition
  tempSizeRangeValue,
}) => {
  const [sizeRangeWidth, setSizeRangeWidth] = useState(114); // Default width set to 114 pixels
  const [sizeRangeHeight, setSizeRangeHeight] = useState(256); // Will be recalculated based on aspect ratio
  const [aspectRatio, setAspectRatio] = useState(1.0); // Aspect ratio of the image

  const [imagePosition, setImagePosition] = useState({
    x: tempXYOnMount?.x || 0,
    y: tempXYOnMount?.y || 0,
  });

  const canvasRef = useRef(null);
  const imageRef = useRef(null);

  const canvasWidth = 154; // Fixed canvas width
  const canvasHeight = 256; // Fixed canvas height

  useEffect(() => {
    setImagePosition({
      x: tempXYOnMount?.x || 0,
      y: tempXYOnMount?.y || 0,
    });
  }, [tempXYOnMount]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const image = imageRef.current;

    image.onload = () => {
      // Calculate natural dimensions of the image
      const naturalWidth = image.naturalWidth;
      const naturalHeight = image.naturalHeight;

      const naturalAspectRatio = naturalWidth / naturalHeight;
      setAspectRatio(naturalAspectRatio);

      // Calculate scaled dimensions based on current sizeRangeWidth
      const newHeight = sizeRangeWidth / naturalAspectRatio;
      setSizeRangeHeight(newHeight);

      // Log scaled dimensions
      console.log(
        `Scaled Width: ${sizeRangeWidth}, Scaled Height: ${newHeight}`
      );

      // Draw the scaled image onto the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(
        image,
        imagePosition.x,
        imagePosition.y,
        sizeRangeWidth,
        newHeight
      );

      // Send dimensions to parent
      sendDataToParent({
        x: imagePosition.x,
        y: imagePosition.y,
        scaledWidth: sizeRangeWidth,
        scaledHeight: newHeight,
      });
    };

    image.src = photo; // Trigger image load
  }, [photo, sizeRangeWidth, imagePosition]);

  useEffect(() => {
    // Adjust size based on sizeRangeValue
    const scaleFactor = sizeRangeValue / 50;
    const newWidth = 154 * scaleFactor; // Width proportional to sizeRangeValue
    const newHeight = newWidth / aspectRatio;

    const widthDiff = newWidth - sizeRangeWidth;
    const heightDiff = newHeight - sizeRangeHeight;

    setSizeRangeWidth(newWidth);
    setSizeRangeHeight(newHeight);

    // Log dynamically updated dimensions

    sendDataToParent2({
      // x: imagePosition.x,
      // y: imagePosition.y,
      scaledWidth: newWidth,
      scaledHeight: newHeight,
    });

    setImagePosition((prev) => ({
      x: prev.x - widthDiff / 2, // Adjust horizontally to keep the image centered
      y: prev.y - heightDiff / 2, // Adjust vertically without re-centering
    }));
  }, [sizeRangeValue]);

  const handleMouseDown = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    const startX = offsetX - imagePosition.x;
    const startY = offsetY - imagePosition.y;

    const handleMouseMove = (e) => {
      const rect = canvas.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;

      setImagePosition({
        x: offsetX - startX,
        y: offsetY - startY,
      });
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    if (vertcalAlign) {
      setImagePosition((prevImagePosition) => ({
        ...prevImagePosition,
        y: (canvasHeight - sizeRangeHeight) / 2, // Center vertically
      }));
    }
  }, [vertcalAlign]);

  useEffect(() => {
    if (horzontalAlign) {
      setImagePosition((prevImagePosition) => ({
        ...prevImagePosition,
        x: (canvasWidth - sizeRangeWidth) / 2, // Center horizontally
      }));
    }
  }, [horzontalAlign]);

  return (
    <>
      <canvas
        ref={canvasRef}
        width={canvasWidth}
        height={canvasHeight}
        className='cursor-move'
        onMouseDown={handleMouseDown}
      />
      <img
        ref={imageRef}
        crossorigin='anonymous'
        alt='shirt'
        className='hidden'
      />
    </>
  );
};

export default Canvass;
