import { useState, useEffect } from 'react';
import { ReactComponent as InfoSvg } from '../../../assets/images/contributor/fileManager/iIconSmall.svg';
// import infofile from '../../../assets/images/contributor/infofile.png';
import { ReactComponent as MoveToSvg } from '../../../assets/images/contributor/fileManager/moveToIconSmall.svg';
import { ReactComponent as EditContent } from '../../../assets/images/contributor/fileManager/editIconSmall.svg';
import { ReactComponent as ProductsSvg } from '../../../assets/images/contributor/fileManager/Icon_Products.svg';
import mainLogo from '../../../assets/images/header/mainLogo.svg';
import { ReactComponent as ShareSvg } from '../../../assets/images/contributor/fileManager/shareIconSmall.svg';
import { ReactComponent as Level3 } from '../../../assets/images/contributor/activateProd/Level3.svg';

// import { ReactComponent as DeleteSvg } from '../../../assets/images/contributor/fileManager/deleteIconSmall.svg';

import { ReactComponent as Exclusive_Icon16 } from '../../../assets/images/contributor/add_details/Icon_Exclusive_16x16.svg';
import { ReactComponent as Featured_Icon16 } from '../../../assets/images/contributor/add_details/Icon_Featured_16x16.svg';
// import sharefile from '../../../assets/images/contributor/sharefile.png';
// import deletefile from '../../../assets/images/contributor/deletefile.png';
import { ReactComponent as CartIcon } from '../../../assets/images/artList/CartIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAllFilesImagesCustomer } from '../../../store/fileImageSlice';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import { setMovePopup } from '../../../store/fileImageSlice';
import { setDelelePopup } from '../../../store/fileImageSlice';

import { ReactComponent as DeleteSvg } from '../../../assets/images/contributor/svg/delete.svg';

// import { ReactComponent as CartIcon } from '../../../assets/images/artList/CartIcon.svg';

import { ReactComponent as Festivee } from '../../../assets/images/art-details/FestiveOffer.svg';

import { ReactComponent as CertificateAuth } from '../../../assets/images/art-details/icons/CertificateAuth.svg';

import { ReactComponent as Icon_Backpacks } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Backpacks.svg';
import { ReactComponent as Icon_Bandanas } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Bandanas.svg';
import { ReactComponent as Icon_BaseballCaps } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_BaseballCaps.svg';
import { ReactComponent as Icon_BucketHats } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_BucketHats.svg';
import { ReactComponent as Icon_ButtonBadges } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_ButtonBadges.svg';
import { ReactComponent as Icon_CarryAllPouches } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_CarryAllPouches.svg';
import { ReactComponent as Icon_DrawstringBags } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_DrawstringBags.svg';
import { ReactComponent as Icon_DuffleBags } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_DuffleBags.svg';
import { ReactComponent as Icon_FannyPacks } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_FannyPacks.svg';
import { ReactComponent as Icon_Footwear } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Footwear.svg';
import { ReactComponent as Icon_GiftPouches } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_GiftPouches.svg';
import { ReactComponent as Icon_Hoodies } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Hoodies.svg';
import { ReactComponent as Icon_Scarfs } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Scarfs.svg';
import { ReactComponent as Icon_Shorts } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Shorts.svg';
import { ReactComponent as Icon_SunglassPouches } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_SunglassPouches.svg';
import { ReactComponent as Icon_ToteBags } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_ToteBags.svg';
import { ReactComponent as Icon_TShirts } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_TShirts.svg';
import { ReactComponent as Icon_Wristbands } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_WristBands.svg';

const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px;
    width: 1062px;
    // height: 299px;
  }
`;

const AllCustFiles = ({ image }) => {
  // hover
  // hover
  const [hovered, setHovered] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const [popupLogin, setpopupLogin] = useState(false);

  const dispatch = useDispatch();

  const selectedAllFilesImages = useSelector(
    (state) => state.fileimages.selectedAllFilesImagesCustomer
  );

  useEffect(() => {
    console.log('Contri ', selectedAllFilesImages);
  }, [selectedAllFilesImages]);

  const handleCheckboxChange = () => {
    // setIsChecked(!isChecked);

    // if ('adminArtProductId' in image) {
    //   if (FindAdminArtProductId !== undefined) {
    //     dispatch(setSelectedAllFilesImagesCustomer([image]));
    //   } else {
    //     dispatch(
    //       setSelectedAllFilesImagesCustomer(
    //         selectedAllFilesImages.filter(
    //           (img) =>
    //             img.adminArtProductId !== image.adminArtProductId
    //         )
    //       )
    //     );
    //   }
    // } else {

    console.log('FindArtId()', FindArtId());
    if (FindArtId()) {
      dispatch(
        setSelectedAllFilesImagesCustomer(
          selectedAllFilesImages?.filter(
            (img) => img.artId !== image.artId
          )
        )
      );
    } else {
      dispatch(
        setSelectedAllFilesImagesCustomer([
          ...selectedAllFilesImages,
          image,
        ])
      );
    }
    // }

    // if (!isChecked) {
    //   dispatch(setSelectedAllFilesImagesCustomer([image]));
    // } else {
    //   dispatch(
    //     setSelectedAllFilesImagesCustomer(
    //       selectedAllFilesImages.filter(
    //         (img) => img.artId !== image.artId
    //       )
    //     )
    //   );
    // }
  };

  const FindArtId = () => {
    // Ensure it's an array before using find()
    if (!Array.isArray(selectedAllFilesImages)) return undefined;

    const find = selectedAllFilesImages?.find(
      (obj) => obj?.artId === image?.artId
    );
    if (find === undefined) {
      return false;
    } else {
      return true;
    }
  };

  const FindAdminArtProductId = () => {
    // Ensure it's an array before using find()
    if (!Array.isArray(selectedAllFilesImages)) return undefined;

    const find = selectedAllFilesImages?.find(
      (obj) => obj?.adminArtProductId === image?.adminArtProductId
    );
    if (find === undefined) {
      return false;
    } else {
      return true;
    }
  };

  const handleCheckboxChangeArtProduct = () => {
    if (FindAdminArtProductId()) {
      dispatch(
        setSelectedAllFilesImagesCustomer(
          selectedAllFilesImages.filter(
            (img) => img.adminArtProductId !== image.adminArtProductId
          )
        )
      );
    } else {
      dispatch(
        setSelectedAllFilesImagesCustomer([
          ...selectedAllFilesImages,
          image,
        ])
      );
    }
  };

  const [checked, setchecked] = useState(false);

  useEffect(() => {
    const abc = checkCheckboxTrueOrNot();
    setchecked(abc);
  }, [selectedAllFilesImages]);

  const checkCheckboxTrueOrNot = () => {
    if ('adminArtProductId' in image) {
      const bool = selectedAllFilesImages.find(
        (item) => item.adminArtProductId === image.adminArtProductId
      );

      return bool;
    } else {
      const bool = selectedAllFilesImages.find(
        (item) => item.artId === image.artId
      );

      return bool;
    }
  };

  useEffect(() => {
    dispatch(setSelectedAllFilesImagesCustomer([]));
  }, []);

  // const SetSelectedImagesToMoveSet = () => {
  //   dispatch(setSelectedAllFilesImagesCustomer([]));
  //   dispatch(setSelectedAllFilesImagesCustomer([image]));
  //   // dispatch(setMovePopup(true));
  // };

  const SetSelectedImagesToMoveSet = () => {
    dispatch(setSelectedAllFilesImagesCustomer([]));
    dispatch(setSelectedAllFilesImagesCustomer([image]));
    dispatch(setMovePopup(true));
  };
  const SetSelectedImagesToDeleteSet = () => {
    dispatch(setSelectedAllFilesImagesCustomer([]));
    dispatch(setSelectedAllFilesImagesCustomer([image]));
    dispatch(setDelelePopup(true));
  };

  return (
    <>
      {'adminArtProductId' in image ? (
        <div className='flex flex-col text-center'>
          <div
            onMouseEnter={() => setHovered(image.adminArtProductId)}
            onMouseLeave={() => setHovered(null)}
            className='w-[181px] h-[181px] rounded-[16px] inset-0 relative cursor-pointer'
          >
            <div
              className={`h-[100%] w-[100%] bg-no-repeat bg-center bg-cover filter brightness-100 absolute rounded-[16px] overflow-hidden ${
                hovered === image.adminArtProductId
                  ? 'brightness-[70%]'
                  : ''
              }`}
              style={{
                backgroundImage: `url("${image.images[0]?.image}")`,
              }}
            ></div>
            {(selectedAllFilesImages.length > 0 &&
              selectedAllFilesImages?.find(
                (img) =>
                  img.adminArtProductId === image.adminArtProductId
              )) ||
            hovered ? (
              <div className='absolute inset-0 flex items-center justify-center'>
                {/* <button
                className='px-[8px] h-[20px] bg-[#e8e8e8] leading-[1] text-primaryGray flex items-center text-[11px] opacity-[100%] cursor-pointer rounded-[10px]'
              >
                Edit
              </button> */}
              </div>
            ) : (
              <div></div>
            )}
            {(selectedAllFilesImages.length > 0 &&
              selectedAllFilesImages?.find(
                (img) =>
                  img.adminArtProductId === image.adminArtProductId
              )) ||
            hovered ? (
              <div className='absolute top-0 right-0 p-2'>
                <label className='containerCheckWhite p-2'>
                  <input
                    type='checkbox'
                    checked={checked}
                    // checked={isChecked}
                    onChange={handleCheckboxChangeArtProduct}
                  />{' '}
                  {/* {obj.width}cm x {obj.height}cm */}
                  <span className='checkmarkWhite'></span>
                </label>
              </div>
            ) : (
              <div></div>
            )}

            {/* 3buttons */}

            {(selectedAllFilesImages.length > 0 &&
              selectedAllFilesImages.find(
                (img) =>
                  img.adminArtProductId === image.adminArtProductId
              )) ||
            hovered ? (
              <>
                <div className='absolute bottom-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
                  <InfoSvg
                    // className=''
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                    onClick={() => {
                      setpopupLogin(!popupLogin);
                    }}
                  />

                  {popupLogin && (
                    <div
                      className={`z-[9999] w-[350px] right-[117px] bg-[#fff] rounded-[16px] w-[266px] absolute bottom-[44px] left-[-157px]`}
                      style={{
                        boxShadow: '0px 0px 18px rgba(0, 0, 0, 0.2)',
                      }}
                      onMouseLeave={() => setpopupLogin(null)}
                    >
                      <div className=' p-[18px] leading-[18px] '>
                        <p className='text-[15px] text-center'>
                          {/* Futuristic abstract building with
                        <br /> modern architecture */}
                          {image?.adminArtProductName}
                        </p>
                        <div className='border-t border-t-[2px] mt-[13px]  border-b-[2px] border-b border-[#EFEFEF] w-[100%] flex'>
                          <div
                            className='flex flex-col text-start'
                            // style={{
                            //   width: 'fit-content',
                            // }}
                          >
                            <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Art Id:
                            </p>
                            <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Property Release:
                            </p>
                            <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Category:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Subject:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Style
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Medium
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Commercial Usage:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Type of Content:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Date Submitted:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Date Reviewed:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 whitespace-pre'>
                              Date Approved:
                            </p>
                          </div>
                          <div className='flex flex-col w-[100%] text-start'>
                            <p className='text-primaryGray text-sm12 pl-[35px] leading-4 border-b border-[#EFEFEF]'>
                              {/* ANS15994539 */}
                              {image?.artProductUniqueNo}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              Available
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* Art */}
                              {image?.type}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* Architecture */}
                              {image?.artProductUniqueNo}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              Abstract
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              Digital
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px] leading-4 border-b border-[#EFEFEF]'>
                              Web Media, Print Media
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              Original Content{' '}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              04/01/2022
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              06/01/2022
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 '>
                              08/01/2022
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='absolute left-[47%] bottom-[-10px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                    </div>
                  )}
                  <MoveToSvg
                    onClick={SetSelectedImagesToMoveSet}
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  />
                  {/* <CartIcon /> */}
                  {/* <EditContent className='cursor-pointer fill-[#e8e8e8]' />
                  <ProductsSvg className='cursor-pointer' /> */}

                  <DeleteSvg
                    onClick={SetSelectedImagesToDeleteSet}
                    className=' fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  />
                  <ShareSvg className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer' />
                </div>
              </>
            ) : (
              <div></div>
            )}
            <div className='absolute top-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
              <Exclusive_Icon16 className='fill-[#333333]' />
              <Featured_Icon16 className='fill-[#333333]' />
            </div>

            {/* 3buttons */}
          </div>
          <p className='text-sm12 text-primaryBlack font-medium pt-[7px]'>
            {image?.adminArtProductName.length < 26 ? (
              image?.adminArtProductName
            ) : (
              <span>
                {image?.adminArtProductName.slice(0, 26)}...
              </span>
            )}
          </p>
          <p className='text-sm11 text-primaryGray mt-[2px]'>
            {/* Image ID: {image.artId.substring(0, 10)} */}Art ID:{' '}
            {/* ANS9876543210 */}
            {image?.artProductUniqueNo}
          </p>
          <p className='text-sm11 text-primaryGray'>
            {/* Uploaded: {image.submittedDate} */}
            {/* Uploaded: {image.submittedDate?.substring(0, 10)} */}
            Saved: Jan 4, 2022
          </p>
        </div>
      ) : (
        <div className='flex flex-col text-center'>
          <div
            onMouseEnter={() => setHovered(image.artId)}
            onMouseLeave={() => setHovered(null)}
            className='w-[181px] h-[181px] cursor-pointer rounded-[16px] inset-0 relative'
          >
            <div
              className={`h-[100%] w-[100%] bg-no-repeat bg-center bg-cover filter brightness-100 absolute rounded-[16px] overflow-hidden ${
                hovered === image?.artId ? 'brightness-[70%]' : ''
              }`}
              style={{
                backgroundImage: `url("${image?.imageMaster?.imageOrientation?.squareUrl}")`,
              }}
            ></div>
            {(selectedAllFilesImages.length > 0 &&
              selectedAllFilesImages?.find(
                (img) => img?.artId === image?.artId
              )) ||
            hovered ? (
              <div className='absolute inset-0 flex items-center justify-center'>
                {/* <button
                className='px-[8px] h-[20px] bg-[#e8e8e8] leading-[1] text-primaryGray flex items-center text-[11px] opacity-[100%] cursor-pointer rounded-[10px]'
              >
                Edit
              </button> */}
              </div>
            ) : (
              <div></div>
            )}
            {(selectedAllFilesImages.length > 0 &&
              selectedAllFilesImages?.find(
                (img) => img?.artId === image?.artId
              )) ||
            hovered ? (
              <div className='absolute top-0 right-0 p-2'>
                <label className='containerCheckWhite p-2'>
                  <input
                    type='checkbox'
                    checked={checked}
                    // checked={isChecked}
                    onChange={handleCheckboxChange}
                  />{' '}
                  {/* {obj.width}cm x {obj.height}cm */}
                  <span className='checkmarkWhite'></span>
                </label>
              </div>
            ) : (
              <div></div>
            )}

            {/* 3buttons */}
            {(selectedAllFilesImages.length > 0 &&
              selectedAllFilesImages?.find(
                (img) => img?.artId === image?.artId
              )) ||
            hovered ? (
              <>
                <div className='absolute bottom-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
                  <InfoSvg
                    // className=''
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                    onClick={() => {
                      setpopupLogin(!popupLogin);
                    }}
                  />

                  {popupLogin && (
                    <div
                      className={`z-[9999] w-[350px] right-[117px] bg-[#fff] rounded-[16px] w-[266px] absolute bottom-[44px] left-[-157px]`}
                      style={{
                        boxShadow: '0px 0px 18px rgba(0, 0, 0, 0.2)',
                      }}
                      onMouseLeave={() => setpopupLogin(null)}
                    >
                      <div className=' p-[18px] leading-[18px]'>
                        <p className='text-[15px] text-center'>
                          {/* Futuristic abstract building with
                        <br /> modern architecture */}
                          {image?.artName}
                        </p>
                        <div className='border-t border-t-[2px] mt-[13px]  border-b-[2px] border-b border-[#EFEFEF] w-[100%] flex'>
                          <div
                            className='flex flex-col text-start'
                            // style={{
                            //   width: 'fit-content',
                            // }}
                          >
                            <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Art Id:
                            </p>
                            <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Property Release:
                            </p>
                            <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Category:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Subject:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Style
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Medium
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Commercial Usage:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Type of Content:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Date Submitted:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Date Reviewed:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4  whitespace-pre'>
                              Date Approved:
                            </p>
                          </div>
                          <div className='flex flex-col w-[100%] text-start'>
                            <p className='text-primaryGray text-sm12 pl-[35px] leading-4 border-b border-[#EFEFEF]'>
                              {/* ANS15994539 */}
                              {image?.arProductNo}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              Available
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* Art */}
                              {image?.type}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* Architecture */}
                              {image?.subjectMaster?.subjectName}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* Abstract */}
                              {image?.styleMaster?.name}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* Digital */}
                              {image?.styleMaster?.name}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px] leading-4 border-b border-[#EFEFEF]'>
                              {/* Web Media, Print Media */}{' '}
                              {image?.styleMaster?.name}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* Original Content{' '} */}{' '}
                              {image?.commercialUser?.map((obj) => (
                                <span>{obj}, </span>
                              ))}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              04/01/2022
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              06/01/2022
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 '>
                              08/01/2022
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='absolute left-[47%] bottom-[-10px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                    </div>
                  )}
                  <MoveToSvg
                    onClick={SetSelectedImagesToMoveSet}
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  />
                  {/* <CartIcon /> */}
                  {/* <EditContent className='cursor-pointer fill-[#e8e8e8]' />
                  <ProductsSvg className='cursor-pointer' /> */}

                  <DeleteSvg
                    onClick={SetSelectedImagesToDeleteSet}
                    className=' fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  />
                  <ShareSvg className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer' />
                </div>
              </>
            ) : (
              <div></div>
            )}

            <div className='absolute top-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
              <Exclusive_Icon16 className='fill-[#333333]' />
              <Featured_Icon16 className='fill-[#333333]' />
            </div>

            {/* 3buttons */}
          </div>
          <p className='text-sm12 text-primaryBlack font-medium pt-[7px]'>
            {image?.artName?.length < 26 ? (
              image?.artName
            ) : (
              <span>{image?.artName?.slice(0, 26)}...</span>
            )}
            {/* {image.artName} */}
          </p>
          <p className='text-sm11 text-primaryGray mt-[2px]'>
            {/* Image ID: {image.artId.substring(0, 10)} */}Art ID:{' '}
            {image.arProductNo}
          </p>
          <p className='text-sm11 text-primaryGray'>
            {/* Uploaded: {image.submittedDate} */}
            {/* Uploaded: {image.submittedDate?.substring(0, 10)} */}
            Saved: Jan 4, 2022
          </p>
        </div>
      )}
    </>
  );
};

export default AllCustFiles;

// <>
//   <div className='flex flex-col text-center'>
//     <div
//       onMouseEnter={() => setHovered(image.artId)}
//       onMouseLeave={() => setHovered(null)}
//       className='w-[181px] h-[181px] rounded-[16px] inset-0 relative'
//     >
//       <div
//         className={`h-[100%] w-[100%] bg-no-repeat bg-center bg-cover filter brightness-100 absolute rounded-[16px] overflow-hidden ${
//           hovered === image.artId ? 'brightness-[70%]' : ''
//         }`}
//         style={{
//           backgroundImage: `url("${image.imageMaster?.secureUrl}")`,
//         }}
//       ></div>
//       {selectedAllFilesImages.find(
//         (img) => img.artId === image.artId
//       ) || hovered ? (
//         <div className='absolute inset-0 flex items-center justify-center'>
//           {/* <button
//             className='px-[8px] h-[20px] bg-[#e8e8e8] leading-[1] text-primaryGray flex items-center text-[11px] opacity-[100%] cursor-pointer rounded-[10px]'
//           >
//             Edit
//           </button> */}
//         </div>
//       ) : (
//         <div></div>
//       )}
//       {selectedAllFilesImages.find(
//         (img) => img.artId === image.artId
//       ) || hovered ? (
//         <div className='absolute top-0 right-0 p-2'>
//           <label className='containerCheckWhite p-2'>
//             <input
//               type='checkbox'
//               checked={
//                 selectedAllFilesImages.length &&
//                 selectedAllFilesImages[0].artId == image.artId
//               }
//               // checked={isChecked}
//               onChange={handleCheckboxChange}
//             />{' '}
//             {/* {obj.width}cm x {obj.height}cm */}
//             <span className='checkmarkWhite'></span>
//           </label>
//         </div>
//       ) : (
//         <div></div>
//       )}

//       {/* 3buttons */}
//       {selectedAllFilesImages.find(
//         (img) => img.artId === image.artId
//       ) || hovered ? (
//         <>
//           <div className='absolute bottom-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
//             <StyledPopup
//               trigger={
//                 <InfoSvg
//                   className='cursor-pointer'
//                   onClick={() => {
//                     setpopupLogin(!popupLogin);
//                   }}
//                 />
//               }
//               modal
//               closeOnDocumentClick
//             >
//               {(close) => (
//                 <>
//                   <div className='flex flex-col'>
//                     <div className='flex gap-[28px]'>
//                       <div
//                         style={{
//                           width: 'fit-content',
//                         }}
//                         className='flex flex-col text-center '
//                       >
//                         <div
//                           className='flex flex-col justify-center w-[485px] h-[311px] mx-[auto] bg-[#f7f7f7] rounded-t-[20px] bg-no-repeat bg-center bg-cover'
//                           style={{
//                             backgroundImage: `url(${image?.imageMaster?.secureUrl})`,
//                             // borderRadius: '32px',
//                           }}
//                         >
//                           {!image?.imageMaster.secureUrl && (
//                             <div>
//                               <p className='text-pinkColor text-[16px] my-[auto]'>
//                                 Select thumbnail to view the
//                                 image.
//                               </p>
//                               <p className='text-primaryGray text-[11px] my-[auto]'>
//                                 Select an item to view the image.{' '}
//                                 <span className='text-orangeColor text-[11px]'>
//                                   Learn more
//                                 </span>
//                               </p>
//                             </div>
//                           )}
//                         </div>
//                         <div className=' flex items-center justify-between h-[40px] bg-[white]  w-[485px] rounded-b-[20px] border border-[#efefef] px-[13px]'>
//                           <div>
//                             <img
//                               src={mainLogo}
//                               className='w-[135px] h-[100%]'
//                               alt=''
//                             />
//                           </div>
//                           <p className='text-[13px] text-primaryBlack'>
//                             Art ID: ANS987654321
//                           </p>
//                         </div>
//                       </div>

//                       <div>
//                         <p className='text-[24px] font-medium leading-[29px] text-primaryBlack mb-[15px]'>
//                           Printable Wall Art Home Decor <br />{' '}
//                           Artnstock Limited Edition
//                         </p>
//                         <div className='flex gap-[4px] mt-[5px]'>
//                           {/* {artDetails?.userMaster?.coverImage ===
//                     null ? (
//                       <AccountCircleIcon
//                         style={{
//                           fontSize: 'xx-large',
//                         }}
//                       />
//                     ) : ( */}
//                           <div
//                             className='w-[32px] h-[32px] bg-cover bg-center rounded-[50%]'
//                             style={{
//                               backgroundImage: `url(http://res.cloudinary.com/artnstockimg/image/upload/v1702903740/artnstock/smlqjmzfztdrf9zvl4tc.jpg)`,
//                             }}
//                           ></div>
//                           {/* )} */}

//                           {/* <div className=''></div> */}
//                           <div className='flex flex-col mt-[2px]'>
//                             <div>
//                               <Level3 />
//                             </div>

//                             <p className='text-[11px] text-primaryGray leading-[15px]'>
//                               by{' '}
//                               <span
//                                 // onClick={() => {
//                                 //   navigate('/view-my-store', {
//                                 //     state: artDetails?.userMaster,
//                                 //   });
//                                 // }}
//                                 className='text-orangeColor cursor-pointer'
//                               >
//                                 {/* {artDetails?.userMaster?.displayName} */}{' '}
//                                 Azra Creations
//                               </span>
//                             </p>
//                           </div>
//                         </div>

//                         <div className='w-[314px]'>
//                           <div className='mt-5 border-t border-b border-[#EFEFEF] w-[100%] flex '>
//                             <div
//                               style={{
//                                 width: 'fit-content',
//                               }}
//                               className='flex flex-col '
//                             >
//                               <p
//                                 style={{
//                                   whiteSpace: 'nowrap',
//                                 }}
//                                 className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-t border-b border-[#EFEFEF] whitespace-now-wrap'
//                               >
//                                 Art ID:
//                               </p>

//                               {/* {topMatSelect > 0 && ( */}
//                               <p
//                                 style={{
//                                   whiteSpace: 'nowrap',
//                                 }}
//                                 className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-now-wrap'
//                               >
//                                 Availability:
//                               </p>
//                               {/* )} */}

//                               {/* {bottomMatSelect > 0 && ( */}
//                               <p
//                                 style={{
//                                   whiteSpace: 'nowrap',
//                                 }}
//                                 className='text-primaryGray text-sm12 py-[1px] w-[100%] font-medium leading-4 border-b border-[#EFEFEF] whitespace-now-wrap'
//                               >
//                                 Property Release:
//                               </p>
//                               {/* )} */}

//                               {/* {includeFrame && ( */}
//                               <p
//                                 style={{
//                                   whiteSpace: 'nowrap',
//                                 }}
//                                 className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-now-wrap'
//                               >
//                                 Category:
//                               </p>
//                               {/* )} */}

//                               <p
//                                 style={{
//                                   whiteSpace: 'nowrap',
//                                 }}
//                                 className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-now-wrap'
//                               >
//                                 Subject
//                               </p>

//                               <p
//                                 style={{
//                                   whiteSpace: 'nowrap',
//                                 }}
//                                 className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-now-wrap'
//                               >
//                                 Style
//                               </p>

//                               <p
//                                 style={{
//                                   whiteSpace: 'nowrap',
//                                 }}
//                                 className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-now-wrap'
//                               >
//                                 Medium
//                               </p>
//                               <p
//                                 style={{
//                                   whiteSpace: 'nowrap',
//                                 }}
//                                 className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-now-wrap'
//                               >
//                                 Print Size
//                               </p>
//                               <p
//                                 style={{
//                                   whiteSpace: 'nowrap',
//                                 }}
//                                 className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-now-wrap'
//                               >
//                                 Commercial Usage:
//                               </p>
//                               <p
//                                 style={{
//                                   whiteSpace: 'nowrap',
//                                 }}
//                                 className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-now-wrap'
//                               >
//                                 Type of Content:
//                               </p>

//                               <p
//                                 style={{
//                                   whiteSpace: 'nowrap',
//                                 }}
//                                 className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-now-wrap'
//                               >
//                                 Year Created:
//                               </p>
//                               <p
//                                 style={{
//                                   whiteSpace: 'nowrap',
//                                 }}
//                                 className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-now-wrap'
//                               >
//                                 Certificate:
//                               </p>
//                               <p
//                                 style={{
//                                   whiteSpace: 'nowrap',
//                                 }}
//                                 className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-now-wrap'
//                               >
//                                 Signature:
//                               </p>
//                             </div>

//                             <div className='flex flex-col w-[100%] '>
//                               <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] border-t leading-4  border-b border-[#EFEFEF]'>
//                                 ANS15994539
//                               </p>

//                               <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
//                                 In stock
//                               </p>

//                               <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
//                                 Available
//                               </p>

//                               <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
//                                 Art
//                               </p>

//                               <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
//                                 Architecture
//                               </p>

//                               <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
//                                 Abstract
//                               </p>

//                               <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
//                                 Digital
//                               </p>

//                               <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
//                                 47 x 33 Inches (Maximum)
//                               </p>

//                               <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
//                                 Web Media, Print Media
//                               </p>

//                               <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
//                                 Original Content
//                               </p>
//                               <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
//                                 02/03/2020
//                               </p>

//                               <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
//                                 Included
//                               </p>

//                               <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
//                                 Included
//                               </p>
//                             </div>
//                           </div>

//                           <div className='flex gap-[16px] mt-[31px] items-center'>
//                             <div>
//                               <CertificateAuth />
//                             </div>
//                             <div>
//                               <Festivee />
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className='w-[100%] h-[2px] bg-[#efefef] my-[28px]'></div>
//                     <div className='flex flex-col'>
//                       <p className='text-[15px] leading-[18px] text-center font-medium'>
//                         Products Activated
//                       </p>
//                       <div className='w-[100%] flex justify-center'>
//                         <div className='flex pt-[5px]'>
//                           <div
//                             // onClick={() => setNestedLifeStyle('tshirt')}
//                             className='flex-col w-[56px] relative'
//                           >
//                             <div>
//                               <Icon_TShirts className='mx-[auto]' />
//                             </div>
//                             {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
//                             <p className='iconsBelowText text-primaryGray text-center'>
//                               T-Shirts
//                             </p>
//                           </div>
//                           <div className='flex-col  w-[56px] '>
//                             <div>
//                               <Icon_Hoodies className='mx-[auto]' />
//                             </div>
//                             {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
//                             <p className='iconsBelowText text-primaryGray text-center'>
//                               Hoodies
//                             </p>
//                           </div>
//                           <div className='flex-col  w-[56px] '>
//                             <div>
//                               <Icon_GiftPouches className='mx-[auto]' />
//                             </div>
//                             {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
//                             <p className='iconsBelowText text-primaryGray text-center'>
//                               Gift <br /> Pouches
//                             </p>
//                           </div>
//                           <div className='flex-col  w-[56px]'>
//                             <div>
//                               <Icon_Bandanas className='mx-[auto]' />
//                             </div>
//                             {/* <img className='mx-auto' src={glasscolor} alt='' /> */}
//                             <p className='iconsBelowText text-primaryGray text-center'>
//                               Bandanas
//                             </p>
//                           </div>
//                           <div className='flex-col  w-[56px] '>
//                             <div>
//                               <Icon_ToteBags className='mx-[auto]' />
//                             </div>
//                             {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
//                             <p className='iconsBelowText text-primaryGray text-center'>
//                               Tote
//                               <br /> Bags
//                             </p>
//                           </div>
//                           <div className='flex-col  w-[56px] '>
//                             <div>
//                               <Icon_DuffleBags className='mx-[auto]' />
//                             </div>
//                             {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
//                             <p className='iconsBelowText text-primaryGray text-center'>
//                               Duffle <br />
//                               Bags
//                             </p>
//                           </div>
//                           <div className='flex-col  w-[56px] '>
//                             <div>
//                               <Icon_Scarfs className='mx-[auto]' />
//                             </div>
//                             {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
//                             <p className='iconsBelowText text-primaryGray text-center'>
//                               Scarfs
//                             </p>
//                           </div>
//                           <div className='flex-col  w-[56px]'>
//                             <div>
//                               <Icon_CarryAllPouches className='mx-[auto]' />
//                             </div>
//                             {/* <img className='mx-auto' src={giftcolor} alt='' /> */}
//                             <p className='iconsBelowText text-primaryGray text-center'>
//                               Carry-All
//                               <br />
//                               Pouches
//                             </p>
//                           </div>
//                           <div className='flex-col  w-[56px]'>
//                             <div>
//                               <Icon_BucketHats className='mx-[auto]' />
//                             </div>
//                             {/* <img className='mx-auto' src={coastercolor} alt='' /> */}
//                             <p className='iconsBelowText text-primaryGray text-center'>
//                               Bucket
//                               <br />
//                               Hats
//                             </p>
//                           </div>
//                         </div>
//                       </div>{' '}
//                       <div className='w-[100%] '>
//                         <button className='gray30HButton mt-[8px] mx-[auto]'>
//                           Go to Detail Page
//                         </button>
//                       </div>
//                       <p className='text-sm11 text-primaryGray text-center mt-[28px]'>
//                         ©Copyright 2023. Artnstock Private
//                         Limited. All rights reserved.
//                       </p>
//                       <p className='text-sm11 text-primaryGray text-center'>
//                         No image or information display on this
//                         image may be reproduced, transmitted or
//                         copied. Contravention is an <br />
//                         infringement of the Copyright Act and its
//                         amendments and may be subject to legal
//                         action.
//                       </p>
//                     </div>
//                   </div>
//                 </>
//               )}
//             </StyledPopup>

//             {/* {popupLogin && (
//               <div
//                 className={`z-[9999] w-[350px] right-[117px] bg-[#fff] rounded-[16px] w-[266px] absolute bottom-[44px] left-[-157px]`}
//                 style={{
//                   boxShadow: '0px 0px 18px rgba(0, 0, 0, 0.2)',
//                 }}
//                 onMouseLeave={() => setpopupLogin(null)}
//               >
//                 <div className=' p-[18px] leading-[18px] '>
//                   <p className='text-[15px] text-center'>
//                     Futuristic abstract building with
//                     <br /> modern architecture
//                   </p>
//                   <div className='border-t border-t-[2px] mt-[13px]  border-b-[2px] border-b border-[#EFEFEF] w-[100%] flex'>
//                     <div
//                       className='flex flex-col text-start'

//                     >
//                       <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
//                         Art Id:
//                       </p>
//                       <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
//                         Property Release:
//                       </p>
//                       <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
//                         Category:
//                       </p>
//                       <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
//                         Subject:
//                       </p>
//                       <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
//                         Style
//                       </p>
//                       <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
//                         Medium
//                       </p>
//                       <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
//                         Commercial Usage:
//                       </p>
//                       <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
//                         Type of Content:
//                       </p>
//                       <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
//                         Date Submitted:
//                       </p>
//                       <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
//                         Date Reviewed:
//                       </p>
//                       <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
//                         Date Approved:
//                       </p>
//                     </div>
//                     <div className='flex flex-col w-[100%] text-start'>
//                       <p className='text-primaryGray text-sm12 pl-[35px] leading-4 border-b border-[#EFEFEF]'>
//                         ANS15994539
//                       </p>
//                       <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
//                         Available
//                       </p>
//                       <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
//                         Art
//                       </p>
//                       <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
//                         Architecture
//                       </p>
//                       <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
//                         Abstract
//                       </p>
//                       <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
//                         Digital
//                       </p>
//                       <p className='text-primaryGray text-sm12 pl-[35px] leading-4 border-b border-[#EFEFEF]'>
//                         Web Media, Print Media
//                       </p>
//                       <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
//                         Original Content{' '}
//                       </p>
//                       <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
//                         04/01/2022
//                       </p>
//                       <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
//                         06/01/2022
//                       </p>
//                       <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
//                         08/01/2022
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//                 <div className='absolute left-[47%] bottom-[-10px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
//               </div>
//             )} */}
//             <MoveToSvg />

//             <CartIcon />
//             <ShareSvg />
//             <DeleteSvg />
//           </div>
//           <div className='absolute top-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
//             <Exclusive_Icon16 className='fill-[#333333]' />
//             <Featured_Icon16 className='fill-[#333333]' />
//           </div>
//         </>
//       ) : (
//         <div></div>
//       )}

//       {/* 3buttons */}
//     </div>
//     <p className='text-sm12 text-primaryBlack font-medium pt-[7px]'>
//       {image.name}
//     </p>
//     <p className='text-sm11 text-primaryGray mt-[2px]'>
//       {/* Image ID: {image.artId.substring(0, 10)} */}Art ID:
//       ANS9876543210
//     </p>
//     <p className='text-sm11 text-primaryGray'>
//       {/* Uploaded: {image.submittedDate} */}
//       {/* Uploaded: {image.submittedDate?.substring(0, 10)} */}
//       Saved: Jan 4, 2022
//     </p>
//   </div>
// </>
