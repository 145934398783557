import React from 'react';

import { ReactComponent as LogoWithoutText } from '../../assets/images/ShoppingCart/logoWithoutText.svg';
import { ReactComponent as Paypal } from '../../assets/images/footer/Paypal.svg';
import { ReactComponent as Visa } from '../../assets/images/footer/Visa.svg';
import { ReactComponent as Mastercard } from '../../assets/images/footer/Mastercard.svg';
import { ReactComponent as AmerExpress } from '../../assets/images/footer/AmerExpress.svg';
import { ReactComponent as Discover } from '../../assets/images/footer/Discover.svg';
import Certificate1 from '../../assets/images/FooterSubComp/Certificate1.png';
import Certificate2 from '../../assets/images/FooterSubComp/Certificate2.png';
import Certificate3 from '../../assets/images/FooterSubComp/Certificate3.png';

import { ReactComponent as Exclusive_Icon16 } from '../../assets/images/contributor/add_details/Icon_Exclusive_16x16.svg';
import combo from '../../assets/images/Menubar/combo.png';
import mug from '../../assets/images/Menubar/mug.png';

import leftarrow from '../../assets/images/ShoppingCart/leftarrow.png';

import { ReactComponent as PrintIcon } from '../../assets/images/Icons/printIcon.svg';

import lock from '../../assets/images/ShoppingCart/lock.png';
import welcomeOffer from '../../assets/images/trackOrder/welcomeOffer.jpg';

import Thumbnail1 from '../../assets/images/Checkout/Thumbnail1.jpg';
import { ReactComponent as DownloadSvg } from '../../assets/images/Icons/downloadIcon.svg';
import Footer from '../footer/Footer';
import { ReactComponent as Icon_RightArrow_12x12 } from '../../assets/images/ShoppingCart/28Oct24/Icon_RightArrow_12x12.svg';

const CheckoutThanks = () => {
  return (
    <>
      <div className='w-[100%] pt-[20px] mb-[360px]'>
        <div className='text-[11px] leading-[1] text-[#d6d6d6] font-medium flex justify-center gap-[8px] items-center   '>
          <span>SHOPPING CART</span>
          <div>
            <Icon_RightArrow_12x12 className='fill-[#d6d6d6]' />
          </div>
          <span> SHIPPING ADDRESS </span>
          <div>
            <Icon_RightArrow_12x12 className='fill-[#d6d6d6]' />
          </div>
          <span className={` text-pinkColor`}> PAYMENT</span>
        </div>

        {/* <div className='flex flex-col mx-auto mt-[10px] mb-[500px]'>
        <div className='h-[1px] w-[100%] bg-[#efefef] mt-[10px] mb-[28px]'></div> */}

        <div className='flex gap-[152px] mt-[32px] mx-[auto] justify-center'>
          {/* div  1*/}

          <div className='max-w-[448px] w-[100%] flex flex-col'>
            <p className='text-[25px] font-semibold text-primaryBlack leading-[1] mb-[16px] cursor-pointer'>
              Thank You For Your Shopping
            </p>
            <div
              // onClick={() => {
              //   navigate('/checkout2');
              // }}
              className='text-[25px] font-medium text-primaryGray leading-[1] gap-[4px] mb-[16px] cursor-pointer flex flex-col'
            >
              <p className='text-[12px] leading-[1] '>Order Number</p>{' '}
              <p>ANS9876547</p>
            </div>

            <div className='text-primaryGray text-sm12 font-medium leading-4'>
              <div className='flex border-t border-t-[2px] border-b gap-[8px] border-[#efefef]'>
                <p className='w-[61px] font-medium'>Customer:</p>
                <p className='font-normal'>Khalid Shaikh</p>
              </div>
              <div className='flex  border-b-[2px] border-b gap-[8px] border-[#efefef]'>
                <p className='w-[61px] font-medium'>Order Date:</p>
                <p className='font-normal'>18 Jan 2024</p>
              </div>
            </div>

            <div className='flex flex-col'>
              <p className='text-primaryBlack text-[16px] mt-[30px] mb-[8px] leading-[18px] font-medium'>
                Payment Method
              </p>
              <div>
                <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>
              <p className='text-sm12 text-primaryGray mt-[8px] flex items-center'>
                HDFC Bank Credit Card ending in 2139
              </p>
              <p className='text-sm11 text-primaryGray flex items-center'>
                Name on the Card:{' '}
                <span className='text-primaryBlack font-medium'>
                  Arshaan Shaikh
                </span>
              </p>
            </div>
            <div className='mt-[16px]'>
              <p className='text-primaryBlack text-[16px]  mb-[16px] leading-[18px] font-medium'>
                Shipping Address
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* {userData?.userFirstName} {userData?.userLastName} */}
                Khalid Shaikh
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* {userData?.residentialAddress?.addressLine1}, */}
                S. No. 203/2A, Plot 16A, Rajiv Nagar South,
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* {userData?.residentialAddress?.addressLine2},{' '}
                  {userData?.residentialAddress?.cityName}{' '}
                  {userData?.residentialAddress?.zipCode}. */}
                Viman Nagar, Pune 132323.
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* {userData?.residentialAddress?.stateName} */}
                Maharashtra
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* {userData?.residentialAddress?.countryName} */}
                India
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* Contact No.:{userData?.residentialAddress?.phoneNo} */}
                Mobile No.: +91 7378666093
              </p>
              <p className='text-sm12  text-primaryGray'>
                Email: ksgrafiks2012@gmail.com
              </p>
              {/* <p className='text-sm12  text-primaryGray'>
                          Email:{userData?.emailAddress}
                        </p> */}
            </div>
            <div className='mt-[40px]'>
              <p className='text-primaryBlack text-[186x] mb-[4px] leading-[18px] font-medium'>
                Shipping Method
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* {select?.shippingMethodName} */}Standard
              </p>
              {/* <p className='text-sm12 text-primaryGray'>
                          Order now to receive in{' '}
                          {select?.dayToReceive} business days
                        </p> */}
            </div>

            <div className='flex mt-[16px]'>
              <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
                <div className='flex flex-col mt-[1px] gap-[8px] w-[52px] text-center'>
                  <div>
                    <PrintIcon className='mx-[auto]' />
                  </div>
                  <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                    Print <br /> Invoice
                  </p>
                </div>
              </div>
              <div className='contributorUploadIconsMainDiv '>
                <div className='flex flex-col mt-[1px] gap-[8px] w-[52px] text-center'>
                  <div>
                    <DownloadSvg className='cursor-pointer fill-[#888888] mx-[auto]' />
                  </div>
                  <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                    Download <br /> Invoice
                  </p>
                </div>
              </div>
            </div>
            {/* <div className='mt-[20px]'>
              <p className='text-primaryBlack text-[15px] mt-[23px] mb-[12px] leading-[18px] font-medium'>
                Shipping Address
              </p>
              <p className='text-sm12  text-primaryGray'>
               
                Khalid Shaikh
              </p>
              <p className='text-sm12  text-primaryGray'>
               
                S. No. 203/2A, Plot 16A, Rajiv Nagar South,
              </p>
              <p className='text-sm12  text-primaryGray'>
               
                Viman Nagar, Pune 132323.
              </p>
              <p className='text-sm12  text-primaryGray'>
                
                Maharashtra
              </p>
              <p className='text-sm12  text-primaryGray'>
            
                India
              </p>
              <p className='text-sm12  text-primaryGray'>
              
                Contact No.:6768787787
              </p>
              <p className='text-sm12  text-primaryGray'>
               
                Email:apps242000@gmail.com
              </p>
            </div>
            <div className='mt-[20px]'>
              <p className='text-primaryBlack text-[15px] mt-[23px] mb-[12px] leading-[18px] font-medium'>
                Shipping Method
              </p>
              <p className='text-sm12  text-primaryGray'>
               Standard
              </p>
              <p className='text-sm12 text-primaryGray'>
              
                Order now to receive in 10-15 business days
              </p>
            </div> */}
          </div>

          <div className='w-[328px] '>
            <div className='rounded-2xl overflow-hidden w-[328px]'>
              <div className='bg-[#bbbbbb] h-[40px] px-[16px] flex justify-between items-center'>
                <p className='text-[16px] leading-[1] text-primaryBlack font-medium'>
                  Your Order Summary
                </p>
                {/* <img src={leftarrow} alt='' /> */}
              </div>

              {/* <div className='bg-[#EEEEEE] px-4 py-2.5 flex items-center'>
                  <div className='bg-[#333333] text-[#FFFFFF] rounded-full w-[25px] h-[25px] flex items-center justify-center'>
                    {cartData?.totalCount}
                  </div>
                  <p className='text-[13px] text-[#333333] ml-1 font-medium'>
                    Items in your cart
                  </p>
                </div> */}

              <div className='h-[60px] bg-[#eeeeee] flex gap-[8px] items-center px-[16px]'>
                <div className='h-[44px] w-[44px] rounded-[5000px] bg-[#dadada] flex justify-center items-center'>
                  <span className='text-[#333333] text-[25px] leading-[1] '>
                    {/* {cartData?.totalCount} */}12
                  </span>
                </div>
                <div className='flex flex-col'>
                  <p className='font-medium text-sm11 text-primaryBlack'>
                    Item(s) in your cart
                  </p>
                  <p className='text-sm11 text-primaryGray '>
                    Shipping Charges, Promotional Discounts, <br />
                    Taxes, and GST calculated at checkout.
                  </p>
                </div>
              </div>

              <div className='px-[16px] pb-[16px] pt-[16px] bg-[#f9f9f9] border-x border-x-[#efefef] border-b border-b-[#efefef] rounded-b-[16px]'>
                <div className='flex justify-between pt-[8px] pb-[8px] cursor-pointer  '>
                  <div className='flex gap-[16px]'>
                    <img
                      className='h-[84px] w-[84px]  rounded-[12px] border border-[#efefef]'
                      src={combo}
                      alt=''
                    />
                    <div>
                      <p className='text-sm12 text-primaryBlack font-medium '>
                        Assorted Artnstock Lifestyle <br />
                        Combo 2024
                      </p>
                      <p className='text-sm11 text-primaryGray '>
                        Combo ID: ANS15994539
                      </p>
                      <div className='flex gap-[8px] mt-[4px]'>
                        <p className='text-sm12 text-pinkColor font-medium'>
                          $200.00
                        </p>
                        <p className='text-sm12 text-primaryGray'>
                          Qty.1
                        </p>
                      </div>
                      <button className='h-[16px] flex px-[4px] mt-[4px] text-[10px] block leading-[1] text-[#33cdf4] cursor-default border border-[#33cdf4] font-medium items-center justify-center rounded-[8px]'>
                        10% Discount Applied
                      </button>
                    </div>
                  </div>

                  {/* <p className='text-[15px] text-orangeColor'>
                              $400.00
                            </p> */}
                </div>
                <div className='flex justify-between pt-[8px] pb-[16px] border-b border-[#d6d6d6] cursor-pointer'>
                  <div className='flex gap-[16px]'>
                    <img
                      className='h-[84px] w-[84px]  rounded-[12px] border border-[#efefef]'
                      src={mug}
                      alt=''
                    />
                    <div>
                      <p className='text-sm12 text-primaryBlack font-medium '>
                        Assorted Artnstock Lifestyle <br />
                        Combo 2024
                      </p>
                      <p className='text-sm11 text-primaryGray '>
                        Combo ID: ANS15994539
                      </p>
                      <div className='flex  gap-[8px] mt-[4px]'>
                        <p className='text-sm12 text-pinkColor font-medium'>
                          $200.00
                        </p>
                        <p className='text-sm12 text-primaryGray'>
                          Qty.1
                        </p>
                      </div>
                      <div className='flex gap-[4px] pt-[4px]'>
                        <div>
                          <Exclusive_Icon16 className='fill-[#b5a56d]' />
                        </div>
                        <div className='flex flex-col font-bold'>
                          <p className='text-[9px] text-[#b5a56d] leading-[1]'>
                            ARTNSTOCK
                          </p>
                          <p className='text-[9px] text-[#b5a56d] leading-[1]'>
                            EXCLUSIVE
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <p className='text-[15px] text-orangeColor'>
                              $400.00
                            </p> */}
                </div>

                {/* <div className='py-[16px]'>
                    <div className='h-[40px] relative w-[294px] rounded-[20px] flex items-center text-center justify-center text-sm11 font-medium'>
                      <span
                        style={{
                          width: 'fit-content',
                        }}
                        className='opacity-[100%]'
                      >
                        Shipping, Taxes, GST, and Promotional
                        Discounts <br />
                        calculated at Checkout.
                      </span>
                      <div className='w-[100%] h-[100%] absolute opacity-[15%] gradient-background rounded-[20px]'></div>
                    </div>
                  </div> */}
                {/* <div
                    className='h-[100%] w-[100%] py-1 mb-[20px]'
                    style={{
                      background: `url(${msgBg})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                    }}
                  >
                    <p className='text-[12px] text-primaryBlack font-semibold text-center'>
                      Shipping, Taxes, GST, and Promotional Discounts{' '}
                      <br />
                      calculated at Checkout.
                    </p>
                  </div> */}
                {/* <div className='h-[2px] w-[100%] bg-[#808080]'></div> */}
                <div className='pt-[16px] pb-[16px]'>
                  <div className=' flex justify-between mb-[8px]'>
                    <div>
                      <p className='text-sm12  text-primaryBlack font-medium'>
                        Your Cart item(s) Total
                      </p>
                      <p className='text-sm11 text-primaryGray '>
                        10% Discount on $220.00 (-$20.00)
                      </p>
                    </div>
                    <p className='text-sm12  text-primaryBlack font-medium'>
                      {/* ${addZeroes(cartData?.totalAmount)} */}
                      $220.00
                    </p>
                  </div>
                  {/* <div className='h-[1px] my-[8px] w-[100%] bg-[#efefef]'></div> */}

                  <div className='flex justify-between mb-[8px]'>
                    <div>
                      <p className='text-sm12  text-primaryBlack font-medium'>
                        Tax
                      </p>
                      <p className='text-sm11 text-primaryGray '>
                        GST and other taxes
                      </p>
                    </div>
                    <p className='text-sm12  text-primaryBlack font-medium'>
                      {/* ${addZeroes(cartData?.taxAmount)} */} $35.00
                    </p>
                  </div>

                  <div className=' flex justify-between  '>
                    <div>
                      <p className='text-sm12  text-primaryBlack font-medium'>
                        Shipping Charges
                      </p>
                      <p className='text-sm11 text-primaryGray '>
                        Your order will be shipped in 1 package
                      </p>
                    </div>
                    <p className='text-sm12  text-primaryBlack font-medium '>
                      {/* ${addZeroes(cartData?.estimateShipping)} */}
                      $12.00
                    </p>
                  </div>
                  {/* <div className='h-[1px] my-[8px] w-[100%] bg-[#efefef]'></div> */}
                </div>
                <div className='h-[2px] w-[100%] bg-[#d6d6d6]'></div>
                <div className='flex justify-between items-center h-[60px] '>
                  <p className='text-[16px] leading-[16px] text-primaryBlack font-medium'>
                    Total Amount
                  </p>

                  {/* <div className='flex flex-col items-end '> */}
                  <div className='flex  items-baseline text-pinkColor  font-light'>
                    <div className='flex items-baseline '>
                      <p className='text-pinkColor text-[32px] '>$</p>
                      <p className='text-pinkColor text-[32px] font-medium'>
                        {/* {addZeroes(cartData?.finalAmount)?.toFixed(
                            2
                          )} */}
                        2500.00
                      </p>
                    </div>
                    {/* <p className='text-[22px] leading-[1]'>$</p>
                        <p className='text-[35px] leading-[1]'>
                          {addZeroes(cartData?.finalAmount).toFixed(
                            2
                          )}
                        </p> */}
                  </div>

                  {/* </div> */}
                </div>
                <div className='h-[2px] w-[100%] bg-[#d6d6d6]'></div>
                {/* Promo Code */}
                <div className='pt-[10px]'>
                  <div className='flex gap-2 items-start'>
                    {/* <div className=''>
                        <label
                          style={{
                            width: 'fit-content',
                          }}
                          className='containerCheckGray text-sm11 text-primaryGray'
                        >
                          <input
                            type='checkbox'
                            checked={applyPromo}
                            onChange={(e) =>
                              setApplyPromo(!applyPromo)
                            }
                           
                          />{' '}
                          Apply Promo codes and Gift Cards
                          <span className='checkmarkGray'></span>
                        </label>
                        <p className='text-sm11 text-primaryGray ml-[21px] relative bottom-[3px]'>
                          (One Promo code per order)
                        </p>
                      </div> */}

                    {/* <p className='text-sm12 text-primaryGray'>
                       
                      </p> */}
                  </div>
                </div>
                {/* {cartData?.list?.length > 0 ? (
                  <div>
                    {cartData?.list?.length > 1 ? (
                      <button
                        // onClick={gotoCheckout}
                        onClick={() => {
                          setFinalCheckout(false);
                        }}
                        className={`blackBtn mt-[32px] mx-auto mb-[16px] block ${
                          !finalCheckout ? 'disabledButtonsPopup' : ''
                        }`}
                      >
                        Continue to Checkout
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setFinalCheckout(false);
                        }}
                        // onClick={() => {
                        //   gotoCheckoutWithOne(
                        //     cartData?.cartId,
                        //     cartData?.list[0]?.cartArtFrameId,
                        //     cartData?.list[0]?.quantity
                        //   );
                        // }}
                        className={`blackBtn mt-[32px] mx-auto mb-[16px] block ${
                          !finalCheckout ? 'disabledButtonsPopup' : ''
                        }`}
                      >
                        Continue to Checkout
                      </button>
                    )}
                  </div>
                ) : (
                  <div></div>
                )} */}

                <p className='text-sm11 text-primaryGray text-center'>
                  Since our products are printed on demand especially{' '}
                  <br /> for you, it is{' '}
                  <span className='font-medium'>
                    {' '}
                    not eligible for cancellations and returns.
                    <br />
                  </span>
                  Read our{' '}
                  <span className='text-orangeColor'>
                    Returns & Exchange policy
                  </span>
                  .
                  <br /> By placing your order, you agree to the{' '}
                  <span className='text-orangeColor'>
                    Delivery Terms
                  </span>
                  .
                </p>

                <p className='text-sm11 text-primaryGray text-center mt-[32px]'>
                  Accepted Payment Methods
                </p>
                <div
                  style={{
                    width: 'fit-content',
                  }}
                  className='flex gap-[4px] mx-[auto] mt-[3px]'
                >
                  <div>
                    <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                  </div>

                  <div>
                    <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                  </div>

                  <div>
                    <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                  </div>

                  <div>
                    <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                  </div>
                  <div>
                    <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                  </div>
                  {/* <div>
                    <Discover />
                  </div> */}
                </div>
                {/* <div
                    style={{
                      boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
                    }}
                    className=' w-[300px] rounded-[16px] flex flex-col bg-[#ffffff] p-[10px] mt-[20px] '
                  >
                    <div className='rounded-[20px] h-[40px] border-[#d6d6d6] border text-primaryBlack text-[12px] flex justify-center items-center px-[30px] gap-[5px]'>
                      <div>
                        <LogoWithoutText />
                      </div>
                      <span className='font-medium'>
                        Sign in to your Artnstock account
                      </span>
                    </div>
                    <p className='text-[15px] text-[#333333] leading-[19px] font-medium mt-[7px]'>
                      Need Help?
                    </p>
                    <p className='text-sm12 text-primaryGray leading-[15px]'>
                      Shipping
                    </p>
                    <p className='text-sm12 text-primaryGray leading-[15px]'>
                      Return & Exchanges
                    </p>
                    <p className='text-sm12 text-primaryGray leading-[15px]'>
                      Contact Us
                    </p>
                  </div> */}
              </div>
            </div>

            {/* <div className='my-[16px] flex justify-center gap-[8px] py-[8px] border-[#efefef] border-t-[2px] border-b-[2px] border-t border-b'>
                <div>
                  <img
                    src={Certificate1}
                    alt=''
                    className='w-[68px] h-[68px]'
                  />
                 
                </div>
                <div>
                  <img
                    src={Certificate2}
                    alt=''
                    className='w-[68px] h-[68px]'
                  />
                  
                </div>
                <div>
                  <img
                    src={Certificate3}
                    alt=''
                    className='w-[68px] h-[68px]'
                  />
                 
                </div>
              </div> */}
            <p className='text-sm11 text-primaryGray text-center mt-[16px]'>
              Faster Checkout Every Time
            </p>
            <div
              style={{
                width: 'fit-content',
              }}
              className='mt-[4px] mx-[auto] h-[40px] px-[14px] flex items-center rounded-gradient-border-for-button'
            >
              <img src={welcomeOffer} alt='' />
            </div>
            <div className='pl-[17px] mt-[16px]'>
              <p className='text-sm12 text-[#333333] font-medium'>
                Customer Support
              </p>
              <p className='text-sm11 text-orangeColor '>Shipping</p>
              <p className='text-sm11 text-orangeColor '>
                Return & Exchanges
              </p>
              <p className='text-sm11 text-orangeColor '>
                Contact Us
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* </div> */}
    </>
  );
};

export default CheckoutThanks;
