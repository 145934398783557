const NoItemsText = ({
  pinkText,
  grayText,
  button,
  link,
  buttonText,
}) => {
  return (
    <div className='w-[360px] mx-[auto]'>
      <p className='text-[16px] text-pinkColor leading-[20px] text-center'>
        {pinkText}
      </p>
      <p className='text-sm12 text-primaryGray text-center mt-[8px]'>
        {grayText}
      </p>
      {button === 'true' && (
        <button className='blackBtn mt-[16px] mx-[auto] block'>
          {buttonText}
        </button>
      )}
    </div>
  );
};

export default NoItemsText;
