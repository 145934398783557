import React, { useRef, useState } from 'react';

import { ReactComponent as Paypal } from '../../Assets/FootageDetails/Paypal.svg';
import { ReactComponent as Visa } from '../../Assets/FootageDetails/Visa.svg';
import { ReactComponent as Mastercard } from '../../Assets/FootageDetails/Mastercard.svg';
import { ReactComponent as AmerExpress } from '../../Assets/FootageDetails/AmerExpress.svg';
import { ReactComponent as Discover } from '../../Assets/FootageDetails/Discover.svg';
import Certificate1 from '../../Assets/FootageDetails/Certificate1.png';
import Certificate2 from '../../Assets/FootageDetails/Certificate2.png';
import Certificate3 from '../../Assets/FootageDetails/Certificate3.png';
import Azra from '../../Assets/FootageDetails/Azra.jpg';
import proImg from '../../Assets/FootageDetails/Pro.svg';
import locatiomIcon from '../../Assets/FootageDetails/Icon - Location Pointer.svg';
import { ReactComponent as ContriRecog1 } from '../../Assets/FootageDetails/ContriRecog1.svg';
import { ReactComponent as ContriRecog2 } from '../../Assets/FootageDetails/ContriRecog2.svg';
import { ReactComponent as ContriRecog3 } from '../../Assets/FootageDetails/ContriRecog3.svg';
import { ReactComponent as Fb } from '../../Assets/FootageDetails/fb.svg';
import { ReactComponent as Linked } from '../../Assets/FootageDetails/linked.svg';
import { ReactComponent as Insta } from '../../Assets/FootageDetails/insta.svg';
import t1 from '../../Assets/TemplateDetails/TemplateDetails/t1.jpg';
import t2 from '../../Assets/TemplateDetails/TemplateDetails/t2.jpg';
import t3 from '../../Assets/TemplateDetails/TemplateDetails/t3.jpg';
import t4 from '../../Assets/TemplateDetails/TemplateDetails/t4.jpg';
import t5 from '../../Assets/TemplateDetails/TemplateDetails/t5.jpg';
import t6 from '../../Assets/TemplateDetails/TemplateDetails/t6.jpg';
import t7 from '../../Assets/TemplateDetails/TemplateDetails/t7.jpg';
import t8 from '../../Assets/TemplateDetails/TemplateDetails/t8.jpg';
import t9 from '../../Assets/TemplateDetails/TemplateDetails/t9.jpg';
import t10 from '../../Assets/TemplateDetails/TemplateDetails/t10.jpg';
import R1 from '../../Assets/FootageDetails/R1.jpg';
import R2 from '../../Assets/FootageDetails/R2.jpg';
import R3 from '../../Assets/FootageDetails/R3.jpg';
import R4 from '../../Assets/FootageDetails/R4.jpg';
import R5 from '../../Assets/FootageDetails/R5.jpg';
import R6 from '../../Assets/FootageDetails/R6.jpg';
import R7 from '../../Assets/FootageDetails/R7.jpg';
import R8 from '../../Assets/FootageDetails/R8.jpg';
import grp from '../../Assets/FootageDetails/grp.png';
import { ReactComponent as Icon_YellowStar } from '../../Assets/FootageDetails/StarYellow.svg';
import { Rating, Slider } from '@mui/material';
import Review1 from '../../Assets/FootageDetails/Review1.jpg';
import Review2 from '../../Assets/FootageDetails/Review2.jpg';
import Review3 from '../../Assets/FootageDetails/Review3.jpg';
import Review4 from '../../Assets/FootageDetails/Review4.jpg';
import Review5 from '../../Assets/FootageDetails/Review5.jpg';
import Review6 from '../../Assets/FootageDetails/Review8.jpg';
import Review7 from '../../Assets/FootageDetails/Review6.jpg';
import Review8 from '../../Assets/FootageDetails/Review7.jpg';
import Review9 from '../../Assets/FootageDetails/Review9.jpg';
import Review10 from '../../Assets/FootageDetails/Review10.jpg';
import dropdown from '../../Assets/FootageDetails/dropdown.png';
import addsmall from '../../Assets/PhotosDetails/Popup/addsmall.png';
import Layer1 from '../../Assets/FootageDetails/Layer1.jpg';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import ksgrafiks from '../../Assets/FootageDetails/ksgrafiks.jpg';
import AzraCreation from '../../Assets/FootageDetails/AzraCreation.jpg';
import Wallpaper from '../../Assets/FootageDetails/Wallpaper.jpg';
import like from '../../Assets/FootageDetails/like.png';

const StyledPopupReview = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px;
    width: 514px;
  }
`;

const SecondComp = () => {
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const [isOpenFilterBy, setIsOpenFilterBy] = useState(false);
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const colors = [
    '#622F22',
    '#A0522D',
    '#C34A2C',
    '#FF8C00',
    '#FC6C85',
    '#F660AB',
    '#7E191B',
    '#FA8072',
    '#0C090A',
  ];
  const keyword = [
    'background',
    'design',
    'illustration',
    'paint',
    'texure',
    'pattern',
    'backdrop',
    'abstract',
    'artistic',
    'cloud',
    'smoke',
    'colour',
    'colourful',
    'concept',
    'background',
    'fine',
    'watercolour',
    'cloud',
    'art',
    'smoke',
    'colour',
  ];
  return (
    <>
      <div className='w-w1168 mx-auto flex flex-col mt-[48px] border-t-[1px] border-b-[1px] border-[#EFEFEF] pt-[48px] pb-[48px]'>
        <div className='flex justify-center'>
          <p className='text-primaryGray text-sm11 font-normal text-center'>
            Note: There might be a slight variation in the shade of
            the <br /> actual product and the image shown on the
            screen, due to the screen resolution and photography
            effects.
          </p>
        </div>
        <div className='text-[12px] text-[black] gap-[17px] flex justify-center mt-[4px]'>
          <span>FAQs </span>
          <span>Returns & Refunds</span>
          <span>Ask a Question</span>
        </div>
        <div className='flex flex-col pt-[10px] gap-[7px]'>
          <p className='text-[15px] font-medium text-[#333333] flex justify-center'>
            Checkout securely with
          </p>
          <div className='flex gap-[4px] justify-center'>
            <div>
              <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
            </div>
            <div>
              <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
            </div>

            <div>
              <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
            </div>

            <div>
              <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
            </div>
            <div>
              <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
            </div>
          </div>
          <div className='flex gap-[10px] justify-center mt-[15px]'>
            <img src={Certificate1} alt='' />
            <img src={Certificate2} alt='' />
            <img src={Certificate3} alt='' />
          </div>
        </div>
      </div>
      <div className='w-w1168 flex mx-auto  mt-[48px]'>
        <div className='left flex-1 text-left'>
          <p className='text-[24px] text-primaryBlack font-medium leading-[27px] mb-[13px]'>
            About this template
            {/* {artDetails?.artName} */}
          </p>
          <p className='text-sm14 text-primaryGray font-normal mb-[22px] w-[575px] '>
            {/* {artDetails?.description} */}
            Art can mimic nature, by seeking to visually replicate
            objects as they actually appear in real life. But abstract
            paintings can also take their visual cue from actual forms
            in nature, such as the painting below. This piece arose
            from the study, observation, and contemplation of natural
            phenomena and natural forms.
          </p>

          <div className='w-[191px] h-[160px]  bg-[#F7F7F7] rounded-[32px] mb-[24px]'></div>
          <div className='mb-[23px] border-t-2 border-b-2 border-[#EFEFEF] w-[365px]'>
            <div className='w-[365px]'>
              <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[59px]'>
                  Category:
                </p>
                <p className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                  Facebook Template
                </p>
              </div>
              <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[59px]'>
                  Subject:
                </p>
                <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                  Nature, Landscape, Sunset, Summer
                </p>
              </div>
              <div className='flex items-center h-[17px]'>
                <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[59px]    '>
                  Medium:
                </p>
                <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                  Digital
                </p>
              </div>
            </div>
          </div>
          <p className='text-[15px] text-primaryBlack font-medium leading-4 mb-[9px]'>
            Colour Palette
          </p>
          <div
            style={{ width: 'fit-content' }}
            className='flex rounded-lg overflow-hidden flex'
          >
            {colors.map((color, index) => (
              <div
                key={index}
                className='h-[40px] w-[16px]'
                style={{ backgroundColor: color }}
              />
            ))}
          </div>
        </div>
        <div className='right flex-1  pl-7 text-left'>
          <p className='text-[24px] mb-[16px] font-medium text-primaryBlack leading-[27px]'>
            About Designer
          </p>
          <div className='flex mb-5'>
            <div>
              <img
                className='w-[7rem] h-[7rem] rounded-full'
                // src={artDetails?.userMaster?.profileImage}
                src={Azra}
                alt=''
              />
            </div>
            <div className='ml-2 mt-[3px]'>
              <img src={proImg} alt='' />
              <p className='text-[16px] text-primaryBlack font-medium leading-[1.5]'>
                {/* {artDetails?.userMaster?.displayName} */}Azra
                Design
              </p>
              <p className='text-sm11 text-primaryGray font-medium leading-[13px]'>
                {/* {artDetails?.userMaster?.userDesignation} */}
                Freelance Videographer
              </p>
              <div className='flex gap-[4px] items-center mb-[4px]'>
                <img className='mr-0.5' src={locatiomIcon} alt='' />
                <p className='text-sm11 text-primaryGray font-normal leading-[16px]'>
                  Pune,India
                </p>
              </div>
              <button
                // onClick={() => {
                //   navigate('/view-my-store', {
                //     state: artDetails?.userMaster,
                //   });
                // }}
                className='bg-[#00F9C6] text-sm12 text-primaryBlack font-medium px-4 py-1.5 rounded-3xl '
              >
                View Store
              </button>
            </div>
          </div>

          <p className='text-[13px] leading-5 text-primaryGray font-normal mb-4 '>
            International Artist Azra's art work dictates the
            expression of man and beast exploring our role and place
            in this universe. Taking inspiration from Primitive Art
            work and a various assortment of Modern Art.
          </p>
          <p className='text-sm14 leading-5 text-primaryBlack font-semibold mb-0.5'>
            Recognition
          </p>
          <ul className='mb-5'>
            <li className='mb-[10px] flex gap-[14px]'>
              <ContriRecog1 />
              <p className='text-sm11 leading-3 font-normal text-primaryGray'>
                Lorem ipsum dolor sit amet consectetur adipiscing
                <br />
                elit sed do eiusmod tempor
              </p>
            </li>
            <li className='mb-[10px] flex gap-[14px]'>
              <ContriRecog2 />
              <p className='text-sm11 leading-3 font-normal text-primaryGray'>
                Lorem ipsum dolor sit amet consectetur adipiscing
                <br />
                elit sed do eiusmod tempor
              </p>
            </li>
            <li className='mb-[10px] flex gap-[14px]'>
              <ContriRecog3 />
              <p className='text-sm11 leading-3 font-normal text-primaryGray'>
                Lorem ipsum dolor sit amet consectetur adipiscing
                <br />
                elit sed do eiusmod tempor
              </p>
            </li>
          </ul>
          <div className='flex gap-4'>
            <a
              // href={artDetails?.userMaster?.socialMedia?.facebookLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Fb className='fill-[#757575] cursor-pointer' />
              {/* <img src={faceBookIcon} alt='' /> */}
            </a>
            <a
              //   href={artDetails?.userMaster?.socialMedia?.linkedinLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Linked className='fill-[#757575] cursor-pointer' />
              {/* <img src={linkdinIcon} alt='' /> */}
            </a>
            <a
              //   href={
              //     artDetails?.userMaster?.socialMedia?.pinterestLink
              //   }
              target='_blank'
              rel='noopener noreferrer'
            >
              <Insta className='fill-[#757575] cursor-pointer' />
              {/* <img src={instaIcon} alt='' /> */}
            </a>
          </div>
        </div>
      </div>

      <div className='w-w1168 mx-[auto] mt-[48px] border-t-[1px] text-left border-b-[1px] border-[#EFEFEF] pt-[48px] pb-[48px]'>
        <p className='text-[16px] text-primaryBlack font-medium'>
          Related Keywords
        </p>
        <div className='flex gap-[4px] flex-wrap mt-[10px]'>
          {keyword?.map((key) => (
            <div
              key={key}
              className='  text-sm12 text-primaryGray cursor-pointer flex h-[25px] items-center border border-[#D6D6D6] hover:text-[#333333] text-[#757575] rounded-2xl px-[10px]'
            >
              {key}
            </div>
          ))}
        </div>
      </div>
      <p className='text-[38px] font-medium text-center text-[#333333] mt-[40px]'>
        More templates by Azra{' '}
        {/* {artDetails?.userMaster?.displayName} */}
      </p>
      <div className='w-w1409 flex justify-center mx-auto mb-7 border-b-[1px] pb-[48px] border-[#EFEFEF] '>
        <div>
          <div className='flex gap-[16px] text-center mt-4'>
            <div className='text-center w-[269px] '>
              <img
                src={t1}
                className='h-[269px] w-[100%] rounded-[16px]'
                alt=''
              />
              <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                #Lorem ipsum dolor{' '}
              </p>
              <p className='text-primaryGray text-sm12 leading-[15px]'>
                Lorem ipsum dolor sit amet labore adipiscing
                consectetur elit sed do eiusmod
              </p>
            </div>
            <div className='text-center w-[269px] '>
              <img
                src={t2}
                className='h-[269px] w-[100%] rounded-[16px]'
                alt=''
              />
              <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                #Lorem ipsum dolor{' '}
              </p>
              <p className='text-primaryGray text-sm12 leading-[15px]'>
                Lorem ipsum dolor sit amet labore adipiscing
                consectetur elit sed do eiusmod
              </p>
            </div>
            <div className='text-center w-[269px] '>
              <img
                src={t3}
                className='h-[269px] w-[100%] rounded-[16px]'
                alt=''
              />
              <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                #Lorem ipsum dolor{' '}
              </p>
              <p className='text-primaryGray text-sm12 leading-[15px]'>
                Lorem ipsum dolor sit amet labore adipiscing
                consectetur elit sed do eiusmod
              </p>
            </div>
            <div className='text-center w-[269px] '>
              <img
                src={t4}
                className='h-[269px] w-[100%] rounded-[16px]'
                alt=''
              />
              <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                #Lorem ipsum dolor{' '}
              </p>
              <p className='text-primaryGray text-sm12 leading-[15px]'>
                Lorem ipsum dolor sit amet labore adipiscing
                consectetur elit sed do eiusmod
              </p>
            </div>
            <div className='text-center w-[269px] '>
              <img
                src={t5}
                className='h-[269px] w-[100%] rounded-[16px]'
                alt=''
              />
              <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                #Lorem ipsum dolor{' '}
              </p>
              <p className='text-primaryGray text-sm12 leading-[15px]'>
                Lorem ipsum dolor sit amet labore adipiscing
                consectetur elit sed do eiusmod
              </p>
            </div>
          </div>
          <button className='blackBtn block mx-auto mt-8'>
            See All templates
          </button>
        </div>
      </div>
      <p className='text-[38px] font-medium text-center text-[#333333] mt-[40px]'>
        other templates you might like{' '}
        {/* {artDetails?.userMaster?.displayName} */}
      </p>
      <div className='w-w1409 flex justify-center mx-auto mb-7 border-b-[1px] pb-[48px] border-[#EFEFEF] '>
        <div>
          <div className='flex gap-[16px] text-center mt-4'>
            <div className='text-center w-[269px] '>
              <img
                src={t6}
                className='h-[269px] w-[100%] rounded-[16px]'
                alt=''
              />
              <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                #Lorem ipsum dolor{' '}
              </p>
              <p className='text-primaryGray text-sm12 leading-[15px]'>
                Lorem ipsum dolor sit amet labore adipiscing
                consectetur elit sed do eiusmod
              </p>
            </div>
            <div className='text-center w-[269px] '>
              <img
                src={t7}
                className='h-[269px] w-[100%] rounded-[16px]'
                alt=''
              />
              <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                #Lorem ipsum dolor{' '}
              </p>
              <p className='text-primaryGray text-sm12 leading-[15px]'>
                Lorem ipsum dolor sit amet labore adipiscing
                consectetur elit sed do eiusmod
              </p>
            </div>
            <div className='text-center w-[269px] '>
              <img
                src={t8}
                className='h-[269px] w-[100%] rounded-[16px]'
                alt=''
              />
              <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                #Lorem ipsum dolor{' '}
              </p>
              <p className='text-primaryGray text-sm12 leading-[15px]'>
                Lorem ipsum dolor sit amet labore adipiscing
                consectetur elit sed do eiusmod
              </p>
            </div>
            <div className='text-center w-[269px] '>
              <img
                src={t9}
                className='h-[269px] w-[100%] rounded-[16px]'
                alt=''
              />
              <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                #Lorem ipsum dolor{' '}
              </p>
              <p className='text-primaryGray text-sm12 leading-[15px]'>
                Lorem ipsum dolor sit amet labore adipiscing
                consectetur elit sed do eiusmod
              </p>
            </div>
            <div className='text-center w-[269px] '>
              <img
                src={t10}
                className='h-[269px] w-[100%] rounded-[16px]'
                alt=''
              />
              <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                #Lorem ipsum dolor{' '}
              </p>
              <p className='text-primaryGray text-sm12 leading-[15px]'>
                Lorem ipsum dolor sit amet labore adipiscing
                consectetur elit sed do eiusmod
              </p>
            </div>
          </div>
          <button className='blackBtn block mx-auto mt-8'>
            See All templates
          </button>
        </div>
      </div>

      <div className='bg-[#F7F7F7] mt-[50px] py-[50px] '>
        <p className='text-[24px] text-primaryBlack font-medium leading-[1] mb-[17px] text-center'>
          Recently Viewed
        </p>
        <div className='flex justify-center gap-[16px] w-w1409 flex-wrap mx-auto text-center'>
          <div className='text-center'>
            <img
              src={R1}
              className='w-[162px] h-[162px] flex justify-center rounded-[16px]'
              alt=''
            />
            <p className='text-primaryBlack font-medium text-sm12 leading-[15px] mt-[4px]'>
              {/* {item.title} */}#Lorem ipsum dolor
            </p>
            <p className='text-primaryGray text-[11px] leading-[14px]'>
              dolor sit amet consectetur <br />
              adipiscing elit
            </p>
          </div>
          <div className='text-center'>
            <img
              src={R2}
              className='w-[162px] h-[162px] flex justify-center rounded-[16px]'
              alt=''
            />
            <p className='text-primaryBlack font-medium text-sm12 leading-[15px] mt-[4px]'>
              {/* {item.title} */}#Lorem ipsum dolor
            </p>
            <p className='text-primaryGray text-[11px] leading-[14px]'>
              dolor sit amet consectetur <br />
              adipiscing elit
            </p>
          </div>
          <div className='text-center'>
            <img
              src={R3}
              className='w-[162px] h-[162px] flex justify-center rounded-[16px]'
              alt=''
            />
            <p className='text-primaryBlack font-medium text-sm12 leading-[15px] mt-[4px]'>
              {/* {item.title} */}#Lorem ipsum dolor
            </p>
            <p className='text-primaryGray text-[11px] leading-[14px]'>
              dolor sit amet consectetur <br />
              adipiscing elit
            </p>
          </div>
          <div className='text-center'>
            <img
              src={R4}
              className='w-[162px] h-[162px] flex justify-center rounded-[16px]'
              alt=''
            />
            <p className='text-primaryBlack font-medium text-sm12 leading-[15px] mt-[4px]'>
              {/* {item.title} */}#Lorem ipsum dolor
            </p>
            <p className='text-primaryGray text-[11px] leading-[14px]'>
              dolor sit amet consectetur <br />
              adipiscing elit
            </p>
          </div>
          <div className='text-center'>
            <img
              src={R5}
              className='w-[162px] h-[162px] flex justify-center rounded-[16px]'
              alt=''
            />
            <p className='text-primaryBlack font-medium text-sm12 leading-[15px] mt-[4px]'>
              {/* {item.title} */}#Lorem ipsum dolor
            </p>
            <p className='text-primaryGray text-[11px] leading-[14px]'>
              dolor sit amet consectetur <br />
              adipiscing elit
            </p>
          </div>

          <div className='text-center'>
            <img
              src={R6}
              className='w-[162px] h-[162px] flex justify-center rounded-[16px]'
              alt=''
            />
            <p className='text-primaryBlack font-medium text-sm12 leading-[15px] mt-[4px]'>
              {/* {item.title} */}#Lorem ipsum dolor
            </p>
            <p className='text-primaryGray text-[11px] leading-[14px]'>
              dolor sit amet consectetur <br />
              adipiscing elit
            </p>
          </div>

          <div className='text-center'>
            <img
              src={R7}
              className='w-[162px] h-[162px] flex justify-center rounded-[16px]'
              alt=''
            />
            <p className='text-primaryBlack font-medium text-sm12 leading-[15px] mt-[4px]'>
              {/* {item.title} */}#Lorem ipsum dolor
            </p>
            <p className='text-primaryGray text-[11px] leading-[14px]'>
              dolor sit amet consectetur <br />
              adipiscing elit
            </p>
          </div>
          <div className='text-center'>
            <img
              src={R8}
              className='w-[162px] h-[162px] flex justify-center rounded-[16px]'
              alt=''
            />
            <p className='text-primaryBlack font-medium text-sm12 leading-[15px] mt-[4px]'>
              {/* {item.title} */}#Lorem ipsum dolor
            </p>
            <p className='text-primaryGray text-[11px] leading-[14px]'>
              dolor sit amet consectetur <br />
              adipiscing elit
            </p>
          </div>
        </div>
      </div>

      <div className='flex justify-center my-[81px]'>
        <img src={grp} alt='' />
      </div>
      <p className='text-[38px] font-medium text-[#333333] text-center  mb-[19px]'>
        Customer Reviews
      </p>
      <div className='w-w1168 mx-auto '>
        <div className='flex justify-center gap-[30px]'>
          <div className='flex flex-col gap-[18px]'>
            {/* <img src={grp144} alt='' /> */}
            <div className='flex gap-[10px]'>
              <p className='text-[38px] text-primaryBlack leading-[0.8] font-medium'>
                4.7
              </p>
              <div className='flex flex-col'>
                <div className='flex gap-[5px]'>
                  <div>
                    <Icon_YellowStar />
                  </div>
                  <div>
                    <Icon_YellowStar />
                  </div>
                  <div>
                    <Icon_YellowStar />
                  </div>
                  <div>
                    <Icon_YellowStar />
                  </div>
                  <div>
                    <Icon_YellowStar />
                  </div>
                </div>
                <p className='text-[12px] text-primaryGray'>
                  126 Reviews
                </p>
              </div>
            </div>
            <div className='flex flex-col'>
              <div className='flex  items-center'>
                <p className='text-[13px] w-[45px] text-primaryGray'>
                  5 Stars
                </p>
                <Slider
                  sx={{
                    '& .MuiSlider-thumb': {
                      display: 'none',
                    },
                    '& .MuiSlider-track': {
                      backgroundColor: '#01e6e6',
                      border: 'none',
                      background: '#01e6e6',
                    },
                    // '& .MuiSlider-track': {
                    //   opacity: '0.5',
                    //   border: 'none',
                    // },
                    height: '12px',
                    width: '520px',
                    borderRadius: '4px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  }}
                  disabled
                  defaultValue={75}
                  aria-label='Default'
                  // valueLabelDisplay='auto'
                />
              </div>
              <div className='flex  items-center'>
                <p className='text-[13px] w-[45px] text-primaryGray'>
                  4 Stars
                </p>
                <Slider
                  sx={{
                    '& .MuiSlider-thumb': {
                      display: 'none',
                    },
                    '& .MuiSlider-track': {
                      backgroundColor: '#01e6e6',
                      border: 'none',
                      background: '#01e6e6',
                    },
                    // '& .MuiSlider-track': {
                    //   opacity: '0.5',
                    //   border: 'none',
                    // },
                    height: '12px',
                    width: '520px',
                    borderRadius: '4px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  }}
                  disabled
                  defaultValue={55}
                  aria-label='Default'
                  // valueLabelDisplay='auto'
                />
              </div>
              <div className='flex  items-center'>
                <p className='text-[13px] w-[45px] text-primaryGray'>
                  3 Stars
                </p>
                <Slider
                  sx={{
                    '& .MuiSlider-thumb': {
                      display: 'none',
                    },
                    '& .MuiSlider-track': {
                      backgroundColor: '#01e6e6',
                      border: 'none',
                      background: '#01e6e6',
                    },
                    // '& .MuiSlider-track': {
                    //   opacity: '0.5',
                    //   border: 'none',
                    // },
                    height: '12px',
                    width: '520px',
                    borderRadius: '4px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  }}
                  disabled
                  defaultValue={45}
                  aria-label='Default'
                  // valueLabelDisplay='auto'
                />
              </div>
              <div className='flex  items-center'>
                <p className='text-[13px] w-[45px] text-primaryGray'>
                  2 Stars
                </p>
                <Slider
                  sx={{
                    '& .MuiSlider-thumb': {
                      display: 'none',
                    },
                    '& .MuiSlider-track': {
                      backgroundColor: '#01e6e6',
                      border: 'none',
                      background: '#01e6e6',
                    },
                    // '& .MuiSlider-track': {
                    //   opacity: '0.5',
                    //   border: 'none',
                    // },
                    height: '12px',
                    width: '520px',
                    borderRadius: '4px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  }}
                  disabled
                  defaultValue={15}
                  aria-label='Default'
                  // valueLabelDisplay='auto'
                />
              </div>
              <div className='flex  items-center'>
                <p className='text-[13px] w-[45px] text-primaryGray'>
                  1 Star
                </p>
                <Slider
                  sx={{
                    '& .MuiSlider-thumb': {
                      display: 'none',
                    },
                    '& .MuiSlider-track': {
                      backgroundColor: '#01e6e6',
                      border: 'none',
                      background: '#01e6e6',
                    },
                    // '& .MuiSlider-track': {
                    //   opacity: '0.5',
                    //   border: 'none',
                    // },
                    height: '12px',
                    width: '520px',
                    borderRadius: '4px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  }}
                  disabled
                  defaultValue={5}
                  aria-label='Default'
                  // valueLabelDisplay='auto'
                />
              </div>
            </div>
          </div>
          <div className='max-w-[572px] w-[100%] text-left'>
            <p className='text-[15px] font-medium'>Customer Images</p>
            <div className='flex gap-[8px]  flex-wrap'>
              <img
                src={Review1}
                className='w-[65px] h-[65px] rounded-[10px]'
                alt=''
              />
              <img
                src={Review2}
                className='w-[65px] h-[65px] rounded-[10px]'
                alt=''
              />
              <img
                src={Review3}
                className='w-[65px] h-[65px] rounded-[10px]'
                alt=''
              />
              <img
                src={Review4}
                className='w-[65px] h-[65px] rounded-[10px]'
                alt=''
              />
              <img
                src={Review5}
                className='w-[65px] h-[65px] rounded-[10px]'
                alt=''
              />
              <img
                src={Review6}
                className='w-[65px] h-[65px] rounded-[10px]'
                alt=''
              />
              <img
                src={Review7}
                className='w-[65px] h-[65px] rounded-[10px]'
                alt=''
              />
              <img
                src={Review8}
                className='w-[65px] h-[65px] rounded-[10px]'
                alt=''
              />
              <img
                src={Review9}
                className='w-[65px] h-[65px] rounded-[10px]'
                alt=''
              />
              <img
                src={Review10}
                className='w-[65px] h-[65px] rounded-[10px]'
                alt=''
              />
            </div>
            <button className='text-[#F88331] text-[12px]'>
              See all customer images
            </button>
          </div>
        </div>
      </div>
      <div className='w-w1168 mx-auto mb-[16px] mt-[50px] flex justify-between'>
        <div className='flex gap-[12px] '>
          <StyledPopupReview
            trigger={
              // <button className='blackBtn'>Write a Review</button>
              <button className='mx-auto text-[#ffffff] hover:bg-[black] text-sm12 flex items-center font-medium bg-primaryBlack h-[28px] px-4 rounded-2xl cursor-pointer'>
                Write a Review
              </button>
            }
            modal
          >
            {(close) => (
              <div>
                <div className='text-center'>
                  <p className='text-primaryBlack leading-[1] font-medium text-[38px]'>
                    Your Opinion Matters!
                  </p>
                  <p className='text-pinkColor font-normal text-[16px] mt-[13px]'>
                    Hey Azra Shaikh!
                  </p>
                  <p className='text-primaryBlack font-normal text-[12px]'>
                    Take a minute to rate and review this product.
                    Your feedback will help others <br /> make smart
                    decisions.
                  </p>
                </div>
                <div className='flex gap-[15px] pb-[20px] border-b border-[#e7e7e7]'>
                  <div className='w-[210px] h-[210px] rounded-[16px] bg-[#f7f7f7] flex justify-center items-center'>
                    <div
                      style={{
                        backgroundImage: `url(${Layer1})`,
                      }}
                      className='w-[180px] h-[180px] bg-cover bg-center'
                    ></div>
                    {/* <img
                      src={artDetails?.image}
                      alt=''
                    /> */}
                  </div>
                  <div>
                    <p className='font-medium text-[16px] leading-[1.2]'>
                      {/* {artDetails?.artName} */}
                      Printable Wall Art Home Decor Artnstock Limited
                      Edition
                    </p>
                    <p className='text-[11px]'>
                      by{' '}
                      <span className='text-orangeColor font-medium'>
                        {' '}
                        {/* {artDetails?.userMaster?.displayName} */}
                        Azra Creation
                      </span>
                    </p>
                    <p className='mt-[3px] text-[11px] font-medium text-primaryGray border-t border-t-[#efefef] border-t-[2px] border-b border-b-[#efefef] border-b-[2px]'>
                      Combo ID: ANS2401067
                    </p>
                    <p className='text-[15px] text-primaryBlack font-medium mt-[7px]'>
                      Description
                    </p>
                    <p className='text-[12px] text-primaryGray leading-[1]'>
                      {/* {artDetails?.description} */}
                      By attempting to depict items as they actually
                      look in real life, art can imitate nature. But
                      abstract artwork can also draw inspiration from
                      real-world natural shapes, as seen in the image
                      below. This work was inspired by the study,
                      observation, and meditation on natural events
                      and forms. This art poster features clear,
                      colourful pictures with excellent colour
                      fidelity. This excellent reproduction, a part of
                      the adaptable family of art prints, embodies the
                      best of both worlds in terms of both quality and
                      price.
                    </p>
                  </div>
                </div>
                <div className='text-center mt-[17px]'>
                  <p className='text-[16px] font-medium mb-[8px]'>
                    Rate this Product
                  </p>
                  <Rating
                    name='size-large'
                    defaultValue={3}
                    size='large'
                    // onChange={handleRatingChange}
                    sx={{
                      '& .MuiSvgIcon-fontSizeInherit': {
                        color: '#e6b900', // Change the border color of empty stars to red
                      },
                    }}
                  />
                </div>
                <div>
                  <p className='text-[12px] text-primaryGray pt-[10px]'>
                    Review Title
                  </p>
                  <input
                    type='text'
                    placeholder='What’s most important to know?'
                    className='w-[100%] h-[40px] rounded-[20px] border-[1px] border-[#d6d6d6] pl-[15px] outline-none'
                    name='reviewTitle'
                    // onChange={postReviewAction}
                  />
                </div>
                <div>
                  <p className='text-[12px] text-primaryGray pt-[10px]'>
                    Add a Photo or Video
                  </p>

                  <div className='flex flex-wrap gap-[9px]'>
                    <div
                      onClick={handleButtonClick}
                      className='w-[83px] h-[83px] bg-[#f7f7f7] rounded-[10px] flex justify-center items-center border border-[#d6d6d6] border-dashed'
                    >
                      <input
                        type='file'
                        multiple
                        // onChange={handleFileChange}
                        accept='image/*'
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                      />
                      <img
                        className='h-[25px] w-[25px]'
                        src={addsmall}
                      />
                    </div>

                    {/* <div className='flex gap-[5px]'>
                                            {postReviewData?.reviewImage?.map((obj) => (
                                                <div
                                                    style={{
                                                        backgroundImage: `url(${obj})`,
                                                    }}
                                                    className='w-[83px] h-[83px] rounded-[10px] bg-cover bg-center'
                                                ></div>
                                            ))}
                                        </div> */}
                  </div>
                </div>
                <div className='pt-[7px]'>
                  <p className='text-[12px] text-primaryGray'>
                    Write a Review
                  </p>
                  <div className=''>
                    <textarea
                      name='reviewMsg'
                      // onChange={postReviewAction}
                      type='text'
                      className='border border-[#d6d6d6] h-[112px] w-[455px] rounded-[16px] pb-[70px] pl-[10px] outline-none'
                    />
                    <p className='text-[11px] text-primaryGray text-right'>
                      123/250 words
                    </p>
                  </div>

                  <div className='flex gap-[0.938rem] mt-[15px] justify-center'>
                    <button
                      // onClick={postReview}
                      // onClick={postRelease}
                      className='blackBtn h-[40px]'
                    >
                      Submit Review
                    </button>
                    <button
                      onClick={close}
                      className='h-[40px] px-6 py-2 rounded-3xl text-sm14 text-primaryBlack border-[0.125rem]'
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </StyledPopupReview>
          <button
            // onClick={() =>
            //   setreviewLength(custReview?.length)
            // }
            className='mx-auto text-[#333333] border border-[#333333] hover:bg-[#ffffff] text-sm12 flex items-center font-medium bg-[#FFFFFF] h-[28px] px-4 rounded-2xl cursor-pointer'
          >
            See All Reviews
          </button>
        </div>

        <div className='flex gap-[10px] items-center'>
          <div>
            <button
              onClick={() => {
                setIsOpenSortBy(!isOpenSortBy);
              }}
              className={`${
                isOpenSortBy === true
                  ? 'rounded-t-[15px] shadow-dropShadowButton border-b border-[#EFEFEF]'
                  : 'rounded-[15px] border  border-[#D6D6D6]'
              } cursor-pointer w-[120px] text-[12px] h-[28px] flex items-center justify-between p-[10px]`}
              // className='cursor-pointer w-[120px] border border-[#D6D6D6] text-[12px] h-[30px] rounded-[15px] flex items-center justify-between p-[10px]'
            >
              <span className='text-[#BBBBBB]'>Sort by</span>
              <img className='inline-block' src={dropdown} alt='' />
            </button>
            {isOpenSortBy && (
              <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#FFFFFF] w-[120px] pl-[10px] text-left text-[12px] text-primaryGray'>
                <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                  Most Relevant
                </li>
                <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                  Most Helpful
                </li>
                <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                  Newest
                </li>
                <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                  Highest Rating
                </li>
                <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                  Lowest Rating
                </li>
              </ul>
            )}
          </div>
          <div>
            <button
              onClick={() => {
                setIsOpenFilterBy(!isOpenFilterBy);
              }}
              className={`${
                isOpenFilterBy === true
                  ? 'rounded-t-[15px] shadow-dropShadowButton border-b border-[#EFEFEF]'
                  : 'rounded-[15px] border  border-[#D6D6D6]'
              } cursor-pointer w-[120px] text-[12px] h-[28px] flex items-center justify-between p-[10px]`}
              // className='cursor-pointer w-[120px] border border-[#D6D6D6] text-[12px] h-[30px] rounded-[15px] flex items-center justify-between p-[10px]'
            >
              <span className='text-[#BBBBBB]'>Filter by</span>
              <img className='inline-block' src={dropdown} alt='' />
            </button>
            {isOpenFilterBy && (
              <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#FFFFFF] w-[120px] pl-[10px] text-left text-[12px] text-primaryGray'>
                <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                  5 Star Reviews
                </li>
                <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                  4 Star Reviews
                </li>
                <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                  3 Star Reviews
                </li>
                <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                  2 Star Reviews
                </li>
                <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                  1 Star Reviews
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      <div
        className='w-w1168 flex flex-col gap-[32px] rounded-[32px] py-[32px] mx-auto pl-4 shadow-regCardShadow text-left'
        // style={{
        //   boxShadow: '#f0f0f0 0px 0px 4.3px 4px',
        // }}
      >
        <div>
          <div className='flex  gap-3'>
            <div>
              <div
                className='w-[40px] h-[40px] bg-center bg-cover rounded-[50%]'
                style={{
                  backgroundImage: `url(${ksgrafiks})`,
                }}
              ></div>
            </div>
            <div>
              <p className='text-[15px] font-medium text-[#333333] leading-[1.2]'>
                {/* {item?.userMaster?.displayName} */}ksgrafiks
              </p>
              <p className='text-[12px] mt-1 mb-[8px] leading-[1.1] text-[#757575]'>
                <span className='font-medium text-[#FF7722]'>
                  {' '}
                  Abstract
                </span>{' '}
                &nbsp;&nbsp;&nbsp; 5 reviews .1 photo
              </p>
              <div className='text-[12px] leading-[1.2] flex gap-3 text-[#757575] flex items-center'>
                <div className='flex gap-[2px]'>
                  <div>
                    <Icon_YellowStar />
                  </div>
                  <div>
                    <Icon_YellowStar />
                  </div>{' '}
                  <div>
                    <Icon_YellowStar />
                  </div>
                  <div>
                    <Icon_YellowStar />
                  </div>{' '}
                  <div>
                    <Icon_YellowStar />
                  </div>
                  <p className='leading-[1] ml-[10px]'>
                    {/* {daysAgoFunction(item.date)} ago */}4 months
                    ago
                  </p>
                </div>
              </div>

              <p className='text-[13px] mt-[8px] mb-[16px] leading-[16px] text-[#757575] w-[889px] h-[47px]'>
                {/* {item.reviewMsg} */}
                Very eye pleasing wall sculpture. Looks best in a
                bright room as the variation is colored glass - leaves
                do not show in dimly lit area.
                <br />
                The leaves of colored glass add a very nice dimension
                as compared to all metal tree-form wall sculptures.
                Made well with iron frame, one stone was off in the
                package but able to be easily glued. I am very pleased
                with the purchase.
              </p>
              <div className='text-[13px] mb-[8px] leading-[1.2] text-[#757575] flex items-center'>
                {/* <ThumbUp
                                //   id={item?.customerReviewId}
                                //   sendDataToParent={
                                //     receiveReviewDataFromChild
                                //   }
                                //   triggerReviewLike={triggerReviewLike}
                                /> */}
                <img src={like} />
                <p className='pl-[6px]'>
                  {' '}
                  24
                  {/* {item.likeCount} */}
                </p>
              </div>
              <div className='border-l-2 text-[12px] leading-[1.2] pl-3 text-[#757575] border-[#dcdcdc]'>
                <span className='text-[#333333] text-[13px] font-medium'>
                  Response from the owner
                </span>
                &nbsp;&nbsp;&nbsp;7 months ago
                <p className='text-[13px] leading-[1.2]'>
                  {/* {reply.adminReviewReply} */}
                  Thanks Azra - Glad we could help!.
                </p>
                {/* {daysAgoFunction(reply.date)} ago */}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className='flex  gap-3'>
            <div>
              <div
                className='w-[40px] h-[40px] bg-center bg-cover rounded-[50%]'
                style={{
                  backgroundImage: `url(${AzraCreation})`,
                }}
              ></div>
            </div>
            <div>
              <p className='text-[15px] font-medium text-[#333333] leading-[1.2]'>
                {/* {item?.userMaster?.displayName} */}Azra Creation
              </p>
              <p className='text-[12px] mt-1 mb-[8px] leading-[1.1] text-[#757575]'>
                <span className='font-medium text-[#FF7722]'>
                  {' '}
                  Creative
                </span>{' '}
                &nbsp;&nbsp;&nbsp; 5 reviews .1 photo
              </p>
              <div className='text-[12px] leading-[1.2] flex gap-3 text-[#757575] flex items-center'>
                <div className='flex gap-[2px]'>
                  <div>
                    <Icon_YellowStar />
                  </div>
                  <div>
                    <Icon_YellowStar />
                  </div>{' '}
                  <div>
                    <Icon_YellowStar />
                  </div>
                  <div>
                    <Icon_YellowStar />
                  </div>{' '}
                  <div>
                    <Icon_YellowStar />
                  </div>
                  <p className='leading-[1] ml-[10px]'>
                    {/* {daysAgoFunction(item.date)} ago */}4 months
                    ago
                  </p>
                </div>
              </div>
              <p className='text-[13px] mt-[8px] mb-[16px] leading-[17px] text-[#757575]  w-[889px]'>
                {/* {item.reviewMsg} */}
                Very eye pleasing wall sculpture. Looks best in a
                bright room as the variation is colored glass - leaves
                do not show in dimly lit area.
                <br />
                The leaves of colored glass add a very nice dimension
                as compared to all metal tree-form wall sculptures.
                Made well with iron frame, one stone was off in the
                package but able to be easily glued. I am very pleased
                with the purchase.
              </p>
              <div className='text-[13px] mb-[8px] leading-[1.2] text-[#757575] flex items-center'>
                {/* <ThumbUp
                                //   id={item?.customerReviewId}
                                //   sendDataToParent={
                                //     receiveReviewDataFromChild
                                //   }
                                //   triggerReviewLike={triggerReviewLike}
                                /> */}
                <img src={like} />
                <p className='pl-[6px]'>
                  {' '}
                  24
                  {/* {item.likeCount} */}
                </p>
              </div>
              <div className='border-l-2 text-[12px] leading-[1.2] pl-3 text-[#757575] border-[#dcdcdc]'>
                <span className='text-[#333333] text-[13px] font-medium'>
                  Response from the owner
                </span>
                &nbsp;&nbsp;&nbsp;7 months ago
                <p className='text-[13px] leading-[1.2]'>
                  {/* {reply.adminReviewReply} */}
                  Thanks Azra - Glad we could help!.
                </p>
                {/* {daysAgoFunction(reply.date)} ago */}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className='flex  gap-3'>
            <div>
              <div
                className='w-[40px] h-[40px] bg-center bg-cover rounded-[50%]'
                style={{
                  backgroundImage: `url(${Wallpaper})`,
                }}
              ></div>
            </div>
            <div>
              <p className='text-[15px] font-medium text-[#333333] leading-[1.2]'>
                {/* {item?.userMaster?.displayName} */}Wallpaper
              </p>
              <p className='text-[12px] mt-1 mb-[8px] leading-[1.1] text-[#757575]'>
                <span className='font-medium text-[#FF7722]'>
                  {' '}
                  Artistic{' '}
                </span>{' '}
                &nbsp;&nbsp;&nbsp; 5 reviews .1 photo
              </p>
              <div className='text-[12px] leading-[1.2] flex gap-3 text-[#757575] flex items-center'>
                <div className='flex gap-[2px]'>
                  <div>
                    <Icon_YellowStar />
                  </div>
                  <div>
                    <Icon_YellowStar />
                  </div>{' '}
                  <div>
                    <Icon_YellowStar />
                  </div>
                  <div>
                    <Icon_YellowStar />
                  </div>{' '}
                  <div>
                    <Icon_YellowStar />
                  </div>
                  <p className='leading-[1] ml-[10px]'>
                    {/* {daysAgoFunction(item.date)} ago */}4 months
                    ago
                  </p>
                </div>
              </div>
              <p className='text-[13px] mt-[8px] mb-[16px] leading-[17px] text-[#757575]  w-[889px]'>
                {/* {item.reviewMsg} */}
                Very eye pleasing wall sculpture. Looks best in a
                bright room as the variation is colored glass - leaves
                do not show in dimly lit area.
                <br />
                The leaves of colored glass add a very nice dimension
                as compared to all metal tree-form wall sculptures.
                Made well with iron frame, one stone was off in the
                package but able to be easily glued. I am very pleased
                with the purchase.
              </p>
              <div className='text-[13px] mb-[8px] leading-[1.2] text-[#757575] flex items-center'>
                {/* <ThumbUp
                                //   id={item?.customerReviewId}
                                //   sendDataToParent={
                                //     receiveReviewDataFromChild
                                //   }
                                //   triggerReviewLike={triggerReviewLike}
                                /> */}
                <img src={like} />
                <p className='pl-[6px]'>
                  {' '}
                  24
                  {/* {item.likeCount} */}
                </p>
              </div>
              <div className='border-l-2 text-[12px] leading-[1.2] pl-3 text-[#757575] border-[#dcdcdc]'>
                <span className='text-[#333333] text-[13px] font-medium'>
                  Response from the owner
                </span>
                &nbsp;&nbsp;&nbsp;7 months ago
                <p className='text-[13px] leading-[1.2]'>
                  {/* {reply.adminReviewReply} */}
                  Thanks Azra - Glad we could help!.
                </p>
                {/* {daysAgoFunction(reply.date)} ago */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <p className='w-w1168 mx-auto flex justify-between text-[#F88331] text-[12px] mt-2 mb-[96px]'>
        <span>Disclosures & Guidlines</span>
        <span>See all 126 reviews</span>
      </p>
    </>
  );
};

export default SecondComp;
