import { useState, useEffect } from 'react';
import pro from '../../assets/images/accountSettings/pro.png';
import payment from '../../assets/images/accountSettings/payment.png';
import { ReactComponent as ProIcon } from '../../assets/images/art-details/Icon_Pro.svg';
// import { ReactComponent as Level3 } from '../../assets/images/contributor/activateProd/Level3.svg';

import { ReactComponent as Paypal } from '../../assets/images/footer/Paypal.svg';
import { ReactComponent as Visa } from '../../assets/images/footer/Visa.svg';
import { ReactComponent as Mastercard } from '../../assets/images/footer/Mastercard.svg';
import { ReactComponent as AmerExpress } from '../../assets/images/footer/AmerExpress.svg';
import { ReactComponent as Discover } from '../../assets/images/footer/Discover.svg';

import { httpClient } from '../../axios';
// import { ReactComponent as ProIcon } from '../../assets/images/art-details/Icon_Pro.svg';
// import { ReactComponent as Level3 } from '../../assets/images/contributor/activateProd/Level3.svg';
import { ReactComponent as Level1 } from '../../assets/images/contributor/activateProd/Level1.svg';
import { ReactComponent as Level2 } from '../../assets/images/contributor/activateProd/Level2.svg';
import { ReactComponent as Level3 } from '../../assets/images/contributor/activateProd/Level3.svg';
import { ReactComponent as Level4 } from '../../assets/images/contributor/activateProd/Level4.svg';
import { ReactComponent as Level5 } from '../../assets/images/contributor/activateProd/Level5.svg';
import { useSelector } from 'react-redux';

import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';

import lock from '../../assets/images/ShoppingCart/lock.png';

import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import Popup from 'reactjs-popup';

const StyledPopupEditPay = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    // text-align: center;
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 20px;
    max-width: 488px;
    width: 100%;
    overflow: hidden;
    border: none;
  }
`;

const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
  ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 20px;
    width: 456px;
    // height: 339px;
    overflow: hidden;
    border: none;
  }
`;

const StyledPopupAddCard = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 20px;
    padding-bottom: 30px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 20px;
    width: 490px;
  }
`;

const Contributor_PaymentInfo = () => {
  const [SelectedTab, setSelectedTab] = useState('Card 1');

  const [selectCity, setselectCity] = useState(false);
  const [selectCountry, setselectCountry] = useState(false);
  const [selectState, setselectState] = useState(false);

  const [isPOpupOpen, setIsPOpupOpen] = useState(true);

  const closeModal = () => {
    setIsPOpupOpen(false);
  };

  useEffect(() => {
    console.log('.........');
    setIsPOpupOpen(true);
  }, []);

  console.log('isPopupOpen:', isPOpupOpen);

  const [userDetail, setUserDetail] = useState({});

  const userId = useSelector((state) => state.auth.userId);

  const navigate = useNavigate();

  const getUserRecord = async () => {
    try {
      const res = await httpClient.get(
        `/user_master/getUserRecord/${userId}`
      );
      setUserDetail(res.data);
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getUserRecord();
  }, []);

  const [editPayPopup, setEditPayPopup] = useState(false);

  return (
    <>
      <StyledPopupEditPay
        open={editPayPopup}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setEditPayPopup(false);
          document.body.classList.remove('body-no-scroll');
        }}
      >
        <p className='text24 text-center'>Edit Payment Details</p>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            className='InputField44H'
            placeholder='Enter Name*'
            name='Name'
            // value={addAdress?.shippingAddress?.addressLine1 || ''}
            // onChange={handleInputChangeAddAddressNested}
          />

          <p className='InputFieldTopText44H'>Name*</p>
        </div>
        <div className='relative  mt-[16px]'>
          <input
            type='text'
            className='InputField44H'
            placeholder='Enter Payment Method'
            name='PaymentMethod'
            // value={addAdress?.shippingAddress?.addressLine1 || ''}
            // onChange={handleInputChangeAddAddressNested}
          />

          <p className='InputFieldTopText44H'>Payment Method*</p>
        </div>
        <div className='relative  mt-[16px]'>
          <input
            type='text'
            className='InputField44H'
            placeholder='Enter Payout Email'
            name='PayoutEmail'
            // value={addAdress?.shippingAddress?.addressLine1 || ''}
            // onChange={handleInputChangeAddAddressNested}
          />

          <p className='InputFieldTopText44H'>Payout Email*</p>
        </div>
        <div className='relative  mt-[16px]'>
          <input
            type='text'
            className='InputField44H'
            placeholder='Enter Minimum Payout'
            name='PayoutEmail'
            // value={addAdress?.shippingAddress?.addressLine1 || ''}
            // onChange={handleInputChangeAddAddressNested}
          />

          <p className='InputFieldTopText44H'>Minimum Payout*</p>
        </div>

        <div className='flex gap-[8px] justify-center w-[100%] mt-[16px]'>
          <button
            onClick={() => {
              // document.body.classList.add('body-no-scroll');
              // CreateShippingAddress();
            }}
            className='black40HButton'
          >
            Save
          </button>
          <button
            onClick={() => {
              // setAddAddressPopup(false);
            }}
            className='outlineBtn40h'
          >
            Cancel
          </button>
        </div>
      </StyledPopupEditPay>

      <div className='flex justify-between'>
        {/* <StyledPopup open={isPOpupOpen} onClose={closeModal} modal>
          <div className=''>
            <p className='text24 text-center'>Payout Name Change</p>
            <p className='text-sm12 text-primaryGray font-medium mt-[16px]'>
              For security reasons you must contact us to make a name
              change.
            </p>
            <p className='text-sm12 mt-[16px] text-primaryGray font-medium'>
              For the security of your account we do not allow
              contributors to update the name associated with the
              account or the name to which payments are made on their
              own.
            </p>
            <p className='text-sm12 mt-[16px] text-primaryGray font-medium'>
              To update this information please contact us by emailing
              <span className='text-sm12 text-[orange]  font-medium'>
                {' '}
                submit@artnstock.com{' '}
              </span>
            </p>
          </div>
        </StyledPopup> */}

        <p>
          <p className='text-[24px] leading-[1] mb-[3px] font-medium text-primaryBlack'>
            Payment Information
          </p>
          <p className='text-sm11 text-primaryGray'>
            Add and manage your preferred payment <br /> methods for
            easy transactions.
          </p>
        </p>
        <div>
          <div className=' flex items-center '>
            <div className='flex flex-col gap-[2px] mt-[2px]'>
              {/* <ProIcon /> */}

              {userDetail?.level === '1' ? (
                <div>
                  <Level1 />
                </div>
              ) : userDetail?.level === '2' ? (
                <div>
                  <Level2 />
                </div>
              ) : userDetail?.level === '3' ? (
                <div>
                  <Level3 />
                </div>
              ) : userDetail?.level === '4' ? (
                <div>
                  <Level4 />
                </div>
              ) : userDetail?.level === '5' ? (
                <div>
                  <Level5 />
                </div>
              ) : (
                <div>
                  <Level1 />
                </div>
              )}
              <div>
                <p className='text-[11px] mt-[3px] font-semibold leading-[1] text-[#bbbbbb]'>
                  {/* {userDetail?.joinDate} */}
                  JOINED
                </p>
                <p
                  style={{
                    // fontSize: '72px',
                    // background: '-webkit-linear-gradient(#eee, #333)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                  className='text-[16px] font-semibold gradient-background3 leading-[1]'
                >
                  {userDetail?.joinDate}
                </p>
              </div>

              {/* className='text-[11px] leading-[1] text-primaryGray' */}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: 'fit-content',
        }}
        className='text-center mt-[32px] mx-[auto]'
      >
        <p className='text-pinkColor text-[16px] leading-[20px]'>
          Save your Debit/Credit card <br />
          during Payment.
        </p>
        <p className='text-primaryGray text-sm12  mt-[8px]'>
          Payment methods you add will appear here. <br />
          Your card information will be secure.
        </p>
      </div>

      <p className='text-[#bbbbbb] text-center font-medium text-[11px] mt-[16px] mb-[2px]'>
        We accept
      </p>
      <div
        style={{
          width: 'fit-content',
        }}
        className='flex gap-[4px] items-center mx-[auto]'
      >
        <div>
          <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
        </div>

        <div>
          <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
        </div>

        <div>
          <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
        </div>

        <div>
          <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
        </div>
        <div>
          <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
        </div>
      </div>

      {/* <style>
        {`
          @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
            :root {
              --chrome-position: relative;
              --chrome-bottom: 6px;
            }
          }
        `}
      </style> */}

      <p className='text-[16px] font-medium leading-[1] text-primaryBlack mb-[16px] mt-[32px]'>
        Saved Card(s)
      </p>

      <div className='flex justify-between'>
        <div className='flex'>
          <input
            style={{
              height: 'fit-content',
            }}
            // style={{
            //   position: 'var(--chrome-position, static)',
            //   bottom: 'var(--chrome-bottom, 0)',
            // }}
            type='radio'
            // onClick={() => setPaymentCheck('card')}
            // checked={paymentCheck === 'card'}
            name='payment'
            className='mr-[7px] '
            id=''
          />{' '}
          <div className='flex flex-col'>
            <div className='flex gap-[8px]'>
              <span className='text-sm12 text-primaryGray'>
                HDFC Bank Credit Card ending in 2139
              </span>
              <span className='text-sm11 text-pinkColor'>
                Default Purchase Preference
              </span>
            </div>
            <span className='text-sm11 text-primaryGray'>
              Name on the Card:{' '}
              <span className='text-sm11 text-primaryBlack font-medium'>
                Arshaan Shaikh
              </span>
            </span>
          </div>
        </div>

        <div>
          <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
        </div>
      </div>
      <div className='flex justify-between mt-[16px]'>
        <div className='flex'>
          <input
            type='radio'
            style={{
              height: 'fit-content',
            }}
            // onClick={() => setPaymentCheck('card')}
            // checked={paymentCheck === 'card'}
            name='payment'
            className='mr-[7px] '
            id=''
          />{' '}
          <div className='flex flex-col'>
            <div className='flex gap-[8px]'>
              <span className='text-sm12 text-primaryGray'>
                HDFC Bank Credit Card ending in 2139
              </span>
              {/* <span className='text-sm11 text-pinkColor'>
                Default Purchase Preference
              </span> */}
            </div>
            <span className='text-sm11 text-primaryGray'>
              Name on the Card:{' '}
              <span className='text-sm11 text-primaryBlack font-medium'>
                Arshaan Shaikh
              </span>
            </span>
          </div>
        </div>

        <div>
          <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
        </div>
      </div>

      {/* <div className='mt-[10px]'>
        <label
          style={{
            width: 'fit-content',
          }}
          className='containerCheckGray text-[12px] text-primaryGray'
        >
          <input
            // onChange={handleCheckboxChange}
            type='checkbox'
            checked={true}
          />{' '}
          <span className='relative top-[1px]'>
            Default purchase preference
          </span>
          <span className='checkmarkGray'></span>
        </label>
      </div> */}

      <button className='gray30HButton mt-[16px]'>Delete Card</button>

      <p className='text-[#bbbbbb] font-medium text-[11px] mt-[16px] mb-[2px]'>
        We accept
      </p>
      <div
        style={{
          width: 'fit-content',
        }}
        className='flex gap-[4px] items-center'
      >
        <div>
          <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
        </div>

        <div>
          <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
        </div>

        <div>
          <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
        </div>

        <div>
          <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
        </div>
        <div>
          <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
        </div>
      </div>

      <div className='h-[2px] bg-[#efefef] w-[100%] mb-[27px] mt-[25px]'></div>

      <p className='text-[16px] font-medium leading-[1] text-primaryBlack mb-[16px] mt-[32px]'>
        Payment Details for Earnings
      </p>

      <div className='flex gap-[16px]'>
        <div className=' flex flex-col gap-[6px]'>
          <div className='flex gap-[16px]'>
            <p className='min-w-[112px] text-[13px] leading-[1] font-medium text-primaryGray'>
              Make Payments to:
            </p>

            <p
              style={{
                whiteSpace: 'nowrap',
                minWidth: 'max-content',
              }}
              className='text-[13px] leading-[1]  text-primaryGray'
            >
              Arshaan Shaikh
              {/* {userDetailCopy?.contributorTypeMaster
                  ?.contributorTypeName || (
                  <span className='text-pinkColor'>Not Provided</span>
                )} */}
            </p>
          </div>

          <div className='flex gap-[16px]'>
            <p className='min-w-[112px] text-[13px] leading-[1] font-medium text-primaryGray'>
              Payment Methods:
            </p>
            <p
              style={{
                whiteSpace: 'nowrap',
                minWidth: 'max-content',
              }}
              className='text-[13px] leading-[1]  text-primaryGray'
            >
              PayPal
              {/* {userDetailCopy?.userSpecialization || (
                  <span className='text-pinkColor'>Not Provided</span>
                )} */}
            </p>
          </div>

          <div className='flex gap-[16px]'>
            <p className='min-w-[112px] text-[13px] leading-[1] font-medium text-primaryGray'>
              Payout Email:
            </p>
            <p
              style={{
                whiteSpace: 'nowrap',
                minWidth: 'max-content',
              }}
              className='text-[13px] leading-[1]  text-primaryGray'
            >
              ksgrafiks2012@gmail.com
              {/* {userDetailCopy?.styles || (
                  <span className='text-pinkColor'>Not Provided</span>
                )} */}
            </p>
          </div>

          <div className='flex gap-[16px]'>
            <p className='min-w-[112px] text-[13px] leading-[1] font-medium text-primaryGray'>
              Minimum Payout:
            </p>
            <p
              style={{
                whiteSpace: 'nowrap',
                minWidth: 'max-content',
              }}
              className='text-[13px] leading-[1]  text-primaryGray'
            >
              $50
              {/* {userDetailCopy?.subjects || (
                  <span className='text-pinkColor'>Not Provided</span>
                )} */}
            </p>
          </div>
        </div>
      </div>

      <button
        onClick={() => setEditPayPopup(true)}
        className='gray30HButton mt-[16px]'
      >
        Edit Payment Details
      </button>

      <div className='mt-[16px]  mb-[128px]'>
        <p className='text-sm11 text-primaryGray font-medium'>
          Payout Name Change
        </p>
        <p className='text-sm11 text-primaryGray  '>
          For security reasons you must contact us to make a name
          change.
        </p>
        <p className='text-sm11  text-primaryGray '>
          For the security of your account we do not allow
          contributors to update the name associated with the account
          or the name to which payments are made on their own. To
          update this information please contact us by emailing
          <a
            href='mailto:submit@artnstock.com'
            className='text-sm11 cursor-pointer text-[orange] no-underline custom-link'
            // className=""
          >
            {' '}
            submit@artnstock.com{' '}
          </a>
          .
        </p>
        {/* <p className='text-text-sm11  text-primaryGray font-medium'>
          
        </p> */}
      </div>

      {/* <div>
        <p className='mt-[32px] text-[11px] text-[#bbbbbb] leading-[1] mb-[5px]'>
          Payment Method (Credit/Debit Cards)
        </p>
        <div className='w-full h-[236px]  rounded-2xl flex justify-center items-center text-center rounded-gradient-border'>
          <div>
            <p className='text-pinkColor text-[16px] leading-[1.2] mb-[2px]'>
              No Payment Methods on File
            </p>
            <p className='text-sm11 text-primaryGray'>
              Payment methods you add will appear here.
            </p>
            <div className='flex justify-center'>
              <StyledPopupAddCard
                trigger={
                  <button className='greenBlueButton mt-[14px] mb-[16px]'>
                    Add Card
                  </button>
                }
                modal
              >
                {(close) => (
                  <div
                    style={{
                     
                      overflowY: 'auto',
                    }}
                    className=' w-[100%] px-[20px]'
                  >
                    <p className='text-[24px] font-medium leading-[29px] text-primaryBlack mb-[15px] text-center'>
                      Add Payment Method
                    </p>

                    <div
                      style={{
                        width: 'fit-content',
                      }}
                      className='flex gap-[4px] mb-[8px] mx-[auto]'
                    >
                      <div
                        onClick={() => {
                          setSelectedTab('Card 1');
                        }}
                        className={`${
                          SelectedTab === 'Card 1'
                            ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                            : 'border-[#eaeaea] text-primaryGray font-medium'
                        }  border rounded-[14px] cursor-pointer h-[28px]  hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                      >
                        Card 1
                      </div>
                      <div
                        onClick={() => {
                          setSelectedTab('Card 2');
                        }}
                        className={`${
                          SelectedTab === 'Card 2'
                            ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                            : 'border-[#eaeaea] text-primaryGray font-medium'
                        }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                      >
                        Card 2
                      </div>
                      <div
                        onClick={() => {
                          setSelectedTab('Card 3');
                        }}
                        className={`${
                          SelectedTab === 'Card 3'
                            ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                            : 'border-[#eaeaea] text-primaryGray  font-medium'
                        }  border rounded-[14px] h-[28px] cursor-pointer  hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px]`}
                      >
                        Card 3
                      </div>
                    </div>

                    {SelectedTab === 'Card 1' ? (
                      <div>
                        <label
                          style={{
                            width: 'fit-content',
                          }}
                          className='containerCheckGray text-[11px] text-primaryGray'
                        >
                          <input
                            type='checkbox'
                          
                          />{' '}
                          <span className='leading-[1] relative top-[2px]'>
                            My shipping and residential address are
                            the same.
                          </span>
                          <span className='checkmarkGray'></span>
                        </label>
                        <div className='relative mt-[8px]'>
                          <input
                            type='text'
                            placeholder='Enter Your Name'
                            className='InputField44H'
                           
                          />

                          <p className='InputFieldTopText44H'>
                            Your Name on the Card
                          </p>
                        </div>

                        <div className='flex gap-[10px] w-[100%] mt-[13px]'>
                         
                          <div className='relative w-[50%]'>
                            <input
                              type='text'
                             
                              className='InputField44H'
                              placeholder='1234 5678 9876 5432'
                              name='zipCode'
                            />

                            <p className='InputFieldTopText44H'>
                              Credit/Debit Card Number
                            </p>
                          </div>

                          <div
                            style={{
                              width: 'fit-content',
                            }}
                            className='flex gap-[4px] items-center'
                          >
                            <div>
                              <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                            </div>

                            <div>
                              <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                            </div>

                            <div>
                              <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                            </div>

                            <div>
                              <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                            </div>
                            <div>
                              <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                            </div>
                          </div>
                        </div>
                        <div className='flex gap-[10px] w-[100%] mt-[13px]'>
                         
                          <div className='relative w-[50%]'>
                            <input
                              type='text'
                             
                              className='InputField44H'
                              placeholder='12/21'
                              name='zipCode'
                            />

                            <p className='InputFieldTopText44H'>
                              Month and Year
                            </p>
                          </div>

                          <div className='relative w-[50%]'>
                            <input
                              type='text'
                             
                              className='InputField44H'
                              placeholder='123'
                              name='zipCode'
                            />

                            <p className='InputFieldTopText44H'>
                              CVC
                            </p>
                           
                          </div>
                        </div>

                       

                        <p className='text-sm11 text-center text-primaryGray mt-[8px]'>
                          Artnstock will make sure your credit card is
                          valid by authorizing it for a small amount.{' '}
                          <br />
                          This is NOT a charge, but rather an
                          authorization that allows us to verify that
                          your credit card is active. The information
                          you enter will apply to all active
                          subscriptions currently enrolled in Auto
                          Renewal for this account.
                        </p>

                        <div className='flex gap-[8px] justify-center mt-[18px]'>
                          <button className='black40HButton'>
                            Add Card
                          </button>
                          <button className='outlineBtn40h'>
                            Remove Card
                          </button>
                        </div>

                        <div
                          style={{
                            width: 'fit-content',
                          }}
                          className='flex items-center mx-[auto] mt-[12px]'
                        >
                          <div>
                            <img src={lock} className='mr-2' alt='' />
                          </div>
                          <p className='text-sm11 text-[#bbbbbb] '>
                            Your encrypted payment method will be
                            saved for future orders.
                          </p>
                        </div>
                      </div>
                    ) : SelectedTab === 'Card 2' ? (
                      <></>
                    ) : SelectedTab === 'Card 3' ? (
                      <></>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </StyledPopupAddCard>
            </div>
            <div>
              <div className='flex justify-center gap-[4px] mx-[auto] '>
                <div>
                  <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>

                <div>
                  <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>

                <div>
                  <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>

                <div>
                  <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>
                <div>
                  <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className='gray30HButton mt-[8px] opacity-[40%]'>
          Remove Payment Method
        </button>
        <p className='text-[16px] text-primaryBlack font-medium mt-[32px]'>
          Payment Details for Referral Earnings
        </p>

        <div className='relative mt-[12px]'>
          <input
            type='text'
            placeholder='Enter Payee Name'
            className='InputField44H'
           
          />

          <p className='InputFieldTopText44H'>Make Payment to</p>
        </div>

        

        <div className='relative mt-[16px]'>
          <input
            type='text'
            placeholder='None Selected'
            className='InputField44H'
           
          />

          <p className='InputFieldTopText44H'>Payment Method</p>
        </div>

       

        <div className='relative mt-[16px]'>
          <input
            type='text'
            placeholder='Enter Payee Email'
            className='InputField44H'
            
          />

          <p className='InputFieldTopText44H'>Payout Email</p>
        </div>

       

        <div className='relative mt-[16px]'>
          <input
            type='text'
            placeholder='$50'
            className='InputField44H'
           
          />

          <p className='InputFieldTopText44H'>Minimum Payout</p>
        </div>

        

        <div className='flex gap-[8px] justify-center mt-[32px] mb-[128px]'>
          <button className='blackBtn'>Save</button>
          <button className=' outlineBtn'>Cancel</button>
        </div>
      </div> */}
    </>
  );
};

export default Contributor_PaymentInfo;
