import selectall from '../../../assets/images/contributor/selectall.png';
import deleteicon from '../../../assets/images/contributor/delete-icon.png';
import undo from '../../../assets/images/contributor/undo.png';
import redo from '../../../assets/images/contributor/redo.png';
import save from '../../../assets/images/contributor/save.png';
import keyword from '../../../assets/images/contributor/keyword.png';
import GrayBox from './GrayBox';
import addImage from '../../../assets/images/contributor/add.png';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setNestedTabValueUpload } from '../../../store/nestedTabSlice';
import { setpath2 } from '../../../store/contriPathSlice';
import { setSelectedImages } from '../../../store/imageSlice';
import { httpClient } from '../../../axios';

import { baseURL } from '../../../axios';

import { ReactComponent as SelectAll } from '../../../assets/images/contributor/svg/Icon_SelectAll.svg';
import { ReactComponent as Deleteicon } from '../../../assets/images/contributor/svg/Icon_DeleteFiles.svg';
import { ReactComponent as Save } from '../../../assets/images/contributor/svg/Icon_SaveDraft.svg';
import { ReactComponent as Keyword } from '../../../assets/images/contributor/svg/Icon_KeywordManager.svg';
import { ReactComponent as Add } from '../../../assets/images/contributor/svg/Icon_Add.svg';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { loaderSliceAction } from '../../../store/loaderSlice';
import { keywordsManagerSliceAction } from '../../../store/keywordsManagerSlice';
import KeywordPopup from './KeywordPopup';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import TechinicalSpec from './TechinicalSpec';

import { popupSliceAction } from '../../../store/popupSlice';

const StyledPopupFiles = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px;
    width: 514px;
  }
`;

const UploadContent = () => {
  const add = addImage;
  const dispatch = useDispatch();

  const selectedImages = useSelector(
    (state) => state.images.selectedImages
  );

  useEffect(() => {
    console.log('selectedImages', selectedImages);
  }, [selectedImages]);

  const navigate = useNavigate();

  const userId = useSelector((state) => state.auth.userId);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedSize, setUploadedSize] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [TempCards, setTempCards] = useState(null);

  const MakeLoaderVisible = () => {
    dispatch(loaderSliceAction.setLoaderTrue());
  };
  const MakeLoaderInvisible = () => {
    dispatch(loaderSliceAction.setLoaderFalse());
  };

  const [cards, setCards] = useState([]);
  const [realCards, setRealCards] = useState([]);
  const [button, setButton] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    console.log(cards);
  }, [cards]);

  useEffect(() => {
    console.log(realCards);
  }, [realCards]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newCards = Array.from(files);

    // if (cards?.length + newCards.length + draftCount > 15) {
    //   dispatch(popupSliceAction.setBackgroundColor('bg-pinkColor'));
    //   dispatch(
    //     popupSliceAction.setTitle1(
    //       'The To Submit Files draft is restricted to a maximum of 15 files in total.'
    //     )
    //   );
    //   dispatch(popupSliceAction.setTitle2(''));
    //   dispatch(popupSliceAction.setOpenPopup());
    //   dispatch(popupSliceAction.setAnimationOpen());
    // } else {
    if (cards?.length + newCards.length > 15) {
      dispatch(popupSliceAction.setBackgroundColor('bg-pinkColor'));
      dispatch(
        popupSliceAction.setTitle1(
          'Only Upload 15 files (maximum) in a single upload.'
        )
      );
      dispatch(popupSliceAction.setTitle2(''));
      dispatch(popupSliceAction.setOpenPopup());
      dispatch(popupSliceAction.setAnimationOpen());
    } else {
      setCards((prevCards) => [...prevCards, ...newCards]);
    }
  };

  useEffect(() => {
    if (cards.length < 15) {
      setgrayNum(grayBox.length - cards.length);
    }

    console.log(cards.length);
  }, [cards]);

  // drag and drop
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    const allowedExtensions = ['jpeg', 'jpg', 'png', 'eps'];

    // Filter files by allowed extensions
    const newCards = Array.from(droppedFiles).filter((file) => {
      const extension = file.name.split('.').pop().toLowerCase();
      return allowedExtensions.includes(extension);
    });

    if (newCards.length < Array.from(droppedFiles).length) {
      dispatch(popupSliceAction.setBackgroundColor('bg-pinkColor'));
      dispatch(
        popupSliceAction.setTitle1(
          'Some files were rejected due to unsupported formats.'
        )
      );
      dispatch(popupSliceAction.setTitle2(''));
      dispatch(popupSliceAction.setOpenPopup());
      dispatch(popupSliceAction.setAnimationOpen());
    }

    // if (cards?.length + newCards.length + draftCount > 15) {
    //   dispatch(popupSliceAction.setBackgroundColor('bg-pinkColor'));
    //   dispatch(
    //     popupSliceAction.setTitle1(
    //       'The To Submit Files draft is restricted to a maximum of 15 files in total.'
    //     )
    //   );
    //   dispatch(popupSliceAction.setTitle2(''));
    //   dispatch(popupSliceAction.setOpenPopup());
    //   dispatch(popupSliceAction.setAnimationOpen());
    // }
    // else {
    if (
      cards?.length + newCards.length > 15 ||
      newCards.length > 15
    ) {
      dispatch(popupSliceAction.setBackgroundColor('bg-pinkColor'));
      dispatch(
        popupSliceAction.setTitle1(
          'Only Upload 15 files (maximum) in a single upload.'
        )
      );
      dispatch(popupSliceAction.setTitle2(''));
      dispatch(popupSliceAction.setOpenPopup());
      dispatch(popupSliceAction.setAnimationOpen());
    } else {
      setCards((prevCards) => [...prevCards, ...newCards]);

      // newCards.forEach((cards) => {
      //   setTempCards(cards);

      //   const xhr = new XMLHttpRequest();
      //   let formDataa = new FormData();
      //   formDataa.append('file', cards);

      //   xhr.upload.addEventListener('progress', (e) => {
      //     if (e.lengthComputable) {
      //       const progress = (e.loaded / e.total) * 100;

      //       console.log('progress', progress);
      //       setUploadProgress(progress);
      //       setUploadedSize(e.loaded / 1024);
      //       setTotalSize(e.total / 1024);
      //     }
      //   });

      //   xhr.open(
      //     'POST',
      //     `${baseURL}/CloudinaryImageUpload?parameter=false`,
      //     true
      //   );

      //   xhr.send(formDataa);

      //   xhr.onreadystatechange = () => {
      //     if (xhr.readyState === XMLHttpRequest.DONE) {
      //       if (xhr.status === 200) {
      //         console.log('Response Text:', xhr.responseText);
      //         try {
      //           const res = xhr.responseText;
      //           console.log('Parsed JSON:', res);
      //           setRealCards((prevCards) => [...prevCards, res]);
      //         } catch (error) {
      //           console.error('Failed to parse JSON:', error);
      //         }
      //       } else {
      //         console.error('Upload failed');
      //       }
      //     }
      //   };
      //   setTempCards(null);
      // });
    }
    // }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (selectedImages?.length > 0) {
      setButton(true);
    } else {
      setButton(false);
    }
  }, [selectedImages]);

  useEffect(() => {
    dispatch(setpath2('/ Upload Files'));
    // getDraftSubmit();
    // getForReviewCount();
    // getApprovedCount();
    // getRejectedCount();
  }, []);

  const next = async () => {
    // MakeLoaderVisible();
    // dispatch(setNestedTabValueUpload('2.2'));

    const tempImag = [];

    try {
      // const uploadPromises = selectedImages?.map(async (card) => {
      //   let formData = new FormData();
      //   formData.append('file', card);
      //   try {
      //     const res = await httpClient.post(
      //       '/CloudinaryImageUpload?parameter=true',
      //       formData,
      //       {
      //         headers: {
      //           'Content-Type': 'multipart/form-data',
      //         },
      //       }
      //     );
      //     console.log('file uploaded successfully: ', res.data);
      //     tempImag.push(res.data.imageId);
      //   } catch (err) {
      //     MakeLoaderInvisible();
      //     console.log(err);
      //   }
      // });

      // await Promise.all(uploadPromises);

      // let obj = {
      //   imageId: tempImag,
      //   userId: userId,
      // };

      // const res = await httpClient.post('/draft_master/create', obj);
      // console.log(res.data);
      dispatch(setNestedTabValueUpload('2.2'));

      dispatch(setpath2('/ To Submit'));
      // MakeLoaderInvisible();F
    } catch (err) {
      // MakeLoaderInvisible();
      console.log(err);
    } finally {
      // MakeLoaderInvisible();
      // dispatch(setNestedTabValueUpload('2.2'));
    }
  };

  // Get Cart Count
  const draftCount = useSelector(
    (state) => state.contriCounter.toSubmitFilesCount
  );
  const forReviewCount = useSelector(
    (state) => state.contriCounter.forReviewFilesCount
  );
  const approvedCount = useSelector(
    (state) => state.contriCounter.approvedFilesCount
  );
  const RejectedCount = useSelector(
    (state) => state.contriCounter.rejectedFilesCount
  );
  const fileUploadLimitCount = useSelector(
    (state) => state.contriCounter.fileUploadLimitCount
  );

  const grayBox = [
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
  ];

  const [grayNum, setgrayNum] = useState(grayBox.length);

  useEffect(() => {
    console.log(grayNum);
  }, [grayNum]);

  const SelectAllImages = () => {
    dispatch(setSelectedImages(cards));
  };

  const deleteCard = () => {
    const newCards = cards.filter(
      (card) => !selectedImages.includes(card)
    );
    setCards(newCards);
  };

  const SetKeywordManagerTrue = () => {
    dispatch(keywordsManagerSliceAction.setKeywordsTrue());
  };

  // const [selectAllHover, setSelectAllHover] = useState(false);
  // const [deleteImageHover, setDeleteImageHover] = useState(false);
  // const [saveDraftHover, setSaveDraftHover] = useState(false);
  // const [keywordManagerHover, setKeywordManagerHover] =
  //   useState(false);

  const [selectAllHover, setSelectAllHover] = useState('enabled');
  const [deleteImageHover, setDeleteImageHover] = useState('enabled');
  const [saveDraftHover, setSaveDraftHover] = useState('enabled');
  const [keywordManagerHover, setKeywordManagerHover] =
    useState('enabled');

  return (
    <div className='font-heebo'>
      <div className='flex justify-center mb-[16px]'>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex border-t border-b border-t-[#efefef] border-b-[#efefef]'
        >
          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-[#f26522]'>
              {/* 13 */}
              {draftCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='contributorUploadtext pr-[8px]'>
                File(s) to be
                <br />
                submitted to
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-[#ffc20E] opacity-[20%] pl-[5px]'>
              {forReviewCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                pending for
                <br />
                review
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className=' contributorUploadCount text-[#8dc73f] opacity-[20%]  pl-[5px]'>
              {approvedCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                approved by
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-[#00aeef] opacity-[20%]  pl-[5px]'>
              {RejectedCount}
            </span>
            <div className='opacity-[40%] h-[40px] flex items-center'>
              <p className='contributorUploadtext'>
                File(s)
                <br />
                rejected by
                <br />
                Artnstock
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='contributorTitleStyling'>
        <div className='w-[1168px] flex justify-between'>
          <div className='flex flex-col gap-[2px] max-w-[328px] w-[100%]'>
            <div className='flex gap-[8px] items-baseline'>
              <p className='text-[24px] font-medium leading-[1] text-primaryBlack'>
                Upload Files
              </p>
              <p className='pinkTextUploadFlow '>
                {draftCount}/15 File(s) Uploaded
                {/* {selectedImages?.length}/15 File(s) uploaded */}
              </p>
            </div>
            <p className='text-sm11 text-primaryGray mt-[1px]'>
              Upload 15 files (maximum) in a single upload. Click
              Continue <br /> to Add Details and Activate Products.
              <span
                className='text-orangeColor cursor-pointer'
                onClick={() => navigate('/upload-file2')}
              >
                {' '}
                Learn more
              </span>
            </p>
          </div>

          <div className='flex'>
            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
              {cards?.length > 0 ? (
                <div
                  onMouseEnter={() => setSelectAllHover('hovered')}
                  onMouseLeave={() => setSelectAllHover('enabled')}
                  onMouseUp={() => setSelectAllHover('hovered')}
                  onMouseDown={() => setSelectAllHover('pressed')}
                  onClick={SelectAllImages}
                  className='flex flex-col mt-[1px] gap-[8px] w-[52px] text-center cursor-pointer'
                >
                  <div>
                    <SelectAll
                      className={`mx-[auto] ${
                        selectAllHover === 'hovered'
                          ? 'fill-primaryBlack'
                          : selectAllHover === 'pressed'
                          ? 'fill-pressedColor'
                          : 'fill-[#888888]'
                      }`}
                    />
                  </div>
                  <p
                    className={` text-sm11 text-center ${
                      selectAllHover === 'hovered'
                        ? 'text-primaryBlack'
                        : selectAllHover === 'pressed'
                        ? 'text-pressedColor'
                        : 'text-primaryGray'
                    }`}
                  >
                    Select <br /> All
                  </p>
                </div>
              ) : (
                <div
                  // onMouseEnter={() => setSelectAllHover(true)}
                  // onMouseLeave={() => setSelectAllHover(false)}
                  // onClick={SelectAllImages}
                  className='flex flex-col mt-[1px] gap-[8px] opacity-[40%] w-[52px] text-center'
                >
                  <div>
                    <SelectAll
                      className={`mx-[auto] fill-[#888888]
        `}
                    />
                  </div>
                  <p
                    className={`text-primaryGray text-sm11 text-center`}
                  >
                    Select <br /> All
                  </p>
                </div>
              )}
            </div>

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
              {selectedImages?.length > 0 ? (
                <div
                  onMouseEnter={() => setDeleteImageHover('hovered')}
                  onMouseLeave={() => setDeleteImageHover('enabled')}
                  onMouseUp={() => setDeleteImageHover('hovered')}
                  onMouseDown={() => setDeleteImageHover('pressed')}
                  onClick={deleteCard}
                  className='flex flex-col  mt-[1px] gap-[8px] w-[52px] text-center  cursor-pointer'
                >
                  <div>
                    <Deleteicon
                      className={`mx-[auto] ${
                        deleteImageHover === 'hovered'
                          ? 'fill-primaryBlack'
                          : deleteImageHover === 'pressed'
                          ? 'fill-pressedColor'
                          : 'fill-[#888888]'
                      }`}
                    />
                  </div>
                  <p
                    className={` text-sm11 text-center ${
                      deleteImageHover === 'hovered'
                        ? 'text-primaryBlack'
                        : deleteImageHover === 'pressed'
                        ? 'text-pressedColor'
                        : 'text-primaryGray'
                    }`}
                  >
                    Delete File(s)
                  </p>
                </div>
              ) : (
                <div
                  onClick={deleteCard}
                  className='flex flex-col mt-[1px] opacity-[40%]  gap-[8px] w-[52px] text-center '
                >
                  <div>
                    <Deleteicon
                      className={`mx-[auto]  ${
                        deleteImageHover === 'hovered'
                          ? 'fill-primaryBlack'
                          : deleteImageHover === 'pressed'
                          ? 'fill-pressedColor'
                          : 'fill-[#888888]'
                      }`}
                    />
                  </div>
                  <p
                    className={`  text-sm11 ${
                      deleteImageHover === 'hovered'
                        ? 'text-primaryBlack'
                        : deleteImageHover === 'pressed'
                        ? 'text-pressedColor'
                        : 'text-primaryGray'
                    } text-center`}
                  >
                    Delete File(s)
                  </p>
                </div>
              )}
            </div>

            {/* <div className='flex-column max-w-[34px] text-center'>
              <img
                src={undo}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px]'>
                Undo Changes
              </p>
            </div>
            <div className='flex-column max-w-[34px] text-center'>
              <img
                src={redo}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px]'>
                Redo Changes
              </p>
            </div> */}

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef] border-r-[2px]'>
              <div className='flex flex-col  mt-[1px] opacity-[40%] gap-[8px] w-[52px] text-center '>
                <div>
                  <Save className='mx-[auto] fill-[#888888]' />
                </div>
                <p className='text-primaryGray text-sm11 text-center'>
                  Save <br /> Draft
                </p>
              </div>
            </div>

            {/* <div className=' w-[53px] text-center border-r border-r-[#efefef] '>
              <img
                src={save}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px] text-center'>
                Save <br /> Draft
              </p>
            </div> */}

            <div className='contributorUploadIconsMainDiv'>
              <div
                onMouseEnter={() => setKeywordManagerHover('hovered')}
                onMouseLeave={() => setKeywordManagerHover('enabled')}
                onMouseUp={() => setKeywordManagerHover('hovered')}
                onMouseDown={() => setKeywordManagerHover('pressed')}
                onClick={SetKeywordManagerTrue}
                className='flex flex-col  mt-[1px] gap-[8px] w-[52px] text-center cursor-pointer'
              >
                <div>
                  <Keyword
                    className={`mx-[auto] ${
                      keywordManagerHover === 'hovered'
                        ? 'fill-primaryBlack'
                        : keywordManagerHover === 'pressed'
                        ? 'fill-pressedColor'
                        : 'fill-[#888888]'
                    }`}
                  />
                </div>
                <p
                  className={` text-sm11 text-center ${
                    keywordManagerHover === 'hovered'
                      ? 'text-primaryBlack'
                      : keywordManagerHover === 'pressed'
                      ? 'text-pressedColor'
                      : 'text-primaryGray'
                  }`}
                >
                  Keyword Manager
                </p>
              </div>
            </div>
          </div>

          <div className='flex  justify-end max-w-[328px] w-[100%]'>
            <div className='flex items-center'>
              <TechinicalSpec />
              {/* <p
                onClick={() => navigate('/upload-file2')}
                className='text-[11px] text-orangeColor leading-[14px] cursor-pointer'
              >
                Technical Specifications <br /> for File Upload
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className='w-[100%] flex justify-center'>
        <div
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className='w-[1168px] justify-center flex-column border-[2px] border-[#efefef] rounded-[32px] gap-[10px] py-[32px]'
        >
          <div className='w-[100%] flex justify-center'>
            <p className='text-pinkColor text-[16px] leading-[22px]'>
              Drag and Drop your files here or
            </p>
          </div>
          <div className='w-[100%] flex justify-center mt-[5px]'>
            <input
              type='file'
              multiple
              onChange={handleFileChange}
              accept='.jpeg,.jpg,.png,.eps'
              style={{ display: 'none' }}
              ref={fileInputRef}
            />

            <button
              onClick={handleButtonClick}
              className='text-[#333333] bg-[#00f9c6] rounded-[5000px] text-[12px] leading-[1] px-[13px] h-[28px] flex items-center font-medium'
            >
              Select Multiple Files
            </button>
          </div>
          {/* <div className='w-[100%] flex justify-center mt-[6px]'>
            <p className='text-primaryGray text-[11px] leading-[14px]'>
              (Maximum 15 Images in a single upload)
            </p>
          </div> */}
          {/* gap-[16.68px] px-[16.68px] */}
          <div className='flex justify-start flex-wrap gap-[16px] px-[18px] pt-[17px] pb-[32px]'>
            {cards?.map((card, index) => (
              <div>
                <GrayBox
                  key={index}
                  card={card}
                  cardsLength={cards.length}
                  TempCards={TempCards}
                />
              </div>
            ))}

            {cards?.length < 15 && (
              <>
                <div
                  onClick={handleButtonClick}
                  className={
                    'h-[127px] w-[127px] bg-[#f7f7f7] rounded-[16px] flex items-center justify-center cursor-pointer'
                  }
                >
                  {/* <div className='w-[100%]'> */}
                  {/* <img
                  className='m-[auto] mt-[45px]'
                  src={add}
                  draggable='false'
                /> */}
                  <Add className='fill-[#dddddd]' />
                  {/* </div> */}
                </div>
              </>
            )}

            {cards?.length < 15 && (
              <>
                {grayBox?.slice(0, grayNum).map((card, index) => (
                  <div
                    className={
                      'h-[127px] w-[127px] bg-[#f7f7f7] rounded-[16px]'
                    }
                  ></div>
                ))}
              </>
            )}
          </div>
          <div className='w-[100%] px-[16.68px]'>
            <div className='bg-[#efefef] h-[2px] w-[100%]'></div>
          </div>

          <div className='flex flex-wrap justify-center pt-[32px]'>
            <div className='pr-[16px] border-r border-[#efefef]'>
              <p className='text-[#333333] text-[15px] leading-[18px] font-medium mb-[15px]'>
                Art
              </p>
              <div className='flex gap-[8px]'>
                <div className='flex flex-col'>
                  <p className='uploadPageDataLeft'>File Format:</p>
                  <p className='uploadPageDataLeft'>File Size:</p>
                  <p className='uploadPageDataLeft'>Colour Space:</p>
                  <p className='uploadPageDataLeft'>Resolution:</p>
                  <p className='uploadPageDataLeft'>
                    Horizontal Size:
                    <br />
                    <br />
                  </p>
                  <p className='uploadPageDataLeft'>
                    Vertical Size:
                    <br />
                    <br />
                  </p>
                  <p className='uploadPageDataLeft'>Square Size:</p>
                </div>
                <div>
                  <p className='uploadPageData'>
                    JPEG/EPS for Vector
                  </p>
                  <p className='uploadPageData'>150 MB (Maximum)</p>
                  <p className='uploadPageData'>RGB</p>
                  <p className='uploadPageData'>300 DPI</p>
                  <p className='uploadPageData'>
                    Width 47 Inches (Fixed) <br /> Height 25-33 Inches
                  </p>
                  <p className='uploadPageData'>
                    Width 25-33 Inches <br />
                    Height 47 Inches (Fixed){' '}
                  </p>
                  <p className='uploadPageData'>
                    47 x 47 Inches (Fixed)
                  </p>
                  {/* <p className='uploadPageData'>
                    JPEG (Quality: Maximum)
                  </p> */}
                </div>
              </div>
            </div>

            <div className='px-[16px] border-r border-[#efefef]'>
              <p className='text-[#333333] text-[15px] leading-[18px] font-medium mb-[15px]'>
                Photos
              </p>
              <div className='flex gap-[8px]'>
                <div>
                  <p className='uploadPageDataLeft'>File Format:</p>
                  <p className='uploadPageDataLeft'>File Size:</p>
                  <p className='uploadPageDataLeft'>Colour Space:</p>
                  <p className='uploadPageDataLeft'>Resolution:</p>
                  <p className='uploadPageDataLeft'>
                    Horizontal Size: <br />
                    <br />
                  </p>
                  <p className='uploadPageDataLeft'>
                    Vertical Size: <br />
                    <br />
                  </p>
                  <p className='uploadPageDataLeft'>Square Size: </p>
                </div>

                <div>
                  <p className='uploadPageData'>JPEG</p>
                  <p className='uploadPageData'>50 MB (Maximum) </p>
                  <p className='uploadPageData'>RGB</p>
                  <p className='uploadPageData'>300 DPI</p>

                  <p className='uploadPageData'>
                    Width 36 Inches (Fixed)
                  </p>
                  <p className='uploadPageData'>
                    Height 16-24 Inches
                  </p>
                  <p className='uploadPageData'>Width 16-24 Inches</p>
                  <p className='uploadPageData'>
                    Height 36 Inches (Fixed)
                  </p>
                  <p className='uploadPageData'>
                    6 x 36 Inches (Fixed)
                  </p>
                </div>
              </div>
            </div>

            <div className='px-[16px] border-r border-[#efefef]'>
              <p className='text-[#333333] text-[15px] leading-[18px] font-medium mb-[15px]'>
                Footage
              </p>
              <div className='flex gap-[8px]'>
                <div>
                  <p className='uploadPageDataLeft'>
                    File Format: <br />
                    <br />
                    <br />
                  </p>
                  <p className='uploadPageDataLeft'>File Size:</p>
                  <p className='uploadPageDataLeft'>
                    Frames-Per-Second: <br />
                    <br />
                    <br />
                  </p>
                  <p className='uploadPageDataLeft'>
                    Resolution:
                    <br />
                    <br />
                  </p>
                  <p className='uploadPageDataLeft'>Clip Duration:</p>
                </div>
                <div>
                  <p className='uploadPageData'>ASF, AVI, DV, FLV,</p>
                  <p className='uploadPageData'>MOV, MPEG/MPG,</p>
                  <p className='uploadPageData'>WMV</p>
                  <p className='uploadPageData'>250 MB (Maximum)</p>
                  <p className='uploadPageData'>
                    23.98, 24, 25, 29.97,
                  </p>
                  <p className='uploadPageData'>
                    30, 47.95, 47.96, 48,
                  </p>
                  <p className='uploadPageData'>50, 59.94, 60</p>
                  <p className='uploadPageData'>4K (4096 x 2160)</p>
                  <p className='uploadPageData'>HD (1920 x 1080)</p>
                  <p className='uploadPageData'>5 - 60 Seconds</p>
                </div>
              </div>
            </div>

            <div className='px-[16px] border-r border-[#efefef]'>
              <p className='text-[#333333] text-[15px] leading-[18px] font-medium mb-[15px]'>
                Music
              </p>
              <div className='flex gap-[8px]'>
                <div>
                  <p className='uploadPageDataLeft'>File Format:</p>
                  <p className='uploadPageDataLeft'>File Size:</p>
                  <p className='uploadPageDataLeft'>Bit Depth:</p>
                  <p className='uploadPageDataLeft'>Sample Rate:</p>
                  <p className='uploadPageDataLeft'>Clip Duration:</p>
                </div>

                <div>
                  <p className='uploadPageData'>WAV, MP3</p>
                  <p className='uploadPageData'>50 MB (Maximum)</p>
                  <p className='uploadPageData'>16 or 24 bit</p>
                  <p className='uploadPageData'>44.1 or 44kHz</p>
                  <p className='uploadPageData'>
                    2:30 - 3:00 Minutes
                  </p>
                </div>
              </div>
            </div>

            <div className='pl-[16px]'>
              <p className='text-[#333333] text-[15px] leading-[18px] font-medium mb-[15px]'>
                Templates
              </p>
              <div className='flex gap-[8px]'>
                <div className='flex flex-col'>
                  <p className='uploadPageDataLeft'>File Format:</p>
                  <p className='uploadPageDataLeft'>File Size:</p>
                  <p className='uploadPageDataLeft'>Colour Space:</p>
                  <p className='uploadPageDataLeft'>Resolution:</p>
                  <p className='uploadPageDataLeft'>
                    Horizontal Size:
                    <br />
                    <br />
                  </p>
                  <p className='uploadPageDataLeft'>
                    Vertical Size:
                    <br />
                    <br />
                  </p>
                  <p className='uploadPageDataLeft'>Square Size:</p>
                </div>
                <div>
                  <p className='uploadPageData'>
                    JPEG/EPS for Vector
                  </p>
                  <p className='uploadPageData'>150 MB (Maximum)</p>
                  <p className='uploadPageData'>RGB</p>
                  <p className='uploadPageData'>300 DPI</p>
                  <p className='uploadPageData'>
                    Width 47 Inches (Fixed) <br /> Height 25-33 Inches
                  </p>
                  <p className='uploadPageData'>
                    Width 25-33 Inches <br />
                    Height 47 Inches (Fixed){' '}
                  </p>
                  <p className='uploadPageData'>
                    47 x 47 Inches (Fixed)
                  </p>
                  {/* <p className='uploadPageData'>
                    JPEG (Quality: Maximum)
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-[100%] text-center pt-[32px]'>
        {button == true ? (
          <button onClick={next} className='blackBtn mx-auto block'>
            Continue
          </button>
        ) : (
          <button className='grayBtn mx-auto block'>Continue</button>
        )}
      </div>

      <KeywordPopup />
    </div>
  );
};

export default UploadContent;
