import React, { useState, useEffect } from 'react';
import captcha from '../../assets/recaptcha.jpg';
import { httpClient } from '../../axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { authSliceAction } from '../../store/authSlice';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { popupSliceAction } from '../../store/popupSlice';

const Login = () => {
  const GetLoginStatus = async () => {
    try {
      const res = await httpClient.get(
        `/user_master/checkUserLoginStatus/${email}`
      );
      console.log(res.data);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginForm = {
    userName: '',
    password: '',
  };

  const login = async () => {
    const loginStatus = await GetLoginStatus();

    // if (!loginStatus) {
    try {
      if (email.length > 0 && password.length > 0) {
        loginForm.userName = email;
        loginForm.password = password;

        const res = await httpClient.post(
          '/user_master/userLogin',
          loginForm
        );

        console.log(res.data);

        if (res.data.responseCode === 200) {
          dispatch(
            popupSliceAction.setBackgroundColor(
              'gradient-background3'
            )
          );
          dispatch(
            popupSliceAction.setTitle1('Successfully Logged In')
          );
          // dispatch(popupSliceAction.setTitle2('the Art Details Draft.'));
          dispatch(popupSliceAction.setOpenPopup());
          dispatch(popupSliceAction.setAnimationOpen());
          // toast.success('Successfully Logged In');
          dispatch(authSliceAction.loginUser(true));
          dispatch(authSliceAction.setUserRole(res.data.userRole[0]));
          dispatch(authSliceAction.setUserId(res.data.userId));
          dispatch(authSliceAction.setUserDetails(res.data));
          console.log(res.data);
          navigate('/');
        } else {
          toast.error(res.data.message);
        }
      } else {
        toast.error('Please enter email and password');
      }
    } catch (error) {
      console.error('Login error:', error);
      toast.error('An error occurred during login');
    }
    // } else {
    //   toast.error(
    //     'User is already logged in from another device. Please logout from that device to continue the login process here.'
    //   );
    // }
  };

  // const login = () => {
  //   if (email.length > 0 && password.length > 0) {
  //     loginForm.userName = email;
  //     loginForm.password = password;
  //     // console.log(loginForm);
  //     httpClient
  //       .post('/user_master/userLogin', loginForm)
  //       .then((res) => {
  //         console.log(res.data);
  //         if (res.data.responseCode === 200) {
  //           toast.success('Successfully Logged In');
  //           dispatch(authSliceAction.loginUser(true));
  //           dispatch(
  //             authSliceAction.setUserRole(res.data.userRole[0])
  //           );
  //           dispatch(authSliceAction.setUserId(res.data.userId));
  //           dispatch(authSliceAction.setUserDetails(res.data));
  //           console.log(res.data);
  //           navigate('/');
  //         } else {
  //           toast.error(res.data.message);
  //         }
  //       });
  //   } else {
  //     toast.error('Please enter email and password');
  //   }
  // };

  const onChangeCaptcha = (value) => {
    // console.log('Captcha value:', value);
  };

  return (
    <div className='w-full h-[100vh] bg-[#FF369F] flex justify-center'>
      <div className='loginCard py-7 px-[67px]'>
        <p className='text-primaryBlack leading-[2.5rem] mb-0.5 text-[2.375rem] font-medium'>
          Customer Sign In
        </p>

        <div className='relative mt-[10px]'>
          <input
            className='InputField40H'
            placeholder='Email Address'
            type='email'
            name='email'
            autoComplete='off'
            onChange={(e) => setEmail(e.target.value)}
          />

          <p className='InputFieldTopText44H'>Enter Email Address</p>
        </div>

        <div className='relative mt-[10px]'>
          <input
            className='InputField40H'
            placeholder='Password'
            type='password'
            name='password'
            autoComplete='off'
            onChange={(e) => setPassword(e.target.value)}
          />

          <p className='InputFieldTopText44H'>Enter Password</p>
        </div>
        {/* <img className='mx-auto my-5' src={captcha} alt='' /> */}
        <div
          className='mx-auto my-5 '
          style={{ width: 'fit-content' }}
        >
          <ReCAPTCHA
            sitekey='6LfPQd4pAAAAAAJbcS8OOVfF1CRNrhihC436dFXf'
            onChange={onChangeCaptcha}
          />
        </div>
        <button onClick={login} className='blackBtn'>
          Sign In
        </button>
        <p
          onClick={() => navigate('/forgot-password')}
          className='text-pinkColor mt-1 text-sm11 cursor-pointer'
        >
          Forgot Password?
        </p>
        <p
          onClick={() => {
            navigate('/join');
          }}
          className='text-orangeColor text-sm14 mt-4 cursor-pointer'
        >
          Create an Account
        </p>
      </div>
    </div>
  );
};

export default Login;
