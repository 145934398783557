import selectall from '../../../assets/images/contributor/selectall.png';
import deleteicon from '../../../assets/images/contributor/delete-icon.png';
import undo from '../../../assets/images/contributor/undo.png';
import redo from '../../../assets/images/contributor/redo.png';
import save from '../../../assets/images/contributor/save.png';
import keyword from '../../../assets/images/contributor/keyword.png';
import { useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import GrayBoxforDetails from './GrayBoxforDetails';
import iconart from '../../../assets/images/contributor/icon-art.png';
import iconphotos from '../../../assets/images/contributor/icon-photos.png';
import iconfootage from '../../../assets/images/contributor/icon-footage.png';
import iconmusic from '../../../assets/images/contributor/icon-music.png';
import icontemplates from '../../../assets/images/contributor/icon-templates.png';
import addsmall from '../../../assets/images/contributor/addsmall.png';
import { httpClient } from '../../../axios';
import { baseURL } from '../../../axios';
import { artMasterModel } from '../../../models/allModel';
import TechinicalSpec from './TechinicalSpec';

import { setpath2 } from '../../../store/contriPathSlice';
import { useDispatch } from 'react-redux';
import view from '../../../assets/images/contributor/view.png';
import edit from '../../../assets/images/contributor/edit.png';
import deletee from '../../../assets/images/contributor/delete.png';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { toast } from 'react-toastify';

import { popupSliceAction } from '../../../store/popupSlice';

import Popup from 'reactjs-popup';
import styled from 'styled-components';

import { ReactComponent as SelectAll } from '../../../assets/images/contributor/svg/Icon_SelectAll.svg';
import { ReactComponent as Deleteicon } from '../../../assets/images/contributor/svg/Icon_DeleteFiles.svg';
import { ReactComponent as Save } from '../../../assets/images/contributor/svg/Icon_SaveDraft.svg';
import { ReactComponent as Keyword } from '../../../assets/images/contributor/svg/Icon_KeywordManager.svg';
import { ReactComponent as Plus24Icon } from '../../../assets/images/Icons/plus24px.svg';

import { setNestedTabValueUpload } from '../../../store/nestedTabSlice';

import { ReactComponent as DropArrow } from '../../../assets/images/Icons/Down arrow.svg';
import { ReactComponent as DropArrowSmall } from '../../../assets/images/contributor/svg/Icon_DownArrow_Small.svg';

import { keywordsManagerSliceAction } from '../../../store/keywordsManagerSlice';

import { ReactComponent as ArtIcon } from '../../../assets/images/contributor/add_details/IconAD_Art.svg';
import { ReactComponent as PhotoIcon } from '../../../assets/images/contributor/add_details/IconAD_Photos.svg';
import { ReactComponent as FootageIcon } from '../../../assets/images/contributor/add_details/IconAD_Footage.svg';
import { ReactComponent as MusicIcon } from '../../../assets/images/contributor/add_details/IconAD_Music.svg';
import { ReactComponent as TemplatesIcon } from '../../../assets/images/contributor/add_details/IconAD_Templates.svg';

import { ReactComponent as IconColor } from '../../../assets/images/art-details/Icon_Colour.svg';

import Exclusive from '../../../assets/images/contributor/ForReview/Exclusive.jpg';
import Featured from '../../../assets/images/contributor/ForReview/Featured.jpg';

// import AddHori from '../../../assets/images/Icons/Icon_Add_Hori.jpg';
// import AddVert from '../../../assets/images/Icons/Icon_Add_Vert.jpg';
// import AddSqua from '../../../assets/images/Icons/Icon_Add_Squa.jpg';
import { setSelectedImages } from '../../../store/imageSlice';

import { ReactComponent as Exclusive_Icon28 } from '../../../assets/images/contributor/add_details/Icon_Exclusive_28x28.svg';
import { ReactComponent as Featured_Icon28 } from '../../../assets/images/contributor/add_details/Icon_Featured_28x28.svg';

import { ReactComponent as Exclusive_Icon24 } from '../../../assets/images/contributor/add_details/Icon_Exclusive_24x24.svg';
import { ReactComponent as Featured_Icon24 } from '../../../assets/images/contributor/add_details/Icon_Featured_24x24.svg';

import { ReactComponent as Icon_Edit } from '../../../assets/images/contributor/add_details/Icon_Edit.svg';

import { ReactComponent as Exclusive_Icon16 } from '../../../assets/images/contributor/add_details/Icon_Exclusive_16x16.svg';
import { ReactComponent as Featured_Icon16 } from '../../../assets/images/contributor/add_details/Icon_Featured_16x16.svg';

import { ReactComponent as AddHori } from '../../../assets/images/contributor/add_details/Icon_HorizontalOrientation.svg';
import { ReactComponent as AddVert } from '../../../assets/images/contributor/add_details/Icon_VerticalOrientation.svg';
import { ReactComponent as AddSqua } from '../../../assets/images/contributor/add_details/Icon_SquareOrientation.svg';

import { ReactComponent as Cross8 } from '../../../assets/images/contributor/add_details/Icon_KeywordDelete_7x7.svg';

import { ReactComponent as IconBW } from '../../../assets/images/art-details/Icon_BlackWhite.svg';

import { ReactComponent as ViewIcon } from '../../../assets/images/contributor/svg/view.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/contributor/svg/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/contributor/svg/delete.svg';
import { ReactComponent as DownloadRelease } from '../../../assets/images/accountSettings/svg/DownloadRelease.svg';

import ReleaseForm from './ReleaseForm';

// import styled from 'styled-components';
// import Popup from 'reactjs-popup';

import { useNavigate } from 'react-router-dom';

import Slider from '@mui/material/Slider';
import SliderButton from '../../../assets/images/artList/sliderButton1.svg';
import scale from '../../../assets/images/contributor/scale.png';

import { ReactComponent as ResetButton } from '../../../assets/images/Icons/Icon_SetDefault_16x16.svg';
import { ReactComponent as IconAdd } from '../../../assets/images/Icons/Icon_Add_8x8.svg';

import KeywordPopup from './KeywordPopup';

import Cropper from 'react-easy-crop';

import { ReactComponent as Icon_DownArrow_Small } from '../../../assets/images/contributor/svg/Icon_DownArrow_Small.svg';

import horizontalCropIcon from '../../../assets/images/contributor/svg/horizontalCropIcon.png';
import verticalCropIcon from '../../../assets/images/contributor/svg/verticalCropIcon.png';
import squareCropIcon from '../../../assets/images/contributor/svg/squareCropIcon.png';

import { ReactComponent as HorizontalCropIcon } from '../../../assets/images/contributor/svg/Icon_HorizontalArtPosition_24x24.svg';
import { ReactComponent as VerticalCropIcon } from '../../../assets/images/contributor/svg/Icon_VerticalArtPosition_24x24.svg';
import { ReactComponent as SquareCropIcon } from '../../../assets/images/contributor/svg/Icon_SquareArtPosition_24x24.svg';

import { ReactComponent as Icon_AlignHorizontal } from '../../../assets/images/contributor/svg/Icon_AlignHorizontal_24x24.svg';
import { ReactComponent as Icon_AlignVertical } from '../../../assets/images/contributor/svg/Icon_AlignVertical_24x24.svg';

import { ReactComponent as Plus } from '../../../assets/images/contributor/svg/Plus.svg';
import { ReactComponent as Minus } from '../../../assets/images/contributor/svg/Minus.svg';

// import Reset from '../../../assets/images/contributor/svg/Reset.png';

// import colourizeIcon from '../../../assets/images/contributor/svg/colourizeIcon.png';

import { ReactComponent as ColourizeIcon } from '../../../assets/images/contributor/svg/Icon_ColourizeArt_24x24.svg';
import { ReactComponent as Reset } from '../../../assets/images/contributor/svg/Icon_Reset_24x24.svg';

import PopupCustom from './PopupCustom';
import { IndeterminateCheckBox } from '@mui/icons-material';

// import styled from 'styled-components';
// import Popup from 'reactjs-popup';

const StyledPopupDelete = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 32px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 16px;
    padding-right: 16px;
    width: 288px;
  }
`;

const StyledPopupAll = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px 50px 30px 50px;
    width: 438px;
    transition: opacity 0.5s ease-in-out !important;
    // height: 299px;
  }
`;

const StyledPopupFiles = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px;
    width: 514px;
  }
`;
const StyledPopupPackage = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px;
    width: 514px;
  }
`;

const StyledPopupViewRelease = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    // background-color: #ffffff;
    // color: #333333;
    border-radius: 30px;
    padding: 16px;
    width: fit-content;
    heigth: 825px;
  }
`;

const releaseCards = [
  { id: '1', name: 'jn jencj jbnejne eudnedn' },
  { id: '1', name: 'jn jencj jbnejne eudnedn' },
  { id: '1', name: 'jn jencj jbnejne eudnedn' },
];

const AddDetails = () => {
  const [images, setImages] = useState([]);

  const [selectedCard, setSelectedCard] = useState(null);
  const [thumbnail, setThumbnail] = useState('');
  const [description, setDescription] = useState('');
  const [charCountt, setCharCount] = useState(0);
  const [charNotesCount, setCharNotesCount] = useState(0);
  const [cards, setCards] = useState([]);
  const [textWord, setTextWord] = useState('');

  // drop down menu states
  const [isOpenSubject, setIsOpenSubject] = useState(false);
  const [isOpenStyle, setIsOpenStyle] = useState(false);
  const [isOpenMedium, setIsOpenMedium] = useState(false);

  const [allDropHover, setAllDropHover] = useState(false);

  const [subjectMenu, setSubjectMenu] = useState(null);
  const [styleMenu, setStyleMenu] = useState(null);
  const [mediumMenu, setMediumMenu] = useState(null);

  // const [releaseTypeMenu, setReleaseTypeMenu] = useState(null);

  const [isOpenReleaseType, setIsOpenReleaseType] = useState('');
  const [isOpenReleaseTypeBoolean, setIsOpenReleaseTypeBoolean] =
    useState(false);

  // useEffect(() => {
  //   console.log('setIsOpenReleaseType: ', isOpenReleaseType);
  // }, [isOpenReleaseType]);

  const [subjectMenuList, setSubjectMenuList] = useState([]);
  const [styleMenuList, setStyleMenuList] = useState([]);
  const [mediumMenuList, setMediumMenuList] = useState([]);

  const dispatch = useDispatch();

  const userId = useSelector((state) => state.auth.userId);

  const navigate = useNavigate();

  const keywordsFromManager = useSelector(
    (state) => state.keyword.keywords
  );

  // useEffect(() => {
  //   console.log(selectedCard);
  // }, [selectedCard]);

  useEffect(() => {
    dispatch(setpath2('/ Add Details'));
  }, []);

  const setReleaseTypeMenu = (id, type) => {
    setRelease((prevItems) => {
      return prevItems.map((item) => {
        if (item.releaseId === id) {
          return { ...item, type: type }; // Update the name of the matched item
        }
        return item; // Return other items unchanged
      });
    });
  };
  const setReleaseTypeMenuTemp = (index, type) => {
    settempRelese((prevItems) => {
      return prevItems.map((item, i) => {
        if (i === index) {
          return { ...item, type: type }; // Update the name of the matched item
        }
        return item; // Return other items unchanged
      });
    });
  };

  // useEffect(() => {
  //   return (cleanUp = () => {});
  // }, [temp]);

  // api calls start
  useEffect(() => {
    const getSubjectList = async () => {
      const response = await httpClient.get('/subject_master');
      setSubjectMenuList(response.data);
      // console.log(response.data);
    };

    const getStyleList = async () => {
      const response = await httpClient.get('/style_master');
      setStyleMenuList(response.data);
      console.log(response.data);
    };
    const getMediumList = async () => {
      const response = await httpClient.get('/medium_Master');
      setMediumMenuList(response.data);
      console.log(response.data);
    };
    getSubjectList();
    getStyleList();
    getMediumList();
  }, []);

  const [draftId, setdraftId] = useState('');

  // useEffect(() => {
  //   console.log(subjectMenu);
  // }, [subjectMenu]);

  const validateForm = () => {
    if (
      !formData.artName.trim() ||
      !formData.description.trim() ||
      formData.keywords.length === 0 ||
      !formData.mediumId.trim() ||
      !formData.subjectId.trim() ||
      !formData.styleId.trim() ||
      formData.typeOfContent.length === 0 ||
      !formData.notes.trim() ||
      formData.commercialUser.length === 0 ||
      !formData.price === 0 ||
      formData.keywords.length === 0 ||
      formData.imageOrientation.horizontalBCUrl === undefined ||
      formData.imageOrientation.horizontalBCUrl === null ||
      !formData.imageOrientation.horizontalBCUrl.trim() ||
      formData.imageOrientation.verticalBCUrl === undefined ||
      formData.imageOrientation.verticalBCUrl === null ||
      !formData.imageOrientation.verticalBCUrl.trim() ||
      formData.imageOrientation.squareBCUrl === undefined ||
      formData.imageOrientation.squareBCUrl === null ||
      !formData.imageOrientation.squareBCUrl.trim() ||
      formData.imageOrientation.horizontalUrl === undefined ||
      formData.imageOrientation.horizontalUrl === null ||
      !formData.imageOrientation.horizontalUrl.trim() ||
      formData.imageOrientation.verticalUrl === undefined ||
      formData.imageOrientation.verticalUrl === null ||
      !formData.imageOrientation.verticalUrl.trim() ||
      formData.imageOrientation.squareUrl === undefined ||
      formData.imageOrientation.squareUrl === null ||
      !formData.imageOrientation.squareUrl.trim() ||
      !formData.imageOrientation === undefined ||
      !formData.imageOrientation === null
    ) {
      return true;
    } else {
      return false;
    }
  };

  const addToActivateProd = async () => {
    if (validateForm()) {
      dispatch(popupSliceAction.setBackgroundColor('bg-pinkColor'));
      dispatch(
        popupSliceAction.setTitle1(
          'Please ensure all fields are completed and a thumbnail is created.'
        )
      );
      dispatch(popupSliceAction.setTitle2(''));
      dispatch(popupSliceAction.setOpenPopup());
      dispatch(popupSliceAction.setAnimationOpen());

      return;
    } else {
      let obj = {
        draftId,
        imageMasters: [formData],
        activeStatus: true,
        status: 'adddetails',
      };

      try {
        const res = await httpClient.put(
          `/images/updateImageMaster`,
          obj
        );

        // ogFormData.imageMaster.imageOrientaition =
        //   formData.imageOrientaition;

        console.log(ogFormData);

        const ress = await httpClient.put(
          `/images/updateImg`,
          ogFormData?.imageMaster
        );

        dispatch(setNestedTabValueUpload('2.4'));

        dispatch(setpath2('/ Activate Products'));
        console.log(res.data);
        console.log(ress.data);

        dispatch(
          popupSliceAction.setBackgroundColor('gradient-background3')
        );
        dispatch(
          popupSliceAction.setTitle1(
            'Successfully Moved to Activate Products'
          )
        );
        dispatch(popupSliceAction.setTitle2(''));
        dispatch(popupSliceAction.setOpenPopup());
        dispatch(popupSliceAction.setAnimationOpen());
      } catch (error) {
        dispatch(popupSliceAction.setBackgroundColor('bg-pinkColor'));
        dispatch(popupSliceAction.setTitle1('Something Went Wrong.'));
        dispatch(popupSliceAction.setTitle2(''));
        dispatch(popupSliceAction.setOpenPopup());
        dispatch(popupSliceAction.setAnimationOpen());
        // toast.error('Something Went Wrong');
        console.log(error);
      }
    }
  };

  const [tempRelese, settempRelese] = useState([]);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedSize, setUploadedSize] = useState(0);
  const [totalSize, setTotalSize] = useState(0);

  const [tempReleaseName, setTempReleaseName] = useState('');

  const handleFileChangeRelease = (event) => {
    const files = event.target.files;
    const newImages = Array.from(files);

    newImages.forEach((cards) => {
      setTempReleaseName(cards?.name);
      const xhr = new XMLHttpRequest();
      let formDataa = new FormData();
      formDataa.append('uploadfile', cards);

      xhr.upload.addEventListener('progress', (e) => {
        if (e.lengthComputable) {
          const progress = (e.loaded / e.total) * 100;

          console.log('progress', progress);
          setUploadProgress(progress);
          setUploadedSize(e.loaded / 1024); // Convert to KB
          setTotalSize(e.total / 1024); // Convert to KB
        }
      });
      setReleaseUploading(true);
      xhr.open('POST', `${baseURL}/uploadfiles`, true);

      // Do not manually set the Content-Type header here
      // xhr.setRequestHeader('Content-Type', 'multipart/form-data');

      xhr.send(formDataa);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            const res = JSON.parse(xhr.responseText);
            console.log('File uploaded successfully');
            console.log(res);

            const dataa = res;

            const obj = {
              type: 'default',
              nameOfPerson: 'art',
              description: 'art release',
              userId,
              releaseStatus: 'submitted',
              fileUrl: dataa.path,
              fileName: cards.name,
              fileSize: formatFileSize(cards.size), // Convert to KB
              artDetailsId: formData?.artDetailsId,
            };
            setReleaseUploading(false);
            settempRelese((prevImages) => [...prevImages, obj]);
          } else {
            setReleaseUploading(false);
            console.error('Upload failed');
          }
        }
      };
    });
  };

  useEffect(() => {
    console.log(totalSize);
  }, [totalSize]);

  // const handleFileChangeRelease = (event) => {
  //   const files = event.target.files;
  //   const newImagesArray = Array.from(files);

  //   newImagesArray.forEach((cards) => {
  //     const formDataa = new FormData();
  //     formDataa.append('uploadfile', cards);

  //     httpClient
  //       .post('/uploadfiles', formDataa, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //         onUploadProgress: (e) => {
  //           if (e.lengthComputable) {
  //             const progress = (e.loaded / e.total) * 100;

  //             console.log('progress', progress);
  //             setUploadProgress(progress);
  //             setUploadedSize(e.loaded / 1024);
  //             setTotalSize(e.total / 1024);
  //           }
  //         },
  //       })
  //       .then((res) => {
  //         console.log('File uploaded successfully');
  //         console.log(res.data);
  //         const dataa = res.data;
  //         const obj = {
  //           type: 'default',
  //           nameOfPerson: 'art',
  //           description: 'art release',
  //           userId,
  //           releaseStatus: 'submitted',
  //           fileUrl: dataa.path,
  //           fileName: cards.name,
  //           fileSize: formatFileSize(cards.size),
  //           artDetailsId: formData?.artDetailsId,
  //         };

  //         settempRelese((prevImages) => [...prevImages, obj]);
  //       })
  //       .catch((err) => {
  //         console.error('Upload failed', err);
  //       });
  //   });
  // };

  const formatSize = (size) => {
    if (size < 1024) {
      return `${size} KB`;
    } else {
      const sizeInMB = size / 1024;
      return sizeInMB < 1024
        ? `${sizeInMB.toFixed(2)} MB`
        : `${(sizeInMB / 1024).toFixed(2)} GB`;
    }
  };

  // const handleFileChangeRelease = (event) => {
  //   const files = event.target.files;
  //   const newImages = Array.from(files);

  //   newImages?.forEach((cards) => {

  //     const xhr = new XMLHttpRequest();
  //     let formDataa = new FormData();
  //     formDataa.append('uploadfile', cards);

  //     xhr.upload.addEventListener('progress', (e) => {
  //       if (e.lengthComputable) {
  //         const progress = (e.loaded / e.total) * 100;
  //         setUploadProgress(progress);
  //         setUploadedSize(e.loaded);
  //         setTotalSize(e.total);
  //       }
  //     });

  //     httpClient
  //       .post('/uploadfiles', formDataa, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       })

  //       .then((res) => {
  //         console.log('file uploaded successfully');
  //         console.log(res.data);
  //         const dataa = res.data;

  //         const obj = {
  //           type: 'default',
  //           nameOfPerson: 'art',
  //           description: 'art release',
  //           userId,
  //           releaseStatus: 'submitted',
  //           fileUrl: dataa.path,
  //           fileName: cards?.name,
  //           fileSize: formatFileSize(cards.size),
  //           artDetailsId: formData?.artDetailsId,
  //         };

  //         settempRelese((prevImages) => [...prevImages, obj]);
  //       })

  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   });

  // };

  const AddRelease = async () => {
    try {
      const res = await httpClient.post(
        '/release_master/createReleaseMaster',
        tempRelese
      );
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  // api calls end

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newCards = Array.from(files);

    setCards((prevCards) => [...prevCards, ...newCards]);

    // console.log('new card', newCards);
    newCards.forEach((cards) => {
      let formData = new FormData();
      formData.append('file', cards);
      httpClient
        .post('/CloudinaryImageUpload?parameter=false', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          console.log('file uploaded successfully');
          // console.log(res);
          setFormData((prevFormData) => ({
            ...prevFormData,
            referenceFile: [...prevFormData.referenceFile, res.data],
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const selectedImages = useSelector(
    (state) => state.images.selectedImages
  );

  const [pencilState, setPencilState] = useState(false);

  useEffect(() => {
    if (selectedImages.length <= 0) {
      setEdit(false);
      setPencilState(false);
    } else {
      setEdit(true);
      setPencilState(true);
    }
  }, [selectedImages]);

  const toggleMenuSubject = () => {
    setIsOpenSubject(!isOpenSubject);
  };

  const toggleMenuStyle = () => {
    setIsOpenStyle(!isOpenStyle);
  };

  const toggleMenuMedium = () => {
    setIsOpenMedium(!isOpenMedium);
  };

  const handleCardSelect = (card) => {
    // console.log('card selected', card);
    setSelectedCard(card);

    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   image: card,
    // }));
  };

  // dropdown select func
  const handleSubjectMenuClick = (subject) => {
    setSubjectMenu(subject);
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['subjectId']: subject.subjectId,
    }));
  };

  const handleStyleMenuClick = (style) => {
    setStyleMenu(style);
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['styleId']: style.styleId,
    }));
  };

  const handleMediumMenuClick = (value) => {
    setMediumMenu(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['mediumId']: value.mediumId,
    }));
  };

  // button click
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // description onchange func
  const handleDescriptionChange = (event) => {
    const char = event.target.value.slice(0, 450);
    setDescription(char);
    // const CharCount = char.length;
    // setCharCount(CharCount);
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['description']: char,
    }));
    // console.log(charCountt);
  };

  const handleNotesChange = (event) => {
    const char = event.target.value.slice(0, 150);
    // setDescription(char);
    // const CharCount = char.length;
    // setCharNotesCount(CharCount);
    // handleInputChange(event);
    // console.log(charCountt);
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['notes']: char,
    }));
  };

  const keywordText = (e) => {
    const text = e.target.value;
    setTextWord(text);
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['keywords']: keywordsFromManager,
    }));
  }, [keywordsFromManager]);

  const isOnlySpaces = () => {
    return textWord?.trim().length === 0;
  };

  const addKeyword = () => {
    if (checkForComma(textWord)) {
      const newKeywords = textWord
        .split(',')
        .map((word) => word.trim());

      if (hasCommonString(newKeywords, formData?.keywords)) {
        dispatch(popupSliceAction.setBackgroundColor('bg-pinkColor'));
        dispatch(
          popupSliceAction.setTitle1('Please enter a unique Keywords')
        );
        dispatch(popupSliceAction.setTitle2(''));
        dispatch(popupSliceAction.setOpenPopup());
        dispatch(popupSliceAction.setAnimationOpen());
      } else {
        // Update the state with the new keywords
        setFormData((prevFormData) => ({
          ...prevFormData,
          keywords: [...prevFormData.keywords, ...newKeywords],
        }));
        setTextWord('');
      }
    } else {
      if (formData?.keywords?.length < 50 && textWord.length > 0) {
        if (!checkSameWordinKeywords(textWord)) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            ['keywords']: [...prevFormData.keywords, textWord],
          }));
          setTextWord('');
        } else {
          dispatch(
            popupSliceAction.setBackgroundColor('bg-pinkColor')
          );
          dispatch(
            popupSliceAction.setTitle1(
              'Please enter a unique Keyword'
            )
          );
          dispatch(popupSliceAction.setTitle2(''));
          dispatch(popupSliceAction.setOpenPopup());
          dispatch(popupSliceAction.setAnimationOpen());
        }
      }
    }
  };

  const checkForComma = (str) => {
    return str.includes(',');
  };

  const checkSameWordinKeywords = (word) => {
    const find = formData?.keywords?.some(
      (element) => element === word
    );
    return find;
  };

  const hasCommonString = (array1, array2) => {
    const set1 = new Set(array1);
    return array2.some((item) => set1.has(item));
  };

  const deleteKeyword = (keyword) => {
    const updatedKeyword = formData?.keywords?.filter(
      (obje) => obje !== keyword
    );
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['keywords']: updatedKeyword,
    }));
    const find = MatchWithSuggestedKeywordsList(keyword);
    // console.log(find);
    if (find?.keyword) {
      let tempKeywordSugg = [...keywordSugg];
      tempKeywordSugg?.splice(find?.index, 0, find?.keyword);
      console.log(tempKeywordSugg);
      setkeywordSugg(tempKeywordSugg);
    }
  };

  const MatchWithSuggestedKeywordsList = (keyword) => {
    const find = keywordSuggCompare?.find((item) => item === keyword);

    if (find) {
      const index = keywordSuggCompare.indexOf(keyword);
      return {
        keyword: find,
        index,
      };
    } else {
      return {
        keyword: find,
        index: null,
      };
    }
  };

  const [basePrice, setbasePrice] = useState(0);
  const [sellPrice, setsellPrice] = useState(0);

  const handleInputChangePrice = (event) => {
    const { name, value } = event.target;
    const parsedValue = parseFloat(value);
    setFormData({ ...formData, [name]: parsedValue });
    getSellAndBasePrice(parsedValue);
  };

  const getSellAndBasePrice = async (markup) => {
    try {
      const res = await httpClient.get(
        `/shape_master/getContributerEarning100percentage/${markup}`
      );
      console.log(res.data);
      setsellPrice(res.data.sellingPrince);
      setbasePrice(res.data.basicPrice);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    console.log(event.target.name);
    console.log(event.target.value);
    console.log(typeof event.target.value);

    if (type === 'checkbox' && name == 'commercialUser') {
      if (checked) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ['commercialUser']: [...prevFormData.commercialUser, value],
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ['commercialUser']: prevFormData.commercialUser.filter(
            (usage) => usage !== value
          ),
        }));
      }
    } else if (type === 'checkbox' && name == 'typeOfContent') {
      if (checked) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ['typeOfContent']: [...prevFormData.typeOfContent, value],
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ['typeOfContent']: prevFormData.typeOfContent.filter(
            (usage) => usage !== value
          ),
        }));
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    // setImages(selectedImages);
    getActivateProductList();
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   userId: userId,
    // }));
  }, []);

  const setExclusiveCheck = (value) => {
    if (!typeOfContentCheckoxesTick('Exclusive Art')) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ['typeOfContent']: [...prevFormData.typeOfContent, value],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ['typeOfContent']: prevFormData.typeOfContent.filter(
          (usage) => usage !== value
        ),
      }));
    }
  };

  const setFeaturedCheck = (value) => {
    if (!typeOfContentCheckoxesTick('Featured Art')) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ['typeOfContent']: [...prevFormData.typeOfContent, value],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ['typeOfContent']: prevFormData.typeOfContent.filter(
          (usage) => usage !== value
        ),
      }));
    }
  };

  // useEffect(() => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     referenceFile: cards,
  //   }));
  // }, [cards]);

  // releasefileInputRefRelease

  const fileInputRefRelease = useRef(null);

  const handleButtonClickRelease = () => {
    fileInputRefRelease.current.click();
  };

  const formatFileSize = (bytes) => {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;

    if (bytes < kilobyte) {
      return bytes + ' B';
    } else if (bytes < megabyte) {
      return (bytes / kilobyte).toFixed(2) + ' KB';
    } else {
      return (bytes / megabyte).toFixed(2) + ' MB';
    }
  };

  useEffect(() => {
    console.log(tempRelese);
  }, [tempRelese]);

  const handleCheckboxChangeRelease = (obj) => {
    const array = formData?.releases?.find(
      (item) => item === obj.dataa
    );

    console.log('releases: ', array);
    if (!array) {
      setFormData((prevImages) => ({
        ...prevImages,
        ['releases']: [...prevImages.releases, obj.dataa],
      }));
    } else {
      setFormData((prevImages) => ({
        ...prevImages,
        ['releases']: prevImages.releases.filter(
          (item) => item !== obj.dataa
        ),
      }));
    }
  };

  const scrollbarStyle = `
  ::-webkit-scrollbar {
    background: transparent!important;
    width: 7px;
 
  }

  ::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 5px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb:hover { 
    background: gray; /* Change the thumb background color on hover */
  }

    /* Add any other scrollbar styles here */
  `;

  // handle outside click

  const handleClickOutsideSubject = () => {
    // Code to handle click outside
    setIsOpenSubject(false);
  };

  const subjectClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideSubject,
  });

  const handleClickOutsideReleaseType = () => {
    // Code to handle click outside
    setIsOpenReleaseTypeBoolean(false);
  };

  const releaseTypeClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideReleaseType,
  });

  const handleClickOutsideStyle = () => {
    // Code to handle click outside
    setIsOpenStyle(false);
  };

  const styleClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideStyle,
  });

  const handleClickOutsideMedium = () => {
    // Code to handle click outside
    setIsOpenMedium(false);
  };

  const mediumClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideMedium,
  });

  const grayBox = ['a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a'];

  const [grayNum, setgrayNum] = useState(grayBox.length);

  useEffect(() => {
    if (images.length < 11) {
      setgrayNum(grayBox.length - images.length);
    }

    console.log(images.length);
  }, [images]);

  // const postAddDetailsForm = async () => {
  //   try {
  //     const res = await httpClient.post(
  //       '/Add_Details_Master',
  //       formData
  //     );

  //     console.log(res.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const updateAddDetailsForm = async () => {
  //   console.log(formData);

  //   try {
  //     const res = await httpClient.put(
  //       '/Add_Details_Master',
  //       formData
  //     );

  //     toast.success('Successfully Art Details saved to Draft');
  //     console.log(res.data);
  //   } catch (error) {
  //     toast.error('Something Went Wrong');
  //     console.log(error);
  //   }
  // };

  const [draftApiCall, setdraftApiCall] = useState(false);

  const updateAddDetailsForm = async () => {
    // console.log(formData);
    // console.log(tempRelese);
    setdraftApiCall(true);
    try {
      // First, make the PUT request
      const res = await httpClient.put(
        '/Add_Details_Master',
        formData
      );
      console.log(res.data);

      // Then, iterate over each object in tempRelese array
      for (const release of tempRelese) {
        // Make API call for each release object
        const createReleaseRes = await httpClient.post(
          '/release_master/createReleaseMaster',
          release
        );

        // Handle response for each API call
        console.log(createReleaseRes.data);
      }
      // Then, iterate over each object in tempRelese array
      for (const release of Release) {
        let copy = { ...release };

        copy.artDetailsId = copy?.addDetailsMaster?.artDetailsId;

        copy.userId = copy?.userMaster?.userId;

        delete copy?.addDetailsMaster;
        delete copy?.userMaster;

        // Make API call for each release object
        const oldReleaseRes = await httpClient.put(
          '/release_master/updateReleaseMaster',
          copy
        );

        // Handle response for each API call
        console.log('oldReleaseRes', oldReleaseRes.data);
      }

      // Handle success
      // toast.success('Successfully Art Details saved to Draft');
      // setNormalPopupOpen(true);
      setdraftApiCall(false);
      dispatch(
        popupSliceAction.setBackgroundColor('gradient-background3')
      );
      dispatch(popupSliceAction.setTitle1('Successfully Saved'));
      dispatch(popupSliceAction.setTitle2('the Art Details Draft.'));
      dispatch(popupSliceAction.setOpenPopup());
      dispatch(popupSliceAction.setAnimationOpen());
    } catch (error) {
      // Handle errors
      // toast.error('Something Went Wrong');
      setdraftApiCall(false);
      dispatch(popupSliceAction.setBackgroundColor('bg-pinkColor'));
      dispatch(popupSliceAction.setTitle1('Something Went Wrong.'));
      dispatch(popupSliceAction.setTitle2(''));
      dispatch(popupSliceAction.setOpenPopup());
      dispatch(popupSliceAction.setAnimationOpen());

      console.log(error);
    }
  };

  const [Release, setRelease] = useState([]);

  useEffect(() => {
    console.log('Release', Release);
  }, [Release]);

  const GetReleaseByAddDetailsId = async (id) => {
    try {
      const res = await httpClient.get(
        `/release_master/getReleasesByArtDetailsId/${id}`
      );
      setRelease(res.data);
      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const checkAddDetailExists = async () => {
    const obj = {
      userId: userId,
      imageId: selectedImages[0]?.imageId,
    };

    try {
      const res = await httpClient.post(
        '/Add_Details_Master/checkExistOrNot',
        obj
      );
      if (res.data === true) {
        updateAddDetailsForm();
      } else {
        // postAddDetailsForm();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // main state of formData

  // artName: '',
  // commercialUser: [],
  // typeOfContent: [],
  // referenceFile: [],
  // description: '',
  // keywords: [],
  // artMedium: '',
  // subjectId: '',
  // styleId: '',
  // image: '',
  // userId: '',
  // releases: [],
  // price: 0,
  // notes: '',
  // imageId: selectedImages[0]?.imageId,
  // draftId,

  const [formData, setFormData] = useState({});

  const getActivateProductList = async () => {
    dispatch(setSelectedImages([]));
    try {
      const res = await httpClient.get(
        `/draft_master/getDraftMasterByStatus/adddetails/${userId}`
      );
      // const res = await httpClient.get(
      //   `/draft_master/getDraftMasterByStatus/adddetails/${userId}`
      // );

      // console.log(res.data);
      console.log(res.data);
      setImages(res.data.imageMaster);
      // setFormData({});

      setdraftId(res.data.draftId);

      dispatch(setSelectedImages([res.data.imageMaster[0]]));
      setSelectedCard(res.data.imageMaster[0]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log('selectedImages', selectedImages);
  }, [selectedImages]);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const [userUniqueNo, setuserUniqueNo] = useState('');

  const [StyleName, setStyleName] = useState('');
  const [SubjectName, setSubjectName] = useState('');

  const [ogFormData, setOgFormData] = useState();

  useEffect(() => {
    console.log(ogFormData);
  }, [ogFormData]);

  const getSingleImageDataOnSelect = async () => {
    const obj = {
      userId,
      imageId: selectedCard?.imageId,
    };
    try {
      const res = await httpClient.post(
        '/Add_Details_Master/getArtDetails',
        obj
      );

      // herenow
      console.log('res: ', res.data);
      console.log('res: ', res.data?.imageMaster?.zoom);

      setuserUniqueNo(res.data.userMaster.userUniqueNo);

      // setStyleName(res.data.styleMaster.styleName);

      // setSubjectName(res.data.subjectMaster.subjectName);

      // settingConvertedFormDataObject(res.data);

      const temp = convertAddDetailsObj(res.data);
      setOgFormData(res.data);
      setFormData(temp);
      getSellAndBasePrice(temp?.price);
      settempRelese([]);
      GetReleaseByAddDetailsId(res?.data?.artDetailsId);
      // setZoom(temp.zoom);

      // setCrop((prevState) => ({
      //   ...prevState,
      //   x: temp.xaxis,
      //   y: temp.yaxis,
      // }));

      // setCrop(temp.zoom);

      // if (res.data.zoom === 0) {
      //   setFormData((prevFormData) => ({
      //     ...prevFormData,
      //     ['zoom']: 1,
      //   }));
      // }

      if (res.data.subjectMaster?.subjectId === null) {
        setSubjectMenu(null);
      } else {
        setSubjectMenu(res.data.subjectMaster);
      }

      if (res.data.styleMaster?.styleId === null) {
        setStyleMenu(null);
      } else {
        setStyleMenu(res.data.styleMaster);
      }

      if (res.data.mediumMaster?.mediumId === null) {
        setMediumMenu(null);
      } else {
        setMediumMenu(res.data.mediumMaster);
      }

      if (selectedImages && selectedImages.length > 0) {
        const firstImg = selectedImages[0];

        // setFormData((prevFormData) => ({
        //   ...prevFormData,
        //   ['imageId']: firstImg?.imageId,
        // }));

        const url = firstImg;
        setThumbnail(url);
      }
      // setObjToForm(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const convertAddDetailsObj = (objj) => {
    const obj = { ...objj };

    // console.log('obj: ', obj);
    // console.log('objj: ', objj);
    if (obj.styleMaster?.styleId !== null) {
      obj.styleId = obj.styleMaster?.styleId;

      delete obj.styleMaster;
    } else {
      delete obj.styleMaster;
      obj.styleId = '';
    }

    if (obj.subjectMaster?.subjectId !== null) {
      obj.subjectId = obj.subjectMaster?.subjectId;

      delete obj.subjectMaster;
    } else {
      delete obj.subjectMaster;
      obj.subjectId = '';
    }

    if (obj.mediumMaster?.mediumId !== null) {
      obj.mediumId = obj.mediumMaster?.mediumId;

      delete obj.mediumMaster;
    } else {
      delete obj.mediumMaster;
      obj.mediumId = '';
    }

    // console.log(obj.imageMaster);
    obj.imageOrientation = obj.imageMaster?.imageOrientation;

    obj.zoom = obj.imageMaster?.zoom;
    obj.xaxis = obj.imageMaster?.xaxis;
    obj.yaxis = obj.imageMaster?.yaxis;

    if (obj.imageMaster?.imageId !== null) {
      obj.imageId = obj.imageMaster?.imageId;

      delete obj.imageMaster;
    } else {
      delete obj.imageMaster;
      obj.imageId = '';
    }

    obj.userId = obj.userMaster?.userId;

    delete obj.userMaster;

    console.log(obj);
    return obj;
  };

  const setObjToForm = (obj) => {
    // setFormData(obj);
  };

  useEffect(() => {
    console.log(subjectMenu);
  }, [subjectMenu]);

  useEffect(() => {
    getSingleImageDataOnSelect();
  }, [selectedCard]);

  const handleChildFunctionCall = (message) => {
    // This function is called from the child component
    console.log(message);
  };

  // crop image code
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(3);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // useEffect(() => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     ['xaxis']: crop.x,
  //     ['yaxis']: crop.y,
  //   }));
  // }, [crop]);

  // useEffect(() => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     ['zoom']: zoom,
  //   }));
  // }, [zoom]);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const horizontalAlign = () => {
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   ['xaxis']: 0,
    //   ['yaxis']: 0,
    // }));

    if (currentOri === 'horizontal') {
      setFormData((prevState) => ({
        ...prevState,
        previews: {
          ...prevState?.previews,
          horizontalPreview: {
            ...prevState?.previews?.horizontalPreview,
            xaxis: 0,
            // yaxis: 0,
          },
        },
      }));
    } else if (currentOri === 'vertical') {
      setFormData((prevState) => ({
        ...prevState,
        previews: {
          ...prevState?.previews,
          verticalPreview: {
            ...prevState?.previews?.verticalPreview,
            xaxis: 0,
            //  yaxis: 0,
          },
        },
      }));
    } else if (currentOri === 'square') {
      setFormData((prevState) => ({
        ...prevState,
        previews: {
          ...prevState.previews,
          squarePreview: {
            ...prevState?.previews?.squarePreview,
            xaxis: 0,
            //  yaxis: 0,
          },
        },
      }));
    }
  };
  const verticalAlign = () => {
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   ['xaxis']: 0,
    //   ['yaxis']: 0,
    // }));

    if (currentOri === 'horizontal') {
      setFormData((prevState) => ({
        ...prevState,
        previews: {
          ...prevState?.previews,
          horizontalPreview: {
            ...prevState?.previews?.horizontalPreview,
            // xaxis: 0,
            yaxis: 0,
          },
        },
      }));
    } else if (currentOri === 'vertical') {
      setFormData((prevState) => ({
        ...prevState,
        previews: {
          ...prevState?.previews,
          verticalPreview: {
            ...prevState?.previews?.verticalPreview,
            // xaxis: 0,
            yaxis: 0,
          },
        },
      }));
    } else if (currentOri === 'square') {
      setFormData((prevState) => ({
        ...prevState,
        previews: {
          ...prevState.previews,
          squarePreview: {
            ...prevState?.previews?.squarePreview,
            // xaxis: 0,
            yaxis: 0,
          },
        },
      }));
    }
  };

  const handleCropChange = (crop) => {
    // console.log(crop);
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   ['xaxis']: crop.x,
    //   ['yaxis']: crop.y,
    // }));
    // setCrop(crop);

    if (currentOri === 'horizontal') {
      setFormData((prevState) => ({
        ...prevState,
        previews: {
          ...prevState?.previews,
          horizontalPreview: {
            ...prevState?.previews?.horizontalPreview,
            xaxis: crop.x,
            yaxis: crop.y,
          },
        },
      }));
    } else if (currentOri === 'vertical') {
      setFormData((prevState) => ({
        ...prevState,
        previews: {
          ...prevState?.previews,
          verticalPreview: {
            ...prevState?.previews?.verticalPreview,
            xaxis: crop.x,
            yaxis: crop.y,
          },
        },
      }));
    } else if (currentOri === 'square') {
      setFormData((prevState) => ({
        ...prevState,
        previews: {
          ...prevState.previews,
          squarePreview: {
            ...prevState?.previews?.squarePreview,
            xaxis: crop.x,
            yaxis: crop.y,
          },
        },
      }));
    }
  };

  const handleZoomChange = (zoom) => {
    // if (zoom > 2) {
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   ['zoom']: zoom,
    // }));
    if (currentOri === 'horizontal') {
      setFormData((prevState) => ({
        ...prevState,
        previews: {
          ...prevState.previews,
          horizontalPreview: {
            ...prevState.previews.horizontalPreview,
            zoom: zoom,
          },
        },
      }));
    } else if (currentOri === 'vertical') {
      setFormData((prevState) => ({
        ...prevState,
        previews: {
          ...prevState.previews,
          verticalPreview: {
            ...prevState.previews.verticalPreview,
            zoom: zoom,
          },
        },
      }));
    } else if (currentOri === 'square') {
      setFormData((prevState) => ({
        ...prevState,
        previews: {
          ...prevState.previews,
          squarePreview: {
            ...prevState.previews.squarePreview,
            zoom: zoom,
          },
        },
      }));
    }
    // setZoom(zoom);
    // }
  };

  const handleZoomChangeSlider = (e) => {
    const zoom = e.target.value;
    // console.log(zoom);
    // if (zoom > 2) {
    if (currentOri === 'horizontal') {
      setFormData((prevState) => ({
        ...prevState,
        previews: {
          ...prevState.previews,
          horizontalPreview: {
            ...prevState.previews.horizontalPreview,
            zoom: zoom,
          },
        },
      }));
    } else if (currentOri === 'vertical') {
      setFormData((prevState) => ({
        ...prevState,
        previews: {
          ...prevState.previews,
          verticalPreview: {
            ...prevState.previews.verticalPreview,
            zoom: zoom,
          },
        },
      }));
    } else if (currentOri === 'square') {
      setFormData((prevState) => ({
        ...prevState,
        previews: {
          ...prevState.previews,
          squarePreview: {
            ...prevState.previews.squarePreview,
            zoom: zoom,
          },
        },
      }));
    }
    // setZoom(zoom);
    // }
  };

  const [imageFile, setimageFile] = useState();

  const handleCropImage = async () => {
    try {
      const croppedImageFile = await getCroppedImg(
        selectedImages[0]?.thumbnailUrl,
        croppedAreaPixels
      );
      // Handle cropped image file (e.g., save it or display it)
      console.log('Cropped image file:', croppedImageFile);

      let formData = new FormData();
      formData.append('file', croppedImageFile);

      const res = await httpClient.post(
        '/CloudinaryImageUpload?parameter=false',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (res) {
        return res.data;
      }
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  };

  const getCroppedImg = (imageSrc, crop) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.src = imageSrc;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        // Determine the image format based on the source
        const format = imageSrc.endsWith('.png')
          ? 'image/png'
          : 'image/jpeg';

        canvas.toBlob((blob) => {
          if (!blob) {
            console.error('Canvas is empty');
            reject(new Error('Canvas is empty'));
            return;
          }
          const fileName = `cropped_image.${format.split('/')[1]}`; // Extract extension from MIME type
          const file = new File([blob], fileName, {
            type: format,
          });
          resolve(file);
        }, format);
      };

      image.onerror = (error) => {
        console.error('Error loading image:', error);
        reject(error);
      };
    });
  };

  // const getCroppedImg = (imageSrc, crop) => {
  //   return new Promise((resolve, reject) => {
  //     const image = new Image();
  //     image.crossOrigin = 'anonymous';
  //     image.src = imageSrc;
  //     image.onload = () => {
  //       const canvas = document?.createElement('canvas');
  //       const scaleX = image?.naturalWidth / image?.width;
  //       const scaleY = image?.naturalHeight / image?.height;
  //       canvas.width = crop?.width;
  //       canvas.height = crop?.height;
  //       const ctx = canvas.getContext('2d');
  //       ctx.drawImage(
  //         image,
  //         crop?.x * scaleX,
  //         crop?.y * scaleY,
  //         crop?.width * scaleX,
  //         crop?.height * scaleY,
  //         0,
  //         0,
  //         crop?.width,
  //         crop?.height
  //       );
  //       canvas.toBlob((blob) => {
  //         if (!blob) {
  //           console.error('Canvas is empty');
  //           reject(new Error('Canvas is empty'));
  //           return;
  //         }
  //         const fileName = 'cropped_image.jpg'; // Set the desired file name
  //         const file = new File([blob], fileName, {
  //           type: 'image/jpeg',
  //         });
  //         resolve(file);
  //       }, 'image/jpeg');
  //     };
  //     image.onerror = (error) => {
  //       console.error('Error loading image:', error);
  //       reject(error);
  //     };
  //   });
  // };

  const [cropHeight, setCropHeight] = useState(486);
  const [cropWidth, setCropWidth] = useState(688);

  useEffect(() => {
    console.log(cropWidth);
  }, [cropWidth]);

  const [sliderValue, setSliderValue] = useState(1);
  const [currentOri, setCurrentOri] = useState('horizontal');
  useEffect(() => {
    if (currentOri === 'horizontal') {
      setSliderValue(
        formData?.previews?.horizontalPreview?.zoom || 1
      );
    } else if (currentOri === 'vertical') {
      setSliderValue(formData?.previews?.verticalPreview?.zoom || 1);
    } else if (currentOri === 'square') {
      setSliderValue(formData?.previews?.squarePreview?.zoom || 1);
    }
  }, [formData?.previews, currentOri]);

  const [HoriHover, setHoriHover] = useState(false);

  // useEffect(() => {
  //   ImageCropCapture();
  // }, [cropWidth, cropHeight, currentOri]);

  const ImageCropCapture = async () => {
    if (BWEnable) {
      dispatch(popupSliceAction.setBackgroundColor('bg-pinkColor'));
      dispatch(
        popupSliceAction.setTitle1(
          'Please disable Black and White preview before creating a thumbnail'
        )
      );
      dispatch(popupSliceAction.setTitle2(''));
      dispatch(popupSliceAction.setOpenPopup());
      dispatch(popupSliceAction.setAnimationOpen());
      // toast.error(
      //   'Please disable Black and White preview before creating a thumbnail'
      // );
      return;
    }
    const value = await handleCropImage();

    console.log(value);

    if (currentOri === 'horizontal') {
      if (formData.imageOrientaition !== null) {
        const GrayUrl = addGrayscaleTransformation(value);

        setFormData({
          ...formData,
          ['imageOrientation']: {
            ...formData.imageOrientation,
            horizontalUrl: value,
            horizontalBCUrl: GrayUrl,
          },
        });
        setOgFormData({
          ...ogFormData, // Spread operator to clone formData
          imageMaster: {
            ...ogFormData.imageMaster, // Spread operator to clone imageMaster
            imageOrientation: {
              ...ogFormData.imageMaster.imageOrientation, // Spread operator to clone imageOrientation
              horizontalUrl: value, // Update horizontalUrl with new value
              horizontalBCUrl: GrayUrl, // Update horizontalBCUrl with new value
            },
          },
        });
      }

      // toast.success('Successfully Captured Horizontal Thumbnail');
      dispatch(popupSliceAction.setTitle1('Successfully Captured'));
      dispatch(
        popupSliceAction.setTitle2(
          'Horizontal Orientation Thumbnail of the Art'
        )
      );
      dispatch(popupSliceAction.setOpenPopup());
      dispatch(popupSliceAction.setAnimationOpen());
    } else if (currentOri === 'vertical') {
      if (formData.imageOrientaition !== null) {
        const GrayUrl = addGrayscaleTransformation(value);
        setFormData({
          ...formData,
          ['imageOrientation']: {
            ...formData.imageOrientation,
            verticalUrl: value,
            verticalBCUrl: GrayUrl,
          },
        });

        setOgFormData({
          ...ogFormData, // Spread operator to clone ogFormData
          imageMaster: {
            ...ogFormData.imageMaster, // Spread operator to clone imageMaster
            imageOrientation: {
              ...ogFormData.imageMaster.imageOrientation, // Spread operator to clone imageOrientation
              verticalUrl: value, // UpdateverticalUrl with new value
              verticalBCUrl: GrayUrl, // UpdateverticalBCUrl with new value
            },
          },
        });
      }
      // toast.success('Successfully Captured Vertical Thumbnail');
      dispatch(popupSliceAction.setTitle1('Successfully Captured'));
      dispatch(
        popupSliceAction.setTitle2(
          'Vertical Orientation Thumbnail of the Art'
        )
      );
      dispatch(popupSliceAction.setOpenPopup());
      dispatch(popupSliceAction.setAnimationOpen());
    } else if (currentOri === 'square') {
      if (formData.imageOrientaition !== null) {
        const GrayUrl = addGrayscaleTransformation(value);
        setFormData({
          ...formData,
          ['imageOrientation']: {
            ...formData.imageOrientation,
            squareUrl: value,
            squareBCUrl: GrayUrl,
          },
        });
        setOgFormData({
          ...ogFormData, // Spread operator to clone ogFormData
          imageMaster: {
            ...ogFormData.imageMaster, // Spread operator to clone imageMaster
            imageOrientation: {
              ...ogFormData.imageMaster.imageOrientation, // Spread operator to clone imageOrientation
              squareUrl: value, // UpdatesquareUrl with new value
              squareBCUrl: GrayUrl, // UpdateverticalBCUrl with new value
            },
          },
        });
      }
      // toast.success('Successfully Captured Square Thumbnail');
      dispatch(popupSliceAction.setTitle1('Successfully Captured'));
      dispatch(
        popupSliceAction.setTitle2(
          'Square Orientation Thumbnail of the Art'
        )
      );
      dispatch(popupSliceAction.setOpenPopup());
      dispatch(popupSliceAction.setAnimationOpen());
    }
  };

  function addGrayscaleTransformation(originalUrl) {
    // Find the index of "upload/" in the URL
    const uploadIndex = originalUrl.indexOf('upload/');

    if (uploadIndex !== -1) {
      // Add "e_grayscale/" after "upload/"
      const modifiedUrl =
        originalUrl.slice(0, uploadIndex + 7) +
        'e_grayscale/' +
        originalUrl.slice(uploadIndex + 7);
      return modifiedUrl;
    } else {
      // If "upload/" is not found, return the original URL
      return originalUrl;
    }
  }

  function addGrayscaleTransformationForDisplay(originalUrl) {
    console.log('originalUrl', originalUrl);
    // Find the index of "upload/" in the URL
    const uploadIndex = originalUrl.indexOf('upload/');

    if (uploadIndex !== -1) {
      // Add "e_grayscale/" after "upload/"
      const modifiedUrl =
        originalUrl.slice(0, uploadIndex + 7) +
        'h_800,e_grayscale,dn_72,q_auto:low/' +
        originalUrl.slice(uploadIndex + 7);
      return modifiedUrl;
    } else {
      // If "upload/" is not found, return the original URL
      return originalUrl;
    }
  }

  const [pendingOri, setPendingOri] = useState(null);

  const SetHorizontalOri = () => {
    setCropHeight(486);
    setCropWidth(688);
    setPendingOri('horizontal');
  };

  const SetVerticalOri = () => {
    setCropHeight(486);
    setCropWidth(343);
    setPendingOri('vertical');
  };

  const SetSquareOri = () => {
    setCropHeight(486);
    setCropWidth(486);
    setPendingOri('square');
  };

  useEffect(() => {
    if (pendingOri) {
      setCurrentOri(pendingOri);
      setPendingOri(null);
    }
  }, [cropHeight, cropWidth, pendingOri]);
  // crop image code

  const [Exclusive, setExclusive] = useState(true);
  const [Featured, setFeatured] = useState(true);

  // checkboxes check valid function

  const typeOfContentCheckoxesTick = (value) => {
    return formData.typeOfContent?.some((str) => str === value);
  };
  const commercialUsageCheckoxesTick = (value) => {
    return formData.commercialUser?.some((str) => str === value);
  };

  useEffect(() => {
    console.log(styleMenu);
  }, [styleMenu]);

  const [HoriOrientHover, setHoriOrientHover] = useState(false);
  const [VertOrientHover, setVertOrientHover] = useState(false);
  const [SquaOrientHover, setSquaOrientHover] = useState(false);

  const deleteReferenceImage = (obj) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['referenceFile']: prevFormData.referenceFile.filter(
        (usage) => usage !== obj
      ),
    }));
  };

  const clearKeywords = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['keywords']: [],
    }));
  };

  const [BWEnable, setBWEnable] = useState(false);

  const [categorySelected, setcategorySelected] = useState('art');

  const [artIconHover, setArtIconHover] = useState(false);
  const [photosIconHover, setPhotosIconHover] = useState(false);

  const DeleteReleaseTemp = () => {
    const NewR = tempRelese.filter(
      (obj, index) => index !== saveTempReleaseId
    );

    settempRelese(NewR);
    setConfirmDeleteTempReleasePopup(false);
  };

  const [saveReleaseId, setSaveReleaseId] = useState();
  const [saveTempReleaseId, setSaveTempReleaseId] = useState();

  const DeleteRelease = async () => {
    try {
      const res = await httpClient.delete(
        `/release_master/deleteByReleaseId/${saveReleaseId}`
      );
      setConfirmDeleteReleasePopup(false);
      console.log(res.data);
      GetReleaseByAddDetailsId(formData?.artDetailsId);
    } catch (error) {
      setConfirmDeleteReleasePopup(false);
      console.error(error);
    }
  };
  // const DeleteRelease = async (releaseId) => {
  //   try {
  //     const res = await httpClient.delete(
  //       `/release_master/deleteByReleaseId/${releaseId}`
  //     );
  //     console.log(res.data);
  //     GetReleaseByAddDetailsId(formData?.artDetailsId);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    console.log('tempRelease', tempRelese);
  }, [tempRelese]);

  const draftCount = useSelector(
    (state) => state.contriCounter.toSubmitFilesCount
  );
  const forReviewCount = useSelector(
    (state) => state.contriCounter.forReviewFilesCount
  );
  const approvedCount = useSelector(
    (state) => state.contriCounter.approvedFilesCount
  );
  const RejectedCount = useSelector(
    (state) => state.contriCounter.rejectedFilesCount
  );
  const fileUploadLimitCount = useSelector(
    (state) => state.contriCounter.fileUploadLimitCount
  );

  const SetKeywordManagerTrue = () => {
    dispatch(keywordsManagerSliceAction.setKeywordsTrue());
  };

  const alterObjectAcoordToDeleteApi = () => {
    let attributeToKeep = 'imageId';
    let newArray = selectedImages?.map((object) => {
      // Get the value of the attribute to keep
      let valueToKeep = object[attributeToKeep];

      // Create a new object with only the attribute to keep
      let newObj = {
        [attributeToKeep]: valueToKeep,
      };

      return newObj;
    });

    return newArray;
    // console.log(newArray);
  };

  const deleteImages = async () => {
    const imagesId = alterObjectAcoordToDeleteApi();
    const obj = {
      draftId,
      imageMasterIds: imagesId,
    };

    console.log(obj);
    try {
      const res = await httpClient.post('/images/deleteImages', obj);
      getActivateProductList();
      dispatch(setSelectedImages([]));
      setConfirmDeletePopup(false);
      console.log(res.data);
    } catch (error) {
      setConfirmDeletePopup(false);
      console.error(error);
    }
  };

  const [sizeRangeValue, setSizeRangeValue] = useState(50);

  const sizeRange = (event) => {
    const value = event.target.value;
    if (value !== 0) {
      setSizeRangeValue(value);
      // console.log(value);\
      // setTempArtProduct((prev) => ({
      //   ...prev,

      //   canvasSize: value,
      // }));
    }
  };

  const susKeyArr = [
    'Beautiful',
    'Multicoloured',
    'Leaves',
    'Tree',
    'Vivid',
    'Enchanting',
    'Natural',
    'Splendor',
    'Diversity',
    'Majestic',
    'Branches',
    'Graceful',
    'Beauty',
    'Spectacular',
    'Seasonal',
    'Transformation',
  ];

  // const [selectAllHover, setSelectAllHover] = useState('enabled');
  const [deleteImageHover, setDeleteImageHover] = useState('enabled');
  const [saveDraftHover, setSaveDraftHover] = useState('enabled');
  const [keywordManagerHover, setKeywordManagerHover] =
    useState('enabled');

  const [edit, setEdit] = useState(true);

  const [keywordSugg, setkeywordSugg] = useState();
  const [keywordSuggCompare, setkeywordSuggCompare] = useState();

  const getKeywordSuggessionsByArtTitle = async () => {
    try {
      const res = await httpClient.get(
        `/art_master/getAllKeywordByArtName/${formData?.artName}`
      );
      console.log(res.data);
      setkeywordSugg(res.data);
      setkeywordSuggCompare(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getKeywordSuggessionsByArtTitle();
  }, [formData?.artName]);

  const AddSuggestedKeywordsToKeywords = (keyword) => {
    if (hasCommonString(keyword, formData?.keywords)) {
      dispatch(popupSliceAction.setBackgroundColor('bg-pinkColor'));
      dispatch(popupSliceAction.setTitle1('Something Went Wrong.'));
      dispatch(popupSliceAction.setTitle2(''));
      dispatch(popupSliceAction.setOpenPopup());
      dispatch(popupSliceAction.setAnimationOpen());
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ['keywords']: [...prevFormData.keywords, keyword],
      }));
      ClearFromSugg(keyword);
    }
  };
  const AddAllSuggKeywordsToKeywords = () => {
    // if (hasCommonString(keyword, formData?.keywords)) {
    //   toast.error('Please enter a unique Keywords');
    // } else {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['keywords']: [...prevFormData.keywords, ...keywordSugg],
    }));
    setkeywordSugg([]);
  };

  const ClearFromSugg = (keyword) => {
    const updatedKeyword = keywordSugg?.filter(
      (obje) => obje !== keyword
    );

    setkeywordSugg(updatedKeyword);
  };

  // const [keywordSuggHover, setkeywordSuggHover] = useState(false);

  const [preview, setPreview] = useState('horizontal');

  useEffect(() => {
    console.log(preview);
  }, [preview]);

  const [normalPopupOpen, setNormalPopupOpen] = useState(false);

  useEffect(() => {
    if (normalPopupOpen) {
      // Automatically close the popup after 2 seconds
      const timer = setTimeout(() => {
        setNormalPopupOpen(false);
      }, 2000);

      // Clear the timeout if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [normalPopupOpen]);

  const [releaseUploading, setReleaseUploading] = useState(false);

  // const [isVisible, setIsVisible] = useState(false);
  // const [animationClass, setAnimationClass] = useState('');

  // const openPopup = () => {
  //   setIsVisible(true);
  //   setAnimationClass('animate-fadeIn');
  // };

  // const closePopup = () => {
  //   setAnimationClass('animate-fadeOut');
  //   setTimeout(() => setIsVisible(false), 500);
  // };

  // useEffect(() => {
  //   if (isVisible) {
  //     setAnimationClass('animate-fadeIn');
  //   } else {
  //     setAnimationClass('animate-fadeOut');
  //   }
  // }, [isVisible]);

  const [colorIconHover, setColorIconHover] = useState(false);
  const [GrayScaleIconHover, setGrayScaleIconHover] = useState(false);
  const [pencilHover, setPencilHover] = useState(false);
  const [ExclusiveHover, setExclusiveHover] = useState(false);
  const [FeaturedHover, setFeaturedHover] = useState(false);
  const [HorizontalCropHover, setHorizontalCropHover] =
    useState(false);
  const [VerticalCropHover, setVerticalCropHover] = useState(false);
  const [SquareCropHover, setSquareCropHover] = useState(false);

  const [AlignVerticalHover, setAlignVerticalHover] = useState(false);
  const [AlignHorizontalHover, setAlignHorizontalHover] =
    useState(false);

  const [ResetHover, setResetHover] = useState(false);
  const [ColourizeIconHover, setColourizeIconHover] = useState(false);

  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);

  const [confirmDeleteReleasePopup, setConfirmDeleteReleasePopup] =
    useState(false);

  const [
    confirmDeleteTempReleasePopup,
    setConfirmDeleteTempReleasePopup,
  ] = useState(false);

  return (
    <>
      {/* <button onClick={openPopup}>Open Popup</button>
      {isVisible && (
        <div
          className={`popup-overlay  ${animationClass}`}
          onClick={closePopup}
        >
          <div
            className='popup-content'
            onClick={(e) => e.stopPropagation()}
          >
            <p>Successfully Art Details saved to Draft.</p>
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )} */}

      <StyledPopupDelete
        open={confirmDeletePopup}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setConfirmDeletePopup(false);
          document.body.classList.remove('body-no-scroll');
          // setOpenOnMountPop2(true);
        }}
      >
        <p className='text-[16px] text-primaryBlack leading-[20px] font-medium text-center'>
          Delete Art File(s){' '}
        </p>
        <p className='text-sm12 text-primaryGray font-medium text-center mt-[14px]'>
          Are you sure you want to delete Art File(s) from <br />
          Add Details page?{' '}
        </p>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex gap-[4px] mx-[auto]  mt-[16px]'
        >
          <button onClick={deleteImages} className='gray30HButton'>
            Delete
          </button>
          <button
            onClick={() => {
              setConfirmDeletePopup(false);
            }}
            className='gray30HBorderButton'
          >
            Cancel
          </button>
        </div>
      </StyledPopupDelete>

      <StyledPopupDelete
        open={confirmDeleteReleasePopup}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setConfirmDeletePopup(false);
          document.body.classList.remove('body-no-scroll');
          // setOpenOnMountPop2(true);
        }}
      >
        <p className='text-[16px] text-primaryBlack leading-[20px] font-medium text-center'>
          Delete Art File(s){' '}
        </p>
        <p className='text-sm12 text-primaryGray font-medium text-center mt-[14px]'>
          Are you sure you want to delete release(s) from <br />
          To Submit page?{' '}
        </p>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex gap-[4px] mx-[auto]  mt-[16px]'
        >
          <button onClick={DeleteRelease} className='gray30HButton'>
            Delete
          </button>
          <button
            onClick={() => {
              setConfirmDeleteReleasePopup(false);
            }}
            className='gray30HBorderButton'
          >
            Cancel
          </button>
        </div>
      </StyledPopupDelete>

      <StyledPopupDelete
        open={confirmDeleteTempReleasePopup}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setConfirmDeleteTempReleasePopup(false);
          document.body.classList.remove('body-no-scroll');
          // setOpenOnMountPop2(true);
        }}
      >
        <p className='text-[16px] text-primaryBlack leading-[20px] font-medium text-center'>
          Delete Art File(s){' '}
        </p>
        <p className='text-sm12 text-primaryGray font-medium text-center mt-[14px]'>
          Are you sure you want to delete release(s) from <br />
          Add Details page?{' '}
        </p>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex gap-[4px] mx-[auto]  mt-[16px]'
        >
          <button
            onClick={DeleteReleaseTemp}
            className='gray30HButton'
          >
            Delete
          </button>
          <button
            onClick={() => {
              setConfirmDeleteTempReleasePopup(false);
            }}
            className='gray30HBorderButton'
          >
            Cancel
          </button>
        </div>
      </StyledPopupDelete>

      <div className='flex justify-center mb-[16px]'>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex border-t border-b border-t-[#efefef] border-b-[#efefef]'
        >
          <div className='flex items-center gap-[5px]'>
            <span
              className='contributorUploadCount text-[#f26522]
          '
            >
              {/* 13 */}
              {draftCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='contributorUploadtext pr-[8px]'>
                File(s) to be
                <br />
                submitted to
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span
              className='contributorUploadCount text-[#ffc20E]
           opacity-[20%] pl-[5px]'
            >
              {forReviewCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                pending for
                <br />
                review
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span
              className=' contributorUploadCount text-[#8dc73f]
          opacity-[20%]  pl-[5px]'
            >
              {approvedCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                approved by
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount  text-[#00aeef] opacity-[20%]  pl-[5px]'>
              {RejectedCount}
            </span>
            <div className='opacity-[40%] h-[40px] flex items-center'>
              <p className='contributorUploadtext'>
                File(s)
                <br />
                rejected by
                <br />
                Artnstock
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='contributorTitleStyling'>
        <div className='w-[1168px] flex justify-between'>
          <div className='flex flex-col gap-[2px] max-w-[328px] w-[100%]'>
            <div className='flex gap-[8px] items-baseline'>
              <p className='text-[24px] font-medium leading-[1]  text-primaryBlack'>
                Add Details
              </p>
              <p className='pinkTextUploadFlow  '>
                {selectedImages?.length}/{images?.length} File(s)
                Selected
              </p>
            </div>
            <p className='text-sm11 text-primaryGray  mt-[1px]'>
              Select thumbnail(s) to add details, and click Continue
              <br /> to Activate Products.
              <span
                onClick={() => navigate('/upload-file2')}
                className='text-orangeColor cursor-pointer'
              >
                {' '}
                Learn more
              </span>
            </p>
          </div>

          <div className='flex'>
            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
              <div
                // onMouseEnter={() => setSelectAllHover(true)}
                // onMouseLeave={() => setSelectAllHover(false)}
                className='flex flex-col  gap-[8px] w-[52px] mt-[1px] text-center opacity-[40%]'
              >
                {/* <img
                  src={selectall}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto] bg-[#d7d7d7]'
                /> */}
                <div>
                  <SelectAll
                    className={`mx-[auto] fill-[#888888]
                   `}
                  />{' '}
                </div>
                <p
                  className={`text-primaryGray text-sm11 text-center`}
                >
                  Select <br /> All
                </p>
              </div>
            </div>

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
              {selectedImages?.length > 0 ? (
                <div
                  onMouseEnter={() => setDeleteImageHover('hovered')}
                  onMouseLeave={() => setDeleteImageHover('enabled')}
                  onMouseUp={() => setDeleteImageHover('hovered')}
                  onMouseDown={() => setDeleteImageHover('pressed')}
                  onClick={() => {
                    setConfirmDeletePopup(true);
                  }}
                  // onClick={deleteImages}
                  className='flex flex-col mt-[1px] cursor-pointer  gap-[8px] w-[52px] text-center '
                >
                  <div>
                    <Deleteicon
                      className={`mx-[auto] ${
                        deleteImageHover === 'hovered'
                          ? 'fill-primaryBlack'
                          : deleteImageHover === 'pressed'
                          ? 'fill-pressedColor'
                          : 'fill-[#888888]'
                      }`}
                    />
                  </div>
                  <p
                    className={` text-sm11 text-center ${
                      deleteImageHover === 'hovered'
                        ? 'text-primaryBlack'
                        : deleteImageHover === 'pressed'
                        ? 'text-pressedColor'
                        : 'text-primaryGray'
                    }`}
                  >
                    Delete File(s)
                  </p>
                </div>
              ) : (
                <div className='flex flex-col  mt-[1px] opacity-[40%]  gap-[8px] w-[52px] text-center '>
                  <div>
                    <Deleteicon className='mx-[auto] fill-[#888888]' />
                  </div>
                  <p className='text-primaryGray text-sm11 text-center'>
                    Delete File(s)
                  </p>
                </div>
              )}
            </div>

            {/* <div className='flex-column max-w-[34px] text-center'>
              <img
                src={undo}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px]'>
                Undo Changes
              </p>
            </div>
            <div className='flex-column max-w-[34px] text-center'>
              <img
                src={redo}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px]'>
                Redo Changes
              </p>
            </div> */}

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef] border-r-[2px]'>
              {selectedImages?.length > 0 && !draftApiCall ? (
                <div
                  onMouseEnter={() => setSaveDraftHover('hovered')}
                  onMouseLeave={() => setSaveDraftHover('enabled')}
                  onMouseUp={() => setSaveDraftHover('hovered')}
                  onMouseDown={() => setSaveDraftHover('pressed')}
                  onClick={updateAddDetailsForm}
                  className={`flex flex-col cursor-pointer  mt-[1px]  gap-[8px] w-[52px] text-center `}
                >
                  <div>
                    <Save
                      className={`mx-[auto] ${
                        saveDraftHover === 'hovered'
                          ? 'fill-primaryBlack'
                          : saveDraftHover === 'pressed'
                          ? 'fill-pressedColor'
                          : 'fill-[#888888]'
                      }`}
                    />
                  </div>
                  <p
                    className={` text-sm11 text-center ${
                      saveDraftHover === 'hovered'
                        ? 'text-primaryBlack'
                        : saveDraftHover === 'pressed'
                        ? 'text-pressedColor'
                        : 'text-primaryGray'
                    }`}
                  >
                    Save <br /> Draft
                  </p>
                </div>
              ) : (
                <div
                  //onClick={updateAddDetailsForm}
                  className={`flex flex-col  opacity-[40%] mt-[1px] gap-[8px] w-[52px] text-center `}
                >
                  {/* <img
                      src={save}
                      alt=''
                      className='w-[16px] h-[16px] mx-[auto]'
                    /> */}
                  <div>
                    <Save className='mx-[auto] fill-[#888888]' />
                  </div>
                  <p className='text-primaryGray  text-sm11 text-center'>
                    Save <br /> Draft
                  </p>
                </div>
              )}
            </div>

            {/* <div className=' w-[53px] text-center border-r border-r-[#efefef] '>
              <img
                src={save}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px] text-center'>
                Save <br /> Draft
              </p>
            </div> */}

            <div className='contributorUploadIconsMainDiv '>
              <div
                onMouseEnter={() => setKeywordManagerHover('hovered')}
                onMouseLeave={() => setKeywordManagerHover('enabled')}
                onMouseUp={() => setKeywordManagerHover('hovered')}
                onMouseDown={() => setKeywordManagerHover('pressed')}
                onClick={SetKeywordManagerTrue}
                className='flex flex-col  mt-[1px] gap-[8px] w-[52px] text-center cursor-pointer'
              >
                <div>
                  <Keyword
                    className={`mx-[auto] ${
                      keywordManagerHover === 'hovered'
                        ? 'fill-primaryBlack'
                        : keywordManagerHover === 'pressed'
                        ? 'fill-pressedColor'
                        : 'fill-[#888888]'
                    }`}
                  />
                </div>
                <p
                  className={` text-sm11 text-center ${
                    keywordManagerHover === 'hovered'
                      ? 'text-primaryBlack'
                      : keywordManagerHover === 'pressed'
                      ? 'text-pressedColor'
                      : 'text-primaryGray'
                  }`}
                >
                  Keyword Manager
                </p>
              </div>
            </div>
          </div>

          <div className='flex   justify-end max-w-[328px] w-[100%]'>
            <div className='flex items-center'>
              <TechinicalSpec />
              {/* <p
                onClick={() => navigate('/upload-file2')}
                className='text-[11px] text-orangeColor leading-[14px] cursor-pointer'
              >
                Technical Specifications <br /> for File Upload
              </p> */}
            </div>
          </div>
          {/* <div className='pl-[206px] leading-[1] '>
            <p className='text-[11px] text-primaryGray leading-[14px]'>
              Files uploaded this week
            </p>
            <p className='text-[24px] leading-[0.9] text-pinkColor  font-medium'>
              {fileUploadLimitCount?.artCount}/
              {fileUploadLimitCount?.uploadedCount}
              <span className='text-[11px] font-normal ml-[2px]'>
                File(s)
              </span>
            </p>
            <button className='bg-[#888888] text-[white] text-[10px]  font-normal leading-[1] flex items-center rounded-[20px] px-[8px] h-[17px]'>
              Files Upload Summary
            </button>
          </div> */}
        </div>
      </div>

      {images?.length > 0 ? (
        <>
          <div className='w-[100%] flex justify-center'>
            <div className='w-w1168  flex-column gap-[10px] pb-[32px]'>
              <div className='flex justify-start flex-wrap gap-[12px]'>
                {/* dynamic gray box */}

                {images?.map((card, index) => (
                  <GrayBoxforDetails
                    key={index}
                    card={card}
                    isSelected={selectedCard === card}
                    onCardSelect={handleCardSelect}
                  />
                ))}

                {/* test */}
                {grayBox.slice(0, grayNum).map((card, index) => (
                  <div
                    className={
                      'h-[106px] w-[106px] bg-[#f7f7f7] rounded-[16px]'
                    }
                  ></div>
                ))}
                {/* test */}
              </div>
            </div>
          </div>

          {selectedImages?.length > 0 ? (
            <div className='w-[688px] mx-[auto]'>
              {/* test */}
              <p className='text-[#bbbbbb] font-medium mb-[4px] text-sm11'>
                You have selected
              </p>
              <div className='w-[100%] flex justify-center'>
                <div
                  // style={{
                  //   borderRadius: '32px!important',
                  // }}
                  className='w-[688px] bg-[#ffffff] rounded-gradient-border pt-[17px] pb-[33px] px-[17px]'
                >
                  {/* {typeOfContentCheckoxesTick('Exclusive Art') ||
                typeOfContentCheckoxesTick('Featured Art') ? (
                  <div className='flex mt-[7px] h-[52px]'>
                    {typeOfContentCheckoxesTick('Exclusive Art') && (
                      <div className='w-[42px] flex flex-col mr-[11px]'>
                        <div className='flex w-[100%] justify-center'>
                         
                          <div>
                            <Exclusive_Icon28 />
                          </div>
                        </div>
                        <p className=' text-[10px] text-primaryGray mt-[5px]'>
                          Exclusive
                        </p>
                      </div>
                    )}

                    {typeOfContentCheckoxesTick('Exclusive Art') &&
                      typeOfContentCheckoxesTick('Featured Art') && (
                        <div className='w-[1px] h-[52px] bg-[#efefef]'></div>
                      )}

                    {typeOfContentCheckoxesTick('Featured Art') && (
                      <div
                        className={`${
                          typeOfContentCheckoxesTick('Exclusive Art')
                            ? 'w-[68px]'
                            : ''
                        } flex flex-col`}
                      >
                        <div className='flex w-[100%]  justify-center'>
                         
                          <div>
                            <Featured_Icon28 />
                          </div>
                        </div>
                        <p className='text-center text-[10px] text-primaryGray mt-[5px]'>
                          Featured
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )} */}

                  <div className='flex gap-[8px]'>
                    <div className='w-[64px] h-[64px] z-[99] bg-[#f7f7f7] rounded-[12px] relative'>
                      <div
                        className='h-full w-full z-[99] bg-[#f7f7f7] rounded-[12px] bg-cover bg-center absolute overflow-hidden inset-0'
                        // style={{
                        //   backgroundImage: `url(${selectedImages[0]?.thumbnailUrl})`,
                        // }}
                      ></div>

                      <div
                        className='h-full w-full  z-[999] rounded-[12px] bg-cover bg-center absolute overflow-hidden inset-0'
                        style={{
                          backgroundImage: `url(${selectedImages[0]?.thumbnailUrl})`,
                        }}
                      ></div>
                    </div>

                    <div className='w-[64px] h-[64px] z-[99] bg-[#f7f7f7] rounded-[12px] relative'>
                      <div
                        className='h-full w-full z-[99] bg-[#f7f7f7] rounded-[12px] bg-cover bg-center absolute overflow-hidden inset-0'
                        // style={{
                        //   backgroundImage: `url(${selectedImages[0]?.thumbnailUrl})`,
                        // }}
                      ></div>

                      <div
                        className='h-full w-full rounded-[12px] z-[999] bg-cover bg-center  absolute overflow-hidden inset-0'
                        style={{
                          backgroundImage: `url(${addGrayscaleTransformationForDisplay(
                            selectedImages[0]?.thumbnailUrl
                          )})`,
                        }}
                      ></div>
                    </div>

                    {/* <div
                      className='w-[64px] h-[64px] rounded-[12px] bg-cover bg-center'
                      style={{
                        backgroundImage: `url(${selectedImages[0]?.thumbnailUrl})`,
                      }}
                    ></div> */}

                    {/* <div className='w-[64px] h-[64px] rounded-[10px] bg-[#f7f7f7]'></div> */}
                    <div className='w-[64px] h-[64px] rounded-[12px] bg-[#f7f7f7]'></div>
                    <div className='w-[64px] h-[64px] rounded-[12px] bg-[#f7f7f7]'></div>
                    <div className='w-[64px] h-[64px] rounded-[12px] bg-[#f7f7f7]'></div>
                  </div>
                  <div className='mt-5 border-t border-[#EFEFEF] w-[100%] flex '>
                    <div
                      style={{
                        width: 'fit-content',
                      }}
                      className='flex flex-col '
                    >
                      <p className='text-primaryGray text-sm12  py-[1px] font-medium leading-4  border-b border-[#EFEFEF] whitespace-no-wrap'>
                        Category:
                      </p>

                      {/* {topMatSelect > 0 && ( */}

                      {formData.artName !== '' && (
                        <p className='text-primaryGray text-sm12  py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                          Art Title:
                        </p>
                      )}
                      {/* )} */}

                      {/* {bottomMatSelect > 0 && ( */}

                      {subjectMenu && (
                        <p className='text-primaryGray text-sm12  py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                          Subject:
                        </p>
                      )}
                      {/* )} */}

                      {/* {includeFrame && ( */}
                      {styleMenu && (
                        <p className='text-primaryGray text-sm12  py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                          Style:
                        </p>
                      )}
                      {/* )} */}

                      {mediumMenu && (
                        <p className='text-primaryGray text-sm12  py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                          Medium:
                        </p>
                      )}

                      {formData.typeOfContent?.length > 0 && (
                        <p
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                          className='text-primaryGray text-sm12  py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'
                        >
                          Type of Content:
                        </p>
                      )}
                      {/* <p
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                      className='text-primaryGray text-sm12  py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-now-wrap'
                    >
                      Case Number:
                    </p> */}

                      {formData.commercialUser?.length > 0 && (
                        <p
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                          className='text-primaryGray text-sm12  py-[1px] font-medium leading-4  border-b border-[#EFEFEF] whitespace-no-wrap whitespace-no-wrap'
                        >
                          Commercial Usage:
                        </p>
                      )}
                      {/* <p
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                      className='text-primaryGray text-sm12 font-medium leading-4  whitespace-no-wrap whitespace-no-wrap'
                    >
                      Date Submitted:
                    </p> */}

                      {tempRelese?.length + Release?.length > 0 && (
                        <p
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                          className='text-primaryGray text-sm12  py-[1px] font-medium leading-4  border-b border-[#EFEFEF] whitespace-no-wrap whitespace-no-wrap'
                        >
                          Releases:
                        </p>
                      )}
                      {formData?.price > 0 && (
                        <p
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                          className='text-primaryGray text-sm12  py-[1px] font-medium leading-4  border-b border-[#EFEFEF] whitespace-no-wrap whitespace-no-wrap'
                        >
                          Art Print Markup:
                        </p>
                      )}
                    </div>

                    <div className='flex flex-col w-[100%] '>
                      <p className='text-primaryGray text-sm12  py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                        Art
                      </p>
                      {formData.artName !== '' && (
                        <p className='text-primaryGray text-sm12  py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                          {formData.artName}
                        </p>
                      )}
                      {subjectMenu ? (
                        <p className='text-primaryGray text-sm12  py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                          {subjectMenu?.subjectName}
                        </p>
                      ) : (
                        <></>
                      )}

                      {styleMenu ? (
                        <p className='text-primaryGray text-sm12  py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                          {styleMenu?.name}
                        </p>
                      ) : (
                        <></>
                      )}
                      {mediumMenu && (
                        <p className='text-primaryGray text-sm12  py-[1px] pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                          {mediumMenu?.mediumType}
                        </p>
                      )}
                      {formData?.typeOfContent?.length > 0 && (
                        <p className='text-primaryGray text-sm12  py-[1px] pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                          {formData?.typeOfContent?.map(
                            (obj, index) => (
                              <span>
                                {obj}
                                <span
                                  className={`${
                                    formData?.typeOfContent?.length -
                                      1 ===
                                      index ||
                                    formData?.typeOfContent
                                      ?.length === 1
                                      ? 'invisible'
                                      : ''
                                  }`}
                                >
                                  ,{' '}
                                </span>
                              </span>
                            )
                          )}
                        </p>
                      )}
                      {/* <p className='text-primaryGray text-sm12  py-[1px] pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                      ANS7459309542
                    </p> */}

                      {formData.commercialUser?.length > 0 && (
                        <p className='text-primaryGray text-sm12  py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                          {formData.commercialUser?.map(
                            (obj, index) => (
                              <span>
                                {obj}
                                <span
                                  className={`${
                                    formData.commercialUser?.length -
                                      1 ===
                                      index ||
                                    formData.commercialUser
                                      ?.length === 1
                                      ? 'invisible'
                                      : ''
                                  }`}
                                >
                                  ,{' '}
                                </span>
                              </span>
                            )
                          )}
                        </p>
                      )}

                      {tempRelese?.length + Release?.length > 0 && (
                        <p className='text-primaryGray text-sm12  py-[1px] pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                          Attached
                        </p>
                      )}
                      {formData?.price > 0 && (
                        <p className='text-primaryGray text-sm12  py-[1px] pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                          Markup {formData?.price}, Base Price{' '}
                          {basePrice}, Selling Price {sellPrice}
                        </p>
                      )}

                      {/* <p className='text-primaryGray text-sm12  pl-[8px] leading-4 border-b border-[#EFEFEF]'>
                          Web Media, Print Media, Print on Products
                        </p> */}
                      {/* <p className='text-primaryGray text-sm12  pl-[8px] leading-4 '>
                      05/06/2023
                    </p> */}
                    </div>
                  </div>

                  {/* <p
                  onClick={() => setshowMore(true)}
                  className='mt-[7px] text-[#bbbbbb]  text-sm11 font-normal cursor-pointer'
                >
                  Show more
                </p> */}

                  {/* test */}
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <div className='w-[100%] text-center mt-[32px]'>
            <div
              className={`flex flex-col justify-center relative w-[688px] h-[486px]  mx-[auto] bg-[#f7f7f7] ${
                currentOri !== 'horizontal' || !edit
                  ? 'rounded-[32px]'
                  : ''
              } h-full w-full bg-no-repeat bg-center bg-cover`}
              style={{
                // backgroundImage: `url(${selectedImages[0]?.thumbnailUrl})`,
                overflow: 'hidden',
              }}
            >
              {!selectedCard ? (
                <div>
                  <p className='text-pinkColor text-[16px] my-[auto]'>
                    Select thumbnail to view the image.
                  </p>
                  <p className='text-primaryGray text-[11px] my-[auto]'>
                    Select an item to add details and submit for
                    review.{' '}
                    <span className='text-orangeColor text-[11px]'>
                      Learn more
                    </span>
                  </p>
                </div>
              ) : (
                <>
                  {/* <div
                style={{
                  width: '690px',
                  height: '445px',
                  position: 'relative',
                 
                }}
                className='w-w690 h-[27.813rem] mx-[auto] bg-[#f7f7f7] rounded-[32px]'
              > */}
                  <div className='absolute z-[999] top-[16px] left-[16px] flex gap-[15px]'>
                    {typeOfContentCheckoxesTick('Exclusive Art') && (
                      <div>
                        <Exclusive_Icon24 className='fill-[#333333]' />
                      </div>
                    )}

                    {typeOfContentCheckoxesTick('Featured Art') && (
                      <div>
                        <Featured_Icon24 className='fill-[#333333]' />
                      </div>
                    )}
                  </div>

                  {!edit ? (
                    <div className='relative h-[486px] flex justify-center items-center relative'>
                      {preview === 'horizontal' ? (
                        <div className='w-[100%] h-[100%] flex justify-center items-center'>
                          {
                            // formData?.imageOrientation
                            //   ?.horizontalUrl === undefined ||
                            formData?.imageOrientation
                              ?.horizontalUrl === null ? (
                              //    ||
                              // formData?.imageOrientation
                              //   ?.horizontalUrl === ''
                              <img
                                className={`${
                                  ogFormData?.imageMaster?.type ===
                                  'Horizontal'
                                    ? 'w-[100%]'
                                    : ogFormData?.imageMaster
                                        ?.type === 'Vertical'
                                    ? 'h-[100%]'
                                    : ogFormData?.imageMaster
                                        ?.type === 'Square'
                                    ? 'h-[100%]'
                                    : 'w-[100%] absolute rounded-[16px]'
                                }`}
                                src={
                                  !BWEnable
                                    ? ogFormData?.imageMaster
                                        ?.thumbnailUrl
                                    : addGrayscaleTransformationForDisplay(
                                        ogFormData?.imageMaster
                                          ?.thumbnailUrl
                                      )
                                }
                                alt=''
                              />
                            ) : (
                              <img
                                className={` absolute w-[100%]  rounded-[16px]`}
                                src={
                                  !BWEnable
                                    ? formData?.imageOrientation
                                        ?.horizontalUrl
                                    : formData?.imageOrientation
                                        ?.horizontalBCUrl
                                }
                                alt=''
                              />
                            )
                          }
                        </div>
                      ) : preview === 'vertical' ? (
                        <div className='w-[100%] h-[100%] flex justify-center items-center'>
                          {
                            // formData?.imageOrientation
                            //   ?.horizontalUrl === undefined ||
                            formData?.imageOrientation
                              ?.verticalUrl === null ? (
                              //    ||
                              // formData?.imageOrientation
                              //   ?.verticalUrl === ''
                              <img
                                className={`${
                                  ogFormData?.imageMaster?.type ===
                                  'Horizontal'
                                    ? 'w-[100%]'
                                    : ogFormData?.imageMaster
                                        ?.type === 'Vertical'
                                    ? 'h-[100%]'
                                    : ogFormData?.imageMaster
                                        ?.type === 'Square'
                                    ? 'h-[100%]'
                                    : 'w-[100%] absolute  rounded-[16px]'
                                }`}
                                src={
                                  !BWEnable
                                    ? ogFormData?.imageMaster
                                        ?.thumbnailUrl
                                    : addGrayscaleTransformationForDisplay(
                                        ogFormData?.imageMaster
                                          ?.thumbnailUrl
                                      )
                                }
                                alt=''
                              />
                            ) : (
                              <img
                                className={` absolute h-[100%]  rounded-[16px]
                          `}
                                src={
                                  !BWEnable
                                    ? formData?.imageOrientation
                                        ?.verticalUrl
                                    : formData?.imageOrientation
                                        ?.verticalBCUrl
                                }
                                alt=''
                              />
                            )
                          }
                        </div>
                      ) : preview === 'square' ? (
                        <div className='w-[100%] h-[100%] flex justify-center items-center'>
                          {
                            // formData?.imageOrientation
                            //   ?.horizontalUrl === undefined ||
                            formData?.imageOrientation?.squareUrl ===
                            null ? (
                              //    ||
                              // formData?.imageOrientation
                              //   ?.squareUrl === ''
                              <img
                                className={`${
                                  ogFormData?.imageMaster?.type ===
                                  'Horizontal'
                                    ? 'w-[100%]'
                                    : ogFormData?.imageMaster
                                        ?.type === 'Vertical'
                                    ? 'h-[100%]'
                                    : ogFormData?.imageMaster
                                        ?.type === 'Square'
                                    ? 'h-[100%]'
                                    : 'w-[100%] absolute  rounded-[16px]'
                                }`}
                                src={
                                  !BWEnable
                                    ? ogFormData?.imageMaster
                                        ?.thumbnailUrl
                                    : addGrayscaleTransformationForDisplay(
                                        ogFormData?.imageMaster
                                          ?.thumbnailUrl
                                      )
                                }
                                alt=''
                              />
                            ) : (
                              <img
                                className={` absolute h-[100%]  rounded-[16px]
                        `}
                                src={
                                  !BWEnable
                                    ? formData?.imageOrientation
                                        ?.squareUrl
                                    : formData?.imageOrientation
                                        ?.squareBCUrl
                                }
                                alt=''
                              />
                            )
                          }
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <>
                      {/* <div
                        className={` bg-[#333333] `}
                      ></div> */}
                      {/* <div className='w-[100%] h-[100%] brightness-[50%] absolute overflow-hidden inset-0'></div> */}
                      <div
                        className={`${
                          currentOri === 'horizontal'
                            ? 'hidden'
                            : currentOri === 'vertical'
                            ? 'w-[172px]'
                            : currentOri === 'square'
                            ? 'w-[101px]'
                            : ''
                        } h-[100%] z-[99] left-[0px]  absolute bg-[#bbbbbb] `}
                        // } h-[100%] z-[99] left-[0px]  absolute bg-[#bbbbbb] opacity-[15%]`}
                      ></div>
                      <div
                        className={`${
                          currentOri === 'horizontal'
                            ? 'hidden'
                            : currentOri === 'vertical'
                            ? 'w-[172px]'
                            : currentOri === 'square'
                            ? 'w-[101px]'
                            : ''
                        } h-[100%] z-[99] right-[0px]  absolute bg-[#bbbbbb]`}
                        // } h-[100%] z-[99] right-[0px]  absolute bg-[#000000] opacity-[15%]`}
                      ></div>

                      {/* <div className='w-[100%] h-[100%]'> */}
                      {/* <div
                          className={`w-[${cropWidth}px] absolute h-[${cropHeight}px]`}
                        ></div> */}
                      <div className={`cropper-container `}>
                        <Cropper
                          image={
                            BWEnable
                              ? addGrayscaleTransformationForDisplay(
                                  selectedImages[0]?.thumbnailUrl
                                )
                              : selectedImages[0]?.thumbnailUrl
                          }
                          crop={{
                            x:
                              currentOri === 'horizontal'
                                ? formData?.previews
                                    ?.horizontalPreview?.xaxis
                                : currentOri === 'vertical'
                                ? formData?.previews?.verticalPreview
                                    ?.xaxis
                                : currentOri === 'square'
                                ? formData?.previews?.squarePreview
                                    ?.xaxis
                                : 0,
                            y:
                              currentOri === 'horizontal'
                                ? formData?.previews
                                    ?.horizontalPreview?.yaxis
                                : currentOri === 'vertical'
                                ? formData?.previews?.verticalPreview
                                    ?.yaxis
                                : currentOri === 'square'
                                ? formData?.previews?.squarePreview
                                    ?.yaxis
                                : 0,
                          }}
                          zoom={
                            currentOri === 'horizontal'
                              ? formData?.previews?.horizontalPreview
                                  ?.zoom
                              : currentOri === 'vertical'
                              ? formData?.previews?.verticalPreview
                                  ?.zoom
                              : currentOri === 'square'
                              ? formData?.previews?.squarePreview
                                  ?.zoom
                              : 1
                          }
                          // zoom={formData?.zoom}
                          // aspect={1 / 1} // Aspect ratio (width / height)
                          // initialAspectRatio={1} // Set initial aspect ratio
                          restrictPosition={false}
                          onCropChange={handleCropChange}
                          onZoomChange={handleZoomChange}
                          onCropComplete={onCropComplete}
                          style={{
                            width: '100%',
                            height: '100%',
                            brightness: '100%',
                            zIndex: '999',
                          }} // Set Cropper dimensions to fill its container
                          cropSize={{
                            width: cropWidth,
                            height: cropHeight,
                          }} // Set desired crop size
                          // viewMode={2}
                        />
                      </div>

                      {/* </div> */}
                    </>
                  )}

                  {/* </div> */}
                  {/* <div className='flex gap-[10px]'>
                <img onClick={SetHorizontalOri} src={AddHori} alt='' />
                <img onClick={SetVerticalOri} src={AddVert} alt='' />
                <img onClick={SetSquareOri} src={AddSqua} alt='' />
              </div>
              <button
                onClick={handleCropImage}
                className='mt-[10px]'
               
              >
                Crop Image
              </button>
              {imageFile && <img src={imageFile} alt='Uploaded Image' />} */}
                </>
              )}
            </div>
          </div>

          {/* <button
                    onClick={handleCropImage}
                    className='mt-[10px]'
                  >
                    Crop Image
                  </button> */}

          <div className='w-[100%]'>
            <div className='w-[688px] mx-[auto]'>
              <div className='flex justify-between'>
                <div className='flex gap-[16px] pt-[8px]'>
                  <div className={`flex gap-[8px] `}>
                    <div
                      onMouseEnter={() => setColorIconHover(true)}
                      onMouseLeave={() => setColorIconHover(false)}
                      className=' relative'
                    >
                      {colorIconHover && (
                        <div
                          style={{
                            width: 'max-content',
                          }}
                          className='tooltip  top-[32px] left-[-32px]'
                        >
                          Colour Preview
                        </div>
                      )}
                      <IconColor
                        onClick={() => setBWEnable(false)}
                        className='cursor-pointer hover:brightness-[70%]'
                      />
                    </div>
                    <div
                      onMouseEnter={() => setGrayScaleIconHover(true)}
                      onMouseLeave={() =>
                        setGrayScaleIconHover(false)
                      }
                      className=' relative'
                    >
                      <IconBW
                        onClick={() => setBWEnable(true)}
                        className='cursor-pointer hover:brightness-[70%]'
                      />

                      {GrayScaleIconHover && (
                        <div
                          style={{
                            width: 'max-content',
                          }}
                          className='tooltip  top-[32px] left-[-42px]'
                        >
                          Gray Scale Preview
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={`flex gap-[8px]  `}>
                    <div
                      onMouseEnter={() => setHoriOrientHover(true)}
                      onMouseLeave={() => setHoriOrientHover(false)}
                      className='relative'
                    >
                      {HoriOrientHover && (
                        <div
                          style={{
                            width: 'max-content',
                          }}
                          className='tooltip  top-[32px] left-[-40px]'
                        >
                          Horizontal Preview
                        </div>
                      )}
                      <div
                        onClick={() => setPreview('horizontal')}
                        onMouseEnter={() => setHoriOrientHover(true)}
                        onMouseLeave={() => setHoriOrientHover(false)}
                        className={`cursor-pointer relative ${
                          edit && 'disabled'
                        }`}
                      >
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            x='23.5'
                            y='0.5'
                            width='23'
                            height='23'
                            rx='5.5'
                            transform='rotate(90 23.5 0.5)'
                            // stroke='#888888'
                            className={`${
                              !edit &&
                              (HoriOrientHover ||
                                preview === 'horizontal')
                                ? 'stroke-[#333333]'
                                : 'stroke-[#888888]'
                            } `}
                          />
                          <path
                            className={`${
                              !edit &&
                              (HoriOrientHover ||
                                preview === 'horizontal')
                                ? 'fill-[#333333]'
                                : 'fill-[#888888]'
                            }`}
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M6 6C4.89539 6 4 6.89539 4 8V16C4 17.1046 4.89539 18 6 18H18C19.1046 18 20 17.1046 20 16V8C20 6.89539 19.1046 6 18 6H6ZM10.4866 11C10.0075 10.9998 9.56445 11.2557 9.32385 11.6718L7 15.0118H13.9727L11.6488 11.6729C11.4086 11.2566 10.9658 11.0002 10.4866 11ZM15.4789 13.0118C14.902 12.183 13.9661 11.8108 12.9789 12.0118C13.1702 12.1653 13.5913 12.8624 14.006 13.5492C14.4077 14.2144 14.8035 14.8696 14.9789 15.0118H16.9611L15.4789 13.0118Z'
                            // fill='#888888'
                          />
                        </svg>

                        {/* <AddHori  /> */}
                      </div>
                    </div>

                    <div
                      onMouseEnter={() => setVertOrientHover(true)}
                      onMouseLeave={() => setVertOrientHover(false)}
                      className='relative'
                    >
                      {VertOrientHover && (
                        <div
                          style={{
                            width: 'max-content',
                          }}
                          className='tooltip  top-[32px] left-[-35px]'
                        >
                          Vertical Preview
                        </div>
                      )}
                      <div
                        // onClick={SetVerticalOri}
                        onClick={() => setPreview('vertical')}
                        onMouseEnter={() => setVertOrientHover(true)}
                        onMouseLeave={() => setVertOrientHover(false)}
                        className={`cursor-pointer ${
                          edit && 'disabled'
                        }`}
                      >
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            x='23.5'
                            y='0.5'
                            width='23'
                            height='23'
                            rx='5.5'
                            transform='rotate(90 23.5 0.5)'
                            className={`${
                              !edit &&
                              (VertOrientHover ||
                                preview === 'vertical')
                                ? 'stroke-[#333333]'
                                : 'stroke-[#888888]'
                            }`}
                          />
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M8 4C6.89539 4 6 4.89539 6 6V18C6 19.1046 6.89539 20 8 20H16C17.1046 20 18 19.1046 18 18V6C18 4.89539 17.1046 4 16 4H8ZM10.4866 11C10.0075 10.9998 9.56445 11.2557 9.32385 11.6718L7 15.0118H13.9727L11.6488 11.6729C11.4086 11.2566 10.9658 11.0002 10.4866 11ZM15.4789 13.0118C14.902 12.183 13.9661 11.8108 12.9789 12.0118C13.1702 12.1653 13.5913 12.8624 14.006 13.5492C14.4077 14.2144 14.8035 14.8696 14.9789 15.0118H16.9611L15.4789 13.0118Z'
                            className={`${
                              !edit &&
                              (VertOrientHover ||
                                preview === 'vertical')
                                ? 'fill-[#333333]'
                                : 'fill-[#888888]'
                            }`}
                          />
                        </svg>

                        {/* <AddVert /> */}
                      </div>
                    </div>

                    <div
                      onMouseEnter={() => setSquaOrientHover(true)}
                      onMouseLeave={() => setSquaOrientHover(false)}
                      className='relative'
                    >
                      {SquaOrientHover && (
                        <div
                          style={{
                            width: 'max-content',
                          }}
                          className='tooltip  top-[32px] left-[-35px]'
                        >
                          Square Preview
                        </div>
                      )}
                      <div
                        className={`cursor-pointer ${
                          edit && 'disabled'
                        }`}
                        onClick={() => setPreview('square')}
                        // onClick={SetSquareOri}
                        onMouseEnter={() => setSquaOrientHover(true)}
                        onMouseLeave={() => setSquaOrientHover(false)}
                      >
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            x='23.5'
                            y='0.5'
                            width='23'
                            height='23'
                            rx='5.5'
                            transform='rotate(90 23.5 0.5)'
                            className={`${
                              !edit &&
                              (SquaOrientHover ||
                                preview === 'square')
                                ? 'stroke-[#333333]'
                                : 'stroke-[#888888]'
                            }`}
                          />
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M8 6C6.89539 6 6 6.89539 6 8V16C6 17.1046 6.89539 18 8 18H16C17.1046 18 18 17.1046 18 16V8C18 6.89539 17.1046 6 16 6H8ZM10.4866 11C10.0075 10.9998 9.56445 11.2557 9.32385 11.6718L7 15.0118H13.9727L11.6488 11.6729C11.4086 11.2566 10.9658 11.0002 10.4866 11ZM15.4789 13.0118C14.902 12.183 13.9661 11.8108 12.9789 12.0118C13.1702 12.1653 13.5913 12.8624 14.006 13.5492C14.4077 14.2144 14.8035 14.8696 14.9789 15.0118H16.9611L15.4789 13.0118Z'
                            className={`${
                              !edit &&
                              (SquaOrientHover ||
                                preview === 'square')
                                ? 'fill-[#333333]'
                                : 'fill-[#888888]'
                            }`}
                          />
                        </svg>

                        {/* <AddSqua /> */}
                      </div>
                    </div>
                  </div>

                  {/* onClick={horizontalAlign} */}

                  <div className='flex gap-[8px]'>
                    {/* <div>
                      <ResetButton
                        className='cursor-pointer'
                        onClick={horizontalAlign} 
                      />
                    </div> */}

                    <div
                      onClick={() => setEdit(!edit)}
                      className={`cursor-pointer  relative ${
                        !pencilState ? 'disabled' : ''
                      }`}
                      onMouseEnter={() => setPencilHover(true)}
                      onMouseLeave={() => setPencilHover(false)}
                    >
                      {pencilHover && (
                        <div
                          style={{
                            width: 'max-content',
                          }}
                          className='tooltip  top-[32px] left-[-15px]'
                        >
                          Edit Art
                        </div>
                      )}
                      <Icon_Edit
                        className={`hover:fill-[#333333] ${
                          edit ? 'fill-[#333333]' : 'fill-[#888888]'
                        } `}
                      />
                    </div>
                  </div>
                </div>

                <div className='flex gap-[16px] pt-[8px] herenow'>
                  <div
                    onMouseEnter={() => setExclusiveHover(true)}
                    onMouseLeave={() => setExclusiveHover(false)}
                    className='relative'
                  >
                    {ExclusiveHover && (
                      <div
                        style={{
                          width: 'max-content',
                        }}
                        className='tooltip  top-[32px] left-[-29px]'
                      >
                        Exclusive Art
                      </div>
                    )}
                    <Exclusive_Icon24
                      onClick={() => {
                        setExclusiveCheck('Exclusive Art');
                      }}
                      className={`${
                        typeOfContentCheckoxesTick('Exclusive Art')
                          ? 'fill-[#333333]'
                          : 'fill-[#888888]'
                      } hover:fill-[#333333] cursor-pointer`}
                    />
                  </div>
                  <div
                    onMouseEnter={() => setFeaturedHover(true)}
                    onMouseLeave={() => setFeaturedHover(false)}
                    className='relative'
                  >
                    {FeaturedHover && (
                      <div
                        style={{
                          width: 'max-content',
                        }}
                        className='tooltip  top-[32px] left-[-30px]'
                      >
                        Featured Art
                      </div>
                    )}
                    <Featured_Icon24
                      onClick={() => {
                        setFeaturedCheck('Featured Art');
                      }}
                      className={`${
                        typeOfContentCheckoxesTick('Featured Art')
                          ? 'fill-[#333333]'
                          : 'fill-[#888888]'
                      } hover:fill-[#333333] cursor-pointer`}
                    />
                  </div>
                </div>
              </div>

              {edit && (
                <div className='mt-[16px]'>
                  <div className=''>
                    <p className='text-sm11 font-medium text-[#bbbbbb] text-center'>
                      Resize, Colourize and Brightness/Contrast
                    </p>
                    <p className='text-sm11 text-primaryGray text-center'>
                      Colourize and Brightness/Contrast will be
                      applied to all orientation{' '}
                    </p>
                  </div>
                  <div
                    style={{
                      width: 'fit-content',
                    }}
                    className='flex gap-[16px] mx-[auto] mt-[16px]'
                  >
                    <div className='flex gap-[8px]'>
                      <div
                        onMouseEnter={() =>
                          setHorizontalCropHover(true)
                        }
                        onMouseLeave={() =>
                          setHorizontalCropHover(false)
                        }
                        onClick={SetHorizontalOri}
                        className='cursor-pointer relative'
                      >
                        {HorizontalCropHover && (
                          <div
                            style={{
                              width: 'max-content',
                            }}
                            className='tooltip  top-[32px] left-[-52px]'
                          >
                            Horizontal Art Position
                          </div>
                        )}
                        <HorizontalCropIcon
                          className={`${
                            currentOri === 'horizontal'
                              ? 'fill-primaryBlack'
                              : ''
                          } fill-[#888888] hover:fill-primaryBlack`}
                        />
                      </div>
                      <div
                        onClick={SetVerticalOri}
                        className='cursor-pointer relative'
                        onMouseEnter={() =>
                          setVerticalCropHover(true)
                        }
                        onMouseLeave={() =>
                          setVerticalCropHover(false)
                        }
                      >
                        {VerticalCropHover && (
                          <div
                            style={{
                              width: 'max-content',
                            }}
                            className='tooltip  top-[32px] left-[-47px]'
                          >
                            Vertical Art Position
                          </div>
                        )}
                        <VerticalCropIcon
                          className={`${
                            currentOri === 'vertical'
                              ? 'fill-primaryBlack'
                              : ''
                          } fill-[#888888] hover:fill-primaryBlack`}
                        />
                      </div>
                      <div
                        onClick={SetSquareOri}
                        className='cursor-pointer relative'
                        onMouseEnter={() => setSquareCropHover(true)}
                        onMouseLeave={() => setSquareCropHover(false)}
                      >
                        {SquareCropHover && (
                          <div
                            style={{
                              width: 'max-content',
                            }}
                            className='tooltip  top-[32px] left-[-45px]'
                          >
                            Square Art Position
                          </div>
                        )}
                        <SquareCropIcon
                          className={`${
                            currentOri === 'square'
                              ? 'fill-primaryBlack'
                              : ''
                          } fill-[#888888] hover:fill-primaryBlack`}
                        />
                      </div>
                      {/* <img
                        className='cursor-pointer'
                        onClick={SetHorizontalOri}
                        src={horizontalCropIcon}
                        alt=''
                      />
                      <img
                        className='cursor-pointer'
                        onClick={SetVerticalOri}
                        src={verticalCropIcon}
                        alt=''
                      />
                      <img
                        className='cursor-pointer'
                        onClick={SetSquareOri}
                        src={squareCropIcon}
                        alt=''
                      /> */}
                    </div>
                    <div className='flex gap-[8px]'>
                      <div
                        className='cursor-pointer relative'
                        onClick={horizontalAlign}
                        onMouseEnter={() =>
                          setAlignHorizontalHover(true)
                        }
                        onMouseLeave={() =>
                          setAlignHorizontalHover(false)
                        }
                      >
                        {AlignHorizontalHover && (
                          <div
                            style={{
                              width: 'max-content',
                            }}
                            className='tooltip  top-[32px] left-[-38px]'
                          >
                            Align Horizontal
                          </div>
                        )}
                        <Icon_AlignHorizontal className='fill-[#888888] hover:fill-primaryBlack' />
                      </div>
                      <div
                        className='cursor-pointer relative'
                        onClick={verticalAlign}
                        onMouseEnter={() =>
                          setAlignVerticalHover(true)
                        }
                        onMouseLeave={() =>
                          setAlignVerticalHover(false)
                        }
                      >
                        {AlignVerticalHover && (
                          <div
                            style={{
                              width: 'max-content',
                            }}
                            className='tooltip  top-[32px] left-[-30px]'
                          >
                            Align Vertical
                          </div>
                        )}
                        <Icon_AlignVertical className='fill-[#888888] hover:fill-primaryBlack' />
                      </div>

                      <div
                        onMouseEnter={() => setResetHover(true)}
                        onMouseLeave={() => setResetHover(false)}
                        className='cursor-pointer relative'
                      >
                        {/* <img src={Reset} alt='' /> */}

                        {ResetHover && (
                          <div
                            style={{
                              width: 'max-content',
                            }}
                            className='tooltip  top-[32px] left-[-12px]'
                          >
                            Reset
                          </div>
                        )}
                        <Reset className='fill-[#888888] hover:fill-primaryBlack' />
                      </div>
                    </div>

                    <div
                      onMouseEnter={() => setColourizeIconHover(true)}
                      onMouseLeave={() =>
                        setColourizeIconHover(false)
                      }
                      className='cursor-pointer relative'
                    >
                      {/* <img src={colourizeIcon} alt='' /> */}

                      {ColourizeIconHover && (
                        <div
                          style={{
                            width: 'max-content',
                          }}
                          className='tooltip  top-[32px] left-[-28px]'
                        >
                          Colourize Art
                        </div>
                      )}
                      <ColourizeIcon className='fill-[#888888] hover:fill-primaryBlack' />
                    </div>
                  </div>

                  <div
                    style={{
                      width: 'fit-content',
                    }}
                    className={`mx-[auto]  mt-[32px] flex items-center gap-[8px]`}
                  >
                    <div>
                      <Minus />
                    </div>
                    <Slider
                      value={sliderValue}
                      // value={
                      //   currentOri === 'horizontal'
                      //     ? formData?.previews?.horizontalPreview?.zoom
                      //     : currentOri === 'vertical'
                      //     ? formData?.previews?.verticalPreview?.zoom
                      //     : currentOri === 'square'
                      //     ? formData?.previews?.squarePreview?.zoom
                      //     : 1
                      // }
                      onChange={handleZoomChangeSlider}
                      // onChange={sizeRange}
                      sx={{
                        width: '248px',
                        height: '4px',
                        padding: '0',
                        '& .MuiSlider-rail': {
                          opacity: '100%',
                          background:
                            'linear-gradient(to right, #ff369f, #ff7a6d, #ffd133, #a4e154, #36deed)',
                          // 'linear-gradient(to right, #ff369f,#ff7a6d,#ffd133,#a4e154,#36deed)',
                        },
                        '& .MuiSlider-track': {
                          opacity: '0',
                        },
                        '& .MuiSlider-thumb': {
                          width: '11px',
                          height: '13px',
                          borderStyle: 'solid',
                          borderRadius: '1px',
                          borderBottomLeftRadius: '21px',
                          borderBottomRightRadius: '21px',
                          backgroundColor: '#888888',
                        },
                        '& .MuiSlider-thumb': {
                          background: 'none',
                          boxShadow: 'none!important',
                          '-webkit-box-shadow': 'none!important',
                          // backgroundImage: `url(${SliderButton})`,
                          height: '10px',
                          width: '10px',
                          backgroundColor: '#888888',
                          borderRadius: '5000px',
                          boxShadow: '0 0 #0000!important',
                        },
                        '& .css-eg0mwd-MuiSlider-thumb:before': {
                          boxShadow: 'none!important',
                        },
                        '& .MuiSlider-thumb:before': {
                          boxShadow: 'none!important',
                        },
                      }}
                      // defaultValue={
                      //   formData?.zoom === 0 ? 1 : formData?.zoom
                      // }
                      // defaultValue={2}

                      min={1}
                      max={3}
                      step={0.000000000000001} // to allow very fine adjustments
                      // aria-label='Disabled slider'
                    />
                    <div>
                      <Plus />
                    </div>

                    {/* <img
                      className='relative bottom-[6px]'
                      src={scale}
                      alt=''
                    /> */}
                  </div>
                  <div
                    style={{
                      width: 'fit-content',
                    }}
                    className='flex gap-[8px] mx-[auto] mt-[32px] mb-[16px]'
                  >
                    <button
                      onClick={ImageCropCapture}
                      className='gray30HButton'
                    >
                      Save Orientation
                    </button>
                    <button
                      onClick={() => setEdit(false)}
                      className='gray30HBorderButton'
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}

              <div className='h-[1px] bg-[#efefef] w-[100%] mt-[32px]'></div>

              <div className='mt-[31px]'>
                <p className='pinkTextUploadFlow  '>
                  All fields are mandatory.
                </p>
                <p className='text-primaryGray text-sm11 font-normal max-w-[456px] w-[100%] mt-[1px]'>
                  Art submissions that requires a Note and does not
                  fall in any of the situations below, please contact{' '}
                  <span className='text-orangeColor'>
                    support@artnstock.com
                  </span>{' '}
                  to assist you with your submission.
                </p>
              </div>

              <div className='flex flex-col pt-[27px]'>
                <p className='text-[16px] text-primaryBlack font-medium'>
                  Select Category
                </p>
                <div className='flex pt-[12px]'>
                  <div
                    onClick={() => setcategorySelected('art')}
                    className='flex flex-col cursor-pointer'
                  >
                    <div
                      onMouseEnter={() => setArtIconHover(true)}
                      onMouseLeave={() => setArtIconHover(false)}
                      className='w-[48px] mr-[4px]'
                    >
                      <svg
                        width='48'
                        height='48'
                        viewBox='0 0 48 48'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M38.4 0H9.6C4.29807 0 0 4.29807 0 9.6V38.4C0 43.7019 4.29807 48 9.6 48H38.4C43.7019 48 48 43.7019 48 38.4V9.6C48 4.29807 43.7019 0 38.4 0Z'
                          fill=''
                          className={`${
                            artIconHover || categorySelected === 'art'
                              ? 'fill-[#bbbbbb]'
                              : 'fill-[#EEEEEE]'
                          } `}
                        />
                        <path
                          className={`${
                            artIconHover || categorySelected === 'art'
                              ? 'fill-[#333333]'
                              : 'fill-[#888888]'
                          } `}
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M25.4907 12V13.5H33.45C34.8583 13.5 36 14.6417 36 16.05V24.45C36 25.8583 34.8583 27 33.45 27H28.4789L29.9731 36H28.4848L27.7365 31.5H20.2665L19.5199 36H18.0317L19.5258 27H14.55C13.1417 27 12 25.8583 12 24.45V16.05C12 14.6417 13.1417 13.5 14.55 13.5H22.5141V12H25.4907ZM26.9907 27H25.4965V30H27.4875L26.9907 27ZM22.5082 27H21.0141L20.5155 30H22.5082V27ZM21.7616 17.9823C21.0428 17.982 20.3783 18.3659 20.0175 18.99L16.5317 24H26.9907L23.5048 18.9915C23.1445 18.3671 22.4803 17.9826 21.7616 17.9823ZM29.25 21C28.3846 19.7567 26.9807 19.1985 25.5 19.5C26.0648 19.953 27.9651 23.5665 28.5 24H31.4731L29.25 21Z'
                        />
                      </svg>

                      {/* <ArtIcon
                        className={`${
                          categorySelected === 'art'
                            ? 'fill-[#333333]'
                            : 'fill-[#888888]'
                        }  hover:fill-[#333333]`}
                      /> */}
                    </div>
                    <p
                      className={`${
                        artIconHover || categorySelected === 'art'
                          ? 'text-primaryBlack'
                          : 'text-primaryGray '
                      } text-center text-[11px] leading-[14px] mt-[2px] `}
                    >
                      Art
                    </p>
                  </div>
                  <div
                    // onMouseEnter={() => setPhotosIconHover(true)}
                    // onMouseLeave={() => setPhotosIconHover(false)}
                    onClick={() => setcategorySelected('photos')}
                    className='flex flex-col  cursor-pointer w-[56px]'
                  >
                    <svg
                      width='48'
                      height='48'
                      viewBox='0 0 48 48'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className='mx-[auto]'
                    >
                      <path
                        d='M38.4 0H9.6C4.29807 0 0 4.29807 0 9.6V38.4C0 43.7019 4.29807 48 9.6 48H38.4C43.7019 48 48 43.7019 48 38.4V9.6C48 4.29807 43.7019 0 38.4 0Z'
                        fill='#EEEEEE'
                        className={`${
                          photosIconHover ||
                          categorySelected === 'ajbjb'
                            ? 'fill-[#bbbbbb]'
                            : 'fill-[#EEEEEE]'
                        }`}
                      />
                      <path
                        className={`${
                          photosIconHover ||
                          categorySelected === 'ajbjb'
                            ? 'fill-[#333333]'
                            : 'fill-[#888888]'
                        }`}
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M26.7545 14C27.4179 14.3932 28.0066 14.9006 28.4935 15.4988L29.9914 18.4963H32.9871C33.8064 18.404 34.6231 18.69 35.2062 19.2734C35.7892 19.8568 36.075 20.674 35.9828 21.4938V31.9852C36.0756 32.8052 35.7899 33.6228 35.2068 34.2063C34.6236 34.7898 33.8065 35.0756 32.9871 34.9828H15.0129C14.1935 35.0756 13.3764 34.7898 12.7932 34.2063C12.2101 33.6228 11.9244 32.8052 12.0172 31.9852V21.4938C11.925 20.674 12.2108 19.8568 12.7938 19.2734C13.3769 18.69 14.1936 18.404 15.0129 18.4963H18.0086L19.5065 15.4988C19.9119 14.9133 20.4192 14.4057 21.0043 14H26.7545ZM24 21.4938C21.5183 21.4938 19.5065 23.5069 19.5065 25.9902C19.5065 28.4734 21.5183 30.4865 24 30.4865C26.4817 30.4865 28.4935 28.4734 28.4935 25.9902C28.4935 23.5069 26.4817 21.4938 24 21.4938Z'
                      />
                    </svg>

                    {/* <div>
                      <PhotoIcon
                        className={`${
                          categorySelected === 'photos'
                            ? 'fill-[#333333]'
                            : 'fill-[#888888]'
                        } hover:fill-[#333333]`}
                      />
                    </div> */}
                    <p
                      className={`${
                        photosIconHover ||
                        categorySelected === 'ajbjb'
                          ? 'text-primaryBlack'
                          : 'text-primaryGray '
                      } text-center text-[11px] leading-[14px] mt-[2px]`}
                    >
                      Photos
                    </p>
                  </div>
                  <div className='flex flex-col  w-[56px] cursor-pointer'>
                    <div>
                      <FootageIcon className='mx-[auto]' />
                    </div>
                    <p className='text-primaryGray text-center text-[11px] leading-[14px] mt-[2px]'>
                      Footage
                    </p>
                  </div>
                  <div className='flex flex-col  w-[56px] cursor-pointer'>
                    <div>
                      <MusicIcon className='mx-[auto]' />
                    </div>
                    <p className='text-primaryGray text-center text-[11px] leading-[14px] mt-[2px]'>
                      Music
                    </p>
                  </div>
                  <div className='flex flex-col  w-[56px] cursor-pointer'>
                    <div>
                      <TemplatesIcon className='mx-[auto]' />
                    </div>
                    <p className='text-primaryGray text-center text-[11px] leading-[14px] mt-[2px]'>
                      Templates
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {categorySelected === 'art' ? (
            <div className='w-[690px] mx-[auto]'>
              <div className='flex flex-col pt-[31px]'>
                {/* <p className='text-[16px] text-primaryBlack font-medium'>
                  Select Art Category
                </p> */}
                <div className='relative'>
                  <input
                    type='text'
                    placeholder='Enter Art Title'
                    className='InputField44H'
                    onChange={handleInputChange}
                    value={formData?.artName || ''}
                    name='artName'
                  />

                  <p className='InputFieldTopText44H'>Art Title</p>
                </div>
                <div className='flex gap-[8px]  pt-[16px]'>
                  <div className='flex flex-col'>
                    <div
                      className='abc w-[224px]'
                      ref={styleClickOutside}
                    >
                      <style
                        dangerouslySetInnerHTML={{
                          __html: scrollbarStyle,
                        }}
                      />

                      <div
                        onClick={() => setIsOpenStyle(!isOpenStyle)}
                        className={`${
                          isOpenStyle
                            ? 'dropdownTopButtonOpenedStyle'
                            : 'dropdownTopButtonClosedStyle'
                        } dropdownTopButtonCommonStyle `}
                      >
                        {styleMenu ? (
                          <div className='flex flex-col h-[100%]'>
                            <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                              Style
                            </p>
                            <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                              {styleMenu.name}
                            </p>
                          </div>
                        ) : (
                          <div className='flex flex-col h-[100%]'>
                            <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                              Select
                            </p>
                            <p className='text-[#bbbbbb] mt-[2px] font-medium flex text-[14px] leading-[1]'>
                              Select Style
                            </p>
                          </div>
                        )}

                        <DropArrow
                          className={`${
                            isOpenStyle === true
                              ? 'transform rotate-180'
                              : ''
                          }`}
                          style={{
                            fill: '#878787',
                          }}
                        />
                      </div>
                      {isOpenStyle && (
                        <div className='relative'>
                          <div
                            onMouseEnter={() => setAllDropHover(true)}
                            onMouseLeave={() =>
                              setAllDropHover(false)
                            }
                            style={{
                              boxShadow:
                                'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                            }}
                            className={`dropdownTopDivOfULCommonStyle ${
                              allDropHover ? 'pr-[4px]' : ''
                            }`}
                          >
                            <ul
                              className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                            >
                              {styleMenuList?.map((style, index) => (
                                <li
                                  key={index}
                                  onClick={() => {
                                    handleStyleMenuClick(style);
                                    setIsOpenStyle(false);
                                  }}
                                  className={`dropdownLiCommonStyle  ${
                                    index === styleMenuList.length - 1
                                      ? 'pb-[4px] h-[39px]'
                                      : 'border-b'
                                  }   border-[#efefef]  hover:bg-[#f0f0f0]  hover:text-primaryBlack  h-[35px]`}
                                >
                                  {style.name}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='flex flex-col'>
                    {/* <p className='text-[12px] primary-Gray pt-[10px]'>
                      Subject
                    </p> */}

                    <div
                      className='abc w-[224px]'
                      ref={subjectClickOutside}
                    >
                      <style
                        dangerouslySetInnerHTML={{
                          __html: scrollbarStyle,
                        }}
                      />
                      <div
                        onClick={() =>
                          setIsOpenSubject(!isOpenSubject)
                        }
                        className={`${
                          isOpenSubject
                            ? 'dropdownTopButtonOpenedStyle'
                            : 'dropdownTopButtonClosedStyle'
                        } dropdownTopButtonCommonStyle `}
                      >
                        {subjectMenu ? (
                          <div className='flex flex-col h-[100%]'>
                            <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                              Subject
                            </p>
                            <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                              {subjectMenu?.subjectName}
                            </p>
                          </div>
                        ) : (
                          <div className='flex flex-col h-[100%]'>
                            <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                              Subject
                            </p>
                            <p className='text-[#bbbbbb] mt-[2px] font-medium flex text-[14px] leading-[1]'>
                              Select Subject
                            </p>
                          </div>
                        )}

                        <DropArrow
                          className={`${
                            isOpenSubject === true
                              ? 'transform rotate-180'
                              : ''
                          }`}
                          style={{
                            fill: '#878787',
                          }}
                        />
                      </div>
                      {isOpenSubject && (
                        <div className='relative'>
                          <div
                            onMouseEnter={() => setAllDropHover(true)}
                            onMouseLeave={() =>
                              setAllDropHover(false)
                            }
                            style={{
                              boxShadow:
                                'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                            }}
                            className={`dropdownTopDivOfULCommonStyle ${
                              allDropHover ? 'pr-[4px]' : ''
                            }`}
                          >
                            <ul
                              className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                            >
                              {subjectMenuList?.map(
                                (subject, index) => (
                                  <li
                                    key={index}
                                    onClick={() => {
                                      handleSubjectMenuClick(subject);
                                      setIsOpenSubject(false);
                                    }}
                                    className={`dropdownLiCommonStyle  ${
                                      index ===
                                      subjectMenuList.length - 1
                                        ? 'pb-[4px] h-[39px]'
                                        : 'border-b'
                                    }   border-[#efefef]  hover:bg-[#f0f0f0]  hover:text-primaryBlack  h-[35px]`}
                                  >
                                    {subject.subjectName}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='flex flex-col '>
                    <div
                      className='abc w-[224px]'
                      ref={mediumClickOutside}
                    >
                      <style
                        dangerouslySetInnerHTML={{
                          __html: scrollbarStyle,
                        }}
                      />

                      <div
                        onClick={() => {
                          setIsOpenMedium(!isOpenMedium);
                        }}
                        className={`${
                          isOpenMedium
                            ? 'dropdownTopButtonOpenedStyle'
                            : 'dropdownTopButtonClosedStyle'
                        } dropdownTopButtonCommonStyle `}
                      >
                        {mediumMenu ? (
                          <div className='flex flex-col h-[100%]'>
                            <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                              Medium
                            </p>
                            <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                              {mediumMenu?.mediumType}
                            </p>
                          </div>
                        ) : (
                          <div className='flex flex-col h-[100%]'>
                            <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                              Medium
                            </p>
                            <p className='text-[#bbbbbb] mt-[2px] font-medium flex text-[14px] leading-[1]'>
                              Select Medium
                            </p>
                          </div>
                        )}

                        <DropArrow
                          className={`${
                            isOpenMedium === true
                              ? 'transform rotate-180'
                              : ''
                          }`}
                          style={{
                            fill: '#878787',
                          }}
                        />
                      </div>
                      {isOpenMedium && (
                        <div className='relative'>
                          <div
                            onMouseEnter={() => setAllDropHover(true)}
                            onMouseLeave={() =>
                              setAllDropHover(false)
                            }
                            className={`dropdownTopDivOfULCommonStyle ${
                              allDropHover ? 'pr-[4px]' : ''
                            }`}
                            style={{
                              boxShadow:
                                'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                            }}
                            // className='dropdownTopDivOfULCommonStyle'
                          >
                            <ul
                              className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                            >
                              {mediumMenuList?.map(
                                (medium, index) => (
                                  <li
                                    key={index}
                                    onClick={() => {
                                      handleMediumMenuClick(medium);
                                      setIsOpenMedium(false);
                                    }}
                                    className={`dropdownLiCommonStyle  ${
                                      index ===
                                      mediumMenuList.length - 1
                                        ? 'pb-[4px] h-[39px]'
                                        : 'border-b'
                                    }   border-[#efefef]  hover:bg-[#f0f0f0]  hover:text-primaryBlack  h-[35px]`}
                                  >
                                    {medium?.mediumType}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* <div
                      className='abc'
                      onClick={() => {
                        setIsOpenMedium(!isOpenMedium);
                      }}
                      ref={mediumClickOutside}
                    >
                      <button className='w-[224px] h-[44px] rounded-[5000px]  flex items-center justify-between  border-[1px] border-[#d6d6d6] px-[20px] text-[14px] text-primaryGray text-start'>
                        {mediumMenu ? (
                          <div className='flex flex-col gap-[1px]'>
                            <p className='text-[#bbbbbb] text-[11px] leading-[1]'>
                              Select Medium
                            </p>
                            <p className='text-primaryGray font-medium flex text-[14px] leading-[1]'>
                              {mediumMenu?.mediumType}
                            </p>
                          </div>
                        ) : (
                          <div className='flex flex-col gap-[1px]'>
                            <p className='text-[#bbbbbb] text-[11px] leading-[1]'>
                              Select Medium
                            </p>
                            <p className='text-primaryGray font-medium flex text-[14px] leading-[1]'>
                              Select Medium
                            </p>
                          </div>
                        )}
                        <div>
                          <DropArrow
                            className={`fill-[#878787]  ${
                              isOpenMedium ? 'rotate-180' : ''
                            }`}
                          />
                        </div>
                      </button>
                      {isOpenMedium && (
                        <ul
                          className='shadow-dropShadow rounded-b-2xl dropdown__menu absolute z-50 bg-[#ffffff] w-[224px] text-center text-[14px] text-primaryGray max-h-[220px] overflow-y-auto'
                          style={{
                            boxShadow:
                              ' rgba(149, 157, 165, 0.2) 0px 8px 24px',
                          }}
                        >
                          {mediumMenuList &&
                            mediumMenuList?.map((medium) => (
                              <li
                                className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'
                                onClick={() => {
                                  handleMediumMenuClick(medium);
                                }}
                              >
                                {medium?.mediumType}
                              </li>
                            ))}
                        </ul>
                      )}
                    </div> */}
                  </div>
                </div>
              </div>

              {/* tempi */}

              <div className='pt-[16px]'>
                {/* <p className='text-[12px] text-primaryGray'>
                  Description
                </p> */}
                <div className='relative'>
                  <textarea
                    name='description'
                    // placeholder='Art Description'
                    type='text'
                    className='resize-none border border-[#d6d6d6] h-[236px] w-[456px] text-primaryGray text-sm14 rounded-[16px] px-[16px] pt-[23px] outline-none'
                    value={formData?.description || ''}
                    onChange={handleDescriptionChange}
                  />
                  <p className='absolute top-[8px] left-[16px] text-[11px] leading-[14px] text-[#bbbbbb]'>
                    Art Description
                  </p>
                  <p className='absolute bottom-[14px] left-[16px] text-[11px] text-[#bbbbbb]'>
                    {formData?.description?.length}/450 characters
                  </p>
                </div>
              </div>

              <div className='h-[1px] bg-[#efefef] w-[100%] mb-[27px] mt-[25px]'></div>

              <div className='flex flex-col w-[456px]'>
                <p className='text-[16px] text-primaryBlack font-medium'>
                  Select Type of Content
                </p>
                <p className='text-sm11 text-primaryGray mt-[11px]'>
                  Use the <span className='font-medium'>Notes </span>
                  section to explain, if your Art, Photos, Footage,
                  Music, or Template is Original <br />
                  Content, Art with Reference, Exclusive Art, Matured
                  Content, or Re-Submission.
                </p>

                <div className='flex flex-col gap-[4px] pt-[14px]'>
                  <label
                    style={{
                      width: 'fit-content',
                    }}
                    className='containerCheckGray text-sm14 font-medium text-primaryGray '
                  >
                    <input
                      type='checkbox'
                      name='typeOfContent'
                      value='Original Art'
                      onChange={handleInputChange}
                      checked={typeOfContentCheckoxesTick(
                        'Original Art'
                      )}
                    />{' '}
                    Original Art
                    <span className='checkmarkGray'></span>
                  </label>

                  <label
                    style={{
                      width: 'fit-content',
                    }}
                    className='containerCheckGray  text-sm14 font-medium text-primaryGray '
                  >
                    <input
                      type='checkbox'
                      name='typeOfContent'
                      value='Art with Reference'
                      onChange={handleInputChange}
                      checked={typeOfContentCheckoxesTick(
                        'Art with Reference'
                      )}
                    />{' '}
                    Art with Reference
                    <span className='checkmarkGray'></span>
                  </label>
                </div>
                <div className={`pt-[6px] `}>
                  {typeOfContentCheckoxesTick(
                    'Art with Reference'
                  ) ? (
                    <div className='flex flex-col w-[456px] flex-wrap border border-dashed rounded-[13px] pb-[8px] pt-[7px] pl-[8px] border-[#d6d6d6]'>
                      <p
                        className={`text-sm11 text-primaryGray ${
                          typeOfContentCheckoxesTick(
                            'Art with Reference'
                          )
                            ? ''
                            : 'opacity-[40%]'
                        }`}
                      >
                        Attach reference file (Acceptable File: JPEG,
                        72dpi/300dpi, 2 MB Maximum)
                      </p>

                      <div
                        className={`flex gap-[8px] pt-[8px] ${
                          typeOfContentCheckoxesTick(
                            'Art with Reference'
                          )
                            ? ''
                            : 'opacity-[40%]'
                        }`}
                      >
                        <input
                          type='file'
                          multiple
                          onChange={handleFileChange}
                          accept='image/*'
                          style={{ display: 'none' }}
                          ref={fileInputRef}
                        />
                        {formData?.referenceFile?.map(
                          (card, index) => (
                            <div
                              style={{
                                backgroundImage: `url(${card})`,
                              }}
                              className='w-[64px] relative hover:brightness-[80%] h-[64px] rounded-[12px] flex justify-center items-center bg-no-repeat bg-center bg-cover transform translate-z-0'
                            >
                              <div
                                onClick={() => {
                                  deleteReferenceImage(card);
                                }}
                              >
                                <Cross8 className='absolute herennow cursor-pointer top-[5px] right-[5px]' />
                              </div>
                            </div>
                            // <AttachFileCards key={index} card={card} />
                          )
                        )}
                        <div
                          onClick={handleButtonClick}
                          className='w-[64px] h-[64px] bg-[#f7f7f7] rounded-[12px] flex justify-center items-center cursor-pointer'
                        >
                          <div>
                            <Plus24Icon className='fill-[#888888] hover:fill-[#333333]' />
                          </div>
                          {/* <img
                      className='h-[25px] w-[25px]'
                      src={addsmall}
                    /> */}
                        </div>
                      </div>

                      {/* {cards.map((card) => (
                <div
                  style={{
                    backgroundImage: `url(${URL.createObjectURL(
                      card
                    )})`,
                  }}
                  className='w-[65px] h-[65px] rounded-[10px] flex justify-center items-center bg-no-repeat bg-center bg-cover transform translate-z-0'
                ></div>
              ))} */}
                    </div>
                  ) : (
                    <div className='flex flex-col w-[456px] flex-wrap border border-dashed rounded-[13px] pb-[8px] pt-[7px] pl-[8px] border-[#d6d6d6]'>
                      <p
                        className={`text-sm11 text-primaryGray opacity-[40%]
        `}
                      >
                        Attach reference file (Acceptable File: JPEG,
                        72dpi/300dpi, 2 MB Maximum)
                      </p>

                      <div
                        className={`flex gap-[8px] pt-[8px] opacity-[40%]
        `}
                      >
                        <input
                          type='file'
                          multiple
                          onChange={handleFileChange}
                          accept='image/*'
                          style={{ display: 'none' }}
                          ref={fileInputRef}
                        />
                        {formData?.referenceFile?.map(
                          (card, index) => (
                            <div
                              style={{
                                backgroundImage: `url(${card})`,
                              }}
                              className='w-[64px] relative h-[64px] rounded-[10px] flex justify-center items-center bg-no-repeat bg-center bg-cover transform translate-z-0'
                            >
                              <div
                              // onClick={() => {
                              //   deleteReferenceImage(card);
                              // }}
                              >
                                <Cross8 className='absolute herennow top-[5px] right-[5px]' />
                              </div>
                            </div>
                            // <AttachFileCards key={index} card={card} />
                          )
                        )}
                        <div
                          // onClick={handleButtonClick}
                          className='w-[64px] h-[64px] bg-[#f7f7f7] rounded-[10px] flex justify-center items-center'
                        >
                          <div>
                            <Plus24Icon className='fill-[#888888]' />
                          </div>
                          {/* <img
        className='h-[25px] w-[25px]'
        src={addsmall}
      /> */}
                        </div>
                      </div>

                      {/* {cards.map((card) => (
  <div
    style={{
      backgroundImage: `url(${URL.createObjectURL(
        card
      )})`,
    }}
    className='w-[65px] h-[65px] rounded-[10px] flex justify-center items-center bg-no-repeat bg-center bg-cover transform translate-z-0'
  ></div>
))} */}
                    </div>
                  )}
                </div>
                <div className='flex flex-col gap-[4px] pt-[6px]'>
                  <div className='flex items-center gap-[8px]'>
                    <label
                      style={{
                        width: 'fit-content',
                      }}
                      className='containerCheckGray  text-sm14 font-medium text-primaryGray '
                    >
                      <input
                        type='checkbox'
                        name='typeOfContent'
                        value='Exclusive Art'
                        onChange={handleInputChange}
                        checked={typeOfContentCheckoxesTick(
                          'Exclusive Art'
                        )}
                      />{' '}
                      Exclusive Art
                      <span className='checkmarkGray'></span>
                    </label>
                    <div>
                      <Exclusive_Icon16 className='fill-[#bbbbbb]' />
                    </div>
                  </div>

                  <div className='flex items-center gap-[8px]'>
                    <label
                      style={{
                        width: 'fit-content',
                      }}
                      className='containerCheckGray  text-sm14 font-medium text-primaryGray '
                    >
                      <input
                        type='checkbox'
                        name='typeOfContent'
                        value='Featured Art'
                        onChange={handleInputChange}
                        checked={typeOfContentCheckoxesTick(
                          'Featured Art'
                        )}
                      />{' '}
                      Featured Art
                      <span className='checkmarkGray'></span>
                    </label>
                    <div>
                      <Featured_Icon16 className='fill-[#bbbbbb]' />
                    </div>
                  </div>

                  <label
                    style={{
                      width: 'fit-content',
                    }}
                    className='containerCheckGray  text-sm14 font-medium text-primaryGray '
                  >
                    <input
                      type='checkbox'
                      name='typeOfContent'
                      value='Matured Art'
                      onChange={handleInputChange}
                      checked={typeOfContentCheckoxesTick(
                        'Matured Art'
                      )}
                    />{' '}
                    Matured Art
                    <span className='checkmarkGray'></span>
                  </label>
                  <label
                    style={{
                      width: 'fit-content',
                    }}
                    className='containerCheckGray  text-sm14 font-medium text-primaryGray '
                  >
                    <input
                      type='checkbox'
                      name='typeOfContent'
                      value='Art Re-Submission'
                      onChange={handleInputChange}
                      checked={typeOfContentCheckoxesTick(
                        'Art Re-Submission'
                      )}
                    />{' '}
                    Art Re-Submission
                    <span className='checkmarkGray'></span>
                  </label>
                </div>
              </div>

              <div className='mt-[6px] relative w-[456px]'>
                {/* <p className='text-[12px] primary-Gray pt-[10px]'>
                    Image Title
                  </p> */}
                {typeOfContentCheckoxesTick('Art Re-Submission') ? (
                  <>
                    <input
                      type='text'
                      placeholder='Case Number'
                      className='InputField44H'
                      // onChange={handleInputChange}
                      // readOnly
                      // value={userUniqueNo || ''}

                      name='caseNumber'
                      style={{ position: 'relative' }}
                    />
                    <p className='InputFieldTopText44H '>
                      Case Number
                    </p>
                  </>
                ) : (
                  <>
                    <input
                      type='text'
                      placeholder='Case Number'
                      className='InputField44H disabled'
                      // onChange={handleInputChange}
                      // readOnly
                      // value={userUniqueNo || ''}
                      // disabled
                      name='caseNumber'
                      style={{ position: 'relative' }}
                    />
                    <p className='InputFieldTopText44H disabled'>
                      Case Number
                    </p>
                  </>
                )}
              </div>

              <div className='pt-[32px]'>
                {/* <p className='text-[12px] text-primaryGray'>
                  Description
                </p> */}
                <div className='relative'>
                  <textarea
                    name='notes'
                    // placeholder='Notes'
                    type='text'
                    className='resize-none border border-[#d6d6d6] h-[148px] w-[456px] text-primaryGray text-sm14 rounded-[16px] px-[16px] pt-[23px] outline-none'
                    value={formData.notes}
                    onChange={handleNotesChange}
                  />
                  <p className='absolute top-[8px] left-[16px] text-[11px] leading-[14px] text-[#bbbbbb]'>
                    Notes
                  </p>
                  <p className='absolute bottom-[14px] left-[16px] text-[11px] text-[#bbbbbb]'>
                    {/* {charCountt}/500 characters */}
                    {formData?.notes?.length}/150 characters
                  </p>
                </div>
              </div>

              {/* tempi */}
              <div className='h-[1px] bg-[#efefef] w-[100%] mb-[27px] mt-[25px]'></div>

              {/* release test */}

              <div>
                <div className='w-[456px]'>
                  <p className='text-[16px] text-primaryBlack font-medium '>
                    Attach Release(s)
                  </p>
                  <p className='text-[11px] text-primaryGray leading-[13px] mb-[14px] mt-[11px]'>
                    The release form is a legal document signed to
                    protect copyrights. It could be a work of any type
                    of art: Music, Visual/Graphic Arts, Literature,
                    Performing Arts, and Decorative Arts.
                  </p>
                  <p className='text-[11px] font-medium text-[#bbbbbb] mb-[3px]'>
                    Acceptable File: PDF/JPEG, 300dpi, 4 MB Maximum
                  </p>
                </div>

                <div></div>

                {tempRelese.length + Release.length > 0 && (
                  <div
                    ref={releaseTypeClickOutside}
                    className='text-[0.750rem] border-t border-b border-t-[2px] border-b-[1px] border-t-[#efefef] border-b-[#efefef] '
                  >
                    {Release?.map((obj, index) => (
                      <p
                        key={index}
                        className={`flex justify-between h-[57px] relative items-center border-b border-[#efefef]`}
                      >
                        <div className='flex gap-[8px] items-center'>
                          <div
                            style={{
                              backgroundImage: `url(${thumbnail?.thumbnailUrl})`,
                            }}
                            className='w-[40px] h-[40px] bg-center bg-cover rounded-[8px]'
                          ></div>

                          <div className='h-[40px] '>
                            <div>
                              <p className='text-sm14 font-medium text-primaryGray'>
                                {obj?.fileName}
                              </p>
                              <p className='text-[11px] leading-[13px]  text-[#bbbbbb]'>
                                File size: {obj?.fileSize}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='flex gap-[16px] items-center'>
                          <div>
                            <div className='abc w-[205px] absolute top-[8px] right-[96px]'>
                              <style
                                dangerouslySetInnerHTML={{
                                  __html: scrollbarStyle,
                                }}
                              />
                              <div
                                onClick={() => {
                                  setIsOpenReleaseType(
                                    'real' + index
                                  );
                                  setIsOpenReleaseTypeBoolean(
                                    !isOpenReleaseTypeBoolean
                                  );
                                }}
                                className={`${
                                  isOpenReleaseType ===
                                    'real' + index &&
                                  isOpenReleaseTypeBoolean
                                    ? 'dropdownTopButtonOpenedStyle  rounded-tr-[16px] rounded-tl-[16px]'
                                    : 'dropdownTopButtonClosedStyle'
                                } dropdownTopButtonCommonStyle h-[40px]`}
                              >
                                <div className='flex flex-col h-[100%]'>
                                  {obj?.type === 'default' ? (
                                    <>
                                      <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                                        {/* {subjectMenu?.subjectName} */}{' '}
                                        Release
                                      </p>
                                      <p className='text-[#bbbbbb]  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                                        {/* {subjectMenu?.subjectName} */}{' '}
                                        Select Type of Release
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                                        {/* {subjectMenu?.subjectName} */}{' '}
                                        Release
                                      </p>
                                      <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                                        {/* {subjectMenu?.subjectName} */}{' '}
                                        {obj?.type}
                                      </p>
                                    </>
                                  )}
                                </div>

                                <DropArrowSmall
                                  className={`${
                                    isOpenReleaseType ===
                                      'real' + index &&
                                    isOpenReleaseTypeBoolean
                                      ? 'transform rotate-180'
                                      : ''
                                  }`}
                                  style={{
                                    fill: '#878787',
                                  }}
                                />
                              </div>
                              {isOpenReleaseType === 'real' + index &&
                                isOpenReleaseTypeBoolean && (
                                  <div className='relative'>
                                    <div
                                      onMouseEnter={() =>
                                        setAllDropHover(true)
                                      }
                                      onMouseLeave={() =>
                                        setAllDropHover(false)
                                      }
                                      style={{
                                        boxShadow:
                                          'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                                      }}
                                      className={`dropdownTopDivOfULCommonStyle `}
                                    >
                                      <ul
                                        className={`dropdownULCommonStyle rounded-bl-[16px] rounded-br-[16px] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                                      >
                                        <li
                                          onClick={() => {
                                            setReleaseTypeMenu(
                                              obj?.releaseId,
                                              'Art Release'
                                            );
                                            setIsOpenReleaseTypeBoolean(
                                              false
                                            );
                                          }}
                                          className={`dropdownLiCommonStyle text-sm14 border-b hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                                        >
                                          Art Release
                                        </li>
                                        <li
                                          className={`dropdownLiCommonStyle disabled  text-sm14 border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                                        >
                                          Model Release
                                        </li>
                                        <li
                                          className={`dropdownLiCommonStyle disabled  text-sm14 border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                                        >
                                          Minor Release
                                        </li>
                                        <li
                                          className={`dropdownLiCommonStyle disabled  text-sm14  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[39px] pb-[4px]`}
                                        >
                                          Property Release
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className=''>
                            {/* <img src={view} alt='' /> */}

                            <StyledPopupViewRelease
                              trigger={
                                <ViewIcon className='cursor-pointer fill-[#888888]' />
                              }
                              modal
                            >
                              {(close) => (
                                <div className='w-[1600px] h-[793px]'>
                                  <iframe
                                    src={obj?.fileUrl}
                                    width='100%'
                                    height='100%'
                                    title='PDF Viewer'
                                  ></iframe>
                                </div>
                              )}
                            </StyledPopupViewRelease>
                          </div>

                          <div className='opacity-[40%]'>
                            {/* <img src={edit} alt='' /> */}
                            <EditIcon className='fill-[#888888]' />
                          </div>

                          <div
                            className='cursor-pointer'
                            onClick={() => {
                              setSaveReleaseId(obj?.releaseId);
                              setConfirmDeleteReleasePopup(true);
                            }}
                          >
                            {/* onClick={() => {
                              DeleteRelease(obj?.releaseId);
                            }}
                          > */}
                            {/* <img src={deletee} alt='' /> */}
                            <DeleteIcon className='fill-[#888888]' />
                          </div>
                        </div>
                      </p>
                    ))}
                    {tempRelese?.map((obj, index) => (
                      <p
                        key={index}
                        className={`flex justify-between h-[57px] relative items-center border-b border-[#efefef]`}
                      >
                        <div className='flex gap-[8px] items-center'>
                          <div
                            style={{
                              backgroundImage: `url(${thumbnail?.thumbnailUrl})`,
                            }}
                            className='w-[40px] h-[40px] bg-center bg-cover rounded-[8px]'
                          ></div>

                          {/* {releaseUploading ? (
                            <div>
                              <div className='w-[300px] h-[4px] bg-[#eaeaea] rounded-[5000px]'>
                                <div className='w-[75%] h-[4px] gradient-background3 rounded-l-[5000px]'></div>
                              </div>
                            </div>
                          ) : ( */}
                          <div className='h-[40px] '>
                            <div>
                              <p className='text-sm14 font-medium text-primaryGray'>
                                {obj?.fileName}
                              </p>
                              <p className='text-[11px] leading-[13px]  text-[#bbbbbb]'>
                                File size: {obj?.fileSize}
                              </p>
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                        <div className='flex gap-[16px] items-center'>
                          <div>
                            <div className='abc w-[205px]  absolute top-[8px] right-[96px]'>
                              <style
                                dangerouslySetInnerHTML={{
                                  __html: scrollbarStyle,
                                }}
                              />
                              <div
                                onClick={() => {
                                  setIsOpenReleaseType(
                                    'temp' + index
                                  );
                                  setIsOpenReleaseTypeBoolean(
                                    !isOpenReleaseTypeBoolean
                                  );
                                }}
                                className={`${
                                  isOpenReleaseType ===
                                    'temp' + index &&
                                  isOpenReleaseTypeBoolean
                                    ? 'dropdownTopButtonOpenedStyle rounded-tr-[16px] rounded-tl-[16px]'
                                    : 'dropdownTopButtonClosedStyle'
                                } dropdownTopButtonCommonStyle h-[40px]`}
                              >
                                {/* {obj?.type !== '' ||
                                obj?.type !== null ||
                                obj?.type !== undefined ? (
                                  <p className='text-primaryGray  font-medium flex text-[12px] leading-[1]'>
                                    {obj?.type}
                                  </p>
                                ) : (
                                  <p className='text-primaryGray  font-medium flex text-[12px] leading-[1]'>
                                    Select type of release
                                  </p>
                                )} */}

                                <div className='flex flex-col h-[100%]'>
                                  {obj?.type === 'default' ? (
                                    <>
                                      <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                                        {/* {subjectMenu?.subjectName} */}{' '}
                                        Release
                                      </p>
                                      <p className='text-[#bbbbbb]  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                                        {/* {subjectMenu?.subjectName} */}{' '}
                                        Select Type of Release
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                                        {/* {subjectMenu?.subjectName} */}{' '}
                                        Release
                                      </p>
                                      <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                                        {/* {subjectMenu?.subjectName} */}{' '}
                                        {obj?.type}
                                      </p>
                                    </>
                                  )}
                                </div>

                                <DropArrowSmall
                                  className={`${
                                    isOpenReleaseType ===
                                      'temp' + index &&
                                    isOpenReleaseTypeBoolean
                                      ? 'transform rotate-180'
                                      : ''
                                  }`}
                                  style={{
                                    fill: '#878787',
                                  }}
                                />
                              </div>
                              {isOpenReleaseType === 'temp' + index &&
                                isOpenReleaseTypeBoolean && (
                                  <div className='relative'>
                                    <div
                                      onMouseEnter={() =>
                                        setAllDropHover(true)
                                      }
                                      onMouseLeave={() =>
                                        setAllDropHover(false)
                                      }
                                      style={{
                                        boxShadow:
                                          'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                                      }}
                                      className={`dropdownTopDivOfULCommonStyle `}
                                    >
                                      <ul
                                        className={`dropdownULCommonStyle rounded-bl-[16px] rounded-br-[16px] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                                      >
                                        {/* {subjectMenuList?.map(
                                (subject, index) => ( */}

                                        <li
                                          // key={index}
                                          onClick={() => {
                                            // handleSubjectMenuClick(subject);

                                            setReleaseTypeMenuTemp(
                                              index,
                                              'Art Release'
                                            );
                                            setIsOpenReleaseTypeBoolean(
                                              false
                                            );
                                          }}
                                          className={`dropdownLiCommonStyle  text-sm14 border-b hover:bg-[#f0f0f0] hover:text-[#333333] border-[#efefef]  h-[35px]`}

                                          // ${
                                          //   index ===
                                          //   subjectMenuList.length - 1
                                          //     ? 'pb-[4px] h-[33px]'
                                          //     : 'border-b'
                                          // }
                                        >
                                          {/* {subject.subjectName} */}
                                          Art Release
                                        </li>
                                        <li
                                          // key={index}
                                          // onClick={() => {
                                          //   setReleaseTypeMenuTemp(
                                          //     index,
                                          //     'Model Release'
                                          //   );
                                          //   setIsOpenReleaseTypeBoolean(
                                          //     false
                                          //   );
                                          // }}
                                          className={`dropdownLiCommonStyle disabled  text-sm14 border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef]  h-[35px]`}

                                          // ${
                                          //   index ===
                                          //   subjectMenuList.length - 1
                                          //     ? 'pb-[4px] h-[33px]'
                                          //     : 'border-b'
                                          // }
                                        >
                                          {/* {subject.subjectName} */}
                                          Model Release
                                        </li>
                                        <li
                                          // key={index}
                                          // onClick={() => {
                                          //   setReleaseTypeMenuTemp(
                                          //     index,
                                          //     'Minor Release'
                                          //   );
                                          //   setIsOpenReleaseTypeBoolean(
                                          //     false
                                          //   );
                                          // }}
                                          className={`dropdownLiCommonStyle disabled  text-sm14 border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef]  h-[35px]`}

                                          // ${
                                          //   index ===
                                          //   subjectMenuList.length - 1
                                          //     ? 'pb-[4px] h-[33px]'
                                          //     : 'border-b'
                                          // }
                                        >
                                          {/* {subject.subjectName} */}
                                          Minor Release
                                        </li>
                                        <li
                                          // key={index}
                                          // onClick={() => {
                                          //   setReleaseTypeMenuTemp(
                                          //     index,
                                          //     'Property Release'
                                          //   );
                                          //   setIsOpenReleaseTypeBoolean(
                                          //     false
                                          //   );
                                          // }}
                                          className={`dropdownLiCommonStyle disabled  text-sm14  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[39px] pb-[4px]`}

                                          // ${
                                          //   index ===
                                          //   subjectMenuList.length - 1
                                          //     ? 'pb-[4px] h-[33px]'
                                          //     : 'border-b'
                                          // }
                                        >
                                          {/* {subject.subjectName} */}
                                          Property Release
                                        </li>

                                        {/* )
                              )} */}
                                      </ul>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className=''>
                            {/* <img src={view} alt='' /> */}
                            <StyledPopupViewRelease
                              trigger={
                                <ViewIcon className='cursor-pointer  fill-[#888888]' />
                              }
                              modal
                            >
                              {(close) => <div></div>}
                            </StyledPopupViewRelease>
                          </div>
                          <div className='opacity-[40%]'>
                            {/* <img src={edit} alt='' /> */}
                            <EditIcon className=' fill-[#888888]' />
                          </div>
                          <div
                            className='cursor-pointer'
                            onClick={() => {
                              setSaveTempReleaseId(index);
                              setConfirmDeleteTempReleasePopup(true);
                              // DeleteReleaseTemp(index);
                            }}
                          >
                            {/* <img src={deletee} alt='' /> */}
                            <DeleteIcon className='fill-[#888888]' />
                          </div>
                        </div>
                      </p>
                    ))}

                    {releaseUploading && (
                      <p
                        // key={index}
                        className={`flex justify-between  h-[57px] items-center relative border-b border-[#efefef]`}
                      >
                        <div className='flex gap-[8px] items-center'>
                          <div
                            style={{
                              backgroundImage: `url(${thumbnail?.thumbnailUrl})`,
                            }}
                            className='w-[40px] h-[40px] bg-center bg-cover rounded-[8px]'
                          ></div>

                          <div className='w-[300px]'>
                            x`
                            <div>
                              <div className='flex justify-between'>
                                <p className='text-sm14 font-medium text-primaryGray'>
                                  {tempReleaseName}
                                </p>
                                <div>
                                  <Cross8 className='cursor-pointer' />
                                </div>
                              </div>
                              <div className='mt-[3px]'>
                                <div className='w-full h-[4px] bg-[#eaeaea] rounded-[5000px]'>
                                  <div
                                    style={{
                                      width: `${uploadProgress}%`,
                                    }}
                                    className={` h-[4px] gradient-background3 rounded-l-[5000px]`}
                                  ></div>
                                </div>
                              </div>
                              <div className='flex justify-between w-[100%] mt-[3px]'>
                                <p className='text-[11px] leading-[13px]  text-[#bbbbbb]'>
                                  Uploading {formatSize(uploadedSize)}{' '}
                                  of {formatSize(totalSize)}
                                </p>
                                <p className='text-[11px] leading-[13px]  text-[#bbbbbb]'>
                                  {uploadProgress}%
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='flex gap-[16px] items-center'>
                          <div>
                            <div className='abc w-[205px]  absolute top-[8px] right-[96px]'>
                              <style
                                dangerouslySetInnerHTML={{
                                  __html: scrollbarStyle,
                                }}
                              />
                              <div
                                onClick={() => {
                                  setIsOpenReleaseTypeBoolean(
                                    !isOpenReleaseTypeBoolean
                                  );
                                }}
                                className={`${
                                  isOpenReleaseTypeBoolean
                                    ? 'dropdownTopButtonOpenedStyle  rounded-tr-[16px] rounded-tl-[16px]'
                                    : 'dropdownTopButtonClosedStyle'
                                } dropdownTopButtonCommonStyle h-[40px]`}
                              >
                                <div className='flex flex-col h-[100%]'>
                                  <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                                    {/* {subjectMenu?.subjectName} */}{' '}
                                    Release
                                  </p>
                                  <p className='text-[#bbbbbb]  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                                    {/* {subjectMenu?.subjectName} */}{' '}
                                    Select Type of Release
                                  </p>
                                </div>

                                <DropArrowSmall
                                  className={`${
                                    isOpenReleaseTypeBoolean
                                      ? 'transform rotate-180'
                                      : ''
                                  }`}
                                  style={{
                                    fill: '#878787',
                                  }}
                                />
                              </div>
                              {isOpenReleaseTypeBoolean && (
                                <div className='relative'>
                                  <div
                                    onMouseEnter={() =>
                                      setAllDropHover(true)
                                    }
                                    onMouseLeave={() =>
                                      setAllDropHover(false)
                                    }
                                    style={{
                                      boxShadow:
                                        'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                                    }}
                                    className={`dropdownTopDivOfULCommonStyle `}
                                  >
                                    <ul
                                      className={`dropdownULCommonStyle rounded-bl-[16px] rounded-br-[16px] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                                    >
                                      <li
                                        onClick={() => {
                                          setIsOpenReleaseTypeBoolean(
                                            false
                                          );
                                        }}
                                        className={`dropdownLiCommonStyle  text-sm14 border-b hover:bg-[#f0f0f0] hover:text-[#333333] border-[#efefef]  h-[35px]`}
                                      >
                                        Art Release
                                      </li>
                                      <li
                                        onClick={() => {
                                          setIsOpenReleaseTypeBoolean(
                                            false
                                          );
                                        }}
                                        className={`dropdownLiCommonStyle  text-sm14 border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef]  h-[35px]`}
                                      >
                                        Model Release
                                      </li>
                                      <li
                                        onClick={() => {
                                          setIsOpenReleaseTypeBoolean(
                                            false
                                          );
                                        }}
                                        className={`dropdownLiCommonStyle  text-sm14 border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef]  h-[35px]`}
                                      >
                                        Minor Release
                                      </li>
                                      <li
                                        onClick={() => {
                                          setIsOpenReleaseTypeBoolean(
                                            false
                                          );
                                        }}
                                        className={`dropdownLiCommonStyle  text-sm14  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[39px] pb-[4px]`}
                                      >
                                        Property Release
                                      </li>

                                      {/* )
                              )} */}
                                    </ul>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className=''>
                            {/* <img src={view} alt='' /> */}
                            <StyledPopupViewRelease
                              trigger={
                                <ViewIcon className='cursor-pointer  fill-[#888888]' />
                              }
                              modal
                            >
                              {(close) => <div></div>}
                            </StyledPopupViewRelease>
                          </div>
                          <div className='opacity-[40%]'>
                            {/* <img src={edit} alt='' /> */}
                            <EditIcon className=' fill-[#888888]' />
                          </div>
                          <div
                            className='cursor-pointer'
                            // onClick={() => {
                            //   DeleteReleaseTemp(index);
                            // }}
                          >
                            {/* <img src={deletee} alt='' /> */}
                            <DeleteIcon className='fill-[#888888]' />
                          </div>
                        </div>
                      </p>
                    )}
                  </div>
                )}

                <div
                  className={`flex gap-[8px] ${
                    tempRelese.length + Release.length > 0
                      ? 'mt-[16px]'
                      : ''
                  } `}
                >
                  <div>
                    <input
                      type='file'
                      multiple
                      onChange={handleFileChangeRelease}
                      // accept='image/*'
                      accept='.jpeg, .jpg, .pdf'
                      style={{ display: 'none' }}
                      ref={fileInputRefRelease}
                    />
                    <button
                      onClick={handleButtonClickRelease}
                      className='gray30HButton'
                    >
                      Attach Release
                    </button>
                  </div>
                </div>
                <div className='mt-[16px] cursor-pointer'>
                  <ReleaseForm />
                  {/* <StyledPopupPackage
                    trigger={<DownloadRelease />}
                    modal
                  >
                    {(close) => <div></div>}
                  </StyledPopupPackage> */}
                </div>

                <p className='text-[#bbbbbb] text-sm11 mt-[6px]'>
                  Available in high resolution in PDF format
                </p>
              </div>
              {/* release test */}

              <div className='h-[1px] bg-[#efefef] w-[100%] mb-[27px] mt-[25px]'></div>

              <div className='flex flex-col w-[456px]'>
                <p className='text-[16px] text-primaryBlack font-medium'>
                  Select Commercial Usage
                </p>
                <p className='text-sm11 text-primaryGray mt-[11px]'>
                  Used to Commercialize, Monetize, Sell, Promote,
                  Advertise a Product, and Business or <br />
                  Service. It could be used on Web Media, Print Media,
                  and Merchandise Products.
                </p>
                <div className='flex flex-col gap-[4px] pt-[14px]'>
                  <label
                    style={{
                      width: 'fit-content',
                    }}
                    className='containerCheckGray text-sm14 font-medium text-primaryGray '
                  >
                    <input
                      type='checkbox'
                      name='commercialUser'
                      value='Web Media'
                      onChange={handleInputChange}
                      checked={commercialUsageCheckoxesTick(
                        'Web Media'
                      )}
                    />{' '}
                    {/* <span className='relative top-[1px]'> */}
                    Web Media
                    {/* </span> */}
                    <span className='checkmarkGray'></span>
                  </label>

                  <label
                    style={{
                      width: 'fit-content',
                    }}
                    className='containerCheckGray  text-sm14 font-medium  text-primaryGray '
                  >
                    <input
                      type='checkbox'
                      name='commercialUser'
                      value='Print Media'
                      onChange={handleInputChange}
                      checked={commercialUsageCheckoxesTick(
                        'Print Media'
                      )}
                    />{' '}
                    Print Media
                    <span className='checkmarkGray'></span>
                  </label>

                  <label
                    style={{
                      width: 'fit-content',
                    }}
                    className='containerCheckGray  text-sm14 font-medium  text-primaryGray '
                  >
                    <input
                      type='checkbox'
                      name='commercialUser'
                      value='Print on Products'
                      onChange={handleInputChange}
                      checked={commercialUsageCheckoxesTick(
                        'Print on Products'
                      )}
                    />{' '}
                    Print on Products
                    <span className='checkmarkGray'></span>
                  </label>

                  <label
                    style={{
                      width: 'fit-content',
                    }}
                    className='containerCheckGray  text-sm14 font-medium  text-primaryGray '
                  >
                    <input
                      type='checkbox'
                      name='commercialUser'
                      value='Sell the rights'
                      onChange={handleInputChange}
                      checked={commercialUsageCheckoxesTick(
                        'Sell the rights'
                      )}
                    />{' '}
                    Sell the rights
                    <span className='checkmarkGray'></span>
                  </label>
                </div>
              </div>

              <div className='h-[1px] bg-[#efefef] w-[100%] mb-[27px] mt-[25px]'></div>

              <div className=' w-[456px]'>
                <div className=''>
                  <p className='text-[16px] text-primaryBlack font-medium'>
                    Art Print Markup
                  </p>
                  <p className='text-sm11 text-primaryGray mt-[11px]'>
                    Artnstock amount gets added to your markup to
                    arrive at the final sell price for print on paper
                    or canvas. Note that Mark-up is 10% fixed for all
                    prints on products.
                  </p>
                </div>

                <div className='mt-[16px]'>
                  <table className='border-separate border-spacing-0 '>
                    <tbody>
                      <tr className='text-[14px] font-medium bg-[#ececec] '>
                        <td className='pl-[8px] w-[114px] text-primaryBlack h-[38px] border border-[#d6d6d6] rounded-tl-[16px]'>
                          Size
                        </td>
                        <td className='pl-[8px] w-[114px] text-primaryBlack h-[38px] border-t border-r border-b border-[#d6d6d6]'>
                          Markup
                        </td>
                        <td className='pl-[8px] w-[114px] text-primaryBlack h-[38px] border-t border-r border-b border-[#d6d6d6]'>
                          Base Price
                        </td>
                        <td className='pl-[8px] w-[114px] text-primaryBlack h-[38px] border-t border-r border-b border-[#d6d6d6] rounded-tr-[16px]'>
                          Selling Price
                        </td>
                      </tr>

                      <tr className='text-[14px] font-medium'>
                        <td className='pl-[8px] text-primaryBlack text-[13px] h-[38px] border border-[#d6d6d6] bg-[#f7f7f7] rounded-bl-[16px]'>
                          Large
                        </td>
                        <td className='pl-[8px] border-t border-r border-b border-[#d6d6d6]'>
                          <input
                            style={{
                              /* Hide the inner spin buttons */
                              '::-webkit-inner-spin-button': {
                                display: 'none',
                              },
                              '::-webkit-outer-spin-button': {
                                display: 'none',
                              },
                              '-moz-appearance': 'textfield',
                              appearance: 'none',
                              MozAppearance: 'textfield!important',
                              WebkitAppearance: 'none!important',
                              appearance: 'none!important',
                            }}
                            type='number'
                            name='price'
                            className='w-[90px] h-[28px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                            onChange={handleInputChangePrice}
                            value={formData?.price}
                            // placeholder='Enter Keywords'
                          />
                        </td>
                        <td className='pl-[8px]  text-[13px] h-[38px] text-primaryGray border-t border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                          <span className='opacity-[40%]'>
                            {basePrice}
                          </span>
                        </td>
                        <td className='pl-[8px]  text-[13px] h-[38px] border-t text-primaryGray border-r border-b border-[#d6d6d6] font-normal rounded-br-[16px] '>
                          {sellPrice}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className='h-[1px] bg-[#efefef] w-[100%] mb-[27px] mt-[25px]'></div>

              <div>
                <div className='w-[456px]'>
                  <p className='text-[16px] text-primaryBlack font-medium'>
                    Add Keywords
                  </p>
                  <p className='text-sm11 text-primaryGray mt-[11px]'>
                    Keywords helps you understand what users are
                    searching for and the content you need to provide
                    to meet their needs.
                  </p>

                  <div className='relative mt-[16px]'>
                    <input
                      type='text'
                      className='w-[100%] h-[44px] rounded-[5000px] text-primaryGray text-[14px] pt-[9px] border border-[#d6d6d6] pl-[20px] outline-none placeholder:font-medium  placeholder:text-[14px] placeholder:text-[#bbbbbb]'
                      placeholder='Enter Keywords'
                      onChange={keywordText}
                      value={textWord}
                    />
                    <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
                      Add minimum 7 and maximum 50 keywords
                    </p>

                    {!isOnlySpaces() ? (
                      <button
                        onClick={addKeyword}
                        className='inputBoxButton'
                      >
                        Add
                      </button>
                    ) : (
                      <button
                        // onClick={addKeyword}
                        className='inputBoxButton'
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
                <div className=''>
                  {/* <div className='flex justify-between w-[220px]'>
                    <p className='text-[11px] text-primaryGray'>
                      Add at least 7 keywords
                    </p>
                    <p className='text-[11px] text-primaryGray'>
                      0/50 characters
                    </p>
                  </div> */}
                  <div className='pt-[31px]'>
                    <p className='text-sm11 text-[#bbbbbb] font-medium'>
                      {formData?.keywords?.length}/50 Keywords Added
                    </p>

                    {formData?.keywords?.length > 0 && (
                      <div className='flex flex-wrap gap-[4px] mt-[4px]'>
                        {formData?.keywords?.map((keyword) => (
                          <div className='uploadKeywords'>
                            {keyword} &nbsp;&nbsp;
                            {/* <span
                            onClick={() => {
                              deleteKeyword(keyword);
                            }}
                            className='text-[15px] text-primaryGray cursor-pointer'
                          >
                            x
                          </span> */}
                            <div>
                              <Cross8
                                className=' cursor-pointer'
                                onClick={() => {
                                  deleteKeyword(keyword);
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  {/* <div className='flex gap-[15px] pt-[10px]'>
                    <p className='text-primaryGray text-[12px]'>
                      Copy Keywords
                    </p>
                    <p className='text-primaryGray text-[12px]'>
                      Paste Keywords
                    </p>
                    <p className='text-orangeColor text-[12px]'>
                      Clear Keywords
                    </p>
                  </div> */}

                  {formData.keywords?.length > 0 ? (
                    <div className='mt-[16px]'>
                      <button
                        onClick={clearKeywords}
                        className='gray30HButton'
                      >
                        Clear All Keywords
                      </button>
                    </div>
                  ) : (
                    <div className='mt-[7px]'>
                      <button className='gray30HButton cursor-default opacity-[40%]'>
                        Clear All Keywords
                      </button>
                    </div>
                  )}

                  <div className='pt-[29px]'>
                    <p className='text-sm11 text-[#bbbbbb] font-medium'>
                      Keyword Suggesions
                    </p>

                    {keywordSugg?.length > 0 && (
                      <div className='flex flex-wrap gap-[4px] mt-[8px]'>
                        {keywordSugg?.map((keyword) => (
                          <div
                            onClick={() => {
                              AddSuggestedKeywordsToKeywords(keyword);
                            }}
                            className='uploadKeywords cursor-pointer hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-[#333333]'
                          >
                            {keyword} &nbsp;&nbsp;
                            <div>
                              <IconAdd className='fill-[#888888] hover:fill-[#333333]' />
                            </div>
                            {/* <span
                            onClick={() => {
                              deleteKeyword(keyword);
                            }}
                            className='text-[15px] text-primaryGray cursor-pointer'
                          >
                            x
                          </span> */}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  {/* <div className='mt-[16px] flex gap-[8px]'>
                    <button
                      onClick={AddAllSuggKeywordsToKeywords}
                      className='gray30HButton'
                    >
                      Add Keywords
                    </button>
                    <button
                      onClick={SetKeywordManagerTrue}
                      className='gray30HButton'
                    >
                      Keyword Manager
                    </button>
                  </div> */}
                </div>
              </div>

              <div className='h-[2px] bg-[#efefef] w-[100%] my-[32px]'></div>

              <div className='flex w-[100%] justify-center text-center gap-[8px] mt-[32px]'>
                {/* <button
              onClick={addToActivateProd}
              className='bg-primaryBlack text-[white] h-[40px] w-[88px] rounded-[30px] text-[14px]'
            >
              Add Details
            </button> */}

                <button
                  onClick={addToActivateProd}
                  className='black40HButton'
                >
                  Continue
                </button>

                {/* <button className='outlineBtn40h'>Cancel</button> */}
                {/* <button className='h-[40px] px-6 py-2 rounded-3xl text-sm14 text-primaryBlack border-[2px]'>
              Cancel
            </button> */}
              </div>
            </div>
          ) : categorySelected === 'photos' ? (
            <></>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className='text-center pt-[8px]'>
          <p className='text-pinkColor text-[16px]'>
            Upload your art files
          </p>
          <p className='text-primaryGray text-[12px]'>
            Get started by uploading your art files to submit
          </p>
          <button
            onClick={() => {
              dispatch(setNestedTabValueUpload('2.1'));
            }}
            className='blackBtn mt-[20px]'
          >
            Upload Files
          </button>
        </div>
      )}
      <KeywordPopup imageUrl={selectedImages[0]?.thumbnailUrl} />

      {/* <StyledPopupAll
        open={normalPopupOpen}
        closeOnDocumentClick={true}
        position={'center'}
        onClose={() => {
          setNormalPopupOpen(false);
         
        }}
        className={`${
          normalPopupOpen ? 'animate-fadeIn' : 'animate-fadeOut'
        }`}
      >
        <p className='text-[12px] text-primaryGray text-center'>
          Successfully Art Details saved to Draft.
        </p>
      </StyledPopupAll> */}

      {/* <div class='m-[auto] bg-[white] absolute text-primaryGray rounded-3xl p-[32px] text-center  w-[450px]'>
        abc
      </div> */}
    </>
  );
};

export default AddDetails;
