import ContriBanner from '../contri-banner/Contri-Banner';

import iconart from './../../../assets/images/contributor/techspec/icon_art.png';
import iconphotos from './../../../assets/images/contributor/techspec/icon-photos.png';
import iconfootage from './../../../assets/images/contributor/techspec/icon-footage.png';
import icontemplates from './../../../assets/images/contributor/techspec/icon-template.png';
import iconmusic from './../../../assets/images/contributor/techspec/icon-music.png';

import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Footer from '../../footer/Footer';
import { useSelector } from 'react-redux';

const UploadFile2 = () => {
  //   const gotoTabs = () => {
  //     // console.log(check);
  //     check && navigate('/tabs');
  //   };

  const draftCount = useSelector(
    (state) => state.contriCounter.toSubmitFilesCount
  );
  const forReviewCount = useSelector(
    (state) => state.contriCounter.forReviewFilesCount
  );
  const approvedCount = useSelector(
    (state) => state.contriCounter.approvedFilesCount
  );
  const RejectedCount = useSelector(
    (state) => state.contriCounter.rejectedFilesCount
  );

  return (
    <>
      {/*Section 1 banner */}
      {/* <ContriBanner />
     
      <div>
        <p className='w-[100%] text-center text-[12px] text-[#757575] py-[40px]'>
          Artnstock / Sell on Artnstock / Technical Specifications
        </p>
      </div> */}
      {/*Section 3 */}

      <div className='flex justify-center mb-[16px]'>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex border-t border-b border-t-[#efefef] border-b-[#efefef]'
        >
          <div className='flex items-center gap-[5px]'>
            <span
              className='contributorUploadCount text-[#f26522]
          '
            >
              {/* 13 */}
              {draftCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='contributorUploadtext pr-[8px]'>
                File(s) to be
                <br />
                submitted to
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span
              className='contributorUploadCount text-[#ffc20E]
           opacity-[20%] pl-[5px]'
            >
              {forReviewCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                pending for
                <br />
                review
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span
              className=' contributorUploadCount text-[#8dc73f]
           opacity-[20%]  pl-[5px]'
            >
              {approvedCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                approved by
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-[#00aeef] opacity-[20%]  pl-[5px]'>
              {RejectedCount}
            </span>
            <div className='opacity-[40%] h-[40px] flex items-center'>
              <p className='contributorUploadtext'>
                File(s)
                <br />
                rejected by
                <br />
                Artnstock
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=' w-[100%] mx-[auto] max-w-[688px] text-[14px]'>
        <p className='text-[32px] font-medium text-center my-[32px]'>
          Technical Specifications
        </p>
        {/*Section 3.1*/}
        <div className='pb-[25px]'>
          <p>
            All of the files you upload will be checked by our
            inspectors, and once they pass inspection, they'll appear
            on the website. Starting from that moment, they are
            available to Buyers, and can start turning a profit!
          </p>
          <p className='pt-[25px] text-primaryGray'>
            By accepting the{' '}
            <span className='text-orangeColor'>
              Terms of Service, License Agreement, Privacy Policy
            </span>{' '}
            and{' '}
            <span className='text-orangeColor'>
              Copyright and Trademark Policy
            </span>{' '}
            of Artnstock that you sign while creating a Artnstock's
            account, and uploading your files to our website, you
            warrant the following:
          </p>
          <ul className='text-primaryGray'>
            <li>• You are the author of all the files you upload.</li>
            <li>• You are 18 or older.</li>
            <li>
              • You possess all the required documents and rights
              allowing you to display and use your files as well as
              their related elements (model releases, property
              releases, minor releases).
            </li>
            <li>
              • All of the files you upload will be checked by our
              inspectors, and once they pass inspection, they'll
              appear on the website. Starting from that moment, they
              are available to buyers, and can start turning a profit.
            </li>
          </ul>
        </div>

        <div className='w-[100%] bg-[#efefef]  h-[2px]' />
        {/*Section 3.2*/}
        <div className='py-[25px]'>
          <p className='text-primaryBlack text24'>
            Files Upload Limit
          </p>
          <p className='text-primaryGray pt-[25px]'>
            Our review team handles hundreds of thousands of file
            submissions every week. To ensure a timely and consistent
            turnaround for our global community of contributors, we
            limit the rate of weekly submissions.
          </p>
          <p className='text-primaryGray pt-[25px]'>
            Artnstock contributors can submit up to{' '}
            <span className='text-primaryBlack font-medium'>
              250 Art, 250 Photos, 90 Video Clips, 90 Music Tracks and
              250 Templates during any 7 day period.
            </span>{' '}
            The 7-day timeframe is a rolling window. If you exceed the
            submission limit, you will receive a pop-up message on the
            dashboard of your account page, that will remain until the
            limit window expires.
          </p>
          <div className='flex gap-[15px] pt-[20px]'>
            <div>
              <img src={iconart} alt='' />
              <p className='text-[11px] text-center  text-primaryGray mt-[2px]'>
                Art
              </p>
              <p className='text-[24px] font-light leading-[24px] text-center  text-primaryGray'>
                250
              </p>
            </div>
            <div>
              <img src={iconphotos} alt='' />
              <p className='text-[11px] text-center   text-[#bbbbbb] mt-[2px]'>
                Photos
              </p>
              <p className='text-[24px] font-light text-center leading-[24px] text-primaryGray'>
                250
              </p>
            </div>
            <div>
              <img src={iconfootage} alt='' />
              <p className='text-[11px] text-center   text-[#bbbbbb] mt-[2px]'>
                Footage
              </p>
              <p className='text-[24px]  font-light text-center leading-[24px] text-primaryGray'>
                90
              </p>
            </div>
            <div>
              <img src={icontemplates} alt='' />
              <p className='text-[11px] text-center   text-[#bbbbbb] mt-[2px]'>
                Templates
              </p>
              <p className='text-[24px]  font-light text-center leading-[24px] text-primaryGray'>
                90
              </p>
            </div>
            <div>
              <img src={iconmusic} alt='' />
              <p className='text-[11px] text-center  text-[#bbbbbb] mt-[2px]'>
                Music
              </p>
              <p className='text-[24px]  font-light text-center leading-[24px] text-primaryGray'>
                250
              </p>
            </div>
          </div>
          <p className='text-[12px] text-primaryGray mt-[8px]'>
            Weekly submissions with
            <span className='text-primaryBlack font-medium'>
              &nbsp;Approval rate more than 80%
            </span>
          </p>
          {/* <div> */}
          <p className='text-primaryGray text-sm11 pt-[20px]'>
            <span className='text-pinkColor text-[14px]'>
              Note that:
            </span>
            <br />
            Contributors with more than 500 files online and an
            approval ratio higher than 80% <br />
            are eligible to submit 2-times more than the current
            upload limit.
            <br /> Feature available only based on{' '}
            <span className='text-orangeColor'>
              application request
            </span>
            .
          </p>
          {/* </div> */}

          <table className='table mt-[20px] border-separate border-spacing-0'>
            <caption className='border text-[15px] h-[32px]  rounded-tr-[16px] leading-[1] rounded-tl-[16px] bg-[#ececec] border-[#dddddd]'>
              <div className='h-[100%] flex items-center justify-center'>
                {' '}
                Files Upload Limit Per Week
              </div>
            </caption>
            <thead>
              <tr className='text-[13px]'>
                <th className='border pr-[40px] h-[32px] pl-[6px] font-normal bg-[#ececec] border-[#dddddd]'>
                  Approval Percentage
                </th>
                <th className='border-t border-r border-b px-[37px] font-normal bg-[#ececec] border-[#dddddd]'>
                  Art
                </th>
                <th className='border-t border-r border-b px-[26px] font-normal bg-[#ececec] border-[#dddddd]'>
                  Photos
                </th>
                <th className='border-t border-r border-b px-[26px] font-normal bg-[#ececec] border-[#dddddd]'>
                  Footage
                </th>
                <th className='border-t border-r border-b px-[30px] font-normal bg-[#ececec] border-[#dddddd]'>
                  Music
                </th>
                <th className='border-t border-r border-b px-[21px] font-normal bg-[#ececec] border-[#dddddd]'>
                  Templates
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='border-r border-b border-l text-[13px]  h-[32px] pl-[6px] bg-[#faeef5]  border-[#d7d5d7]'>
                  Higher than 80%
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#fef2f9] border-[#dddddd]'>
                  250
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#fef2f9] border-[#dddddd]'>
                  250
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#fef2f9] border-[#dddddd]'>
                  90
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#fef2f9] border-[#dddddd]'>
                  90
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#fef2f9] border-[#dddddd]'>
                  250
                </td>
              </tr>
              <tr>
                <td className='border-r border-b border-l text-[13px]  h-[32px] pl-[6px] bg-[#fbfbfb] border-[#dddddd]'>
                  Between 60% - 80%
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
                  200
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
                  200
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
                  70
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
                  70
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
                  200
                </td>
              </tr>
              <tr>
                <td className='border-r border-b border-l text-[13px]  h-[32px] pl-[6px] bg-[#fbfbfb] border-[#dddddd]'>
                  Between 40% - 60%
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
                  150
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
                  150
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
                  50
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
                  50
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
                  150
                </td>
              </tr>
              <tr>
                <td className='border-r border-b border-l text-[13px]  h-[32px] pl-[6px] bg-[#fbfbfb] border-[#dddddd]'>
                  Between 20% - 40%
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
                  100
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
                  100
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
                  30
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
                  30
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
                  100
                </td>
              </tr>
              <tr>
                <td className='border-r border-b border-l text-[13px] h-[32px] pl-[6px] bg-[#fbfbfb] border-[#dddddd] rounded-bl-[16px]'>
                  Between 0% - 20%
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
                  50
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
                  50
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
                  10
                </td>
                <td className='border-r border-b text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
                  10
                </td>
                <td className='border-r border-b text-center text-primaryGray rounded-br-[16px] bg-[#ffffff] border-[#dddddd]'>
                  50
                </td>
              </tr>
            </tbody>
          </table>

          {/* <hr className='border-[lightGray]' /> */}
        </div>
        {/* <hr className='border-[#efefef] h-[2px] mt-[5px]' /> */}
        <div className='w-[100%] bg-[#efefef]  h-[2px] mt-[5px]' />

        <div className='py-[25px]'>
          <p className='text-primaryBlack text24'>
            Technical File Requirements
          </p>
          <p className='text-primaryBlack font-medium text-[16px] pt-[25px] pb-[20px]'>
            Art
          </p>

          <p className='text-primaryGray'>
            To make sure your designs are printing well on products,
            you’ve got to keep Dimensions and Format in mind. When
            uploading your files, please make sure that your files are
            saved in RGB and not CMYK. Only JPEG (raster image), PNG
            (raster image) and EPS (vector image) files will be
            accepted.
          </p>
          <p className='py-[25px] text-primaryGray'>
            Most products require different minimum dimensions to
            ensure a high print quality. If your goal is to enable all
            products Artnstock has to offer including large format
            products like curtains, bedding, outdoor items, wall
            murals, and furniture, we recommend starting with a
            horizontal asset that’s 14040 x 9930 Pixels, a vertical
            asset that’s 9930 x 14040 Pixels, and a square asset
            that’s 14040 x 14040 Pixels (any one of these dimensions)
            at 300 dpi. For certain cut-and-sew products like
            backpacks, duffle bags, and all over graphic tees, we
            recommend referencing our{' '}
            <span className='text-orangeColor'>print templates</span>{' '}
            with required pixels and dimensions to ensure your artwork
            is correctly placed.
          </p>
          <table className='table border-separate border-spacing-0'>
            <caption className='border text-[15px] h-[32px] rounded-tr-[16px] rounded-tl-[16px] border-[#dddddd] bg-[#ececec]'>
              <div className='h-[100%] flex items-center justify-center'>
                {' '}
                Art Image Specifications
              </div>
            </caption>
            <thead>
              <tr className='text-[13px]'>
                <th className='border pr-[45px] h-[32px] pl-[6px] font-normal border-[#dddddd] bg-[#ececec]'>
                  Image Dimensions
                </th>
                <th className='border-t border-r border-b px-[36px] font-normal border-[#dddddd] bg-[#ececec]'>
                  Inches
                </th>
                <th className='border-t border-r border-b px-[36px] font-normal border-[#dddddd] bg-[#ececec]'>
                  Centimeters
                </th>
                <th className='border-t border-r border-b px-[36px] font-normal border-[#dddddd] bg-[#ececec]'>
                  Milimeters
                </th>
                <th className='border-t border-r border-b px-[36px] font-normal border-[#dddddd] bg-[#ececec]'>
                  Pixels
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  rowspan='2'
                  className='border-r border-b border-l text-[13px]  pl-[6px] border-[#dddddd] bg-[#faf1fc]'
                >
                  Horizontal Image Size
                </td>
                <td className='border-r border-b text-center  h-[32px] text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                  33 x 23.38
                </td>
                <td className='border-r border-b text-center text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                  80.82 x 59.40
                </td>
                <td className='border-r border-b text-center text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                  838.20 x 594.02
                </td>
                <td className='border-r border-b text-center text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                  9900 x 7016
                </td>
              </tr>
              <tr className=' h-[32px]'>
                <td
                  colspan='4'
                  className='border-r border-b text-[11px] leading-[1] h-[32px] text-primaryGray border-[#dddddd] bg-[#fef5fb] pl-[6px]'
                >
                  Width: Fixed 33 in / 83.82 cm / 838.20 mm / 9900 px
                  <br />
                  Height: Between 11.69 - 23.38 in / 29.70 - 50.40 cm
                  / 297.01 - 594.02 mm / 3508 - 7016 px
                </td>
              </tr>
              <tr>
                <td
                  rowspan='2'
                  className='border-r border-b border-l text-[13px]  pl-[6px] border-[#dddddd] bg-[#faf1fc]'
                >
                  Vertical Image Size
                </td>
                <td className='border-r border-b text-center h-[32px] text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                  23.38 x 33
                </td>
                <td className='border-r border-b text-center text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                  59.40 x 83.82
                </td>
                <td className='border-r border-b text-center text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                  594.02 x 838.20
                </td>
                <td className='border-r border-b text-center text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                  7016 x 9900
                </td>
              </tr>
              <tr>
                <td
                  colspan='4'
                  className='border-r border-b text-[11px] leading-[1] h-[32px] text-primaryGray border-[#dddddd] bg-[#fef5fb] pl-[6px]'
                >
                  Height: Between 11.69 - 23.38 in / 29.70 - 59.40 cm
                  / 297.01 - 594.02 mm / 3508 - 7016 px
                  <br />
                  Width: Fixed 33 in / 83.82 cm / 838.20 mm / 9900 px
                </td>
              </tr>
              <tr>
                <td
                  rowspan='2'
                  className='border-r border-b border-l text-[13px] pl-[6px] border-[#dddddd] bg-[#faf1fc]'
                >
                  Square Image Size
                </td>
                <td className='border-r border-b text-center  h-[32px] leading-[1] text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                  23.38 x 23.38
                </td>
                <td className='border-r border-b text-center text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                  59.40 x 59.40
                </td>
                <td className='border-r border-b text-center text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                  594.02 x 594.02
                </td>
                <td className='border-r border-b text-center text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                  7016 x 7016
                </td>
              </tr>
              <tr>
                <td
                  colspan='4'
                  className='border-r border-b text-[11px] h-[32px] leading-[1]  text-primaryGray border-[#dddddd] bg-[#fef5fb] pl-[6px]'
                >
                  Fixed Width & Height
                  <br />
                  23.38 x 23.38 in / 59.40 x 59.40 cm / 594.02 x
                  594.02 mm / 7016 x 7016 px
                </td>
              </tr>
              <tr>
                <td className='border-r border-b border-l text-[13px] h-[32px] pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                  Image Resolution
                </td>
                <td
                  colspan='4'
                  className='border-r border-b text-primaryGray border-[#dddddd] pl-[6px]'
                >
                  300 DPI
                </td>
              </tr>
              <tr>
                <td className='border-r border-b border-l h-[32px] leading-[1]  border-b border-l text-[13px] py-[6px] pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                  Colour Space
                </td>
                <td
                  colspan='4'
                  className='border-r border-b text-primaryGray border-[#dddddd] pl-[6px]'
                >
                  RGB
                </td>
              </tr>
              <tr>
                <td className='border-r border-b border-l text-[13px] h-[32px] pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                  Colour Profile
                </td>
                <td
                  colspan='4'
                  className='border-r border-b text-primaryGray border-[#dddddd] pl-[6px]'
                >
                  sRGB IEC61966-2.1
                </td>
              </tr>
              <tr>
                <td className='border-r border-b border-l text-[13px] h-[32px] pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                  File Format
                </td>
                <td
                  colspan='4'
                  className='border-r border-b text-primaryGray border-[#dddddd] pl-[6px]'
                >
                  JPEG (Quality: Maximum)/PNG
                </td>
              </tr>
              <tr>
                <td className='border-r border-b border-l rounded-bl-[16px] text-[13px] h-[32px] pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                  File Size Limit
                </td>
                <td
                  colspan='4'
                  className='border-r border-b rounded-br-[16px] text-primaryGray border-[#dddddd] pl-[6px]'
                >
                  Maximum 50 MB
                </td>
              </tr>
            </tbody>
          </table>

          <table className='table mt-[20px] border-separate border-spacing-0'>
            <caption className='border text-[15px] h-[32px] rounded-tr-[16px] rounded-tl-[16px] border-[#dddddd] bg-[#ececec]'>
              <div className='h-[100%] flex items-center justify-center'>
                {' '}
                Vector Image Specifications
              </div>
            </caption>

            <tbody>
              <tr>
                <td className='border text-[13px] h-[32px] pl-[6px] pr-[75px] border-[#dddddd] bg-[#f7f7f7]'>
                  Colour Space
                </td>
                <td className='border-t border-r border-b text-primaryGray border-[#dddddd] pl-[6px]'>
                  RGB
                </td>
              </tr>
              <tr>
                <td className='border-r border-b border-l h-[32px] text-[13px]  pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                  Colour Profile
                </td>
                <td className='border-r border-b text-primaryGray border-[#dddddd] pl-[6px]'>
                  sRGB IEC61966-2.1
                </td>
              </tr>
              <tr>
                <td className='border-r border-b h-[32px] border-l text-[13px]  pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                  File Format
                </td>
                <td className='border-r border-b pr-[197px] text-primaryGray border-[#dddddd] pl-[6px]'>
                  EPS (Compatible with Adobe Illustrator 8 or 10)
                </td>
              </tr>
              <tr>
                <td className='border-r border-b border-l rounded-bl-[16px] text-[13px] h-[32px] pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                  File Size Limit
                </td>
                <td className='border-r border-b text-primaryGray rounded-br-[16px] border-[#dddddd] pl-[6px]'>
                  Maximum 25 MB
                </td>
              </tr>
            </tbody>
          </table>

          <div className='text-primaryGray text-sm11 mt-[20px]'>
            <span>
              <p className='text-pinkColor text-[14px]'>Note that:</p>
              You must include a JPEG file preview of:
              <br /> Horizontal 2400 x 1697 Pixels (300 DPI) or
              <br /> Vertical: 1697 x 2400 Pixels (300 DPI) or <br />
              Square: 2400 x 2400 Pixels (300 DPI)
              <br />
              We do not accept PSD or TIFF files for Art.
              <br />
              No greater than 100 MB (art image) and 50 MB (vector
              image). <br />
              We recommend referencing our{' '}
              <span className='text-orangeColor'>
                Required Pixels & Dimensions
              </span>{' '}
              page <br /> to ensure your artwork is correctly placed.
            </span>
          </div>

          <button
            type='button'
            className='greenBlueButton mt-[20px] mb-10'
          >
            Required Pixels & Dimensions
          </button>

          <div className='w-[100%] bg-[#efefef]  h-[2px]' />

          <div>
            <p className='text-primaryBlack text-[16px] font-medium mt-[25px]'>
              Photos
            </p>
            <p className='text-[14px] text-primaryGray mt-[15px]'>
              We accept images only in JPEG formats. Artnstock images
              are offered in sRGB since most images on the Internet
              are displayed in that profile. If you submit images in a
              different colour profile, the colours will likely be
              altered during conversion to sRGB, so we recommend
              converting your images to sRGB before uploading them to
              Artnstock.
            </p>
            <p className='text-[14px] text-primaryGray mt-[15px]'>
              All images must be set at highest quality settings to
              produce an acceptable file dimension for submission to
              Artnstock.
            </p>
            <table className='table mt-[20px] border-separate border-spacing-0 mb-6'>
              <caption className='border text-[15px] h-[32px] rounded-tr-[16px] rounded-tl-[16px] border-[#dddddd] bg-[#ececec]'>
                <div className='h-[100%] flex items-center justify-center'>
                  {' '}
                  Photo Image Specifications
                </div>
              </caption>

              <thead>
                <tr className='text-[13px]'>
                  <th className='border pr-[45px] h-[32px] pl-[6px] font-normal border-[#dddddd] bg-[#ececec]'>
                    Image Dimensions
                  </th>
                  <th className='border-t border-r border-b px-[36px] font-normal border-[#dddddd] bg-[#ececec]'>
                    Inches
                  </th>
                  <th className='border-t border-r border-b px-[36px] font-normal border-[#dddddd] bg-[#ececec]'>
                    Centimeters
                  </th>
                  <th className='border-t border-r border-b px-[36px] font-normal border-[#dddddd] bg-[#ececec]'>
                    Milimeters
                  </th>
                  <th className='border-t border-r border-b px-[36px] font-normal border-[#dddddd] bg-[#ececec]'>
                    Pixels
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    rowspan='2'
                    className='border-r border-b border-l text-[13px]  pl-[6px] border-[#dddddd] bg-[#faf1fc]'
                  >
                    Horizontal Image Size
                  </td>
                  <td className='border-r border-b text-center h-[32px] text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                    36 x 24
                  </td>
                  <td className='border-r border-b text-center text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                    91.44 x 60.96
                  </td>
                  <td className='border-r border-b text-center text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                    914.4 x 609.6
                  </td>
                  <td className='border-r border-b text-center text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                    10800 x 7200
                  </td>
                </tr>
                <tr>
                  <td
                    colspan='4'
                    className='border-r border-b text-[11px] h-[32px] leading-[1] text-primaryGray border-[#dddddd] bg-[#fef5fb] pl-[6px]'
                  >
                    Width: Fixed 36 in / 91.44 cm / 914.4 mm / 10800
                    px
                    <br />
                    Height: Between 16 - 24 in / 40.64 - 60.96 cm /
                    406.4 - 609.6 mm / 4800 - 7200 px
                  </td>
                </tr>
                <tr>
                  <td
                    rowspan='2'
                    className='border-r border-b border-l text-[13px]  pl-[6px] border-[#dddddd] bg-[#faf1fc]'
                  >
                    Vertical Image Size
                  </td>
                  <td className='border-r border-b text-center h-[32px] text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                    24 x 36
                  </td>
                  <td className='border-r border-b text-center text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                    60.96 x 91.44
                  </td>
                  <td className='border-r border-b text-center text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                    609.6 x 914.4
                  </td>
                  <td className='border-r border-b text-center text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                    7200 x 10800
                  </td>
                </tr>
                <tr>
                  <td
                    colspan='4'
                    className='border-r border-b text-[11px] h-[32px] leading-[1] text-primaryGray border-[#dddddd] bg-[#fef5fb] pl-[6px]'
                  >
                    Width: Between 16 - 24 in / 40.64 - 60.96 cm /
                    406.4 - 609.6 mm / 4800 - 7200 px
                    <br />
                    Height: Fixed 36 in / 91.44 cm / 914.4 mm / 10800
                    px
                  </td>
                </tr>
                <tr>
                  <td
                    rowspan='2'
                    className='border-r border-b border-l text-[13px]  pl-[6px] border-[#dddddd] bg-[#faf1fc]'
                  >
                    Square Image Size
                  </td>
                  <td className='border-r border-b text-center h-[32px] text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                    36 x 36
                  </td>
                  <td className='border-r border-b text-center text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                    91.44 x 91.44
                  </td>
                  <td className='border-r border-b text-center text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                    914.4 x 914.4
                  </td>
                  <td className='border-r border-b text-center text-primaryGray border-[#dddddd] bg-[#fef5fb]'>
                    10800 x 10800
                  </td>
                </tr>
                <tr>
                  <td
                    colspan='4'
                    className='border-r border-b text-[11px] h-[32px] leading-[1] text-primaryGray border-[#dddddd] bg-[#fef5fb] pl-[6px]'
                  >
                    Fixed Width & Height
                    <br />
                    36 x 36 in / 91.44 x 91.44 cm / 914.4 x 914.4 mm /
                    10800 x 10800 px
                  </td>
                </tr>
                <tr>
                  <td className='border-r border-b border-l text-[13px] h-[32px] pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                    Image Resolution
                  </td>
                  <td
                    colspan='4'
                    className='border-r border-b text-primaryGray border-[#dddddd] pl-[6px]'
                  >
                    300 DPI
                  </td>
                </tr>
                <tr>
                  <td className='border-r border-b border-l text-[13px] h-[32px] pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                    Colour Space
                  </td>
                  <td
                    colspan='4'
                    className='border-r border-b text-primaryGray border-[#dddddd] pl-[6px]'
                  >
                    RGB
                  </td>
                </tr>
                <tr>
                  <td className='border-r border-b border-l text-[13px] h-[32px] pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                    Colour Profile
                  </td>
                  <td
                    colspan='4'
                    className='border-r border-b text-primaryGray border-[#dddddd] pl-[6px]'
                  >
                    sRGB IEC61966-2.1
                  </td>
                </tr>
                <tr>
                  <td className='border-r border-b border-l text-[13px] h-[32px] pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                    File Format
                  </td>
                  <td
                    colspan='4'
                    className='border-r border-b text-primaryGray border-[#dddddd] pl-[6px]'
                  >
                    JPEG (Quality: Maximum)
                  </td>
                </tr>
                <tr>
                  <td className='border-r border-b border-l rounded-bl-[16px] text-[13px] h-[32px] pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                    File Size Limit
                  </td>
                  <td
                    colspan='4'
                    className='border-r border-b rounded-br-[16px] text-primaryGray border-[#dddddd] pl-[6px]'
                  >
                    Maximum 50 MB
                  </td>
                </tr>
              </tbody>
            </table>
            <span className='text-sm11 text-primaryGray mt-4 leading-[13px]'>
              <p className='text-pinkColor text-[14px]'>Note that:</p>
              <p>We do not accept PSD or TIFF files for Photos.</p>
              <p>
                {' '}
                We do not accept still frames from video files as they
                do not meet our
              </p>
              <p>technical quality standards.</p>
            </span>
          </div>
          {/* <hr className='border-[lightGray] mt-10' /> */}
          <div className='w-[100%] bg-[#efefef]  h-[2px] mt-10' />

          <div className='mt-[25px]'>
            <p className='text-primaryBlack text-[16px] font-medium '>
              Footage
            </p>
            <p className='text-[14px] text-primaryGray mt-[15px]'>
              Submitted clips must fit the specified formats. Clips
              should be between 5 and 60 seconds and no larger than
              4GB. All footage should be prepared as QuickTime MOV or
              MP4 files.
            </p>
            <p className='text-[14px] text-primaryGray mt-[15px]'>
              We will accept HD video recorded with a mobile device,
              but please keep the following in mind: When transferring
              your footage from your phone to your computer please be
              sure to use the device cable. Emailing/messaging the
              video as an attachment will result in loss of quality.
              Please keep in mind that on most phones the user-facing
              camera has a much lower resolution than the camera
              facing away from the user. We recommend you to only use
              the camera with the highest resolution.
            </p>
            <table className='table mt-[20px] border-separate border-spacing-0 mb-6'>
              <caption className='border text-[15px] h-[32px] rounded-tr-[16px] rounded-tl-[16px] border-[#dddddd] bg-[#ececec]'>
                <div className='h-[100%] flex items-center justify-center'>
                  {' '}
                  Footage File Specifications
                </div>
              </caption>

              <tbody>
                <tr>
                  <td className='border text-[13px] h-[32px] pl-[6px] pr-[75px] border-[#dddddd] bg-[#faf1fc]'>
                    Clip Duration
                  </td>
                  <td className='border-t border-r border-b text-primaryGray border-[#dddddd] bg-[#fef5fb] pl-[6px]'>
                    5 -60 Seconds
                  </td>
                </tr>
                <tr>
                  <td className='border-r border-b border-l text-[13px] h-[32px] pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                    Frames-Per-Second
                  </td>
                  <td className='border-r border-b text-primaryGray border-[#dddddd] pl-[6px]'>
                    23.98, 24, 25, 29.97, 30, 47.95, 47.96, 48, 50,
                    59.94, 60
                  </td>
                </tr>
                <tr>
                  <td className='border-r border-b border-l text-[13px] h-[32px] pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                    Resolution
                  </td>
                  <td className='border-r border-b pr-[248px] text-primaryGray border-[#dddddd] pl-[6px]'>
                    4K (4096 x 2160) and HD (1920 x 1080)
                  </td>
                </tr>
                <tr>
                  <td className='border-r border-b border-l text-[13px] h-[32px] pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                    File Format
                  </td>
                  <td className='border-r border-b text-primaryGray border-[#dddddd] pl-[6px]'>
                    ASF, AVI, DV, FLV, MOV, MPEG/MPG, WMV
                  </td>
                </tr>
                <tr>
                  <td className='border-r border-b border-l rounded-bl-[16px] text-[13px] h-[32px] pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                    File Size Limit
                  </td>
                  <td className='border-r border-b rounded-br-[16px] text-primaryGray border-[#dddddd] pl-[6px]'>
                    Maximum 250 MB
                  </td>
                </tr>
              </tbody>
            </table>
            <span className='text-sm11 text-primaryGray mt-[15px]'>
              <p className='text-pinkColor text-[14px]'>Note that:</p>

              <p className='text-sm11'>
                We prefer HD and 4K footage, but accept any types and
                resolutions listed below.
              </p>

              <p className='text-sm11'>
                Resolutions in bold are preferred.
              </p>
            </span>
            <div className='text-[12px] text-primaryGray mt-[15px]'>
              <div className='flex gap-[8px]'>
                <p className='min-w-[70px] font-semibold'>4K:</p>
                <p className='tracking-wide'>
                  4096 x 2160 &nbsp; 4096 x 2304 &nbsp; 4000 x 2160
                  &nbsp; 3840 x 2160 &nbsp; 3996 x 2160 &nbsp; 4800 x
                  2700
                </p>
              </div>
              <div className='flex gap-[8px]'>
                <p className='min-w-[70px] font-semibold'>
                  2K & 2.5K:
                </p>
                <p className='tracking-wide'>
                  2048 x 1080 &nbsp; 2400 x 1350 &nbsp; 2432 x 1366
                  &nbsp; 2702 x 1520 &nbsp; 2704 x 1520
                </p>
              </div>
              <div className='flex gap-[8px]'>
                <p className='min-w-[70px] font-semibold'>HD:</p>
                <p className='tracking-wide'>
                  1920 x 1080 &nbsp; 1280 x 720 &nbsp; 1440 x 1080
                  &nbsp; 1888 x 1062 &nbsp; 1920 x 1088
                </p>
              </div>
            </div>
            {/* <hr className='border-[lightGray] mt-8' /> */}
            <div className='w-[100%] bg-[#efefef]  h-[2px] mt-8' />
          </div>

          <div className='mt-[25px]'>
            <p className='text-primaryBlack text-[16px] font-medium '>
              Music (Exclusive)
            </p>
            <p className='text-[14px] text-primaryGray mt-[15px]'>
              Artnstock provides the best platform for sharing your
              royalty-free music with the world. Here is what you need
              to know before joining our community of contributing
              artists and producers.
            </p>
            <p className='text-[14px] text-primaryGray mt-[15px]'>
              The onboarding for a music contributor is different from
              the experience for a footage or image contributor to
              Artnstock. We currently work with a very small number of
              music contributors, so we are able to provide more
              individualized support. When onboarded, each music
              contributor is assigned an individual representative
              from the music content team who will work with them on
              producing the best possible tracks for our collection.
              The terms of our music contributor agreement and payout
              scheme are also different than that for other types of
              media.
            </p>
            <table className='table mt-[20px] border-separate border-spacing-0'>
              <caption className='border text-[15px] h-[32px] rounded-tr-[16px] rounded-tl-[16px] border-[#dddddd] bg-[#ececec]'>
                <div className='h-[100%] flex items-center justify-center'>
                  {' '}
                  Music File Specifications
                </div>
              </caption>

              <tbody>
                <tr>
                  <td className='border text-[13px] h-[32px] pl-[6px] pr-[75px] border-[#dddddd] bg-[#faf1fc]'>
                    Clip Duration
                  </td>
                  <td className='border-t border-r border-b text-primaryGray border-[#dddddd] bg-[#fef5fb] pl-[6px]'>
                    2:30 - 3:00 Minutes
                  </td>
                </tr>
                <tr>
                  <td className='border-r border-b border-l text-[13px] h-[32px] pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                    File Format
                  </td>
                  <td className='border-r border-b text-primaryGray border-[#dddddd] pl-[6px]'>
                    WAV, MP3 and AIF/AIFF
                  </td>
                </tr>
                <tr>
                  <td className='border-r border-b border-l text-[13px] h-[32px] pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                    Bit depth
                  </td>
                  <td className='border-r border-b pr-[417px] text-primaryGray border-[#dddddd] pl-[6px]'>
                    16 or 24 bit
                  </td>
                </tr>
                <tr>
                  <td className='border-r border-b border-l text-[13px] h-[32px] pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                    Sample Rate
                  </td>
                  <td className='border-r border-b text-primaryGray border-[#dddddd] pl-[6px]'>
                    44.1 or 48 kHz
                  </td>
                </tr>
                <tr>
                  <td className='border-r border-b border-l rounded-bl-[16px] text-[13px] h-[32px] pl-[6px] border-[#dddddd] bg-[#f7f7f7]'>
                    File Size Limit
                  </td>
                  <td className='border-r border-b rounded-br-[16px] text-primaryGray border-[#dddddd] pl-[6px]'>
                    Maximum 50 MB
                  </td>
                </tr>
              </tbody>
            </table>
            <div className='flex flex-col text-sm11 text-primaryGray mt-[15px]'>
              <span className='text-sm11'>
                <p className='text-pinkColor text-[14px]'>
                  Note that:
                </p>
                <p className='text-sm11 font-medium'>
                  We do not accept:
                </p>

                <p className='text-sm11'>Sound-alikes </p>
                <p className='text-sm11'>Uncleared samples</p>
                <p className='text-sm11'>
                  Samples from sound libraries that allow for
                  production music library use are acceptable
                </p>
                <p className='text-sm11'>Sound effects</p>
                <p className='text-sm11'>
                  Covers and compositions that are not 100% original
                </p>
              </span>
            </div>
            {/* <button
              type='button'
              className='greenBlueButton mt-[20px]'
            >
              How do I become a Music Contributor
            </button>{' '} */}
            <br />
            {/* <div className='text-center mt-[25px]'>
              <label className='text-[12px] primaryGray w-[100%]'>
                <input
                  className='mr-[17px]'
                  type='checkbox'
                  checked={check}
                  onChange={changeCheck}
                />
                I acknowledge and warrant that I have read and agree
                with Artnstock’s uploading Terms and Conditions.
              </label>{' '}
              <br />
              <div className='flex gap-[15px] justify-center mt-[25px] pb-[70px]'>
                <button className='blackBtn' onClick={gotoTabs}>
                  Continue
                </button>
                <button className='px-6 py-2.5 rounded-3xl text-sm14 text-primaryBlack border-[2px]'>
                  Cancel
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default UploadFile2;
