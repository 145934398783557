import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ContriBanner from '../contri-banner/Contri-Banner';
import UploadContent from '../contri-components/UploadContent';
import Submit from '../contri-components/Submit';
import { useState, useEffect } from 'react';
import AddDetails from '../contri-components/AddDetails';
import { useDispatch, useSelector } from 'react-redux';
import { setNestedTabValueUpload } from '../../../store/nestedTabSlice';
import { setNestedTabValueReleases } from '../../../store/nestedTabSlice';
import ActivateProducts from '../contri-components/ActivateProducts';
import ForReview from '../contri-components/ForReview';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import ApprovedFiles from '../contri-components/ApprovedFiles';
import RejectedFiles from '../contri-components/RejectedFiles';
import FileManager from '../TopTabs/FileManager';
import UploadRelease from '../contri-components/UploadRelease';
import PropertyRelease from '../contri-components/PropertyRelease';
import ModelRelease from '../contri-components/ModelRelease';
import MinorRelease from '../contri-components/MinorRelease';
import AllRelease from '../contri-components/AllRelease';
import Dashboard from '../TopTabs/Dashboard';
import EarningSummary from '../contri-components/EarningSummary';
import ReferralEarnings from '../contri-components/ReferralEarnings';
import UnpaidEarnings from '../contri-components/UnpaidEarnings';
import PaymentHistory from '../contri-components/PaymentHistory';
import TopDownloads from '../contri-components/TopDownloads';
import TaxMain from '../contri-components/tax/TaxMain';
import { setpath } from '../../../store/contriPathSlice';
import { setpath2 } from '../../../store/contriPathSlice';
import Footer from '../../footer/Footer';
import { useLocation } from 'react-router-dom';
import Certificate from '../contri-components/Certificate';
import TopSales from '../contri-components/TopSales';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
// import { useSelector } from 'react-redux';
import { httpClient } from '../../../axios';

import { useNavigate } from 'react-router-dom';

import joinpopup from '../../../assets/images/contributor/Art_TermConditions.jpg';
import { ReactComponent as JoinText } from '../../../assets/images/Icons/joinText.svg';

import { setNestedTabValueEarnings } from '../../../store/nestedTabSlice';

import ArtRelease from '../contri-components/ArtRelease';

import { contriCounterSliceAction } from '../../../store/contriCountersSlice';
import UploadFile2 from '../upload-file/Upload-file2';

// import { Outlet, Route, Routes } from 'react-router-dom';

const StyledPopupOnMount = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 32px;
    padding: 20px;
    width: 400px;
    border: none !important;
  }
`;

const StyledPopupOnMount2 = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    border: 0;
    padding: 0px;
    // padding-top: 32px;
    // padding-bottom: 32px;
    // padding-left: 28px;
    // padding-right: 28px;
    width: 690px;
  }
`;

const theme = createTheme({
  typography: {
    fontFamily: 'Heebo, sans-serif',
  },
});

const Tabs = () => {
  const [value, setValue] = useState('1');
  // const [nestedEarnings, setNestedEarnings] = useState('5.1');
  // const [path, setpath] = useState('/ Dashboard');
  // const [path2, setpath2] = useState('');

  const location = useLocation();

  const userId = useSelector((state) => state.auth.userId);
  const navigate = useNavigate();
  const sta = location.state;

  useEffect(() => {
    if (sta !== '' && sta !== null) {
      dispatch(setpath(sta));
      dispatch(setpath2('/ Upload Files'));
      // navigate(location.pathname, { replace: true, state: null });
    }
  }, [sta]);

  const dispatch = useDispatch();

  const path = useSelector((state) => state.contriPath.path);

  // useEffect(() => {
  //   dispatch(setpath('/ Dashboard'));
  // }, []);

  const path2 = useSelector((state) => state.contriPath.path2);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // useEffect(() => {
  //   console.log('path', path);
  // }, [path]);

  const nestedValue = useSelector(
    (state) => state.nestedTab.nestedUpload
  );

  const nestedReleases = useSelector(
    (state) => state.nestedTab.nestedReleases
  );

  const nestedEarnings = useSelector(
    (state) => state.nestedTab.nestedEarnings
  );

  const handleNestedChangeUpload = (event, newValue) => {
    dispatch(setNestedTabValueUpload(newValue));
  };

  const handleNestedChangeReleases = (event, newValue) => {
    dispatch(setNestedTabValueReleases(newValue));
  };

  const handleClick = (event) => {
    event.preventDefault();
    // console.log('Button clicked');
  };

  const [OpenOnMountPop, setOpenOnMountPop] = useState(false);
  const [OpenOnMountPop2, setOpenOnMountPop2] = useState(false);

  useEffect(() => {
    // setOpenOnMountPop(true);
    // setOpenOnMountPop2(true);
    // checkTermsAndConditions();
    // dispatch(setpath2('/ Upload Files'));
    getDraftSubmit();
    getForReviewCount();
    getApprovedCount();
    getRejectedCount();
    getFileUploadLimitCount();
    getArtReleasesCount();
    getPropertyReleasesCount();
    getModelReleasesCount();
    getMinorReleasesCount();
    getAllReleasesCount();

    // onClick={() => {

    // }}
  }, []);

  const [ContentHovereTabsValue, setContentHovereTabsValue] =
    useState('Art');

  const getDraftSubmit = async () => {
    try {
      const res = await httpClient.get(
        // `/draft_master/getContributorWiseDraftMasterList/${userId}
        `/draft_master/getDraftMasterByStatusAndActiveStatusCount/submit/false/${userId}`
      );
      const resp = await httpClient.get(
        // `/draft_master/getContributorWiseDraftMasterList/${userId}
        // /draft_master/getDraftMasterByStatus/adddetails/${userId}
        `/draft_master/getDraftMasterByStatusAndActiveStatusCount/adddetails/false/${userId}`
      );

      dispatch(
        contriCounterSliceAction.setToSubmitFilesCount(
          res?.data + resp.data
        )
      );
      // setDraftCount();
      console.log('setToSubmitFilesCount: ', res?.data);
      console.log('setToSubmitFilesCountttt: ', resp?.data);
      // setdraftId(res.data.draftId);
      // console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getForReviewCount = async () => {
    try {
      const res = await httpClient.get(
        `/art_master/getCountOfArtByStatus/InReview/${userId}`
      );
      dispatch(
        contriCounterSliceAction.setForReviewFilesCount(res?.data)
      );
      //setForReviewCount(res?.data);
      console.log(res?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getApprovedCount = async () => {
    try {
      const res = await httpClient.get(
        `/art_master/getCountOfArtByStatus/Approved/${userId}`
      );
      dispatch(
        contriCounterSliceAction.setApprovedFilesCount(res.data)
      );
      // setApprovedCount(res?.data);
      console.log(res?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getRejectedCount = async () => {
    try {
      const res = await httpClient.get(
        `/art_master/getCountOfArtByStatus/Rejected/${userId}`
      );
      dispatch(
        contriCounterSliceAction.setRejectedFilesCount(res.data)
      );
      // setRejectedCount(res?.data);
      console.log(res?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getFileUploadLimitCount = async () => {
    try {
      const res = await httpClient.get(
        `/fileUploadLimit/getUserIdWiseRatio/${userId}`
      );
      dispatch(
        contriCounterSliceAction.setFileUploadLimitCount(res.data)
      );
      // setRejectedCount(res?.data);
      console.log('setFileUploadLimitCount: ', res?.data);
    } catch (err) {
      console.error(err);
    }
  };

  // releases

  const getArtReleasesCount = async () => {
    let obj = {
      userId,
      type: 'Art Release',
    };
    try {
      const res = await httpClient.post(
        `/release_master/getReleasesCountByUserIdAndType`,
        obj
      );
      dispatch(
        contriCounterSliceAction.setArtReleasesCount(res.data)
      );
      // setApprovedCount(res?.data);
      console.log('getArtReleasesCount', res?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getPropertyReleasesCount = async () => {
    let obj = {
      userId,
      type: 'Property Release',
    };
    try {
      const res = await httpClient.post(
        `/release_master/getReleasesCountByUserIdAndType`,
        obj
      );
      dispatch(
        contriCounterSliceAction.setPropertyReleasesCount(res.data)
      );
      // setApprovedCount(res?.data);
      console.log('getPropertyReleasesCount', res?.data);
    } catch (err) {
      console.error(err);
    }
  };
  const getModelReleasesCount = async () => {
    let obj = {
      userId,
      type: 'Model Release',
    };
    try {
      const res = await httpClient.post(
        `/release_master/getReleasesCountByUserIdAndType`,
        obj
      );
      dispatch(
        contriCounterSliceAction.setModelReleasesCount(res.data)
      );
      // setApprovedCount(res?.data);
      console.log('getModelReleasesCount', res?.data);
    } catch (err) {
      console.error(err);
    }
  };
  const getMinorReleasesCount = async () => {
    let obj = {
      userId,
      type: 'Minor Release',
    };
    try {
      const res = await httpClient.post(
        `/release_master/getReleasesCountByUserIdAndType`,
        obj
      );
      dispatch(
        contriCounterSliceAction.setMinorReleasesCount(res.data)
      );
      // setApprovedCount(res?.data);
      console.log('getMinorReleasesCount', res?.data);
    } catch (err) {
      console.error(err);
    }
  };
  const getAllReleasesCount = async () => {
    let obj = {
      userId,
      type: 'All Release',
    };
    try {
      const res = await httpClient.post(
        `/release_master/getReleasesCountByUserIdAndType`,
        obj
      );
      dispatch(
        contriCounterSliceAction.setAllReleasesCount(res.data)
      );
      // setApprovedCount(res?.data);
      console.log('getAllReleasesCount', res?.data);
    } catch (err) {
      console.error(err);
    }
  };

  // useEffect(() => {
  //   dispatch(setpath2(path2));
  //   console.log('set2', path2);
  // }, [path2]);

  return (
    <>
      {/* Nested Routes */}

      {/* <StyledPopupOnMount
        open={OpenOnMountPop}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setOpenOnMountPop(false);
          document.body.classList.remove('body-no-scroll');
          setOpenOnMountPop2(true);
        }}
      >
        <p className='text-[16px] leading-[21px] font-medium text-center'>
          Terms of Service Update
        </p>
        <p className='text-center text-sm12 text-primaryGray mt-[8px]'>
          We have recently updated our Contributor Terms of Service.{' '}
        </p>
        <p className='text-center text-sm12 text-primaryGray '>
          By clicking "I understand", you accept Artnstock Terms of
          Service.
        </p>

        <p className='text-center text-sm12 text-primaryGray  mt-[14px]'>
          You can read our updated Terms of Service{' '}
          <span
            className='text-orangeColor'
            onClick={() => navigate('/TermsAndServices')}
          >
            {' '}
            here
          </span>
          .
        </p>
        <p className='text-center text-sm12 text-pinkColor'>
          Email sent on ksgrafiks2012@gmail.com
        </p>
        <div className='w-[100%] mt-[15px] flex justify-center'>
          <button
            onClick={() => {
              setOpenOnMountPop(false);
            }}
            className='gray30HButton'
          >
            I Understand
          </button>
        </div>
      </StyledPopupOnMount> */}
      {/* <StyledPopupOnMount2
        open={OpenOnMountPop2}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setOpenOnMountPop2(false);
          document.body.classList.remove('body-no-scroll');
        }}
      >
        <div className='flex'>
          <div
            style={{
              backgroundImage: `url(${joinpopup})`,
            }}
            className='w-[345px] h-[400px] rounded-l-[30px] flex items-center justify-center'
          >
            <div className='flex flex-col'>
              <div className='mx-[auto]'>
                <JoinText />
              </div>

              <p className='text-primaryBlack text-[16px] font-semibold'>
                the community and start selling in seconds
              </p>
            </div>
            
          </div>
          <div className='w-[345px] pl-[15px] pt-[60px] flex flex-col gap-[16px]'>
            {' '}
            <p className='text-[16px] font-medium leading-[20px]'>
              Thanks for confirming! <br />
              We can not wait to see your <br />
              best work!
            </p>
            <p className='text-[12px] text-primaryGray leading-[15px]'>
              Upload your work that you have created, Add Details,{' '}
              <br />
              Activate Products, and then submit for review.
            </p>
            <div>
              <p className='text-[12px] leading-[15px]'>
                Photos or illustrations
              </p>
              <p className='text-[12px] text-primaryGray leading-[15px]'>
                Upload JPEG files that are at least 4 megapixels.
              </p>
            </div>
            <div>
              <p className='text-[12px] leading-[15px]'>Vectors</p>
              <p className='text-[12px] text-primaryGray leading-[15px]'>
                Upload EPS files with matching JPEG files. EPS files{' '}
                <br />
                must be compatible with illustrator version 8 or 10.
              </p>
              <p className='text-orangeColor cursor-pointer text-[12px] leading-[15px]'>
                Learn more
              </p>
            </div>
            <div>
              <button
                onClick={() => setOpenOnMountPop2(false)}
                className='blackBtn'
              >
                Got It
              </button>
            </div>
          </div>
        </div>
      </StyledPopupOnMount2> */}

      <ThemeProvider theme={theme}>
        <div className='w-[100%] pb-[128px]'>
          {/* <ContriBanner /> */}
          <div>
            {/* <p className='w-[100%] text-center bullet pt-[32px] mb-[3px]'>
            Artnstock / My Account /{' '}
            <span className=''>Contributor</span>{' '}
            <span
              className={`font-medium ${
                path2 === ''
                  ? 'text-primaryBlack'
                  : 'text-primaryGray'
              } `}
            >
              {path}
            </span>{' '}
            <span className={`font-medium text-primaryBlack`}>
              {path2}
            </span>
          </p> */}
            {/* <p className='w-[100%] text-center text-[12px] text-[#757575] pt-[30px] pb-[5px]'>
            Artnstock / My Account / Contributor / Upload / Upload
            Content
          </p> */}
          </div>

          {/* test */}
          <div className='flex w-[100%] justify-center mb-[16px] mt-[24px]'>
            <div className='flex mx-[auto]'>
              <div
                onClick={() => {
                  // setContentHovereTabsValue('Dashboard')
                  dispatch(setpath('/ Dashboard'));
                  dispatch(setpath2(''));
                }}
                className={`${
                  path === '/ Dashboard'
                    ? 'bg-[#bbbbbb] text-[#333333] border-y-[#bbbbbb] border-l-[#bbbbbb] '
                    : 'border-y-[#e9e9e9] bg-[#EEEEEE]  border-l-[#e9e9e9] text-[#757575]  '
                } hover:bg-[#bbbbbb] hover:text-[#333333] hover:border-y-[#bbbbbb] hover:border-l-[#bbbbbb] cursor-pointer border-l border-y text-[15px] font-medium flex items-center leading-5 h-[40px] pl-[20px] pr-[16px]   rounded-l-[5000px] `}
              >
                Dashboard
              </div>
              <div
                onClick={() => {
                  dispatch(setpath('/ Upload'));
                  dispatch(setNestedTabValueUpload('2.1'));
                  dispatch(setpath2('/ Upload Files'));

                  // setContentHovereTabsValue('Upload');
                }}
                className={`${
                  path === '/ Upload'
                    ? 'bg-[#bbbbbb] border-y-[#bbbbbb] text-[#333333]'
                    : 'text-[#757575]  bg-[#EEEEEE]  border-y-[#e9e9e9] '
                } hover:bg-[#bbbbbb] hover:border-y-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[15px] font-medium flex items-center leading-5 h-[40px] px-[16px] border-r-[#D7D7D7] border-l-[#D7D7D7] border-r border-l border-y`}
              >
                Upload
              </div>

              <div
                onClick={() => {
                  // setContentHovereTabsValue('File Manager');
                  dispatch(setpath('/ File Manager'));
                  dispatch(setpath2(''));
                }}
                className={`${
                  path === '/ File Manager'
                    ? 'border-y-[#bbbbbb] bg-[#bbbbbb] text-[#333333]'
                    : 'text-[#757575] border-y-[#e9e9e9] bg-[#EEEEEE]'
                } hover:border-y-[#bbbbbb] hover:bg-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[15px] font-medium flex items-center leading-5 h-[40px] px-[16px] border-r border-r-[#D7D7D7]  border-y `}
              >
                File Manager
              </div>

              <div
                onClick={() => {
                  // setContentHovereTabsValue('Releases');
                  dispatch(setpath('/ Releases'));

                  dispatch(setNestedTabValueReleases('4.1'));
                }}
                className={`${
                  path === '/ Releases'
                    ? 'border-y-[#bbbbbb] bg-[#bbbbbb] text-[#333333]'
                    : 'text-[#757575] bg-[#EEEEEE] border-y-[#e9e9e9]'
                } hover:border-y-[#bbbbbb] hover:bg-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[15px] font-medium flex items-center leading-5 h-[40px] px-[16px] border-r border-r-[#D7D7D7]  border-y `}
              >
                Releases
              </div>
              <div
                onClick={() => {
                  // setContentHovereTabsValue('Templates');
                  dispatch(setpath('/ Earnings'));
                  dispatch(
                    setNestedTabValueEarnings('/ Earning Summary')
                  );
                }}
                className={`${
                  path === '/ Earnings'
                    ? 'bg-[#bbbbbb] border-y-[#bbbbbb] border-r-[#bbbbbb] text-[#333333]'
                    : 'text-[#757575]  bg-[#EEEEEE]  border-y-[#e9e9e9]'
                }
                    hover:bg-[#bbbbbb] hover:border-y-[#bbbbbb] hover:border-r-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[15px] font-medium flex items-center leading-5 h-[40px] pr-[20px] pl-[16px] rounded-r-[500px] border-y border-r border-r-[#e9e9e9]`}
              >
                Earnings
              </div>
            </div>
          </div>
          {/* test */}

          {path === '/ Dashboard' ? (
            <div className=''>
              {' '}
              <div className='relative'>
                <p className='text-center text-[16px] leading-[21px] font-normal text-pinkColor '>
                  Thanks for your submission
                </p>
                <p className='text-center  text-sm12  text-primaryGray '>
                  We will e-mail you when the review is complete.
                </p>
              </div>
              <Dashboard />
            </div>
          ) : path === '/ Upload' ? (
            <div className=''>
              {/* <TabContext value={nestedValue}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    justifyContent: 'center',
                    borderBottom: 'none',
                    '& .MuiTabs-root': {
                      minHeight: 0,
                      paddingBottom: '8px',
                    },
                  }}
                >
                  <TabList
                    onChange={handleNestedChangeUpload}
                    aria-label='nested tab example'
                    sx={{
                      '& .MuiTabs-indicator': {
                        display: 'none',
                      },
                      // styles for the selected tab
                      '& .Mui-selected': {
                        bgcolor: '#e6e6e6',
                        color: '#333333!important',
                        borderColor: '#e6e6e6',
                      },
                    }}
                  >
                    <Tab
                      onClick={() => {
                        dispatch(setpath2('/ Upload Files'));
                      }}
                      label='Upload Files'
                      value='2.1'
                      sx={{
                        textTransform: 'none!important',
                        border: '1px solid #eaeaea',
                        borderRadius: '25px',
                        color: '#7e7e7e',
                        fontSize: '12px',
                        marginLeft: '2px',
                        marginRight: '2px',
                        height: '28px!important',
                        minHeight: 0,
                        minWidth: 'fit-content!important',
                        paddingRight: '12px!important',
                        paddingLeft: '12px!important',

                        '& .MuiTouchRipple-root': {
                          // position: 'static!important',
                          display: 'none!important',
                        },
                        '&:hover': {
                          bgcolor: '#e6e6e6',
                          color: '#333333!important',
                          borderColor: '#e6e6e6',
                        },
                        '&:disabled': {
                          opacity: 1,
                          color: '#7e7e7e',
                        },
                      }}
                      // disabled={true}
                    />

                    <Tab
                      onClick={() => {
                        dispatch(setpath2('/ To Submit'));
                      }}
                      label='To Submit'
                      value='2.2'
                      sx={{
                        textTransform: 'none!important',
                        border: '1px solid #eaeaea',
                        borderRadius: '25px',
                        color: '#7e7e7e',
                        fontSize: '12px',
                        marginLeft: '2px',
                        marginRight: '2px',
                        height: '28px!important',
                        minHeight: 0,
                        minWidth: 'fit-content!important',
                        paddingRight: '12px!important',
                        paddingLeft: '12px!important',
                        '& .MuiTouchRipple-root': {
                          display: 'none!important',
                        },
                        '&:hover': {
                          bgcolor: '#e6e6e6',
                          color: '#333333!important',
                          borderColor: '#e6e6e6',
                        },
                        '&:disabled': {
                          opacity: 1,
                          color: '#7e7e7e',
                        },
                      }}
                      // disabled={true}
                    />
                    <Tab
                      onClick={() => {
                        dispatch(setpath2('/ Add Details'));
                      }}
                      label='Add Details'
                      value='2.3'
                      sx={{
                        textTransform: 'none!important',
                        border: '1px solid #eaeaea',
                        borderRadius: '25px',
                        color: '#7e7e7e',
                        fontSize: '12px',
                        marginLeft: '2px',
                        marginRight: '2px',
                        height: '28px!important',
                        minHeight: 0,
                        minWidth: 'fit-content!important',
                        paddingRight: '12px!important',
                        paddingLeft: '12px!important',
                        '& .MuiTouchRipple-root': {
                          display: 'none!important',
                        },
                        '&:hover': {
                          bgcolor: '#e6e6e6',
                          color: '#333333!important',
                          borderColor: '#e6e6e6',
                        },
                        '&:disabled': {
                          opacity: 1,
                          color: '#7e7e7e',
                        },
                      }}
                      // disabled={true}
                    />
                    <Tab
                      onClick={() => {
                        dispatch(setpath2('/ Activate Products'));
                      }}
                      label='Activate Products'
                      value='2.4'
                      sx={{
                        textTransform: 'none!important',
                        border: '1px solid #eaeaea',
                        borderRadius: '25px',
                        color: '#7e7e7e',
                        fontSize: '12px',
                        marginLeft: '2px',
                        marginRight: '2px',
                        height: '28px!important',
                        minHeight: 0,
                        minWidth: 'fit-content!important',
                        paddingRight: '12px!important',
                        paddingLeft: '12px!important',
                        '& .MuiTouchRipple-root': {
                          display: 'none!important',
                        },
                        '&:hover': {
                          bgcolor: '#e6e6e6',
                          color: '#333333!important',
                          borderColor: '#e6e6e6',
                        },
                      }}
                    />
                    <Tab
                      onClick={() => {
                        dispatch(setpath2('/ For Review'));
                      }}
                      label='For Review'
                      value='2.5'
                      sx={{
                        textTransform: 'none!important',
                        border: '1px solid #eaeaea',
                        borderRadius: '25px',
                        color: '#7e7e7e',
                        fontSize: '12px',
                        marginLeft: '2px',
                        marginRight: '2px',
                        height: '28px!important',
                        minHeight: 0,
                        minWidth: 'fit-content!important',
                        paddingRight: '12px!important',
                        paddingLeft: '12px!important',
                        '& .MuiTouchRipple-root': {
                          display: 'none!important',
                        },
                        '&:hover': {
                          bgcolor: '#e6e6e6',
                          color: '#333333!important',
                          borderColor: '#e6e6e6',
                        },
                      }}
                    />
                    <Tab
                      onClick={() => {
                        dispatch(setpath2('/ Approved Files'));
                      }}
                      label='Approved Files'
                      value='2.6'
                      sx={{
                        textTransform: 'none!important',
                        border: '1px solid #eaeaea',
                        borderRadius: '25px',
                        color: '#7e7e7e',
                        fontSize: '12px',
                        marginLeft: '2px',
                        marginRight: '2px',
                        height: '28px!important',
                        minHeight: 0,
                        minWidth: 'fit-content!important',
                        paddingRight: '12px!important',
                        paddingLeft: '12px!important',
                        '& .MuiTouchRipple-root': {
                          display: 'none!important',
                        },
                        '&:hover': {
                          bgcolor: '#e6e6e6',
                          color: '#333333!important',
                          borderColor: '#e6e6e6',
                        },
                      }}
                    />
                    <Tab
                      onClick={() => {
                        dispatch(setpath2('/ Rejected Files'));
                      }}
                      label='Rejected Files'
                      value='2.7'
                      sx={{
                        textTransform: 'none!important',
                        border: '1px solid #eaeaea',
                        borderRadius: '25px',
                        color: '#7e7e7e',
                        fontSize: '12px',
                        marginLeft: '2px',
                        marginRight: '2px',
                        height: '28px!important',
                        minHeight: 0,
                        minWidth: 'fit-content!important',
                        paddingRight: '12px!important',
                        paddingLeft: '12px!important',
                        '& .MuiTouchRipple-root': {
                          display: 'none!important',
                        },
                        '&:hover': {
                          bgcolor: '#e6e6e6',
                          color: '#333333!important',
                          borderColor: '#e6e6e6',
                        },
                      }}
                    />
                  </TabList>
                </Box> */}

              <div className='flex justify-center gap-[4px] mb-[8px]'>
                <div
                  onClick={() => {
                    dispatch(setpath2('/ Upload Files'));
                  }}
                  className={`${
                    path2 === '/ Upload Files'
                      ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                      : 'border-[#eaeaea] text-primaryGray font-medium'
                  }  border rounded-[14px] cursor-pointer h-[28px] px-[12px] hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] justify-center flex items-center text-[12px]`}
                >
                  Upload Files
                </div>

                <div
                  onClick={() => {
                    dispatch(setpath2('/ To Submit'));
                  }}
                  className={`${
                    path2 === '/ To Submit'
                      ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                      : 'border-[#eaeaea] text-primaryGray font-medium'
                  }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                >
                  To Submit
                </div>

                <div
                  onClick={() => {
                    dispatch(setpath2('/ Add Details'));
                  }}
                  className={`${
                    path2 === '/ Add Details'
                      ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                      : 'border-[#eaeaea] text-primaryGray font-medium'
                  }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                >
                  Add Details
                </div>

                <div
                  onClick={() => {
                    dispatch(setpath2('/ Activate Products'));
                  }}
                  className={`${
                    path2 === '/ Activate Products'
                      ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                      : 'border-[#eaeaea] text-primaryGray font-medium'
                  }  border rounded-[14px] h-[28px] cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                >
                  Activate Products
                </div>

                <div
                  onClick={() => {
                    dispatch(setpath2('/ For Review'));
                  }}
                  className={`${
                    path2 === '/ For Review'
                      ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                      : 'border-[#eaeaea] text-primaryGray  font-medium'
                  }  border rounded-[14px] h-[28px] cursor-pointer  hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px]`}
                >
                  For Review
                </div>

                <div
                  onClick={() => {
                    dispatch(setpath2('/ Approved Files'));
                  }}
                  className={`${
                    path2 === '/ Approved Files'
                      ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                      : 'border-[#eaeaea] text-primaryGray  font-medium'
                  }  border rounded-[14px] h-[28px] cursor-pointer  hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px]`}
                >
                  Approved Files
                </div>
                <div
                  onClick={() => {
                    dispatch(setpath2('/ Rejected Files'));
                  }}
                  className={`${
                    path2 === '/ Rejected Files'
                      ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                      : 'border-[#eaeaea] text-primaryGray  font-medium'
                  }  border rounded-[14px] h-[28px] cursor-pointer  hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px]`}
                >
                  Rejected Files
                </div>
              </div>

              {path2 === '/ Upload Files' ? (
                <>
                  <UploadContent />
                </>
              ) : path2 === '/ To Submit' ? (
                <>
                  <Submit />
                </>
              ) : path2 === '/ Add Details' ? (
                <>
                  <AddDetails />
                </>
              ) : path2 === '/ Activate Products' ? (
                <>
                  <ActivateProducts />
                </>
              ) : path2 === '/ For Review' ? (
                <>
                  <ForReview />
                </>
              ) : path2 === '/ Approved Files' ? (
                <>
                  <ApprovedFiles />
                </>
              ) : path2 === '/ Rejected Files' ? (
                <>
                  <RejectedFiles />
                </>
              ) : path2 === 'TechSpec' ? (
                <>
                  <UploadFile2 />
                </>
              ) : (
                <></>
              )}

              {/* </TabContext> */}
            </div>
          ) : path === '/ File Manager' ? (
            <div className=''>
              <FileManager />
            </div>
          ) : path === '/ Releases' ? (
            <div className=''>
              <TabContext value={nestedReleases}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    justifyContent: 'center',
                    borderBottom: 'none',
                    '& .MuiTabs-root': {
                      minHeight: 0,
                      paddingBottom: '8px',
                    },
                  }}
                >
                  <TabList
                    onChange={handleNestedChangeReleases}
                    aria-label='nested tab example'
                    sx={{
                      '& .MuiTabs-indicator': {
                        display: 'none',
                      },
                      // styles for the selected tab
                      '& .Mui-selected': {
                        bgcolor: '#e6e6e6',
                        color: '#333333!important',
                        borderColor: '#e6e6e6',
                      },
                    }}
                  >
                    <Tab
                      onClick={() => {
                        dispatch(setpath2('/ Art Release'));
                      }}
                      label='Art Releases'
                      value='4.1'
                      sx={{
                        textTransform: 'none!important',
                        border: '1px solid #eaeaea',
                        borderRadius: '25px',
                        color: '#7e7e7e',
                        fontSize: '12px',
                        marginLeft: '2px',
                        marginRight: '2px',
                        height: '28px!important',
                        minHeight: 0,
                        minWidth: 'fit-content!important',
                        paddingRight: '12px!important',
                        paddingLeft: '12px!important',
                        '& .MuiTouchRipple-root': {
                          display: 'none!important',
                        },
                        '&:hover': {
                          bgcolor: '#e6e6e6',
                          color: '#333333!important',
                          borderColor: '#e6e6e6',
                        },
                        '&:disabled': {
                          opacity: 1,
                          color: '#7e7e7e',
                        },
                      }}
                    />
                    <Tab
                      onClick={() => {
                        dispatch(setpath2('/ Property Release'));
                      }}
                      label='Property Releases'
                      value='4.2'
                      sx={{
                        textTransform: 'none!important',
                        border: '1px solid #eaeaea',
                        borderRadius: '25px',
                        color: '#7e7e7e',
                        fontSize: '12px',
                        marginLeft: '2px',
                        marginRight: '2px',
                        height: '28px!important',
                        minHeight: 0,
                        minWidth: 'fit-content!important',
                        paddingRight: '12px!important',
                        paddingLeft: '12px!important',
                        '& .MuiTouchRipple-root': {
                          display: 'none!important',
                        },
                        '&:hover': {
                          bgcolor: '#e6e6e6',
                          color: '#333333!important',
                          borderColor: '#e6e6e6',
                        },
                        '&:disabled': {
                          opacity: 1,
                          color: '#7e7e7e',
                        },
                      }}
                    />
                    <Tab
                      onClick={() => {
                        dispatch(setpath2('/ Model Release'));
                      }}
                      label='Model Releases'
                      value='4.3'
                      sx={{
                        textTransform: 'none!important',
                        border: '1px solid #eaeaea',
                        borderRadius: '25px',
                        color: '#7e7e7e',
                        fontSize: '12px',
                        marginLeft: '2px',
                        marginRight: '2px',
                        height: '28px!important',
                        minHeight: 0,
                        minWidth: 'fit-content!important',
                        paddingRight: '12px!important',
                        paddingLeft: '12px!important',
                        '& .MuiTouchRipple-root': {
                          display: 'none!important',
                        },
                        '&:hover': {
                          bgcolor: '#e6e6e6',
                          color: '#333333!important',
                          borderColor: '#e6e6e6',
                        },
                        '&:disabled': {
                          opacity: 1,
                          color: '#7e7e7e',
                        },
                      }}
                    />
                    <Tab
                      onClick={() => {
                        dispatch(setpath2('/ Minor Release'));
                      }}
                      label='Minor Releases'
                      value='4.4'
                      sx={{
                        textTransform: 'none!important',
                        border: '1px solid #eaeaea',
                        borderRadius: '25px',
                        color: '#7e7e7e',
                        fontSize: '12px',
                        marginLeft: '2px',
                        marginRight: '2px',
                        height: '28px!important',
                        minHeight: 0,
                        minWidth: 'fit-content!important',
                        paddingRight: '12px!important',
                        paddingLeft: '12px!important',
                        '& .MuiTouchRipple-root': {
                          display: 'none!important',
                        },
                        '&:hover': {
                          bgcolor: '#e6e6e6',
                          color: '#333333!important',
                          borderColor: '#e6e6e6',
                        },
                        '&:disabled': {
                          opacity: 1,
                          color: '#7e7e7e',
                        },
                      }}
                    />
                    <Tab
                      onClick={() => {
                        dispatch(setpath2('/ All Releases'));
                      }}
                      label='All Releases'
                      value='4.5'
                      sx={{
                        textTransform: 'none!important',
                        border: '1px solid #eaeaea',
                        borderRadius: '25px',
                        color: '#7e7e7e',
                        fontSize: '12px',
                        marginLeft: '2px',
                        marginRight: '2px',
                        height: '28px!important',
                        minHeight: 0,
                        minWidth: 'fit-content!important',
                        paddingRight: '12px!important',
                        paddingLeft: '12px!important',
                        '& .MuiTouchRipple-root': {
                          display: 'none!important',
                        },
                        '&:hover': {
                          bgcolor: '#e6e6e6',
                          color: '#333333!important',
                          borderColor: '#e6e6e6',
                        },
                        '&:disabled': {
                          opacity: 1,
                          color: '#7e7e7e',
                        },
                      }}
                    />
                  </TabList>
                </Box>
                <TabPanel value='4.1' sx={{ paddingTop: '0px' }}>
                  <ArtRelease />
                </TabPanel>
                <TabPanel value='4.2' sx={{ paddingTop: '0px' }}>
                  <PropertyRelease />
                </TabPanel>
                <TabPanel value='4.3' sx={{ paddingTop: '0px' }}>
                  <ModelRelease />
                </TabPanel>
                <TabPanel value='4.4' sx={{ paddingTop: '0px' }}>
                  <MinorRelease />
                </TabPanel>
                <TabPanel value='4.5' sx={{ paddingTop: '0px' }}>
                  <AllRelease />
                </TabPanel>
              </TabContext>
            </div>
          ) : path === '/ Earnings' ? (
            <div className='w-[100%]'>
              <div>
                <div className='flex justify-center gap-[4px] mb-[8px]'>
                  <div
                    onClick={() => {
                      dispatch(setpath2('/ Earning Summary'));
                      dispatch(
                        setNestedTabValueEarnings('/ Earning Summary')
                      );
                    }}
                    className={`${
                      nestedEarnings === '/ Earning Summary'
                        ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                        : 'border-[#eaeaea] text-primaryGray font-medium'
                    }  border rounded-[14px] cursor-pointer h-[28px] px-[12px] hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] justify-center flex items-center text-[12px]`}
                  >
                    Earning Summary
                  </div>

                  <div
                    onClick={() => {
                      dispatch(setpath2('/ Referral Earnings'));
                      dispatch(
                        setNestedTabValueEarnings(
                          '/ Referral Earnings'
                        )
                      );
                    }}
                    className={`${
                      nestedEarnings === '/ Referral Earnings'
                        ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                        : 'border-[#eaeaea] text-primaryGray font-medium'
                    }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                  >
                    Referral Earnings
                  </div>

                  <div
                    onClick={() => {
                      dispatch(setpath2('/ Payment History'));
                      dispatch(
                        setNestedTabValueEarnings('/ Payment History')
                      );
                    }}
                    className={`${
                      nestedEarnings === '/ Payment History'
                        ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                        : 'border-[#eaeaea] text-primaryGray font-medium'
                    }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                  >
                    Payment History
                  </div>

                  <div
                    onClick={() => {
                      dispatch(setpath2('/ Top Sales'));
                      dispatch(
                        setNestedTabValueEarnings('/ Top Sales')
                      );
                    }}
                    className={`${
                      nestedEarnings === '/ Top Sales'
                        ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                        : 'border-[#eaeaea] text-primaryGray font-medium'
                    }  border rounded-[14px] h-[28px] cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                  >
                    Top Sales
                  </div>

                  <div
                    onClick={() => {
                      dispatch(setpath2('/ Tax'));
                      dispatch(setNestedTabValueEarnings('/ Tax'));
                    }}
                    className={`${
                      nestedEarnings === '/ Tax'
                        ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                        : 'border-[#eaeaea] text-primaryGray  font-medium'
                    }  border rounded-[14px] h-[28px] cursor-pointer  hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px]`}
                  >
                    Tax
                  </div>
                </div>

                {nestedEarnings === '/ Earning Summary' ? (
                  <div>
                    {' '}
                    <EarningSummary />
                  </div>
                ) : nestedEarnings === '/ Referral Earnings' ? (
                  <div>
                    <ReferralEarnings />
                  </div>
                ) : nestedEarnings === '/ Top Sales' ? (
                  <div>
                    <TopSales />
                  </div>
                ) : nestedEarnings === '/ Payment History' ? (
                  <div>
                    <PaymentHistory />
                  </div>
                ) : nestedEarnings === '/ Tax' ? (
                  <div>
                    <TaxMain />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>

        {/* <Certificate /> */}
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default Tabs;
