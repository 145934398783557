import React, { useEffect, useState } from 'react';
import { httpClient } from '../axios';
import Banner from '../components/homepage/banner/Banner';
import Combo from '../components/homepage/combo/Combo';
import HomeJoin from '../components/homepage/homeJoin/HomeJoin';
import LimitedEdition from '../components/homepage/limitedEdition/LimitedEdition';
import Magzine from '../components/homepage/magzine/Magzine';
import MainGrid from '../components/homepage/gridPages/MainGrid';
import ProdList from '../components/homepage/prodList/ProdList';
import Footer from '../components/footer/Footer';
import RoyaltyFree from '../components/Royalty/RoyaltyFree';
import Certificate from '../components/contributor/contri-components/Certificate';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const StyledPopupOnMount = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 32px;
    padding: 20px;
    width: 400px;
    border: none !important;
  }
`;

const MainHomepage = () => {
  const [homeObject, setHomeObject] = useState();

  const navigate = useNavigate();

  const userId = useSelector((state) => state.auth.userId);
  const userDetails = useSelector((state) => state.auth.userDetails);

  useEffect(() => {
    httpClient
      .get(`/dynamic_homepage_content_master/getTypeWiseList/home`)
      .then((res) => {
        // console.log(res);
        setHomeObject(res.data);
      });
  }, []);

  const [OpenOnMountPop, setOpenOnMountPop] = useState(false);

  const checkTermsAndCondition = async () => {
    try {
      const res = await httpClient.get(
        `user_master/checkTermsAndCondition/${userId}`
      );
      // setOpenOnMountPop(false);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userDetails?.termsAndConditions === true) {
      setOpenOnMountPop(true);
    } else {
      // checkTermsAndCondition();
    }
  }, [userDetails]);

  return (
    <>
      <StyledPopupOnMount
        open={OpenOnMountPop}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setOpenOnMountPop(false);
          document.body.classList.remove('body-no-scroll');
          // setOpenOnMountPop2(true);
        }}
      >
        <p className='text-[16px] leading-[21px] font-medium text-center'>
          Terms of Service Update
        </p>
        <p className='text-center text-sm12 text-primaryGray mt-[8px]'>
          We have recently updated our Contributor Terms of Service.{' '}
        </p>
        <p className='text-center text-sm12 text-primaryGray '>
          By clicking "I understand", you accept Artnstock Terms of
          Service.
        </p>

        <p className='text-center text-sm12 text-primaryGray  mt-[14px]'>
          You can read our updated Terms of Service{' '}
          <span
            className='text-orangeColor'
            onClick={() => {
              navigate('/TermsAndServices');
            }}
          >
            {' '}
            here
          </span>
          .
        </p>
        <p className='text-center text-sm12 text-pinkColor'>
          Email sent on ksgrafiks2012@gmail.com
        </p>
        <div className='w-[100%] mt-[15px] flex justify-center'>
          <button
            onClick={() => {
              checkTermsAndCondition();
              setOpenOnMountPop(false);
            }}
            className='gray30HButton'
          >
            I Understand
          </button>
        </div>
      </StyledPopupOnMount>
      <Banner type='home' />
      <MainGrid />
      <div className='hrLine'></div>
      <LimitedEdition logo={homeObject?.smallLogo} type='home' />
      <div className='hrLine'></div>
      <ProdList heading={homeObject?.popularProductTitle} />
      <div className='hrLine'></div>
      <Combo
        logo={homeObject?.smallLogo}
        comboHeading={homeObject?.comboTextImg}
      />
      <div className='hrLine'></div>
      <RoyaltyFree />
      <HomeJoin
        mainBackgroundImg={homeObject?.signInBackground}
        leftBackgroundImg={homeObject?.signInLeftImg}
        leftTextImg={homeObject?.signInLeftMain}
      />
      <Magzine type='home' />
      <Certificate />
      <Footer />
    </>
  );
};

export default MainHomepage;
