import React from 'react';
import { useState, useEffect } from 'react';
import { httpClient } from '../../../axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ViewIcon } from '../../../assets/images/contributor/svg/view.svg';
import { ReactComponent as IIcon } from '../../../assets/images/Icons/iButton.svg';

const ChangePassword_Customer = () => {
  const userId = useSelector((state) => state.auth.userId);

  const navigate = useNavigate();

  const changePassword = async () => {
    try {
      const res = httpClient.put(
        `/user_master/updatePassword/${userId}/`
      );
    } catch (err) {
      console.error(err);
    }
  };

  const [newPassword, setNewPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  // const [passwordError, setPasswordError] = useState('');

  // useEffect(() => {
  //   console.log(object);
  // }, [confirmPassword]);

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const validatePasswords = () => {
    if (password === '') {
      toast.error('Please enter current password');
      return false;
    } else if (newPassword === '' || confirmPassword === '') {
      toast.error('Please enter New Password & Confirm Password');
      return false;
    } else if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match');
      return false;
    } else if (newPassword === confirmPassword) {
      // Passwords match, you can proceed with form submission or other actions

      return true;
    }
  };

  const handleButtonClick = async () => {
    try {
      if (validatePasswords()) {
        // You can submit the form or perform other actions here
        let object = {
          newPassword: newPassword,
          confirmPassword: confirmPassword,
          password: password,
          userId: userId,
        };

        const res = await httpClient.put(
          '/user_master/updatePasswordUser',
          object
        );
        if (res) {
          toast.success('Password successfully updated');
          setConfirmPassword('');
          setNewPassword('');
          setPassword('');
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className='wrapper w-[100%] h-[100vh] bg-[#ffb851] pt-[8px]'>
        <div className='rounded-[32px] w-[388px] mx-auto bg-[#ffffff] py-[20px] px-[20px] text-center'>
          <p className='text24'>Change Password</p>

          <div className='relative mt-[12px]'>
            <div className='flex gap-[8px] absolute right-[13px] top-[13px]'>
              {/* <div>
                <IIcon className='cursor-pointer fill-[#bbbbbb] hover:fill-[#333333]' />
              </div> */}
              <div>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM14 8C12.9902 5.3511 10.6837 3.5 8 3.5C5.31631 3.5 3.00992 5.3511 2 8C3.00992 10.6489 5.31631 12.5 8 12.5C10.6837 12.5 12.9902 10.6489 14 8ZM8 11C9.65683 11 11 9.65683 11 8C11 6.34317 9.65683 5 8 5C6.34317 5 5 6.34317 5 8C5 9.65683 6.34317 11 8 11Z'
                    fill='#bbbbbb'
                  />
                </svg>
              </div>
            </div>
            <input
              type='text'
              placeholder='Enter your current password'
              className='InputField44H  '
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name='password'
              // disabled
            />

            <p className='InputFieldTopText44H'>Current Password</p>
          </div>

          {/* <input
            type='password'
            name='password'
            value={newPassword}
            onChange={handleNewPasswordChange}
            className='regInput mt-[11px]'
            placeholder='New Password'
          /> */}

          <div className='relative mt-[12px]'>
            <div className='flex gap-[8px] absolute right-[13px] top-[13px]'>
              <div>
                <IIcon className='cursor-pointer fill-[#bbbbbb] hover:fill-[#333333]' />
              </div>
              <div>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM14 8C12.9902 5.3511 10.6837 3.5 8 3.5C5.31631 3.5 3.00992 5.3511 2 8C3.00992 10.6489 5.31631 12.5 8 12.5C10.6837 12.5 12.9902 10.6489 14 8ZM8 11C9.65683 11 11 9.65683 11 8C11 6.34317 9.65683 5 8 5C6.34317 5 5 6.34317 5 8C5 9.65683 6.34317 11 8 11Z'
                    fill='#bbbbbb'
                  />
                </svg>
              </div>
            </div>
            <input
              type='text'
              placeholder='Enter your new password'
              className='InputField44H  '
              value={newPassword}
              onChange={handleNewPasswordChange}
              name='password'
              // disabled
            />

            <p className='InputFieldTopText44H'>New Password</p>
          </div>

          <div className='relative mt-[12px]'>
            <div className='flex gap-[8px] absolute right-[13px] top-[13px]'>
              {/* <div>
                <IIcon className='cursor-pointer fill-[#bbbbbb] hover:fill-[#333333]' />
              </div> */}
              <div>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM14 8C12.9902 5.3511 10.6837 3.5 8 3.5C5.31631 3.5 3.00992 5.3511 2 8C3.00992 10.6489 5.31631 12.5 8 12.5C10.6837 12.5 12.9902 10.6489 14 8ZM8 11C9.65683 11 11 9.65683 11 8C11 6.34317 9.65683 5 8 5C6.34317 5 5 6.34317 5 8C5 9.65683 6.34317 11 8 11Z'
                    fill='#bbbbbb'
                  />
                </svg>
              </div>
            </div>
            <input
              type='text'
              placeholder='Enter to confirm new password'
              className='InputField44H  '
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              name='confirmPassword'
              // disabled
            />

            <p className='InputFieldTopText44H'>
              Confirm New Password
            </p>
          </div>

          {/* <input
            type='password'
            name='confirmPassword'
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            className='regInput mt-[11px]'
            placeholder='Confirm New Password'
          /> */}

          {/* <p className='text-[11px] text-[#757575] leading-[1.2] text-start mt-1'>
            Password must be at least 8 characters long
          </p> */}
          <div className='flex gap-[8px] justify-center mt-[16px]'>
            <button
              onClick={handleButtonClick}
              className='black40HButton'
            >
              Change
            </button>
            <button
              onClick={() => {
                navigate('/contributor', {
                  state: 'Change Password',
                });
              }}
              className='outlineBtn40h'
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword_Customer;
