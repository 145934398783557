import React from 'react';
import Certificate from '../contributor/contri-components/Certificate';
import Footer from '../footer/Footer';

const CommunityGuidelines = () => {
  return (
    <>
      <div className='w-w930 mx-auto leading-[22px] mt-[20px] text-primaryBlack '>
        <p className='mb-[3px] bullet text-start'>
          Artnstock / Buyer /
          <span className='text-[#333333] font-medium'>
            {' '}
            Community Guidelines
          </span>
        </p>
        <p className='text-heading font-medium'>
          Community Guidelines
        </p>
        <p className='mt-[22px] text-[15px] text-[#bbbbbb] font-normal'>
          Last Updated Date: July 5, 2022
        </p>
        <p className='mt-[25px] text-[24px] font-medium'>
          Introduction
        </p>
        <p className='mt-[25px] text-[15px] text-primaryGray font-normal'>
          Artnstock Private Limited, provides a service for viewing,
          selling and purchasing original works of art and
          commercially exploiting digital images of works of art
          through our website, accessible at www.artnstock.com (the
          “Site”) or mobile application (the “Mobile Application”)
          together with any services, software, tools, features or
          functionality made available by Artnstock (each, a “Service”
          and collectively, the “Services”). Please read carefully the
          following terms and conditions (“Terms”) and our Privacy
          Policy, which may be found at www.artnstock.com/privacy (the
          “Privacy Policy”). These Terms, the Privacy Policy, and any
          other policies linked to herein, govern your access to and
          use of the Services, and constitute a binding legal
          agreement between you and Artnstock.
        </p>
        <p className='mt-[19px] font-normal  text-primaryGray text-[15px]'>
          Certain areas of the Services and your access to certain
          Services may have different terms and conditions posted or
          may require you to agree with and accept additional terms
          and conditions. If there is a conflict between these Terms
          and the terms and conditions posted for a specific area of
          the Services or for access to specific Services, the latter
          terms and conditions shall take precedence with respect to
          your use of or access to that area of the Services.
        </p>

        <p className='mt-[19px] font-medium text-[15px] '>
          PLEASE READ THESE TERMS CAREFULLY. THESE TERMS INCLUDE AN
          AGREEMENT TO ARBITRATE, WHICH MEANS THAT YOU AGREE TO SUBMIT
          ANY DISPUTE RELATED TO YOUR USE OF THE SERVICES TO BINDING
          INDIVIDUAL ARBITRATION RATHER THAN PROCEED IN COURT. IF YOU
          WANT TO OPT-OUT OF THE AGREEMENT TO ARBITRATE, YOU MAY DO SO
          PROVIDED YOU FOLLOW THE PROCEDURES SET FORTH BELOW IN THE
          SECTION ENTITLED “AGREEMENT TO ARBITRATE”. THE DISPUTE
          RESOLUTION SECTION ALSO INCLUDES A CLASS ACTION WAIVER,
          WHICH MEANS THAT YOU AGREE TO PROCEED WITH ANY DISPUTE
          INDIVIDUALLY AND NOT AS PART OF A PROPOSED CLASS. THESE
          TERMS ALSO INCLUDE A WAIVER OF YOUR RIGHTS TO A TRIAL BY
          JURY IRRESPECTIVE OF WHETHER YOU AGREE TO ARBITRATE YOUR
          CLAIMS.
        </p>

        <p className='mt-[19px] font-medium text-[15px] '>
          YOU ACKNOWLEDGE AND AGREE THAT, BY ACCESSING OR USING THE
          SERVICES OR BY SELLING OR PURCHASING A WORK ON OR THROUGH
          THE SERVICES OR BY POSTING ANY CONTENT ON THE SERVICES, YOU
          ARE INDICATING THAT YOU HAVE READ, UNDERSTAND AND AGREE TO
          BE BOUND BY THESE TERMS, WHETHER OR NOT YOU HAVE REGISTERED
          WITH THE SERVICES. IF YOU DO NOT AGREE TO THESE TERMS AND
          THE PRIVACY POLICY, THEN YOU HAVE NO RIGHT TO ACCESS OR USE
          THE SERVICES.
        </p>
        <p className='mt-[19px] font-normal  text-primaryGray text-[15px]  '>
          If you accept or agree to these Terms on behalf of a company
          or other legal entity, you represent and warrant that you
          have the authority to bind that company or other legal
          entity to these Terms and, in such event, “you” and “your”
          will refer and apply to that company or other legal entity.
        </p>
        <p className='mt-[37px] text-[24px] font-medium'>
          Modifications to these Terms
        </p>
        <p className='mt-[19px] font-normal  text-primaryGray  text-[15px] '>
          Artnstock reserves the right, at its sole discretion, to
          modify, discontinue or terminate the Services or to modify
          these Terms, at any time and without prior notice. If we
          modify these Terms, we will post the modification on the
          Services or provide you with notice of the modification. We
          will also update the “Last Updated Date”. By continuing to
          access or use the Services after we have posted a
          modification on the Services or have provided you with
          notice of a modification, you are indicating that you agree
          to be bound by the modified Terms. If the modified Terms are
          not acceptable to you, your only recourse is to cease using
          the Services. We encourage you to check back regularly to
          review these Terms.
        </p>
        <p className='mt-[37px] text-[24px] font-medium'>
          Modifications to the Services
        </p>

        <p className='mt-[19px] font-normal  text-primaryGray text-[15px]'>
          We reserve the right to change the URL, modify or
          discontinue, and restrict or block access to, the Services
          without notice to you. We may modify or remove any Original
          Works of Art (as defined below), Digital Works (as defined
          below), Artnstock Content (as defined below) or Member
          Content (as defined below) from the Services at any time
          without notice to you, including the removal of any works of
          art or content that we believe to be obscene, lewd,
          lascivious, filthy, excessively violent, harassing, or
          otherwise objectionable.
        </p>

        <p className='mt-[19px] font-normal  text-primaryGray text-[15px] '>
          Occasionally, there may be information on the Services that
          contains typographical errors, inaccuracies or omissions. We
          reserve the right to correct any errors, inaccuracies or
          omissions, and to change or update information if any such
          information on the Services is inaccurate at any time
          without prior notice. We undertake no obligation to update,
          amend or clarify information in the Services, except as
          required by applicable local, state, federal or
          international laws, regulations, or statutes. No specified
          update or refresh date applied to the Services should be
          taken to indicate that all information on the Services has
          been modified or updated.
        </p>

        <p className='mt-[37px] text-[24px] font-medium'>
          Eligibility
        </p>
        <p className='mt-[19px] font-normal  text-primaryGray  text-[15px]  '>
          The Services are intended solely for persons who are 18 or
          older. Any access to or use of the Services by anyone under
          18 is expressly prohibited. By accessing or using the
          Services you represent and warrant that you are 18 or older.
        </p>
        <p className='mt-[37px]  text-[24px] font-medium'>
          Account Registration
        </p>
        <p className='mt-[19px] font-normal  text-primaryGray text-[15px]'>
          In order to list a work on the Services or to make a
          purchase through the Services or writing any comments in
          forums or reviews, (other than users who utilize the guest
          checkout option), you must first create an account
          (“Account”) by completing our registration process. During
          the registration process you will be required to provide
          certain information and you will establish a username and a
          password. Upon completion of our registration process or by
          utilizing our guest checkout option you will become a
          “Member.” You agree to provide accurate, current and
          complete information during the registration process and to
          update such information to keep it accurate, current and
          complete, for example if you become VAT registered.
          Artnstock reserves the right to suspend or terminate your
          Account if any information provided during the registration
          process or thereafter proves to be inaccurate, not current
          or incomplete. You are responsible for safeguarding your
          password. You agree not to disclose your password to any
          third party and to take sole responsibility for any
          activities or actions under your Account, whether or not you
          have authorized such activities or actions. You will
          immediately notify Artnstock of any unauthorized use of your
          Account.
        </p>
        <p className='mt-[19px] font-normal  text-primaryGray text-[15px] '>
          Any information you choose to publish in the public section
          of your profile may be viewed, distributed or linked to
          within the website or in the course of delivering the
          Artnstock service. We care about your privacy and you can
          read our Privacy Policy if you would like to know how we
          handle your personal information.
        </p>
      </div>
      <Certificate />
      <Footer />
    </>
  );
};

export default CommunityGuidelines;
