import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from '../../../assets/images/Icons/searchDarkIcon.svg';
import { useSelector, useDispatch } from 'react-redux';
import { keywordsManagerSliceAction } from '../../../store/keywordsManagerSlice';

import { httpClient } from '../../../axios';
import { toast } from 'react-toastify';

import { KeywordsSliceAction } from '../../../store/keywordsSlice';

const PriceDetailsPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    // padding: 30px;
    width: 878px;
    height: 755px;
    border: none;
  }
`;

// const images = [
//   {
//     id: 1,
//     img: 'https://res.cloudinary.com/artnstockimg/image/upload/v1709209271/artnstock/gijngx88eyuhnj4qmp4x.jpg',
//   },

//   {
//     id: 2,
//     img: 'https://res.cloudinary.com/artnstockimg/image/upload/v1709209271/artnstock/biduhp7b8tphzxkztb6k.jpg',
//   },
//   {
//     id: 3,
//     img: 'https://res.cloudinary.com/artnstockimg/image/upload/v1709209278/artnstock/lp6ztcrbordkvuuhrffc.jpg',
//   },

//   {
//     id: 4,
//     img: 'https://res.cloudinary.com/artnstockimg/image/upload/v1709209278/artnstock/h28giwsqrml9glmghnhd.jpg',
//   },

//   {
//     id: 5,
//     img: 'https://res.cloudinary.com/artnstockimg/image/upload/v1709209279/artnstock/b1fiwpa3hp5tbnb34r1c.jpg',
//   },

//   {
//     id: 6,
//     img: 'https://res.cloudinary.com/artnstockimg/image/upload/v1709286337/artnstock/gkkcaq6u2794an0qozvr.jpg',
//   },

//   {
//     id: 7,
//     img: 'https://res.cloudinary.com/artnstockimg/image/upload/v1709286394/artnstock/gkl6nw8h56qqq6fvuws2.jpg',
//   },
//   {
//     id: 8,
//     img: 'https://res.cloudinary.com/artnstockimg/image/upload/v1709623233/artnstock/d5xasbxcm2wu3dqbqz3p.jpg',
//   },
//   {
//     id: 9,
//     img: 'https://res.cloudinary.com/artnstockimg/image/upload/v1710757150/artnstock/f98ybc3z6y9mwcgjlow9.jpg',
//   },
//   {
//     id: 10,
//     img: 'https://res.cloudinary.com/artnstockimg/image/upload/v1712582551/artnstock/hfexspt4l59olr8hrdso.jpg',
//   },
// ];

const KeywordPopup = ({ open, onClose, imageUrl }) => {
  const [showPrice, setShowPrice] = useState(false);

  const userId = useSelector((state) => state.auth.userId);

  const keyword = [
    'cloud',
    'smoke',
    'colour',
    'colourful',
    'concept',
    'background',
    'fine',
    'watercolour',
    'cloudy sky',
    'background',
    'design',
    'illustraion',
    'paint',
    'texture',
    'pattern',
    'backdrop',
    'abstract',
    'colourful sky',
    'nature',
    'art',
    'artistic',
  ];

  const keywords = useSelector((state) => state.keywords.keywords);

  const dispatch = useDispatch();

  const [searchedKeyword, setSearchedKeyword] = useState('');

  const setKeyword = (e) => {
    const value = e.target.value;
    setSearchedKeyword(value);
  };

  const [images, setimages] = useState([]);

  const getArtBySearch = async () => {
    if (searchedKeyword !== '') {
      try {
        const res = await httpClient.get(
          `/art_master/getArtByKeyword/${searchedKeyword}/${userId}`
        );

        setimages(res.data);
        console.log('getArtByKeyword: ', res.data);
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error('Please Enter atleast one word');
    }
  };

  const [selectedImages, setSelectedImages] = useState([]);

  const [hovered, setHovered] = useState(false);

  const handleButtonClick = (card) => {
    // setIsChecked(!isChecked);
    const findImage = selectedImages.find(
      (item) => item.artId === card.artId
    );

    if (!findImage) {
      dispatch(setSelectedImages([...selectedImages, card]));
    } else {
      dispatch(
        setSelectedImages(
          selectedImages.filter((image) => image.artId !== card.artId)
        )
      );
    }
  };

  const handleCheckboxChange = (card) => {
    // setIsChecked(!isChecked);
    const findImage = selectedImages.find(
      (item) => item.artId === card.artId
    );

    if (!findImage) {
      dispatch(setSelectedImages([...selectedImages, card]));
    } else {
      dispatch(
        setSelectedImages(
          selectedImages.filter((image) => image.artId !== card.artId)
        )
      );
    }
  };

  // const setHoveredFunc = (card) => {
  //   // setIsChecked(!isChecked);
  //   const findImage = hovered?.find(
  //     (item) => item.artId === card.artId
  //   );

  //   if (!findImage) {
  //     setHovered(card);
  //   } else {
  //     setHovered('');
  //   }
  // };

  useEffect(() => {
    console.log(selectedImages);
  }, [selectedImages]);

  // useEffect(() => {
  //   const abc = checkCheckboxTrueOrNot();
  //   setchecked(abc);
  // }, [selectedImages]);

  // const checkCheckboxTrueOrNot = () => {
  //   const bool = selectedImages.find(
  //     (item) => item.artId === card.artId
  //   );
  //   return bool;
  // };

  // Function to extract all keywords from the data
  const extractKeywords = (data) => {
    return data.reduce((acc, obj) => {
      return [...acc, ...obj.keywords];
    }, []);
  };

  const [allKeywords, setAllKeywords] = useState([]);

  useEffect(() => {
    const keywords = extractKeywords(selectedImages);
    setAllKeywords(keywords);
  }, [selectedImages]);

  const copyToClipboard = () => {
    const keywordString = allKeywords.join(',');
    navigator.clipboard
      .writeText(keywordString)
      .then(() => {
        toast('Keywords copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  const SetKeywordManagerFalse = () => {
    dispatch(keywordsManagerSliceAction.setKeywordsFalse());
  };

  return (
    <PriceDetailsPopup
      open={keywords}
      //   closeOnDocumentClick={true}
      position={'top center'}
      onClose={() => {
        dispatch(keywordsManagerSliceAction.setKeywordsFalse());

        document.body.classList.remove('body-no-scroll');
      }}
      onOpen={() => {
        // dispatch(keywordsManagerSliceAction.setKeywordsFalse());

        document.body.classList.add('body-no-scroll');
      }}
    >
      <div
        style={
          {
            // height: '766px',
            // overflowY: 'auto',
            // overflowX: 'hidden',
          }
        }
        className=' w-[100%] h-[100%] p-[30px] h-[100%]'
      >
        {/* <p className='text-heading mb-5 text-center font-medium'>
                    Price Details
                </p> */}

        <p className='text-primaryGray text-[11px]  text-center'>
          {' '}
          Keyword Manager
        </p>
        <p className=' text-primaryBlack text-[11px] text-center'>
          Select thumbnail(s) to view Keywords
        </p>
        {imageUrl && (
          <p className='text-primaryGray text-[11px]  text-center'>
            Editing 1 Art Files
          </p>
        )}
        {imageUrl && (
          <div className='gap-[4px] h-[35px] mx-[auto] flex justify-center mt-[7px]'>
            <div className='h-[32px] w-[32px] rounded '>
              <img
                src={imageUrl}
                className=' h-[32px] w-[32px] rounded'
              />
            </div>

            {/* <div className='h-[32px] w-[32px] rounded '>
          <img
            src='https://res.cloudinary.com/artnstockimg/image/upload/v1709286394/artnstock/gkl6nw8h56qqq6fvuws2.jpg'
            className='h-[32px] w-[32px] rounded'
          />
        </div>
        <div className='h-[32px] w-[32px] rounded '>
          <img
            src='https://res.cloudinary.com/artnstockimg/image/upload/v1709623233/artnstock/d5xasbxcm2wu3dqbqz3p.jpg'
            className='h-[32px] w-[32px] rounded'
          />
        </div>
        <div className='h-[32px] w-[32px] rounded'>
          <img
            src='https://res.cloudinary.com/artnstockimg/image/upload/v1712582551/artnstock/hfexspt4l59olr8hrdso.jpg'
            className='h-[32px] w-[32px] rounded'
          />
        </div> */}
          </div>
        )}

        <div className='mTopUpload flex items-center w-[813px] mx-[auto]'>
          <input
            className='bg-[#EEEEEE] flex-1 outline-none h-[44px] px-5 placeholder:text-placeholderColor placeholder:font-medium placeholder:text-[14px] rounded-tl-3xl rounded-bl-3xl  border-[#DADADA]'
            type='text'
            placeholder='Search by Keyword name'
            onChange={setKeyword}
          />
          <button
            onClick={() => getArtBySearch()}
            className=' rounded-tr-3xl h-[44px] flex items-center rounded-br-3xl border-l-[2px] border-l-[#DADADA] bg-[#E6E6E6] pl-[8px] pr-[19px] text-primaryGray text-sm14 font-medium'
          >
            <SearchIcon className='inline-block mr-2 fill-[#888888]' />
            <span className='relative top-[1px]'>Search</span>
          </button>
        </div>

        {images?.length > 0 ? (
          <div className='w-[813px] mt-[14px] mx-[auto] flex flex-wrap gap-[10px]'>
            {images?.map((card) => (
              // <div key={image?.artId}>
              //   <img
              //     src={image?.imageMaster?.imageOrientation?.squareUrl}
              //     className='h-[106px] w-[106px] rounded-[16px]'
              //   ></img>
              // </div>

              <div
                className={`box-border h-[106px] w-[106px] rounded-[16px] relative`}
                // h-[127px] w-[126.99px]
                onMouseEnter={() => setHovered(card.artId)}
                onMouseLeave={() => setHovered('')}
                // style={style}
              >
                <div
                  className={`h-full w-full bg-no-repeat bg-center bg-cover filter rounded-[16px] brightness-100 absolute overflow-hidden inset-0 ${
                    hovered === card.artId ? 'brightness-[70%]' : ''
                  }`}
                  style={{
                    backgroundImage: `url(${card?.imageMaster?.imageOrientation?.squareUrl})`,
                  }}
                ></div>

                {selectedImages.find(
                  (item) => item.artId === card.artId
                ) || hovered === card.artId ? (
                  <div className='absolute inset-0 flex items-center justify-center'>
                    <div
                      className='imageHoverButtonSmall'
                      onClick={() => handleButtonClick(card)}
                    >
                      {selectedImages.find(
                        (item) => item.artId === card.artId
                      ) ? (
                        <span>Deselect</span>
                      ) : (
                        <span>Select</span>
                      )}
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                {selectedImages.find(
                  (item) => item.artId === card.artId
                ) || hovered === card.artId ? (
                  <label className='containerCheckWhite p-2'>
                    <input
                      type='checkbox'
                      checked={selectedImages?.find(
                        (item) => item.artId === card?.artId
                      )}
                      onChange={() => handleCheckboxChange(card)}
                    />{' '}
                    {/* {obj.width}cm x {obj.height}cm */}
                    <span className='checkmarkWhite'></span>
                  </label>
                ) : (
                  <div></div>
                )}
              </div>
            ))}
            <div className='h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'></div>
            <div className='h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'></div>
            <div className='h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'></div>
            <div className='h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'></div>
            <div className='h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'></div>
            <div className='h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'></div>
            <div className='h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'></div>
            <div className='h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'></div>
            <div className='h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'></div>
            <div className='h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'></div>
            <div className='h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'></div>
            <div className='h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'></div>
            <div className='h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'></div>
            {/* <div className='h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'></div>
          <div className='h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'></div>
          <div className='h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'></div>
          <div className='h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'></div>
          <div className='h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'></div> */}
          </div>
        ) : (
          <div className='text-center pt-[8px] mb-[468px]'>
            <p className='text-pinkColor text-[16px]'>
              Search for Art
            </p>
            <p className='text-primaryGray text-[12px]'>
              We will suggest keywords based on the results you
              select.
            </p>
            {/* <button className='blackBtn mt-[20px]'>Upload Files</button> */}
          </div>
        )}
        {allKeywords?.length > 0 && (
          <p className='text-[11px] text-primaryGray  mt-[30px]'>
            Keywords Used
          </p>
        )}
        <div className='flex flex-wrap gap-[4px] mt-[5px] w-[678px]'>
          {/* <ObjectList data={selectedImages} /> */}
          {allKeywords?.map((key) => (
            <div className='text-[11px] text-primaryGray border border-[#DDDDDD] rounded-[5000px] flex items-center px-[8px] h-[28px]'>
              {key}
            </div>
          ))}
        </div>

        <div className='flex mt-[17px]'>
          {selectedImages?.length > 0 ? (
            <button
              onClick={copyToClipboard}
              //   onClick={handleButtonClickRelease}
              className='gray30HButton'
            >
              Copy Keywords
            </button>
          ) : (
            <button
              // onClick={copyToClipboard}
              //   onClick={handleButtonClickRelease}
              className='bg-[#8e8e8e] rounded-[5000px] text-[12px] text-[#ffffff] font-medium px-[12px] h-[28px] flex items-center opacity-[40%] cursor-default'
            >
              Copy Keywords
            </button>
          )}

          {imageUrl && selectedImages?.length > 0 ? (
            <button
              //   onClick={handleButtonClickRelease}
              onClick={() => {
                dispatch(
                  KeywordsSliceAction.setKeywords(allKeywords)
                );
                SetKeywordManagerFalse();
              }}
              className='gray30HButton ml-[8px]'
            >
              Attach to Selected
            </button>
          ) : (
            <button
              //   onClick={handleButtonClickRelease}
              // onClick={() => {
              //   dispatch(KeywordsSliceAction.setKeywords(allKeywords));
              //   SetKeywordManagerFalse();
              // }}
              className='bg-[#8e8e8e] rounded-[5000px] text-[12px] text-[#ffffff] font-medium px-[12px] h-[28px] flex items-center opacity-[40%] cursor-default ml-[8px]'
            >
              Attach to Selected
            </button>
          )}

          {selectedImages?.length > 0 ? (
            <button
              onClick={() => {
                setAllKeywords([]);
                setSelectedImages([]);
              }}
              className='gray30HBorderButton ml-[8px]'
            >
              Clear Keywords
            </button>
          ) : (
            <button
              // onClick={() => {
              //   setAllKeywords([]);
              //   setSelectedImages([]);
              // }}
              className='gray30HBorderButton ml-[8px] opacity-[40%]'
            >
              Clear Keywords
            </button>
          )}
        </div>
      </div>
    </PriceDetailsPopup>
  );
};

export default KeywordPopup;

// const ObjectList = ({ data }) => (
//   <>
//     {data?.map((obj) => (
//       // <div key={obj.id} className="mb-4">
//       //   <h2 className="text-lg font-semibold mb-2">{obj.name}</h2>
//       <KeywordList keywords={obj.keywords} />
//       // </div>
//     ))}
//   </>
// );

// const KeywordList = ({ keywords }) => (
//   <>
//     {keywords?.map((key) => (
//       <div
//         key={key}
//         className='text-[11px] text-primaryGray border border-[#DDDDDD] rounded-[5000px] flex items-center px-[8px] h-[28px]'
//       >
//         {key}
//       </div>
//     ))}
//   </>
// );
