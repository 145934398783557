import React from 'react';
import SellBanner from '../../assets/images/static/banners/Banner_SellonArtnstock.jpg';
// import SellBanner from '../../assets/images/SellOnArtnstock/SellBanner.jpg';
import Offer from '../../assets/images/SellOnArtnstock/Offer.png';
import wallpaper from '../../assets/images/SellOnArtnstock/Wallpaper.svg';
import button from '../../assets/images/SellOnArtnstock/button.jpg';
import { ReactComponent as ICreate } from '../../assets/images/Royalty/ICreate.svg';
import { ReactComponent as IUpload } from '../../assets/images/Royalty/IUpload.svg';
import { ReactComponent as IEarn } from '../../assets/images/Royalty/IEarn.svg';
import { ReactComponent as IRefer } from '../../assets/images/Royalty/IRefer.svg';

import percentImg from '../../assets/images/static/percentImg.svg';

import { httpClient } from '../../axios';
import { useEffect, useState } from 'react';
import Footer from '../footer/Footer';

// import SellOnartnstockdata from './Sellonartnstockdata';
import ArtIcon from '../../assets/images/SellOnArtnstock/colorIcons/IconBG_Art_72x72.svg';
import PhotosIcon from '../../assets/images/SellOnArtnstock/colorIcons/IconBG_Photos_72x72.svg';
import FootageIcon from '../../assets/images/SellOnArtnstock/colorIcons/IconBG_Footage_72x72.svg';
import MusicIcon from '../../assets/images/SellOnArtnstock/colorIcons/IconBG_Music_72x72.svg';
import ProductsIcon from '../../assets/images/SellOnArtnstock/colorIcons/IconBG_Products_72x72.svg';
import TemplatesIcon from '../../assets/images/SellOnArtnstock/colorIcons/IconBG_Templates_72x72.svg';

// import Faqmaping from './FooterPages/faq/Faqmaping';
import bckimage2 from '../../assets/images/SellOnArtnstock/bckimage2.jpg';
import hr from '../../assets/images/SellOnArtnstock/hr.jpg';
import Wallpaper from '../../assets/images/SellOnArtnstock/Wallpaper.jpg';
import vogue from '../../assets/images/SellOnArtnstock/vogue.jpg';
import Fresh from '../../assets/images/SellOnArtnstock/Fresh Influx.jpg';
import Certificate from '../contributor/contri-components/Certificate';

import ArtHomeFaq from '../faq/ArtHomeFaq';

import Sell1 from '../../assets/images/static/banners/Img_WhySellonArtnstock01.jpg';
import Sell2 from '../../assets/images/static/banners/Img_WhySellonArtnstock02.jpg';

import Sell3 from '../../assets/images/static/banners/Img_WhySellonArtnstock03.jpg';

const SellOnartnstockdata = [
  {
    id: 1,
    level: 'Level 1',
    royalti: '30%',
    sl: '$ 0.25',
    el: '$ 0.25',
    s2: '$ 0.25',
    e2: '$ 0.25',
    s3: '$ 0.35',
    e3: '$ 0.35',
    s4: '$ 0.35',
    e4: '$ 0.35',
    download: 'Up to 499',
  },
  {
    id: 2,
    level: 'Level 2',
    royalti: '32%',
    sl: '$ 0.27',
    el: '$ 0.27',
    s2: '$ 0.27',
    e2: '$ 0.27',
    s3: '$ 0.37',
    e3: '$ 0.37',
    s4: '$ 0.37',
    e4: '$ 0.37',
    download: '500-4,999',
  },
  {
    id: 3,
    level: 'Level 3',
    royalti: '34%',
    sl: '$ 0.29',
    el: '$ 0.29',
    s2: '$ 0.29',
    e2: '$ 0.29',
    s3: '$ 0.39',
    e3: '$ 0.39',
    s4: '$ 0.39',
    e4: '$ 0.39',
    download: '5000-24,999',
  },
  {
    id: 4,
    level: 'Level 4',
    royalti: '36%',
    sl: '$ 0.31',
    el: '$ 0.31',
    s2: '$ 0.31',
    e2: '$ 0.31',
    s3: '$ 0.41',
    e3: '$ 0.41',
    s4: '$ 0.41',
    e4: '$ 0.41',
    download: '25000-149,999 downloads',
  },
  {
    id: 5,
    level: 'Level 5',
    royalti: '38%',
    sl: '$ 0.33',
    el: '$ 0.33',
    s2: '$ 0.33',
    e2: '$ 0.33',
    s3: '$ 0.43',
    e3: '$ 0.43',
    s4: '$ 0.43',
    e4: '$ 0.43',
    download: '150,000+  ',
  },
];
const SellOnArtnstock_Home = () => {
  const earn = [
    {
      img: `${ArtIcon}`,
      title: 'Art',
      description:
        ' For the first year after a referred contributor signs up, you will earn 10% of the final sale price each time one of their videos is downloaded. For the first two years after',
    },
    {
      img: `${PhotosIcon}`,
      title: 'Photos',
      description:
        ' For the first year after a referred contributor signs up, you will earn 10% of the final sale price each time one of their videos is downloaded. For the first two years after',
    },
    {
      img: `${FootageIcon}`,
      title: 'Footage',
      description:
        ' For the first year after a referred contributor signs up, you will earn 10% of the final sale price each time one of their videos is downloaded. For the first two years after',
    },
    {
      img: `${MusicIcon}`,
      title: 'Music',
      description:
        ' For the first year after a referred contributor signs up, you will earn 10% of the final sale price each time one of their videos is downloaded. For the first two years after',
    },
    {
      img: `${TemplatesIcon}`,
      title: 'Templates',
      description:
        ' For the first year after a referred contributor signs up, you will earn 10% of the final sale price each time one of their videos is downloaded. For the first two years after',
    },
    {
      img: `${ProductsIcon}`,
      title: 'Products',
      description:
        ' For the first year after a referred contributor signs up, you will earn 10% of the final sale price each time one of their videos is downloaded. For the first two years after',
    },
  ];

  const [faq, setfaq] = useState([]);
  const getArtWiseFaqList = () => {
    httpClient
      .get(`/frequently_asked_master/getTypeWiseFaq/art`)
      .then((res) => {
        setfaq(res.data);
        // console.log(res);
      });
  };

  useEffect(() => {
    getArtWiseFaqList();
  }, []);

  return (
    <>
      <div
        style={{ maxHeight: '1275px' }}
        className='relative outline-none'
      >
        <img
          alt='ted'
          style={{ width: '100%', maxHeight: '1275px' }}
          src={SellBanner}
        />
        {/* <div className='absolute left-24 z-10 top-[107px] text-left'>
          <p className='text-[4.688rem] leading-[83px] font-semibold mb-[3px] text-primaryBlack'>
            Art{' '}
          </p>
          <p className='text-[2.375rem] font-semibold  leading-[40px] w-[603px] text-primaryBlack'>
            Creatively tailored art in all <br /> forms, media and
            colours{' '}
          </p>
          <button
          
            className='blackBtn my-[16px]'
          >
            Discover Collections
          </button>
          <p className='text-[11px] text-[#333333] font-Regular'>
            Copyright 2022. Artnstock Private Limited.
          </p>
          <p className='text-[11px] text-[#333333]'>
            All content included in the website (including, but not
            limited to, logos, images, <br /> photo, designs, graphics
            and text) is the property of Artnstock Pvt. Ltd.
          </p>
        </div>


        
        <div className='absolute right-24 z-10 top-[102px] text-left'>
          <img src={Offer} alt='' />
          <p className='text-[15px] font-medium leading-[130%] text-primaryBlack mt-[3px]'>
            A special welcome bonus on your purchase{' '}
          </p>
          <p className='text-sm11 text-primaryBlack mb-[8px]'>
            *Offer is available to new customers only.{' '}
          </p>
          <button className='bg-[#a4e154] text-sm12 text-primaryBlack font-semibold rounded-2xl flex items-center justify-center px-[12px] h-[28px]'>
            Shop with 20% Off
          </button>

          <div className='bg-primaryBlack w-[10px] h-[6px] rounded-2xl my-[8px]'></div>

          <img className='cursor-pointer' src={wallpaper} alt='' />
        </div> */}

        <div className='absolute left-24 z-10 top-[107px]'>
          <p className='text-[4.688rem] leading-[83px] font-semibold mb-[3px] text-primaryBlack'>
            Art {/* {data?.bannerLeftTitle} */}
          </p>
          <p className='text-[2.375rem] font-medium leading-[40px] w-[603px] text-primaryBlack'>
            {/* {addLineBreakAfterWord(
                    data?.bannerLeftDescription,
                    '~'
                  )} */}
            Creatively tailored art in all <br /> forms, media and
            colours{' '}
          </p>
          <button
            // onClick={() => {
            //   navigate('/art-list');
            // }}
            className='blackBtn my-[16px]'
          >
            Discover Collections
          </button>
          <p className='w-[300px] leading-[13px] text-primaryBlack text-sm11 font-normal'>
            {/* {data?.copyRightText} */}
            Artnstock has an live-up of{' '}
            <span className='font-medium'>Art</span> ,{' '}
            <span className='font-medium'>Music</span> and cluster of
            <span className='font-medium'>Templates</span>,{' '}
            <span className='font-medium'>Photos</span> and{' '}
            <span className='font-medium'>Footages</span>. Explore our{' '}
            <span className='font-medium'>Products</span> and
            experience our excellence in all.
          </p>
        </div>

        <div className='absolute right-24 z-10 top-[102px]'>
          <img src={percentImg} alt='' />
          <p className='text-[15px] font-medium leading-[130%] text-primaryBlack mt-[3px]'>
            {/* {data?.offerText} */}A special welcome bonus on your
            purchase{' '}
          </p>
          <p className='text-sm11 text-primaryBlack mb-[8px]'>
            {/* {data?.offerDesc} */}*Offer is available to new
            customers only.{' '}
          </p>
          <button
            // onClick={() => {
            //   navigate('/Offers');
            // }}
            className='bg-pinkColor text-sm12 text-primaryBlack font-semibold rounded-2xl flex items-center justify-center px-[12px] h-[28px]'
          >
            Shop with 20% Off
          </button>
          <div className='bg-primaryBlack w-[10px] h-[6px] rounded-2xl my-[8px]'></div>

          <img
            className='cursor-pointer'
            // onClick={() => navigate('/art-list')}
            src={wallpaper}
            alt=''
          />
        </div>
      </div>
      <div className='w-w1168 mx-auto mt-10'>
        <p className='mb-[2px] bullet'>
          <span className='cursor-pointer'>Artnstock /</span>{' '}
          <span className='text-primaryBlack'>Sell on Artnstock</span>
        </p>
        <p className='text-primaryBlack text-[38px] leading-[41px] font-medium text-center'>
          Lucrative approach with your Art, Photos, <br /> Footage,
          Music and Templates
        </p>

        <p className='text-sm14 font-normal mt-[12px] text-center text-primaryGray'>
          Avail our exclusive multiple products and sizes to fit any
          decor style or budget. Our Creative collections will
          artistically sensitise your visual projects <br />
          and would be a perfect solution for anyone who needs
          high-quality art at affordable prices.
        </p>
        <button className='blackBtn mx-auto block mt-[29px] '>
          Become a Contributor
        </button>
      </div>
      <div className='hrLine'></div>
      <div className='   w-[100%]'>
        <div className='flex flex-col mx-auto'>
          <div
            className='mx-auto'
            style={{
              width: 'fit-content',
            }}
          >
            <img src={Sell1} alt='' />
          </div>
          <div
            className='mx-auto'
            style={{
              width: 'fit-content',
            }}
          >
            <img src={Sell2} alt='' />
          </div>
        </div>

        <div
          style={{
            backgroundImage: `url(${Sell3})`,
          }}
          className='flex w-[818px] mx-[auto] h-[100%] bg-cover bg-center'
        >
          <div className='flex flex-col  w-[272px]  text-center border-r border-r-[#efefef]'>
            <p className='mx-[auto] text-[48px] font-extralight text-pinkColor leading-none'>
              1
            </p>
            <p className='text-[15px] font-medium text-center text-primaryBlack  leading-[18px]'>
              Showcase array of your
              <br />
              creative pursuits
            </p>
            <p className='text-sm12 mt-[5px] text-[#757575]  text-center '>
              Let your Imaginative
              <br />
              output be displayed on our platform and
              <br />
              be discovered as your extra earning!
            </p>
          </div>
          {/* <img src={hr} className='h-[128px]  ml-[26px]' /> */}
          <div className='flex flex-col w-[272px]  text-center border-r border-r-[#efefef]'>
            {/* <div className='flex flex-col h-[128px] w-[215px] '> */}
            <p className='mx-[auto] text-[48px] font-extralight text-orangeColor leading-none'>
              2
            </p>
            <p className='text-[15px] font-medium text-center text-primaryBlack  leading-[18px]'>
              Assorted printed products
              <br />
              line-up
            </p>
            <p className='text-sm12 mt-[5px] text-[#757575]  text-center '>
              Checkout our range of
              <br />
              home decor, life style, tech, apparel and
              <br />
              stationary products for you to select.
            </p>
            {/* </div> */}
          </div>
          {/* <img src={hr} className='h-[128px]  ml-[26px]' /> */}
          <div className='flex flex-col  w-[272px]  text-center '>
            {/* <div className='flex flex-col h-[128px] w-[220px] '> */}
            <p className='mx-[auto] text-[48px] font-extralight text-[#34deee] leading-none'>
              3
            </p>
            <p className='text-[15px] font-medium text-center text-primaryBlack  leading-[18px]'>
              Find your type like-minded
              <br />
              people
            </p>
            <p className='text-sm12 mt-[5px] text-[#757575]  text-center '>
              We allure millions of diverse
              <br />
              art-lovers from across the globe who crave genuine Art,
              Photos, and so on…
            </p>
            {/* </div> */}
          </div>
        </div>
        <p className='text-sm14 font-normal text-center text-primaryGray mt-[38px]'>
          Join us! We are a thriving artist community dedicated to
          supporting one another
          <br />
          with InVogue contributors which matches our mission
        </p>
        <p className='text-sm14 text-primaryBlack font-medium text-center'>
          “Passion is Vision”
          <span className='text-primaryGray'>.</span>
        </p>
      </div>

      {/* <div className='w-[1168px] mx-[auto]'> */}
      <div
        style={{
          width: '1168px!important',
        }}
        className='hrLine'
      ></div>
      {/* </div> */}

      <div className='w-[930px] mx-[auto]'>
        <p className='text-primaryBlack   text-heading font-medium text-center'>
          How much you will earn
        </p>
        <p className='text-center font-medium mt-[16px] mb-[8px] text-sm12'>
          On-Demand
        </p>
        <table className='w-[100%] mx-[auto] rounded-bl-[10px] max-w-[930px]  border-separate border-spacing-0'>
          <tr
            // height='29'
            className=' border text-[12px]   font-medium text-primaryBlack'
          >
            <td
              rowSpan='2'
              className='w-[83px] border border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] rounded-tl-[10px]   text-center'
            >
              Levels
            </td>
            <td
              rowSpan='2'
              className=' w-[72px] border-t border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'
            >
              Royalties
            </td>

            <td
              colspan='2'
              className='w-[143px] border-t h-[30px] border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b leading-[1.2] text-center'
            >
              Photos
            </td>
            <td
              colspan='2'
              className='w-[143px] border-t border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b leading-[1.2] text-center'
            >
              Footage
            </td>

            <td
              colspan='2'
              className='w-[143px] border-t border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b leading-[1.2] text-center'
            >
              Music
            </td>
            <td
              colspan='2'
              className='w-[143px] border-t border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b leading-[1.2] text-center'
            >
              Templates
            </td>

            <td
              rowSpan='2'
              className='w-[203px] border-t border-[#d6d6d6] bg-[#f3f3f3]  border-r border-b rounded-tr-[10px] text-center '
            >
              Downloads
            </td>
          </tr>

          <tr className='text-[11px] leading-[12px] text-primaryBlack'>
            <td className='w-[77px] h-[29px] border-r border-b border-[#d6d6d6] border-r-[1px] bg-[#f3f3f3]   text-center '>
              Standard License
            </td>
            <td className='w-[77px] border-r border-b border-[#d6d6d6] border-r-[2px]  bg-[#f3f3f3]  text-center '>
              Enhanced License
            </td>
            <td className='w-[77px] border-r border-b border-[#d6d6d6] border-r-[1px]  bg-[#f3f3f3] text-Black  text-center '>
              Standard License
            </td>
            <td className='w-[77px] border-r border-b border-[#d6d6d6] border-r-[2px]  bg-[#f3f3f3]   text-center '>
              Enhanced License
            </td>
            <td className='w-[77px] border-r border-b border-[#d6d6d6] border-r-[1px]  bg-[#f3f3f3] t  text-center '>
              Standard License
            </td>
            <td className='w-[77px] border-r border-b border-[#d6d6d6] border-r-[2px]  bg-[#f3f3f3]   text-center '>
              Enhanced License
            </td>
            <td className='w-[77px] border-r border-b border-[#d6d6d6] border-r-[1px]  bg-[#f3f3f3]   text-center '>
              Standard License
            </td>
            <td className='w-[77px] border-r border-b border-[#d6d6d6] border-r-[2px]  bg-[#f3f3f3]   text-center '>
              Enhanced License
            </td>
          </tr>
          {SellOnartnstockdata.map((item, index) => (
            <tr
              key={item.id}
              className='text-[12px] text-primaryGray '
            >
              <td
                className={`w-[81px] ${
                  index === SellOnartnstockdata.length - 1
                    ? 'rounded-bl-[10px]'
                    : ''
                }  border-l border-r border-b border-[#d6d6d6] border-r text-primaryBlack font-medium border-r-[2px] h-[26px] border-r-[#dddddd] bg-[#f3f3f3] r text-left pl-[8px] pl-[8px]`}
              >
                {item.level}
              </td>
              <td className='w-[70px]  border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd]  border-r border-b leading-[1.2] text-left pl-[8px]'>
                {item.royalti}
              </td>
              <td className='w-[70px]  border-[#d6d6d6] border-r border-r-[1px] border-r-[#dddddd]  border-r border-b leading-[1.2] text-right pr-[8px]'>
                {item.sl}
              </td>
              <td className='w-[70px]  border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd]  border-r border-b leading-[1.2] text-right pr-[8px]'>
                {item.el}
              </td>
              <td className='w-[70px]  border-[#d6d6d6] border-r border-r-[1px] border-r-[#dddddd]  border-r border-b leading-[1.2] text-right pr-[8px]'>
                {item.s2}
              </td>
              <td className='w-[70px]  border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd]  border-r border-b leading-[1.2] text-right pr-[8px]'>
                {item.e2}
              </td>
              <td className='w-[70px]  border-[#d6d6d6] border-r border-r-[1px] border-r-[#dddddd]  border-r border-b leading-[1.2] text-right pr-[8px]'>
                {item.s3}
              </td>
              <td className='w-[70px]  border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd]  border-r border-b leading-[1.2] text-right pr-[8px]'>
                {item.e3}
              </td>
              <td className='w-[70px]  border-[#d6d6d6] border-r border-r-[1px] border-r-[#dddddd]  border-r border-b leading-[1.2] text-right pr-[8px]'>
                {item.s4}
              </td>
              <td className='w-[70px]  border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd]  border-r border-b leading-[1.2] text-right pr-[8px]'>
                {item.e4}
              </td>
              <td
                className={`w-[70px]  ${
                  index === SellOnartnstockdata.length - 1
                    ? 'rounded-br-[10px]'
                    : ''
                }   border-[#d6d6d6] border-r border-r-[1px] border-r-[#dddddd]    border-r border-b leading-[1.2] text-left pl-[8px]`}
              >
                {item.download}
              </td>
            </tr>
          ))}
        </table>

        <p className='text-center font-medium mt-[32px] mb-[8px] text-sm12'>
          Subscription
        </p>
        <table className='w-[100%] mx-[auto] rounded-bl-[10px] max-w-[930px]  border-separate border-spacing-0'>
          <tr
            // height='29'
            className=' border text-[12px]   font-medium text-primaryBlack'
          >
            <td
              rowSpan='2'
              className='w-[83px] border border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] rounded-tl-[10px]   text-center'
            >
              Levels
            </td>
            <td
              rowSpan='2'
              className=' w-[72px] border-t border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'
            >
              Royalties
            </td>

            <td
              colspan='2'
              className='w-[143px] border-t h-[30px] border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b leading-[1.2] text-center'
            >
              Photos
            </td>
            <td
              colspan='2'
              className='w-[143px] border-t border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b leading-[1.2] text-center'
            >
              Footage
            </td>

            <td
              colspan='2'
              className='w-[143px] border-t border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b leading-[1.2] text-center'
            >
              Music
            </td>
            <td
              colspan='2'
              className='w-[143px] border-t border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b leading-[1.2] text-center'
            >
              Templates
            </td>

            <td
              rowSpan='2'
              className='w-[203px] border-t border-[#d6d6d6] bg-[#f3f3f3]  border-r border-b rounded-tr-[10px] text-center '
            >
              Downloads
            </td>
          </tr>

          <tr className='text-[11px] leading-[12px] text-primaryBlack'>
            <td className='w-[77px] h-[29px] border-r border-b border-[#d6d6d6] border-r-[1px] bg-[#f3f3f3]   text-center '>
              Standard License
            </td>
            <td className='w-[77px] border-r border-b border-[#d6d6d6] border-r-[2px]  bg-[#f3f3f3]  text-center '>
              Enhanced License
            </td>
            <td className='w-[77px] border-r border-b border-[#d6d6d6] border-r-[1px]  bg-[#f3f3f3] text-Black  text-center '>
              Standard License
            </td>
            <td className='w-[77px] border-r border-b border-[#d6d6d6] border-r-[2px]  bg-[#f3f3f3]   text-center '>
              Enhanced License
            </td>
            <td className='w-[77px] border-r border-b border-[#d6d6d6] border-r-[1px]  bg-[#f3f3f3] t  text-center '>
              Standard License
            </td>
            <td className='w-[77px] border-r border-b border-[#d6d6d6] border-r-[2px]  bg-[#f3f3f3]   text-center '>
              Enhanced License
            </td>
            <td className='w-[77px] border-r border-b border-[#d6d6d6] border-r-[1px]  bg-[#f3f3f3]   text-center '>
              Standard License
            </td>
            <td className='w-[77px] border-r border-b border-[#d6d6d6] border-r-[2px]  bg-[#f3f3f3]   text-center '>
              Enhanced License
            </td>
          </tr>
          {SellOnartnstockdata.map((item, index) => (
            <tr
              key={item.id}
              className='text-[12px] text-primaryGray '
            >
              <td
                className={`w-[81px] ${
                  index === SellOnartnstockdata.length - 1
                    ? 'rounded-bl-[10px]'
                    : ''
                }  border-l border-r border-b border-[#d6d6d6] border-r text-primaryBlack font-medium border-r-[2px] h-[26px] border-r-[#dddddd] bg-[#f3f3f3] r text-left pl-[8px] pl-[8px]`}
              >
                {item.level}
              </td>
              <td className='w-[70px]  border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd]  border-r border-b leading-[1.2] text-left pl-[8px]'>
                {item.royalti}
              </td>
              <td className='w-[70px]  border-[#d6d6d6] border-r border-r-[1px] border-r-[#dddddd]  border-r border-b leading-[1.2] text-right pr-[8px]'>
                {item.sl}
              </td>
              <td className='w-[70px]  border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd]  border-r border-b leading-[1.2] text-right pr-[8px]'>
                {item.el}
              </td>
              <td className='w-[70px]  border-[#d6d6d6] border-r border-r-[1px] border-r-[#dddddd]  border-r border-b leading-[1.2] text-right pr-[8px]'>
                {item.s2}
              </td>
              <td className='w-[70px]  border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd]  border-r border-b leading-[1.2] text-right pr-[8px]'>
                {item.e2}
              </td>
              <td className='w-[70px]  border-[#d6d6d6] border-r border-r-[1px] border-r-[#dddddd]  border-r border-b leading-[1.2] text-right pr-[8px]'>
                {item.s3}
              </td>
              <td className='w-[70px]  border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd]  border-r border-b leading-[1.2] text-right pr-[8px]'>
                {item.e3}
              </td>
              <td className='w-[70px]  border-[#d6d6d6] border-r border-r-[1px] border-r-[#dddddd]  border-r border-b leading-[1.2] text-right pr-[8px]'>
                {item.s4}
              </td>
              <td className='w-[70px]  border-[#d6d6d6] border-r border-r-[2px] border-r-[#dddddd]  border-r border-b leading-[1.2] text-right pr-[8px]'>
                {item.e4}
              </td>
              <td
                className={`w-[70px]  ${
                  index === SellOnartnstockdata.length - 1
                    ? 'rounded-br-[10px]'
                    : ''
                }   border-[#d6d6d6] border-r border-r-[1px] border-r-[#dddddd]    border-r border-b leading-[1.2] text-left pl-[8px]`}
              >
                {item.download}
              </td>
            </tr>
          ))}
        </table>

        <div className='flex justify-center text-sm12 text-primaryGray mt-[19px] '>
          <div className='pl-[17px]'>
            <ul
              style={{
                listStyleType: 'disc',
              }}
            >
              <li className=' '>
                New levels are based on the number of times your
                content is licensed.
              </li>
              <li>
                Contributors reset to level 1 for Photos, Footage,
                Music and templates every year on January 1st.
              </li>
              <li>
                Each file downloaded using On-Demand Downloads counts
                as 1 download. A file purchased via subscription plan
                counts as one-third download; i.e., 3 files downloaded
                via subscription plan count as 1 download.
              </li>
              <li>
                If a Photos, Footage, Music and templates is purchased
                with On-Demand downloads under a Standard or Enhanced
                License, your royalty rate depends solely upon your
                Contributor's Level.
              </li>
              <li>
                Royalties for files downloaded under the Program’s are
                aggregated and paid start of the month.
              </li>
              <li>
                Royalty calculations are based on the actual amount
                paid by the buyer not on a "standard" value of a file.
                If a Buyer gets a discount on the purchase, the
                royalties received by the Contributor for the file
                will be based on the amount paid by the Buyer,
                incorporating the discount, and not the “standard”
                value of the file.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className='hrLine'></div>

      <div className='w-w1168 mx-auto'>
        <p className='text-primaryBlack text-[38px] font-medium text-center'>
          Referral Program
        </p>
        <div className='flex gap-[30px] mt-3'>
          <div className='w-[570px] h-[530px] bg-[#f5f5f7] rounded-[32px]'></div>
          <div className=' flex justify-center'>
            <div className='flex flex-col gap-[16px]'>
              {earn.map((item) => {
                return (
                  <div className='flex gap-[17px] w-[570px] '>
                    <img src={item.img} alt='' />
                    <p>
                      <p className='text-[16px] text-bold'>
                        {item.title}
                      </p>
                      <p className='text-[13px] text-[#757575] leading-[1.3]'>
                        {item.description}
                      </p>
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className='hrLine'></div>

      <div className='w-w1168 mx-auto'>
        <p className='text-primaryBlack text-heading font-medium text-center'>
          How it works
        </p>
        <p className='text-sm14 font-normal mt-[12px] text-center text-primaryGray'>
          To become a Artnstock contributor, you need to sign up and
          submit images for review.
          {/* <br /> */}
          Once approved, you can start uploading files to your
          portfolio right away.
          <br /> Be sure to add the right keywords so that
          {/* <br /> */}
          your images are discoverable on our website.
        </p>
        <div className='flex gap-4 justify-center mt-[24px] w-w1168'>
          <div className='border border-[#EFEFEF] w-[280px] h-[280px]  rounded-2xl flex items-center justify-center'>
            <div className='text-center'>
              {/* <img
                src={ICreate}
                alt=''
                className='w-[72px] mx-auto'
              /> */}

              <div className='h-[72px] flex justify-center items-center'>
                <ICreate className='mx-auto' />
              </div>

              <div className='mt-[14px]'>
                <p className='font-medium text-[16px] leading-[22px]'>
                  Create
                </p>
                <p className='text-[12px] text-[#757575] mt-[2px] text-sm12 leading-[15px]'>
                  Produce exclusive Art, Photos,
                  <br /> Footage, Music and Templates for
                  <br /> customers to buy
                </p>
              </div>
            </div>
          </div>
          <div className='border border-[#EFEFEF] w-[280px] h-[280px]   rounded-2xl flex items-center justify-center'>
            <div className='text-center'>
              {/* <img
                src={IUpload}
                alt=''
                className='w-[72px] mx-auto'
              /> */}
              <div className='h-[72px] flex justify-center items-center'>
                <IUpload className='mx-auto' />
              </div>

              <div className='mt-4'>
                <p className='font-medium text-[16px] leading-[22px]'>
                  Submit
                </p>
                <p className='text-[12px] text-[#757575] mt-[2px] text-sm12 leading-[15px]'>
                  Synchronise your content
                  <br /> with our easy-to-use platform,
                  <br /> and spike your success
                </p>
              </div>
            </div>
          </div>
          <div className='border border-[#EFEFEF] w-[280px] h-[280px]   rounded-2xl flex items-center justify-center'>
            <div className='text-center'>
              {/* <img src={IEarn} alt='' className='w-[72px] mx-auto' /> */}
              <div className='h-[72px] flex justify-center items-center'>
                <IEarn className='mx-auto' />
              </div>

              <div className='mt-4'>
                <p className='font-medium text-[16px] leading-[22px]'>
                  Get Paid
                </p>
                <p className='text-[12px] text-[#757575] mt-[2px] text-sm12 leading-[15px]'>
                  Be profitable every time
                  <br /> your content is downloaded by
                  <br /> our customers
                </p>
              </div>
            </div>
          </div>
          <div className='border border-[#EFEFEF] w-[280px] h-[280px]  rounded-2xl flex items-center justify-center '>
            <div className='text-center'>
              {/* <img src={IRefer} alt='' className='w-[72px] mx-auto' /> */}

              <div className='h-[72px] flex justify-center items-center'>
                <IRefer className='mx-auto' />
              </div>

              <div className='mt-4'>
                <p className='font-medium text-[16px] leading-[22px]'>
                  Refer
                </p>
                <p className='text-[12px] text-[#757575] mt-[2px] text-sm12 leading-[15px]'>
                  Refer Customers or Contributors <br />
                  to Artnstock, and earn benefits ensuring your
                  <br />
                  efforts are appreciated.
                </p>
              </div>
            </div>
          </div>
        </div>

        <button className='blackBtn mx-auto block mt-[32px] '>
          Get Started
        </button>
      </div>

      <div className='hrLine'></div>
      {/* <Faqmaping /> */}

      <ArtHomeFaq type={faq} />
      <Certificate />
      <Footer />
    </>
  );
};

export default SellOnArtnstock_Home;
