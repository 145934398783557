import dropdown from '../../../assets/images/socials/dropdown.png';
import view from '../../../assets/images/contributor/view.png';
import edit from '../../../assets/images/contributor/edit.png';
import deletee from '../../../assets/images/contributor/delete.png';
import searchIcon from '../../../assets/images/Icons/searchDarkIcon.svg';

import { ReactComponent as SearchIcon } from '../../../assets/images/Icons/searchDarkIcon.svg';
import backArrow from '../../../assets/images/contributor/backArrow.png';
import nextArrow from '../../../assets/images/contributor/nextArrow.png';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setpath2 } from '../../../store/contriPathSlice';
import { ReactComponent as Deleteicon } from '../../../assets/images/contributor/svg/Icon_DeleteFiles.svg';

import { ReactComponent as ViewIcon } from '../../../assets/images/contributor/svg/view.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/contributor/svg/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/contributor/svg/delete.svg';

import { ReactComponent as DropArrow } from '../../../assets/images/Icons/Down arrow.svg';
import clip from '../../../assets/images/contributor/clip.png';
import ReleaseForm from './ReleaseForm';

import { ReactComponent as DropArrowSmall } from '../../../assets/images/contributor/svg/Icon_DownArrow_Small.svg';
import { useSelector } from 'react-redux';

import { useRef } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

const images = [
  // {
  //   id: 'ANS987654321',
  //   image:
  //     'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
  //   uploaded: '26 Jan, 2022',
  //   title: 'Printable Wall Art Home Decor',
  //   category: 'Abstract',
  //   Usage: 'Commercial',
  //   type: 'Property Release',
  //   form: 'Blue sky and nature Lorem Ipsum dolor amet',
  // },
  // {
  //   id: 'ANS987654321',
  //   image:
  //     'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/hgk5jsxbuqdtd2qeck1l',
  //   uploaded: '26 Jan, 2022',
  //   title: 'Printable Wall Art Home Decor',
  //   category: 'Abstract',
  //   Usage: 'Commercial',
  //   type: 'Property Release',
  //   form: 'Blue sky and nature Lorem Ipsum dolor amet',
  // },
  // {
  //   id: 'ANS987654321',
  //   image:
  //     'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
  //   uploaded: '26 Jan, 2022',
  //   title: 'Printable Wall Art Home Decor',
  //   category: 'Abstract',
  //   Usage: 'Commercial',
  //   type: 'Property Release',
  //   form: 'Blue sky and nature Lorem Ipsum dolor amet',
  // },
  // {
  //   id: 'ANS987654321',
  //   image:
  //     'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/hgk5jsxbuqdtd2qeck1l',
  //   uploaded: '26 Jan, 2022',
  //   title: 'Printable Wall Art Home Decor',
  //   category: 'Abstract',
  //   Usage: 'Commercial',
  //   type: 'Property Release',
  //   form: 'Blue sky and nature Lorem Ipsum dolor amet',
  // },
  // {
  //   id: 'ANS987654321',
  //   image:
  //     'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
  //   uploaded: '26 Jan, 2022',
  //   title: 'Printable Wall Art Home Decor',
  //   category: 'Abstract',
  //   Usage: 'Commercial',
  //   type: 'Property Release',
  //   form: 'Blue sky and nature Lorem Ipsum dolor amet',
  // },
  // {
  //   id: 'ANS987654321',
  //   image:
  //     'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/hgk5jsxbuqdtd2qeck1l',
  //   uploaded: '26 Jan, 2022',
  //   title: 'Printable Wall Art Home Decor',
  //   category: 'Abstract',
  //   Usage: 'Commercial',
  //   type: 'Property Release',
  //   form: 'Blue sky and nature Lorem Ipsum dolor amet',
  // },
  // {
  //   id: 'ANS987654321',
  //   image:
  //     'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
  //   uploaded: '26 Jan, 2022',
  //   title: 'Printable Wall Art Home Decor',
  //   category: 'Abstract',
  //   Usage: 'Commercial',
  //   type: 'Property Release',
  //   form: 'Blue sky and nature Lorem Ipsum dolor amet',
  // },
  // {
  //   id: 'ANS987654321',
  //   image:
  //     'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/hgk5jsxbuqdtd2qeck1l',
  //   uploaded: '26 Jan, 2022',
  //   title: 'Printable Wall Art Home Decor',
  //   category: 'Abstract',
  //   Usage: 'Commercial',
  //   type: 'Property Release',
  //   form: 'Blue sky and nature Lorem Ipsum dolor amet',
  // },
  // {
  //   id: 'ANS987654321',
  //   image:
  //     'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
  //   uploaded: '26 Jan, 2022',
  //   title: 'Printable Wall Art Home Decor',
  //   category: 'Abstract',
  //   Usage: 'Commercial',
  //   type: 'Property Release',
  //   form: 'Blue sky and nature Lorem Ipsum dolor amet',
  // },
  // {
  //   id: 'ANS987654321',
  //   image:
  //     'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/hgk5jsxbuqdtd2qeck1l',
  //   uploaded: '26 Jan, 2022',
  //   title: 'Printable Wall Art Home Decor',
  //   category: 'Abstract',
  //   Usage: 'Commercial',
  //   type: 'Property Release',
  //   form: 'Blue sky and nature Lorem Ipsum dolor amet',
  // },
  // {
  //   id: 'ANS987654321',
  //   image:
  //     'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
  //   uploaded: '26 Jan, 2022',
  //   title: 'Printable Wall Art Home Decor',
  //   category: 'Abstract',
  //   Usage: 'Commercial',
  //   type: 'Property Release',
  //   form: 'Blue sky and nature Lorem Ipsum dolor amet',
  // },
  // {
  //   id: 'ANS987654321',
  //   image:
  //     'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/hgk5jsxbuqdtd2qeck1l',
  //   uploaded: '26 Jan, 2022',
  //   title: 'Printable Wall Art Home Decor',
  //   category: 'Abstract',
  //   Usage: 'Commercial',
  //   type: 'Property Release',
  //   form: 'Blue sky and nature Lorem Ipsum dolor amet',
  // },
];

const PropertyRelease = () => {
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);

  //   paginationn **dont change the sequence of the code below** else will give undefined error
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const dataToRender = images || [];

  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  const currentData = dataToRender.slice(start, end);

  const totalPages = Math.ceil(dataToRender.length / itemsPerPage);

  const handlePrevPage = () => {
    // console.log(currentPage);
    if (currentPage > 1 && currentPage <= totalPages) {
      console.log('i am running');
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    // console.log(currentPage);
    if (currentPage >= 1 && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setpath2('/ Art Release'));
  }, []);

  // const [isOpenSortBy, setIsOpenSortBy] = useState(false);

  const artReleasesCount = useSelector(
    (state) => state.contriCounter.artReleasesCount
  );
  const propertyReleasesCount = useSelector(
    (state) => state.contriCounter.propertyReleasesCount
  );
  const modelReleasesCount = useSelector(
    (state) => state.contriCounter.modelReleasesCount
  );
  const minorReleasesCount = useSelector(
    (state) => state.contriCounter.minorReleasesCount
  );

  const handleClickOutsideSortBy = () => {
    setIsOpenSortBy(false);
  };

  const SortByClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideSortBy,
  });

  return (
    <div>
      <div className='flex justify-center mb-[16px]'>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex border-t border-b border-t-[#efefef] border-b-[#efefef] '
        >
          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-pinkColor opacity-[20%]'>
              {artReleasesCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                Art(s) <br /> Release(s) <br /> uploaded
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-[#f5721a]   pl-[5px]'>
              {propertyReleasesCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className=' contributorUploadtext pr-[8px]'>
                Property <br /> Release(s) <br /> uploaded
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount pl-[5px] opacity-[20%]  text-[#a9af20]'>
              {modelReleasesCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                Model <br /> Release(s) <br /> uploaded
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount pl-[5px] text-[#e7233b] opacity-[20%]'>
              {minorReleasesCount}
            </span>
            <div className='opacity-[40%] h-[40px] flex items-center'>
              <p className='contributorUploadtext '>
                Minor <br /> Release(s) <br /> uploaded
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='contributorTitleStyling'>
        <div className='w-[1168px] flex justify-between'>
          <div className='flex flex-col gap-[2px] max-w-[320px] w-[100%]'>
            <div className='flex gap-[8px] items-baseline'>
              <p className='text-[24px] font-medium leading-[1]  text-primaryBlack'>
                Property Releases{' '}
              </p>
              {/* <p className='text-pinkColor text-[11px] leading-[13px] '>
              0/15 File(s) uploaded
            </p> */}
            </div>
            <p className='text-sm11 text-primaryGray  mt-[1px]'>
              Refer a new customer to earn 30% of their
              <br /> first payment
              <span className='text-orangeColor'> Learn more</span>
            </p>
          </div>

          {/* <div className='w-[3.125rem]  text-center flex flex-col gap-[8px]'>
          <div>
            <Deleteicon className='mx-[auto] w-[16px]' />
          </div>

          <p className='text-[0.688rem] text-primaryGray leading-[1]'>
            Delete Release(s)
          </p>
        </div> */}

          <div className=' leading-[1] flex gap-[16px]'>
            <div>
              <div ref={SortByClickOutside} className='abc w-[164px]'>
                <div
                  onClick={() => {
                    setIsOpenSortBy(!isOpenSortBy);
                  }}
                  className={`${
                    isOpenSortBy
                      ? 'dropdownTopButtonOpenedStyle  rounded-tr-[16px] rounded-tl-[16px]'
                      : 'dropdownTopButtonClosedStyle'
                  } dropdownTopButtonCommonStyle h-[40px]`}
                >
                  <div className='flex flex-col h-[100%]'>
                    <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                      Sort by
                    </p>
                    <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                      {/* {releaseObj?.sortType} */} Newest
                    </p>
                  </div>

                  <DropArrowSmall
                    className={`${
                      isOpenSortBy ? 'transform rotate-180' : ''
                    }`}
                    style={{
                      fill: '#878787',
                    }}
                  />
                </div>
                {isOpenSortBy && (
                  <div className='relative'>
                    <div
                      style={{
                        boxShadow:
                          'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                      }}
                      className={`dropdownTopDivOfULCommonStyle `}
                    >
                      <ul
                        className={`dropdownULCommonStyle rounded-bl-[16px] rounded-br-[16px] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                      >
                        <li
                          onClick={() => {
                            // setTypeFunc('Newest');
                            handleClickOutsideSortBy();
                          }}
                          className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                        >
                          Newest
                        </li>
                        <li
                          onClick={() => {
                            // setTypeFunc('Newest');
                            handleClickOutsideSortBy();
                          }}
                          className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                        >
                          Oldest
                        </li>
                        <li
                          onClick={() => {
                            // setTypeFunc('Newest');
                            handleClickOutsideSortBy();
                          }}
                          className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                        >
                          Exclusive
                        </li>
                        <li
                          onClick={() => {
                            // setTypeFunc('Newest');
                            handleClickOutsideSortBy();
                          }}
                          className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                        >
                          Featured
                        </li>
                        <li
                          onClick={() => {
                            // setTypeFunc('Newest');
                            handleClickOutsideSortBy();
                          }}
                          className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                        >
                          A-Z
                        </li>
                        <li
                          onClick={() => {
                            // setTypeFunc('Newest');
                            handleClickOutsideSortBy();
                          }}
                          className={`dropdownLiCommonStyle    hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[39px] pb-[4px]`}
                        >
                          Z-A
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <ReleaseForm />
          </div>
        </div>
      </div>
      {images?.length > 0 ? (
        <>
          <div className='w-full'>
            <div className='mTopUpload flex items-center w-[1168px] mx-[auto]'>
              <input
                className='bg-[#EEEEEE] flex-1 outline-none h-[44px] px-5  text-primaryGray placeholder:text-placeholderColor placeholder:font-medium placeholder:text-[14px] rounded-tl-3xl rounded-bl-3xl  border-[#DADADA]'
                type='text'
                placeholder='Search by release name'
              />

              <button className=' rounded-tr-3xl h-[44px] flex items-center rounded-br-3xl border-l-[2px] border-l-[#DADADA] bg-[#e6e6e6] pl-[8px] pr-[19px] text-primaryGray text-sm14 font-medium'>
                <SearchIcon className='inline-block mr-2 fill-[#888888]' />

                <span className='relative top-[1px]'>Search</span>
              </button>
            </div>
          </div>

          <div className='w-full'>
            <table className='table mt-[16px] w-[1168px] mx-[auto] rounded-[10px] border-separate border-spacing-0'>
              <tr>
                <td className='w-[396px] h-[35px] border border-b-[2px] border-[#dddddd] rounded-tl-[10px] bg-[#ececec] text-primaryBlack text-[15px] font-medium text-start pl-[8px]'>
                  Items Attached with Release
                </td>
                <td className='w-[141px] border-t border-r border-b  border-b-[2px] text-primaryBlack border-[#dddddd] bg-[#ececec] text-[15px] font-medium text-start  pl-[10px]'>
                  Release Type
                </td>
                <td className='w-[396px] border-t border-r border-b  border-b-[2px] border-[#dddddd] bg-[#ececec] text-primaryBlack text-[15px] font-medium text-start  pl-[10px]'>
                  Release Name
                </td>
                <td className='w-[151px] border-t border-r border-b  border-b-[2px] text-primaryBlack border-[#dddddd] bg-[#ececec] text-[15px] font-medium text-start  pl-[10px]'>
                  Date Uploaded
                </td>
                <th className='border-t border-r border-b rounded-tr-[10px]  border-b-[2px] text-primaryBlack border-[#dddddd] bg-[#ececec] text-[15px] font-medium text-start pl-[10px]'></th>
              </tr>

              <tbody>
                {currentData.map((image, index) => (
                  <tr className=''>
                    <td
                      className={`border-l border-b border-[#dddddd] border-r text-primaryBlack font-medium  pl-[8px] flex items-center h-[81px] flex gap-[8px]
        ${
          index === currentData.length - 1 ? 'rounded-bl-[10px]' : ''
        }`}
                    >
                      <div
                        className='w-[64px] relative h-[64px] rounded-[10px] bg-cover bg-center'
                        style={{
                          backgroundImage: `url(${image.image})`,
                        }}
                      ></div>

                      <div className='h-[64px]'>
                        <div>
                          <p className='text-sm11 text-primaryGray font-medium'>
                            {image.title}
                          </p>
                        </div>
                        <div className='text-primaryGray text-sm11'>
                          <div className='flex'>
                            <p className=' font-medium'>Image ID:</p>
                            <p>{image.id}</p>
                          </div>

                          <div className='flex'>
                            <p className=' font-medium'>
                              Image Category:
                            </p>
                            <p>{image.category}</p>
                          </div>
                          <div className='flex '>
                            <p className=' font-medium'>
                              Image Usage:
                            </p>
                            <p>{image.Usage}</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className='border-b border-[#dddddd] border-r text-[13px]  leading-[1.3] text-primaryGray pl-[10px]'>
                      {image.type}
                    </td>
                    <td className='border-b border-[#dddddd] border-r text-[13px]  leading-[1.3] text-primaryGray pl-[10px]'>
                      {image.form}
                    </td>
                    <td className='border-b border-[#dddddd] border-r text-[13px] leading-[1.3] text-primaryGray pl-[10px]'>
                      {image.uploaded}
                    </td>
                    <td
                      className={`text-sm12 text-primaryGray border-b border-[#dddddd] border-r 
        ${index === currentData.length - 1 ? 'rounded-br-[10px]' : ''}
        `}
                    >
                      <div className='flex gap-[12px] justify-center'>
                        <div className=''>
                          <ViewIcon />
                        </div>
                        <div className='opacity-[40%]'>
                          <EditIcon className='fill-[#888888]' />
                        </div>
                        <div className='opacity-[40%]'>
                          <DeleteIcon className='fill-[#888888]' />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className='flex justify-center'>
            <div className='flex flex-col justify-center'>
              <div className='flex text-[13px] text-primaryGray mt-[32px] gap-[6px]'>
                <p className='my-[auto]'>Page</p>
                <div className='flex items-center w-[88px] h-[30px]'>
                  <div
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-l-[15px] cursor-pointer'
                  >
                    <img src={backArrow} alt='' />
                  </div>
                  <div className='w-[29.33px] h-[30px] flex justify-center items-center border-t border-b border-[#e8e8e8]'>
                    <span>{currentPage}</span>
                  </div>
                  <div
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-r-[15px] cursor-pointer'
                  >
                    <img src={nextArrow} alt='' />
                  </div>
                </div>
                <p className='my-[auto]'>of {totalPages} &nbsp;</p>
              </div>
              <div className='flex justify-center'>
                <button className=' mt-[12px] blackBtn w-[88px]'>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='text-center pt-[8px]'>
            <p className='text-pinkColor text-[16px]'>
              No release files available
            </p>
            <p className='text-primaryGray text-sm12'>
              Currently, there are no release files available. Upload
              files <br /> along with their corresponding releases.
            </p>
            {/* <button
      onClick={() => {
        dispatch(setNestedTabValueUpload('2.1'));
      }}
      className='blackBtn mt-[20px] '
    >
      Upload Files
    </button> */}
          </div>
        </>
      )}
    </div>
  );
};

export default PropertyRelease;
