import orderSummary from '../../assets/images/ShoppingCart/orderSummary.png';
import Paymentmethods from '../../assets/images/ShoppingCart/PaymentMethods.png';
// import Checkmark from '../../assets/images/ShoppingCart/Checkmark.png';
import Rounded_Rectangle from '../../assets/images/ShoppingCart/Rounded_Rectangle.png';
import leftarrow from '../../assets/images/ShoppingCart/leftarrow.png';
import wishlist1 from '../../assets/images/wishlist/wishlist1.png';
import wishlist2 from '../../assets/images/wishlist/wishlist2.png';
import wishlist3 from '../../assets/images/wishlist/wishlist3.png';
import { httpClient } from '../../axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { wishlistSliceAction } from '../../store/wishlistSlice';
import Footer from '../footer/Footer';
import Certificate from '../contributor/contri-components/Certificate';
import Checkmark from '../../assets/images/ShoppingCart/greenTickMark.svg';
import { ReactComponent as Exclusive_Icon16 } from '../../assets/images/contributor/add_details/Icon_Exclusive_16x16.svg';
import NoItemsText from '../contributor/contri-components/NoItemsText';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
// import { ReactComponent as Exclusive_Icon16 } from '../../assets/images/contributor/add_details/Icon_Exclusive_16x16.svg';

const StyledPopupDelete = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 32px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: fit-content;
  }
`;

const Wishlist2 = () => {
  const [popup, setPopup] = useState(false);

  const [wishlist, setwishlist] = useState();

  const userId = useSelector((state) => state.auth.userId);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    getAllWishlistByUserId();
  }, []);

  const getAllWishlistByUserId = async () => {
    try {
      const res = await httpClient.get(
        `/wishlist_master/getByUserIdList/${userId}`
      );
      setwishlist(res.data);
      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [deletewishid, setdeletewishid] = useState('');

  const wishlistDelete = async (wishlistId) => {
    if (deletewishid !== '') {
      try {
        const res = await httpClient.delete(
          `/wishlist_master/delete/${wishlistId}`
        );
        console.log(res.data);
        setdeletewishid('');
        getAllWishlistByUserId();
        getWishlistCount();
      } catch (error) {
        console.error(error);
        setdeletewishid('');
      }
    }
  };

  const getWishlistCount = async () => {
    try {
      const res = await httpClient.get(
        `/wishlist_master/getUserIdWiseWishListCount /${userId}`
      );
      console.log(res.data);
      dispatch(wishlistSliceAction.setWishlistCount(res.data));
      // setwishListCount(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* test */}
      <StyledPopupDelete
        open={popup}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setPopup(false);
          document.body.classList.remove('body-no-scroll');
          // setOpenOnMountPop2(true);
        }}
      >
        <p className='text-[16px] text-primaryBlack leading-[18px] font-medium text-center'>
          Remove Item
        </p>
        <p className='text-sm12 text-primaryGray font-medium text-center mt-[8px]'>
          Are you sure you want to remove this item <br /> from your
          wishlist?
        </p>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex gap-[4px] mx-[auto]  mt-[8px]'
        >
          <button
            onClick={() => {
              wishlistDelete(deletewishid);
              setPopup(false);
            }}
            className='gray30HButton'
          >
            Remove
          </button>
          <button
            onClick={() => {
              setPopup(false);
            }}
            className='gray30HBorderButton'
          >
            Cancel
          </button>
        </div>
      </StyledPopupDelete>
      {/* <div className='w-[100%] fixed'>
        {popup === true ? (
          <div className='bg-[#ffffff] w-[345px] py-8 px-[50px] mx-[auto] rounded-2xl text-center shadow-dropShadow'>
            <p className='text-[#757575] text-[13px] leading-[1.2]'>
              {' '}
              Are you sure you want to remove this item <br /> from
              your wishlist?
            </p>
            <div className='flex gap-[10px] mt-5 justify-center'>
              <button
                onClick={() => {
                  wishlistDelete(deletewishid);
                  setPopup(false);
                }}
                className='blackBtn'
              >
                Yes
              </button>
              <button
                onClick={() => {
                  setPopup(false);
                }}
                className='blackBtn bg-[#ffffff] text-[#333333] border border-[#333333] hover:bg-[#ffffff]'
              >
                No
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div> */}
      {/* test */}
      <div className='mt-[22px]'>
        {/* <p className='mb-[3px] bullet'>
                  Artnstock / My Wishlist
                </p> */}
        <p className='text-primaryBlack text-heading font-medium text-center'>
          My Wishlist
        </p>
      </div>

      {wishlist?.length > 0 ? (
        <div className=''>
          <div className='wrapper justify-center flex '>
            <main>
              <div className='flex flex-wrap gap-[28px] mt-[32px] w-[100%] max-w-[1168px] mb-[128px]'>
                {wishlist?.map((item) => {
                  return (
                    <div
                      className='w-[570px]
                 rounded-[40px] gap-[16px] flex justify-between mb-[20px]'
                    >
                      {item.artMaster !== null ? (
                        <div
                          onClick={() =>
                            navigate(
                              `/art/art-details/${item?.artMaster?.artId}`
                            )
                          }
                          style={{
                            backgroundImage: `url(${item?.artMaster?.imageMaster?.imageOrientation?.squareUrl})`,
                          }}
                          className='w-[224px] cursor-pointer relative h-[224px] bg-cover bg-no-repeat rounded-[16px]'
                        >
                          {' '}
                          <div className='absolute top-[8px] left-[8px]'>
                            <Exclusive_Icon16 className='fill-[#333333]' />
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundImage: `url(${item?.adminArtProductMaster?.images[0]?.image})`,
                          }}
                          className='w-[224px] relative h-[224px] bg-cover bg-no-repeat rounded-[16px]'
                        >
                          {' '}
                          <div className='absolute top-[8px] left-[8px]'>
                            <Exclusive_Icon16 className='fill-[#333333]' />
                          </div>
                        </div>
                      )}

                      <div className='w-[328px]'>
                        {item.artMaster !== null ? (
                          <p
                            onClick={() =>
                              navigate(
                                `/art/art-details/${item?.artMaster?.artId}`
                              )
                            }
                            className='text-[#333333] w-[264px] text-[16px] font-medium cursor-pointer leading-[20px] '
                          >
                            {item.artMaster?.artName.length > 50 ? (
                              <>
                                {item.artMaster?.artName.slice(0, 50)}
                                ...
                              </>
                            ) : (
                              item.artMaster?.artName
                            )}

                            {/* {item.artMaster?.artName} */}
                          </p>
                        ) : (
                          <p className='text-[#333333] w-[264px] text-[16px] font-medium leading-[20px] '>
                            {
                              item.adminArtProductMaster
                                ?.adminArtProductName
                            }
                          </p>
                        )}

                        <p className='text-sm11 text-primaryGray mt-[12px]'>
                          by{' '}
                          <span className='text-orangeColor font-medium'>
                            {item?.userMaster?.displayName}
                          </span>
                        </p>

                        {/* test */}

                        <div className='border-y-2 border-[#EFEFEF] mt-[4px] leading-[1.3]'>
                          <div className='w-[100%]'>
                            <div className='text-primaryGray text-sm12 border-b border-[#EFEFEF] flex items-center h-[17px]'>
                              {/* <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                              Combo ID:
                            </p> */}

                              {item.artMaster !== null ? (
                                <>
                                  <p className='text-primaryGray text-sm12 font-medium w-[68px]'>
                                    Art ID:{' '}
                                  </p>
                                  <p className='text-primaryGray text-sm12  font-normal'>
                                    {item.artMaster?.arProductNo}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className='text-primaryGray text-sm12 font-medium w-[68px]'>
                                    Combo ID:{' '}
                                  </p>
                                  <span className='text-primaryGray  h-[16px] text-sm12  font-normal'>
                                    {
                                      item?.adminArtProductMaster
                                        ?.artProductUniqueNo
                                    }
                                  </span>
                                </>
                              )}
                            </div>
                            <div className=' flex items-center'>
                              <p className='text-primaryGray text-sm12 font-medium  w-[68px]'>
                                Availability:
                              </p>
                              <p className='text-lightGreen h-[16px] text-sm12 leading-[1] font-normal gap-[4px]  flex items-center'>
                                <img
                                  src={Checkmark}
                                  className='inline'
                                  alt=''
                                />
                                <span>
                                  {' '}
                                  {/* <IconTickmark /> */} In Stock{' '}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className='w-[312px] '>
                          <p className='mt-[12px] text-sm12 font-medium text-primaryBlack'>
                            Description:
                          </p>

                          {item.artMaster !== null ? (
                            <p className='text-sm12 text-primaryGray '>
                              {item.artMaster?.description?.slice(
                                0,
                                120
                              )}
                              ...
                            </p>
                          ) : (
                            <p className='text-sm12 text-primaryGray  '>
                              {item.adminArtProductMaster?.productMaster?.description?.slice(
                                0,
                                120
                              )}
                              ...
                            </p>
                          )}
                        </div>

                        <div className='flex gap-[16px] mt-[12px]'>
                          <div className='flex items-baseline '>
                            {item.artMaster !== null ? (
                              <>
                                <p className='text-pinkColor text24 font-medium'>
                                  $
                                </p>
                                <p className='text-pinkColor text24 '>
                                  {item.artMaster?.price}
                                </p>
                              </>
                            ) : (
                              <>
                                <p className='text-pinkColor text24 font-medium'>
                                  $
                                </p>
                                <p className='text-pinkColor text24 '>
                                  {
                                    item.adminArtProductMaster
                                      ?.sizeAndPrices[0].sellPrice
                                  }
                                </p>
                              </>
                            )}
                          </div>

                          <div className='flex gap-[4px] pt-[2px]'>
                            <div>
                              <Exclusive_Icon16 className='fill-[#b5a56d]' />
                            </div>
                            <div className='flex flex-col font-medium'>
                              <p className='text-[9px] text-[#b5a56d] leading-[1]'>
                                ARTNSTOCK
                              </p>
                              <p className='text-[9px] text-[#b5a56d] leading-[1]'>
                                EXCLUSIVE
                              </p>
                            </div>
                          </div>
                        </div>

                        {/* <p className='text-pinkColor flex items-baseline leading-[1]  mt-[12px]'>
                          {item.artMaster !== null ? (
                            <>
                              <p>$</p>
                              <p className='text-[24px]'>
                                {item.artMaster?.price}
                              </p>
                            </>
                          ) : (
                            <>
                              <p>$</p>
                              <p className='text-[24px]'>
                                {
                                  item.adminArtProductMaster
                                    ?.sizeAndPrices[0].sellPrice
                                }
                              </p>
                            </>
                            
                          )}
                        </p> */}

                        <button
                          onClick={() => {
                            // navigate('/')
                          }}
                          className='gray30HButton mt-[12px]'
                        >
                          Shop Now
                        </button>
                        <div className='mb-[24px] mt-[8px]'>
                          <span
                            style={{
                              width: 'fit-content',
                            }}
                            onClick={() => {
                              setPopup(true);
                              setdeletewishid(item?.wishListId);
                            }}
                            className='flex text-primaryGray hover:text-orangeColor text-sm11 cursor-pointer'
                          >
                            Remove from Wishlist
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </main>
          </div>

          {/* Rollover */}
        </div>
      ) : (
        <div className='wrapper text-center'>
          <main>
            <div className='mt-[32px]'>
              <NoItemsText
                pinkText='You have no items in your wishlist.'
                grayText="To add items to your wishlist, simply click the 'Add to wishlist' Icon from any product page."
                button='true'
                link=''
                buttonText='Start Here'
              />
              {/* <p className='text-[#ff369f] text-[16px]'>
                You have no items in your wishlist.
              </p>
              <p className='mt-[10px] mb-[20px] text-[12px] text-[#757575]'>
                To add items to your wishlist, simply click the 'Add
                to wishlist' link from any product page.
              </p>
              <button
                onClick={() => {
                  navigate('/art-list');
                }}
                className='blackBtn text-[14px]'
              >
                Start Here
              </button> */}
            </div>
          </main>
        </div>
      )}

      <Certificate />
      <Footer />
    </>
  );
};

export default Wishlist2;
